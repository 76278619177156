import type { ISimulateState } from '../../pages/msd/shared/components/Simulation/Simulation';
import type { IPayload } from '../moduleData/types';

export const RESULTS_UPDATE = 'RESULTS_UPDATE';
export const RESULTS_RESET = 'RESULTS_RESET';

export interface IResult<T extends ISimulateState | IPayload[]> {
  deps: string[];
  depsHash: { [key: string]: { oldHash: string; newHash: string } };
  hash: string;
  data: T;
}

export interface IResults {
  msd: {
    payloadModePage: IResult<IPayload[]>;
    missionGeometryPage: IResult<ISimulateState>;
    groundSegmentPage: IResult<ISimulateState>;
    platformPage: IResult<ISimulateState>;
    finalPage: IResult<ISimulateState>;
  };
}

interface IResultsUprateAction {
  type: typeof RESULTS_UPDATE;
  results: IResults;
}

interface IResultsResetAction {
  type: typeof RESULTS_RESET;
}

export type IResultsActionTypes = IResultsUprateAction | IResultsResetAction;
