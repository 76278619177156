import type { Telemetry } from 'api/telemetry/types';
import classNames from 'classnames';
import { Group, Icon, TextField } from 'opencosmos-ui';

import s from './index.module.scss';

interface IProps {
  telemetries: Telemetry[];
  handleFilterChange: (newFilter: string) => void;
}

const TelemetryFilter = (props: IProps) => {
  return (
    <Group className={'flex items-center'}>
      <Icon icon="Filter" />
      <TextField
        className={classNames(s.telemetryFilter, 'h-4')}
        onChange={(e) => props.handleFilterChange(e)}
        inputProps={{
          placeholder: 'Filter telemetries...',
          className: 'h-4 p-1 text-item-dark',
        }}
      />
    </Group>
  );
};

export default TelemetryFilter;
