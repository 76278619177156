import type { ISelectedPayloads } from '../../../../../constants/moduleData/types';
import rawPayloadResponse from './satellitePayloads.json';
import rawBatteriesResponse from './satelliteBatteries.json';
import rawEpsResponse from './satelliteEps.json';
import rawCommsResponse from './satelliteComms.json';
import rawAdcsResponse from './satelliteAdcs.json';
import rawObdhResponse from './satelliteObdh.json';
import {
  PAYLOAD_DATA,
  EPS_DATA,
  COMMS_DATA,
  AOCS_DATA,
  POWER_DATA,
  OBDH_DATA,
} from '../../../../../constants/moduleData/constants';

const transformPayloadData = (
  rawData: any,
  type: string
): ISelectedPayloads => {
  const modules: ISelectedPayloads = {};

  rawData[type].map((payload: any) => (modules[payload.id] = payload));

  return modules;
};

interface IModuleDataState {
  [PAYLOAD_DATA]: ISelectedPayloads;
  [POWER_DATA]: ISelectedPayloads;
  [EPS_DATA]: ISelectedPayloads;
  [COMMS_DATA]: ISelectedPayloads;
  [AOCS_DATA]: ISelectedPayloads;
  [OBDH_DATA]: ISelectedPayloads;
}

const initialState: IModuleDataState = {
  [PAYLOAD_DATA]: transformPayloadData(rawPayloadResponse, 'payloads'),
  [POWER_DATA]: transformPayloadData(rawBatteriesResponse, 'batteries'),
  [EPS_DATA]: transformPayloadData(rawEpsResponse, 'eps'),
  [COMMS_DATA]: transformPayloadData(rawCommsResponse, 'comms'),
  [AOCS_DATA]: transformPayloadData(rawAdcsResponse, 'adcs'),
  [OBDH_DATA]: transformPayloadData(rawObdhResponse, 'obdh'),
};

const moduleSelectorReducer = (
  state: IModuleDataState = initialState,
  action: any
): IModuleDataState => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default moduleSelectorReducer;
