import React from 'react';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import AdministrationUsers from './User/components/AdministrationUsers/Users';
import useAllUsers from '../../../services/api/portal/administration/hook/useAllUsers';
import AdministrationLayout from '../shared/components/AdministrationLayout/AdministrationLayout';

import s from './index.module.scss';

const UserAdministration = () => {
  const {
    users,
    availableRoles,
    assignRole,
    deleteRoleAssignments,
    page,
    setPage,
    setUserSearchQuery,
    userSearchQuery,
    isFetching,
  } = useAllUsers();

  const previousPageHandler = () => setPage(page - 1);
  const nextPageHandler = () => setPage(page + 1);

  return (
    <AdministrationLayout breadcrumbs={[{ text: 'Users' }]} graphic="family">
      <AdministrationUsers
        setUserSearchQuery={setUserSearchQuery}
        userSearchQuery={userSearchQuery}
        users={users}
        assignRole={assignRole}
        deleteRoleAssignments={deleteRoleAssignments}
        availableRoles={availableRoles}
        isFetching={isFetching}
      />
      <div className={s.paginator}>
        <Button
          icon={IconNames.CHEVRON_LEFT}
          onClick={previousPageHandler}
          disabled={page === 0}
        />
        <span className={s.paginationNumber}>Page {page + 1}</span>
        <Button icon={IconNames.CHEVRON_RIGHT} onClick={nextPageHandler} />
      </div>
    </AdministrationLayout>
  );
};

export default UserAdministration;
