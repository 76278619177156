import { Dialog, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { toaster } from 'toaster';
import { useRef, useState } from 'react';
import s from 'datacosmos/components/DeleteModal.module.scss';
import type { Scenario } from 'api/scenarios/types';
import { putScenario } from 'api/scenarios/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { LANGUAGE } from 'env';

type DeleteModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  scenario?: Scenario;
  setUserScenarios: () => Promise<void>;
};

export const DeleteModal = ({
  isOpen,
  setIsOpen,
  scenario,
  setUserScenarios,
}: DeleteModalProps) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  const [isDelButtonDisabled, setIsDelButtonDisabled] = useState<boolean>(true);

  const { translate } = useLocalisation();

  const deleteText = LANGUAGE === 'en' ? 'delete' : 'eliminar';

  const handleInput = (text: string): void => {
    setIsDelButtonDisabled(text !== deleteText);
  };

  const handleOnOpen = (): void => {
    cancelButtonRef.current?.focus();
  };

  const handleDelete = async () => {
    if (!scenario) return;

    const { success } = await putScenario({
      params: { scenarioId: scenario.id },
      body: {
        name: scenario.title,
        description: scenario.description,
        is_archived: true,
      },
    });

    if (!success) return;

    setIsOpen(false);

    try {
      await setUserScenarios();
    } catch (error) {
      toaster.show({
        message: translate(
          'datacosmos.deleteProjectDialog.errors.deletionFailed'
        ),
        intent: 'warning',
        icon: IconNames.ERROR,
      });
      return;
    }

    toaster.show({
      message: translate('datacosmos.deleteProjectDialog.success'),
      intent: 'success',
      icon: IconNames.INFO_SIGN,
    });
  };

  return (
    <Dialog
      className={
        Classes.DIALOG_CONTAINER +
        ' dark:bg-surface-dark dark:text-item-dark-contrast bg-surface'
      }
      isOpen={isOpen}
      canOutsideClickClose={true}
      canEscapeKeyClose={true}
      onClose={() => setIsOpen(false)}
      usePortal={true}
      onOpened={() => handleOnOpen()}
    >
      <div
        className={
          s.overlayContentWrapper +
          ' dark:bg-surface-dark dark:text-item-dark-contrast bg-surface'
        }
      >
        <div className="flex flex-col gap-4">
          <div>
            <h1 className={s.overlayTitle}>
              {translate('datacosmos.deleteProjectDialog.warning')}
            </h1>
            <p className="font-semibold">
              {translate('datacosmos.deleteProjectDialog.areYouSure', {
                project: scenario?.title,
              })}
            </p>
          </div>

          <div className={s.overlayBody + ' gap-2'}>
            <p>{translate('datacosmos.deleteProjectDialog.longWarning')}</p>
            <input
              type="text"
              style={{ width: '100%' }}
              placeholder={translate(
                'datacosmos.deleteProjectDialog.placeholder'
              )}
              onChange={(e) => handleInput(e.target.value)}
              className="dark:bg-item-dark dark:text-item-dark-contrast h-8 border-2 border-item-contrast-text-field-stroke"
            />
          </div>

          <div className={s.overlayFooter}>
            <button onClick={() => setIsOpen(false)} ref={cancelButtonRef}>
              {translate('datacosmos.buttons.cancel')}
            </button>
            <button
              className={s.dangerButton}
              onClick={async () => {
                await handleDelete();
              }}
              disabled={isDelButtonDisabled}
            >
              {translate('datacosmos.buttons.confirm')}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
