import React from 'react';
import { Button } from 'opencosmos-ui';
import { ShownCards } from 'pages/ops/Library/components/LibraryEncoding/types';

interface IProps {
  left: React.ReactNode;
  right: React.ReactNode;
  leftWidthFr: number;
  rightWidthFr: number;
  sectionGap: string;
  setShownCards: React.Dispatch<React.SetStateAction<ShownCards>>;
  middleElement?: React.ReactNode;
  backButton?: true;
}

const TwoColumnLayout = (props: IProps) => {
  let innerStyle: any = {
    display: 'grid',
    gap: props.sectionGap,
    height: '100%',
    position: 'relative',
  };

  props.middleElement
    ? (innerStyle = {
        ...innerStyle,
        gridTemplateColumns: `${props.leftWidthFr}fr 0.5fr ${props.rightWidthFr}fr`,
      })
    : (innerStyle = {
        ...innerStyle,
        gridTemplateColumns: `${props.leftWidthFr}fr ${props.rightWidthFr}fr`,
      });
  return (
    <div className="w-[70%] h-[70vh] relative">
      {props.backButton && (
        <Button
          style={{
            position: 'absolute',
            left: -95,
            fontSize: '18px',
            display: 'flex',
            height: '95px',
            borderRadius: '10%',
            width: '100px',
          }}
          isMinimal={true}
          onPress={() => props.setShownCards(ShownCards.HOME_CARDS)}
          icon={'ArrowLeft'}
          className="bg-item dark:bg-item-dark dark:text-item-dark-contrast "
        >
          <span className="text-sm">Back</span>
        </Button>
      )}
      <div style={innerStyle}>
        <div className="bg-item dark:bg-item-dark max-h-[650px] px-2 overflow-y-auto">
          {props.left}
        </div>
        {props.middleElement && <div>{props.middleElement}</div>}
        <div className="bg-item dark:bg-item-dark max-h-[650px] px-2">
          {props.right}
        </div>
      </div>
    </div>
  );
};

export default TwoColumnLayout;
