import classNames from 'classnames';
import { Icon } from 'opencosmos-ui';
import React from 'react';
import { preventDefaultPropagation } from '../../utils/common/CommonUtils';
import useDragAndDropSorting from '../../utils/hooks/useDragAndDropSorting';
import s from './index.module.scss';

interface IProps {
  title: string;
  item: any;
  index: number;
  onTagClick: (item: any) => void;
  handleTagHeaderRightItemClick?: (index: number, item: any) => void;
  moveTagItem?: (dragIndex: number, hoverIndex: number) => void;
  tagHeaderRight?: string | React.ReactNode;
}

const DndListTag = (props: IProps) => {
  const { opacity, dndSortingRef } = useDragAndDropSorting(
    props.index,
    props.moveTagItem
  );

  return (
    <div ref={dndSortingRef}>
      <div
        style={{ opacity }}
        className={classNames(
          s.titleTagPointer,
          'w-full bg-item dark:bg-item-dark dark:text-item-dark-contrast p-1'
        )}
        onClick={() => props.onTagClick(props.index)}
      >
        <div className={classNames(s.tagListItem, 'flex items-center')}>
          <Icon icon="drag-handle-vertical" />
          <div className={classNames(s.tagTitle, 'flex items-center')}>
            <span>{props.title}</span>
            <span
              className="flex items-center justify-center"
              role="button"
              tabIndex={0}
              onKeyDown={preventDefaultPropagation(
                props.handleTagHeaderRightItemClick
              )(props.index, props.item)}
              onClick={preventDefaultPropagation(
                props.handleTagHeaderRightItemClick
              )(props.index, props.item)}
            >
              {props.tagHeaderRight}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DndListTag;
