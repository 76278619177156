import classNames from "classnames";
import { IconName as BpIconName, Icon as BpIcon } from "@blueprintjs/core";

import Angle from "./svg/Angle.svg?raw";
import AoiAdd from "./svg/AoiAdd.svg?raw";
import AoICustom from "./svg/AoICustom.svg?raw";
import Apps from "./svg/Apps.svg?raw";
import Area from "./svg/Area.svg?raw";
import ArrowDown from "./svg/ArrowDown.svg?raw";
import ArrowLeft from "./svg/ArrowLeft.svg?raw";
import ArrowRight from "./svg/ArrowRight.svg?raw";
import ArrowUp from "./svg/ArrowUp.svg?raw";
import BandAlgebra from "./svg/BandAlgebra.svg?raw";
import Bell from "./svg/Bell.svg?raw";
import BellCrossed from "./svg/BellCrossed.svg?raw";
import Calculate from "./svg/Calculate.svg?raw";
import Calculator from "./svg/Calculator.svg?raw";
import CalculatorStrikethrough from "./svg/CalculatorStrikethrough.svg?raw";
import Calendar from "./svg/Calendar.svg?raw";
import Cash from "./svg/Cash.svg?raw";
import Catalog from "./svg/Catalog.svg?raw";
import Center from "./svg/Center.svg?raw";
import CenterWorld from "./svg/CenterWorld.svg?raw";
import ChevronDown from "./svg/ChevronDown.svg?raw";
import ChevronLeft from "./svg/ChevronLeft.svg?raw";
import ChevronLeftDouble from "./svg/ChevronLeftDouble.svg?raw";
import ChevronRight from "./svg/ChevronRight.svg?raw";
import ChevronRightDouble from "./svg/ChevronRightDouble.svg?raw";
import ChevronUp from "./svg/ChevronUp.svg?raw";
import Circle from "./svg/Circle.svg?raw";
import Clipboard from "./svg/Clipboard.svg?raw";
import Clock from "./svg/Clock.svg?raw";
import Cloud from "./svg/12px/Cloud.svg?raw";
import Cog from "./svg/Cog.svg?raw";
import Copy from "./svg/Copy.svg?raw";
import Coverage from "./svg/Coverage.svg?raw";
import Cross from "./svg/Cross.svg?raw";
import DollarSign from "./svg/DollarSign.svg?raw";
import Download from "./svg/Download.svg?raw";
import Emoji from "./svg/Emoji.svg?raw";
import Eye from "./svg/Eye.svg?raw";
import EyeCrossed from "./svg/EyeCrossed.svg?raw";
import File from "./svg/12px/File.svg?raw";
import FilePDF from "./svg/FilePDF.svg?raw";
import FileTxt from "./svg/12px/FileTxt.svg?raw";
import Filter from "./svg/Filter.svg?raw";
import FilterOff from "./svg/FilterOff.svg?raw";
import FilterRevert from "./svg/FilterRevert.svg?raw";
import Folder from "./svg/Folder.svg?raw";
import FolderMinus from "./svg/FolderMinus.svg?raw";
import FolderPlus from "./svg/FolderPlus.svg?raw";
import FontBold from "./svg/FontBold.svg?raw";
import FontColor from "./svg/FontColor.svg?raw";
import FontItalic from "./svg/FontItalic.svg?raw";
import FontStrike from "./svg/FontStrike.svg?raw";
import Glint from "./svg/Glint.svg?raw";
import Grid from "./svg/Grid.svg?raw";
import Hamburger from "./svg/Hamburger.svg?raw";
import Help from "./svg/Help.svg?raw";
import Image from "./svg/Image.svg?raw";
import ImageBrightness from "./svg/ImageBrightness.svg?raw";
import ImageContrast from "./svg/ImageContrast.svg?raw";
import ImageSaturation from "./svg/ImageSaturation.svg?raw";
import Info from "./svg/Info.svg?raw";
import InstallCrossed from "./svg/InstallCrossed.svg?raw";
import Layers from "./svg/Layers.svg?raw";
import List from "./svg/List.svg?raw";
import MagnifyingGlass from "./svg/MagnifyingGlass.svg?raw";
import Map from "./svg/Map.svg?raw";
import Menu from "./svg/Menu.svg?raw";
import Minus from "./svg/Minus.svg?raw";
import Moon from "./svg/Moon.svg?raw";
import Notebook from "./svg/Notebook.svg?raw";
import Overlay from "./svg/Overlay.svg?raw";
import Panoramic from "./svg/Panoramic.svg?raw";
import Pencil from "./svg/Pencil.svg?raw";
import Picker from "./svg/Picker.svg?raw";
import Pin from "./svg/12px/Pin.svg?raw";
import Plus from "./svg/Plus.svg?raw";
import PlusSquare from "./svg/PlusSquare.svg?raw";
import Polygon from "./svg/Polygon.svg?raw";
import Print from "./svg/Print.svg?raw";
import RoundSquare from "./svg/RoundSquare.svg?raw";
import Ruler from "./svg/Ruler.svg?raw";
import Satellite from "./svg/12px/Satellite.svg?raw";
import SatelliteRollAngle from "./svg/SatelliteRollAngle.svg?raw";
import SatelliteTasking from "./svg/SatelliteTasking.svg?raw";
import Save from "./svg/Save.svg?raw";
import SensorType from "./svg/SensorType.svg?raw";
import Share from "./svg/Share.svg?raw";
import ShoppingCart from "./svg/ShoppingCart.svg?raw";
import Square from "./svg/Square.svg?raw";
import Star from "./svg/Star.svg?raw";
import Sun from "./svg/Sun.svg?raw";
import SunAngle from "./svg/SunAngle.svg?raw";
import Sunrise from "./svg/Sunrise.svg?raw";
import Sunset from "./svg/Sunset.svg?raw";
import SwathMultiple from "./svg/SwathMultiple.svg?raw";
import SwathSingle from "./svg/SwathSingle.svg?raw";
import Tag from "./svg/Tag.svg?raw";
import TagAdd from "./svg/TagAdd.svg?raw";
import ThreePointsHorizontal from "./svg/ThreePointsHorizontal.svg?raw";
import ThreePointsVertical from "./svg/ThreePointsVertical.svg?raw";
import Trash from "./svg/Trash.svg?raw";
import Underline from "./svg/Underline.svg?raw";
import Upload from "./svg/Upload.svg?raw";
import User from "./svg/User.svg?raw";
import UserGroup from "./svg/UserGroup.svg?raw";
import World from "./svg/World.svg?raw";

export const IconNames = {
  Angle,
  AoiAdd,
  AoICustom,
  Apps,
  Area,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BandAlgebra,
  Bell,
  BellCrossed,
  Calculate,
  Calculator,
  CalculatorStrikethrough,
  Calendar,
  Cash,
  Catalog,
  Center,
  CenterWorld,
  ChevronDown,
  ChevronLeft,
  ChevronLeftDouble,
  ChevronRight,
  ChevronRightDouble,
  ChevronUp,
  Circle,
  Clipboard,
  Clock,
  Cloud,
  Cog,
  Copy,
  Coverage,
  Cross,
  DollarSign,
  Download,
  Emoji,
  Eye,
  EyeCrossed,
  File,
  FilePDF,
  FileTxt,
  Filter,
  FilterOff,
  FilterRevert,
  Folder,
  FolderMinus,
  FolderPlus,
  FontBold,
  FontColor,
  FontItalic,
  FontStrike,
  Glint,
  Grid,
  Hamburger,
  Help,
  Image,
  ImageBrightness,
  ImageContrast,
  ImageSaturation,
  Info,
  InstallCrossed,
  Layers,
  List,
  MagnifyingGlass,
  Map,
  Menu,
  Minus,
  Moon,
  Notebook,
  Overlay,
  Panoramic,
  Pencil,
  Picker,
  Pin,
  Plus,
  PlusSquare,
  Polygon,
  Print,
  RoundSquare,
  Ruler,
  Satellite,
  SatelliteRollAngle,
  SatelliteTasking,
  Save,
  SensorType,
  Share,
  ShoppingCart,
  Square,
  Star,
  Sun,
  SunAngle,
  Sunrise,
  Sunset,
  SwathMultiple,
  SwathSingle,
  Tag,
  TagAdd,
  ThreePointsHorizontal,
  ThreePointsVertical,
  Trash,
  Underline,
  Upload,
  User,
  UserGroup,
  World,
};

export type IconName = keyof typeof IconNames | BpIconName;

export type IconSize = 8 | 12 | 16 | 18 | 24 | 32 | 64;

const defaultSize: IconSize = 16;

export type IconProps = {
  /**
   * The name of the icon to render.
   */
  icon: IconName;
  /**
   * The size of the icon.
   */
  size?: IconSize;
  className?: string;
  /**
   * The color of the icon.
   */
  stroke?: string;
  inherit?: boolean;
  title?: string;
  fill?: string;
  "data-testid"?: string;
};

export const Icon = ({ inherit = true, ...props }: IconProps) => {
  const size = props.size ?? defaultSize;
  const style = {
    minWidth: size,
    width: size,
    maxWidth: size,
    minHeight: size,
    height: size,
    maxHeight: size,
  };
  const svgData = IconNames[props.icon as keyof typeof IconNames];

  // TODO: Remove this once we have all necessary custom icons
  if (!svgData) {
    return (
      <BpIcon
        icon={props.icon}
        size={size}
        style={style}
        color={props.stroke}
        data-testid={props["data-testid"]}
      />
    );
  }

  return (
    <div
      style={{ ...style, fill: props.fill }}
      className={`icon ${props.className}`}
      aria-label={props.title}
      dangerouslySetInnerHTML={{ __html: svgData }}
      data-testid={props["data-testid"]}
    />
  );
};

export default Icon;
