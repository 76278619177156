import type {
  IRole,
  IRoleAttachment,
} from 'services/api/portal/administration/api/types';
import AddRoleAttachment from './AddRoleAttachment';
import type { PermissionType } from 'api/administration/permissions';
import DangerButton from '_molecules/Button/DangerButton';
import Modal from '_atoms/Modal/Modal';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useState } from 'react';
import { OverlayContainer } from 'react-aria';
import SecondaryButton from '_molecules/Button/SecondaryButton';

interface IProps {
  attachments: IRoleAttachment[];
  getAvailableRolesByType: (type: PermissionType) => IRole[];
  setResourceId: (resourceId: string | number) => void;
  setSelectedPermission: (permission: PermissionType) => void;
  selectedPermission?: PermissionType;
  resourceId: string | number;
  assignRoleAttachment: (roleId: number) => Promise<void>;
  deleteRoleAttachment: (role: IRoleAttachment) => Promise<void>;
}

const RoleAttachments = (props: IProps) => {
  const [openAddAttachment, setOpenAddAttachment] = useState<boolean>(false);
  const roleAttachments = props.attachments;

  const { translate } = useLocalisation();
  const getRoleName = (roleID: number, resourceType: PermissionType) => {
    const availableRoles = props.getAvailableRolesByType(resourceType);
    const roleName = availableRoles.find(
      (resource) => resource.id === roleID
    )?.name;
    return roleName;
  };
  return (
    <div>
      <div className="flex justify-end">
        <SecondaryButton
          text={'Add role attachments'}
          icon="Plus"
          onPress={() => {
            setOpenAddAttachment(true);
          }}
        />
      </div>
      {roleAttachments?.length > 0 ? (
        roleAttachments.map((attachment) => (
          <div key={attachment.id} className="mt-2">
            <div className="flex justify-between items-center border-2 border-neutral-200 mb-1 px-2 bg-surface/50 dark:bg-surface-dark/50 rounded">
              <div>
                <div className="text-xs text-neutral-600 font-bold">
                  {attachment.childResourceType
                    .toUpperCase()
                    .replace(/_/g, ' ')}
                </div>
                <div className="font-bold">{attachment.childResourceId}</div>
              </div>
              <div className="bg-[#5f6b7c] text-neutral-50 p-1 text-xs rounded">
                {getRoleName(
                  attachment.childRoleId,
                  attachment.childResourceType as PermissionType
                )}
              </div>
              <DangerButton
                icon="Trash"
                onPress={() => {
                  void props.deleteRoleAttachment(attachment);
                }}
                text={'Delete'}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="font-bold text-center">{'No attachments added'}</div>
      )}

      <OverlayContainer>
        {openAddAttachment && (
          <Modal
            isOpen={openAddAttachment}
            onClose={() => {
              setOpenAddAttachment(false);
              props.setResourceId('');
            }}
            className="w-2/5 h-max"
            title={translate('rolesPage.addAttachment')}
          >
            <AddRoleAttachment
              getAvailableRolesByType={props.getAvailableRolesByType}
              setResourceId={props.setResourceId}
              setSelectedPermission={props.setSelectedPermission}
              selectedPermission={props.selectedPermission}
              resourceId={props.resourceId}
              assignRole={async (roleId) => {
                await props.assignRoleAttachment(roleId);
                setOpenAddAttachment(false);
              }}
            />
          </Modal>
        )}
      </OverlayContainer>
    </div>
  );
};

export default RoleAttachments;
