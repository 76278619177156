import { DATETIME_FORMAT } from 'constants/datetime';
import moment from 'moment';
import type { TaskingRequest } from 'api/tasking/types';
import {
  CompositeDetailsItem,
  DetailsItem,
} from 'pages/ops/shared/components/DetailsItem';
import UserInfo from '_organisms/UserInfo/UserInfo';
import { useQuery } from 'api/useQuery';
import { getUser } from 'api/users/service';
import Textarea from '_atoms/Textarea/Textarea';
import { Button } from 'opencosmos-ui';
import { useState } from 'react';
import { CancelRequest } from '_organisms/CancelRequestPopup/CancelRequest';

type RequestDetailsCardProps = {
  request: TaskingRequest | undefined;
  totalActivities: number;
  refetchData: () => Promise<void>;
};

export const RequestDetailsCard = ({
  request,
  totalActivities,
  refetchData,
}: RequestDetailsCardProps) => {
  const [isCancelRequestOpen, setIsCancelRequestOpen] =
    useState<boolean>(false);
  const getUserQuery = useQuery(getUser, {
    initialData: undefined,
    params: request ? { subject: request.created_by } : undefined,
  });

  if (!request) {
    return <div>NO REQUEST</div>;
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-between">
        <h2>Request</h2>
        <Button
          className="w-32 h-5"
          intent={'warning'}
          onPress={() => {
            setIsCancelRequestOpen(true);
          }}
          isDisabled={request.status === 'CANCELLED'}
        >
          <span className="text-sm">Cancel</span>
        </Button>
      </div>

      <div className="flex flex-col justify-between gap-3">
        <DetailsItem title="ID: " value={request.id} />
        <DetailsItem title="Project ID: " value={request.project_id} />
        <DetailsItem title="Project Name: " value={request.project_name} />
        <DetailsItem
          title="Organisation Name: "
          value={request.organisation_name}
        />

        <DetailsItem
          title="Created At: "
          value={moment.utc(request.created_at).format(DATETIME_FORMAT)}
        />
        <DetailsItem
          title="Updated At: "
          value={moment.utc(request.updated_at).format(DATETIME_FORMAT)}
        />
        <DetailsItem title="Type: " value={request.type} />
        <DetailsItem title="Status: " value={request.status} />
        <DetailsItem
          title="Number of Activities: "
          value={totalActivities.toString()}
        />
        <DetailsItem title="Region Name: " value={request.region_name} />
        <CompositeDetailsItem
          title="Customer: "
          element={
            <UserInfo
              user={getUserQuery.data}
              loading={getUserQuery.loading}
              fieldsToShow={['email']}
            />
          }
        />
        <CompositeDetailsItem
          title="Notes: "
          element={<Textarea disabled value={request.notes} />}
        />
      </div>
      <CancelRequest
        isOpen={isCancelRequestOpen}
        setIsOpen={setIsCancelRequestOpen}
        request={request}
        refetchData={refetchData}
      />
    </div>
  );
};
