import React, { useEffect, useState } from 'react';

import AlertConfirm from 'components/common/AlertConfirm';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import { Button } from 'opencosmos-ui';

interface IProps {
  onClick: () => void;
  text?: string;
  disabled: boolean;
  icon: IconName;
  alertMessage: string;
  className?: string;
}

const WarnAlertButton = (props: IProps) => {
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [isDebouncedButtonClick, setDebouncedButtonClick] = useState(false);
  let timer: NodeJS.Timeout | null = null;

  const handleDebouncedDirectSend = () => {
    if (isDebouncedButtonClick) {
      setOpenAlert(true);
      return;
    }

    setDebouncedButtonClick(true);
    props.onClick();
    timer = setTimeout(() => setDebouncedButtonClick(false), 1000);
  };

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <AlertConfirm
        onConfirm={props.onClick}
        isOpen={isOpenAlert}
        setIsOpen={setOpenAlert}
        message={props.alertMessage}
      />
      <Button
        icon={props.icon}
        onPress={handleDebouncedDirectSend}
        isDisabled={props.disabled}
        className={props.className}
        intent={'warning'}
      >
        <span className="whitespace-nowrap text-sm">{props.text}</span>
      </Button>
    </>
  );
};

export default WarnAlertButton;
