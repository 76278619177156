import { useEffect, useState } from 'react';
import { find, isEqual, isUndefined, remove, filter } from 'lodash/fp';
import {
  Button,
  Collapse,
  Icon,
  NonIdealState,
  Tag,
  UL,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import MissionList from 'components/OrganisationConfiguration//MissionList';
import CreateButton from 'components/OrganisationConfiguration//CreateButton';
import type { Programme } from 'api/administration/service';
import OrganisationEditableText from 'components/OrganisationConfiguration//OrganisationEditableText';
import { preventDefaultPropagation } from 'utils/common/CommonUtils';
import type { IMission } from 'services/Missions';
import s from 'components/OrganisationConfiguration//index.module.scss';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import classNames from 'classnames';

interface IProps {
  chosenOrg: number | undefined;
  programmes: Programme[];
  chosenProgramme: Programme | undefined;
  missions: IMission[];
  saveProgramme: (name: string, id: number) => void;
  saveMission: (name: string, id: number) => void;
  autoOpenProgramme: boolean;
  onMissionClick?: () => void;
}

const ProgrammeList = (props: IProps) => {
  const [openProgrammeList, setOpenProgrammeList] = useState<number[]>([]);
  const [showNewProgramme, setShowNewProgramme] = useState(false);
  const [showNewMissionProgrammeId, setShowNewMissionProgrammeId] =
    useState<number>();

  const { hasPermission: canCreatePrograme } = useCheckPermissions({
    permissions: props.programmes.map((prog) => ({
      actionScope: 'programme:create',
      type: 'programme',
      id: prog.id,
    })) as ICheckPermissions[],
  });

  const { hasPermission: isAllowedToCreateProgramme } = useCheckPermissions({
    permissions: {
      type: 'organisation',
      actionScope: 'programme:create',
      id: props.chosenOrg,
    },
  });

  useEffect(() => {
    if (props.chosenProgramme) {
      setOpenProgrammeList([props.chosenProgramme.id]);
    }
  }, [props.chosenProgramme]);

  useEffect(() => {
    setShowNewProgramme(false);
  }, [props.chosenOrg]);

  const handleOnTagClick = (id: number) => {
    const openId = find(isEqual(id), openProgrammeList);

    if (!isUndefined(openId)) {
      const list = remove(isEqual(openId), openProgrammeList);
      setOpenProgrammeList([...list]);
      return;
    }

    setOpenProgrammeList([...openProgrammeList, id]);
  };

  const handleNewMissionClick = (programmeId: number) => {
    setShowNewMissionProgrammeId(programmeId);

    const openId = find(isEqual(programmeId), openProgrammeList);
    !openId && handleOnTagClick(programmeId);
  };

  if (!props.chosenOrg) {
    return (
      <NonIdealState
        className={s.programmeWrapperNonIdealState}
        icon={IconNames.SELECT}
        title="Choose Organisation"
      />
    );
  }

  return (
    <div
      className={classNames(
        s.programmeContainer,
        'dark:bg-surface-dark dark:text-item-dark-contrast'
      )}
    >
      <UL className={s.programmeListContainer}>
        {showNewProgramme && (
          <Tag
            fill
            large
            className={[s.programmeTitle, s.editableTagProgramme].join(' ')}
          >
            <OrganisationEditableText
              text="New Programme"
              showEditableText={showNewProgramme}
              hideEditableText={() => setShowNewProgramme(false)}
              handleSaveConfirm={(name: string) =>
                props.saveProgramme(name, props.chosenOrg)
              }
            />
          </Tag>
        )}
        {props.programmes.map((item, i) => (
          <li key={item.id}>
            <Tag
              fill
              large
              className={s.programmeTitle}
              onClick={() => handleOnTagClick(item.id)}
            >
              <div className={s.programmeTitle_container}>
                <span className={s.programmeTitle_name}>{item.name}</span>
                <div className={s.programmeTitle_buttons}>
                  <Button
                    small
                    minimal
                    disabled={canCreatePrograme[i]}
                    text="Mission"
                    rightIcon={IconNames.PLUS}
                    onClick={preventDefaultPropagation(handleNewMissionClick)(
                      item.id
                    )}
                  />
                  <Icon
                    icon={
                      openProgrammeList.includes(item.id)
                        ? IconNames.CHEVRON_DOWN
                        : IconNames.CHEVRON_LEFT
                    }
                  />
                </div>
              </div>
            </Tag>
            <Collapse
              isOpen={
                props.autoOpenProgramme
                  ? props.autoOpenProgramme
                  : openProgrammeList.includes(item.id)
              }
            >
              <MissionList
                missions={filter(['programme', item.id], props.missions)}
                showNewMission={showNewMissionProgrammeId === item.id}
                setShowNewMission={setShowNewMissionProgrammeId}
                saveMission={(name) => props.saveMission(name, item.id)}
                onMissionClick={props.onMissionClick}
              />
            </Collapse>
          </li>
        ))}
      </UL>
      <CreateButton
        disabled={!isAllowedToCreateProgramme}
        text="Programme"
        onClick={() => setShowNewProgramme(true)}
      />
    </div>
  );
};

export default ProgrammeList;
