import type {
  IRIState,
  regionOfInterestTypes,
  IRegionOfInterest,
} from '../../../../../constants/regionsOfInterest/actionTypes';
import {
  RI_ADD,
  RI_UPDATE,
  RI_REMOVE,
  RI_UPLOAD,
} from '../../../../../constants/regionsOfInterest/actionTypes';

const initialState: IRIState = {
  list: [],
};

export default function regionOfInterestReducer(
  state: IRIState = initialState,
  action: regionOfInterestTypes
): IRIState {
  switch (action.type) {
    case RI_ADD: {
      return {
        list: [...state.list, action.regionOfInterest],
      };
    }
    case RI_UPDATE: {
      return {
        list: state.list.map((regionOfInterest): IRegionOfInterest => {
          if (regionOfInterest.id === action.regionOfInterest.id) {
            return { ...action.regionOfInterest };
          }
          return regionOfInterest;
        }),
      };
    }
    case RI_REMOVE: {
      return {
        list: state.list.filter(
          (regionOfInterest): boolean => regionOfInterest.id !== action.id
        ),
      };
    }
    case RI_UPLOAD: {
      return action.regionsOfInterest;
    }
    default: {
      return state;
    }
  }
}
