import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import React from 'react';
import TagHeader from '../../../../../../components/common/TagHeader';
import s from './index.module.scss';

export interface IProps {
  children: React.ReactNode;
  title: string;
  icon: IconName;
  'data-testid'?: string;
}

const SideWrapper = (props: IProps) => (
  <>
    <div className={s.variablesTitle} data-testid={props['data-testid']}>
      <TagHeader icon={props.icon} title={props.title} />
    </div>
    {props.children}
  </>
);

export default SideWrapper;
