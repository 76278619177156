import type { CovarianceMatrixData } from 'api/sampling/service';
import { Dialog } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  covarianceMatrix: CovarianceMatrixData | undefined;
}

const CovarianceModal = ({ isOpen, setIsOpen, covarianceMatrix }: Props) => {
  const { translate } = useLocalisation();
  const noValuesAvailable = !covarianceMatrix?.covariance_matrix?.value?.length;
  const noValuesAvailableMessage = translate(
    'datacosmos.catalogAndItems.covarianceMatrixModal.noValuesAvailableMessage'
  );

  const matrix = covarianceMatrix?.covariance_matrix.value ?? [];

  const variables =
    covarianceMatrix?.covariance_matrix.metadata.variables ?? [];
  const formattedVariablesString =
    variables.slice(0, -1).join(', ') + ' & ' + variables.slice(-1);

  return (
    <Dialog
      isOpen={isOpen}
      buttons={[]}
      onClose={() => {
        setIsOpen(false);
      }}
      title={translate(
        'datacosmos.catalogAndItems.covarianceMatrixModal.title'
      )}
    >
      {noValuesAvailable ? (
        <div>{noValuesAvailableMessage}</div>
      ) : (
        <div>
          <h2>
            {translate(
              'datacosmos.catalogAndItems.covarianceMatrixModal.bandsTitle',
              {
                allBands: formattedVariablesString,
              }
            )}
          </h2>
          <div className="flex flex-col justify-center items-center mt-4">
            <div className="flex font-bold gap-1">
              <div className="w-8 p-1 text-center"></div>
              {variables.map((label) => (
                <div key={label} className="w-16 p-1 text-center">
                  {label}
                </div>
              ))}
            </div>
            {/* Covariance matrix rows */}
            {matrix.map((row, rowIndex) => (
              <div key={row[0]} className="flex gap-1">
                <div className="w-8 p-1 text-center font-bold">
                  {variables[rowIndex]}
                </div>
                {row.map((value) => (
                  <div key={value} className="w-16 p-1 text-center">
                    {value.toFixed(2)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default CovarianceModal;
