import classNames from "classnames";
import React, { useState } from "react";
import Icon, { IconName } from "../../icons/Icon";

interface IProps {
  /**
   * The content to display inside the accordion.
   */
  children: React.ReactNode;
  /**
   * The text to display in the accordion.
   */
  labelText: React.ReactNode;
  /**
   * An optional function to render the accordion text.
   */
  labelRenderer?: (label: string) => React.ReactNode;
  /**
   * Whether the Chevron icon is hidden or not.
   */
  hideIcon?: boolean;
  /**
   * Whether the accordion is open or not on first render.
   */
  startExpanded?: boolean;
  /**
   * The className to style the label in the accordion.
   */
  labelClassName?: string;
  /**
   * The icon to display in the accordion title.
   */
  icon?: IconName;
  /**
   * The className to style the container in the accordion.
   */
  className?: string;
  /**
   * setting isOpen to true of false sets the component in controlled mode.
   */
  isOpen?: boolean;
  /**
   * function to call when the user toggles the expanded state.
   */
  onToggle?: (isOpen: boolean) => void;
}

/**
 * Accordion is a component that can be used to show or hide content.
 */
const Accordion = (props: IProps) => {
  const [isOpenState, setIsOpenState] = useState<boolean>(
    props.startExpanded ? true : false
  );
  const isOpen = props.isOpen !== undefined ? props.isOpen : isOpenState;
  const handleOpen = (isOpen: boolean) => {
    setIsOpenState(isOpen);
    if (props.onToggle) props.onToggle(isOpen);
  };

  return (
    <div className="w-full">
      <div
        className={classNames(
          props.className,
          "flex items-center bg-item dark:bg-item-dark dark:text-item-dark-contrast text-data-dark p-3 mb-px cursor-pointer"
        )}
        onClick={() => {
          handleOpen(!isOpen);
        }}
      >
        {!props.hideIcon && (
          <>
            {isOpen ? (
              <Icon icon="ChevronDown" size={16} />
            ) : (
              <Icon icon="ChevronRight" size={16} />
            )}
          </>
        )}
        <div className={props.labelClassName + " flex w-full"}>
          {props.labelRenderer && typeof props.labelText === "string" ? (
            props.labelRenderer(props.labelText)
          ) : (
            <span>{props.labelText}</span>
          )}
        </div>

        {props.icon && <Icon icon={props.icon} className={"self-end"} />}
      </div>
      {isOpen ? props.children : null}
    </div>
  );
};

export default Accordion;
