import React from 'react';
import type { ShownCards } from 'pages/ops/Library/components/LibraryEncoding/types';
import LibraryNetworkTopology from 'pages/ops/Library/components/NetworkTopology/LibraryNetworkTopology';
import LibraryProtocolSettings from 'pages/ops/Library/components/NetworkSettings/LibraryProtocolSettings';
import TwoColumnLayout from 'pages/ops/Library/components/LibraryEncoding/TwoColumnLayout';
import type {
  GatewayConfigurationOptions,
  GatewayNetworkConfig,
} from 'api/gateway/types';

interface IProps {
  protocolToEdit: GatewayNetworkConfig | undefined;
  missionId: number | undefined;
  networkConfigOptions: GatewayConfigurationOptions;
  handleProtocolEdit: (protocol: GatewayNetworkConfig) => void;
  setShownCards: React.Dispatch<React.SetStateAction<ShownCards>>;
  setShouldDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const LibraryNetworkSettings = (props: IProps) => {
  const protocolSettings = props.protocolToEdit?.protocol_config;

  const protocolSchema = props.networkConfigOptions.protocols.find(
    (prot) => prot.name === props.protocolToEdit?.protocol
  );

  if (!protocolSchema) {
    return null;
  }

  return (
    <TwoColumnLayout
      sectionGap="100px"
      setShownCards={props.setShownCards}
      backButton
      leftWidthFr={7}
      rightWidthFr={10}
      left={
        <LibraryProtocolSettings
          configuration={protocolSettings}
          protocol={props.protocolToEdit?.protocol}
          schema={protocolSchema}
          protocolToEdit={props?.protocolToEdit}
          handleProtocolEdit={props.handleProtocolEdit}
          missionId={props.missionId}
        />
      }
      right={
        <LibraryNetworkTopology
          protocolToEdit={props?.protocolToEdit}
          handleProtocolEdit={props.handleProtocolEdit}
          setShouldDisableSaveButton={props.setShouldDisableSaveButton}
        />
      }
    />
  );
};

export default LibraryNetworkSettings;
