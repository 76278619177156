import { useEffect, useState } from 'react';
import s from './index.module.scss';

import { getGrafanaUrlForAlert, getBonusGrafanaUrlForAlert } from './utilities';
import type { AlertRule } from 'api/alerts/types';
import { Button, Group, Icon, Tooltip } from 'opencosmos-ui';

interface IProps {
  alert: AlertRule | undefined;
  setShowNewDefinition?: (value: boolean) => void;
  editHandler: Function;
  deleteHandler: Function;
}

const reviewStatusToIntent = {
  accepted: '#5ABE96',
  rejected: '#FF695E',
  'changed since review': '#E6E1DC',
  'not reviewed': '#E6E1DC',
};

const reviewTextToIntent = {
  accepted: 'Accepted',
  rejected: 'Rejected',
  'changed since review': 'Changed sinced review',
  'not reviewed': 'Not reviewed',
};

const LibraryAlertsRow = (props: IProps) => {
  const [alert, setAlert] = useState<AlertRule | undefined>();

  useEffect(() => {
    if (props.alert) {
      setAlert(props.alert);
    }
  }, [props.alert]);

  if (!alert) return null;

  let reviewIntent = 'primary';
  if (alert.annotations.reviewed) {
    reviewIntent = reviewStatusToIntent[alert.annotations.reviewed];
  }

  const renderReviewIcon = () => {
    const reviewText = reviewTextToIntent[alert.annotations.reviewed];

    return (
      <Tooltip delay={0} content={reviewText}>
        <Icon icon={'confirm'} stroke={reviewIntent} />
      </Tooltip>
    );
  };

  const renderEditableButtons = () => {
    const encoded = getGrafanaUrlForAlert(alert.expr);
    const bonusEncoded = getBonusGrafanaUrlForAlert(alert.expr);

    return (
      <Group className={'flex items-center gap-1'}>
        <Tooltip delay={0} content="Delete">
          <Button
            // TODO remove `disabled` when api will be connected
            isMinimal={true}
            icon={'Trash'}
            intent={'warning'}
            // @ts-expect-error
            onClick={props.deleteHandler}
          />
        </Tooltip>
        <Tooltip delay={0} content="Configure or approve in editor">
          <Button
            isMinimal={true}
            icon={'edit'}
            // @ts-expect-error
            onClick={props.editHandler}
          />
        </Tooltip>
        <Tooltip
          delay={0}
          content="Show when this alert rule was firing in Grafana"
        >
          <Button
            isMinimal={true}
            icon={'lightbulb'}
            onPress={() => window.open(encoded)}
          />
        </Tooltip>
        {bonusEncoded && (
          <Tooltip content="Show the metric value over time and the threshold to trigger an alert in Grafana">
            <Button
              isMinimal
              icon={'timeline-line-chart'}
              intent={'primary'}
              onPress={() => window.open(bonusEncoded)}
            />
          </Tooltip>
        )}
      </Group>
    );
  };

  return (
    <tr key={alert.id}>
      <td className={s.tableSubsystems}>{alert.labels.system}</td>
      <td className={s.tableIds}>{alert.id || '--'}</td>
      <td className={s.tableNames}>{alert.alert}</td>
      <td className={s.tableDescriptions}>{alert.annotations.description}</td>
      <td className={s.tableExpressions}>{alert.expr}</td>
      <td className={s.tableSeverity}>{alert.labels.severity}</td>
      <td className={s.tableTeam}>{alert.labels.team}</td>
      <td className={s.tableReviewed}>{renderReviewIcon()}</td>
      <td className={s.tableButtons}>{renderEditableButtons()}</td>
    </tr>
  );
};

export default LibraryAlertsRow;
