import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../reducers/rootReducer';
import type { Action } from 'redux';
import { setPopUpAction, resetPopUpAction } from './action';
import type { IPopUp } from '../../../../../../constants/popUp/actionTypes';
import { resetFocus } from '../focus/thunks';

export const setPopUp =
  (popUp: IPopUp): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(setPopUpAction(popUp));
  };

export const resetPopUp =
  (force = true): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(resetFocus(force));
    dispatch(resetPopUpAction());
  };
