import React, { useState } from 'react';
import { get, isEqual, remove } from 'lodash/fp';
import { DndProvider } from 'react-dnd';
import DndListTag from './DndListTag';
import DndListCollapsed from './DndListCollapsed';
import { HTML5Backend } from 'react-dnd-html5-backend';
import s from './index.module.scss';
import classNames from 'classnames';

interface IProps {
  items: unknown[];
  titlePath: string;
  uniqueFieldPath: string;
  renderCollapsedItem: (item: unknown, index: number) => React.ReactNode;
  handleTagHeaderRightItemClick?: (item: unknown) => void;
  tagHeaderRight?: string | React.ReactNode;
  moveTagItem?: (dragIndex: number, hoverIndex: number) => void;
}

const SortedList = (props: IProps) => {
  const [openList, setOpenList] = useState<number[]>([]);

  const handleOnTagClick = (index: number) => {
    const isIncluded = openList.includes(index);

    if (isIncluded) {
      const list = remove(isEqual(index), openList);
      setOpenList([...list]);
      return;
    }

    setOpenList([...openList, index]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ul className={classNames(s.listContainer, 'flex flex-col gap-1')}>
        {props.items.map((item, index) => (
          <li key={get(props.uniqueFieldPath, item) as string}>
            <DndListTag
              title={get(props.titlePath, item) as string}
              item={item}
              index={index}
              onTagClick={handleOnTagClick}
              tagHeaderRight={props.tagHeaderRight}
              handleTagHeaderRightItemClick={
                props.handleTagHeaderRightItemClick
              }
              moveTagItem={props.moveTagItem}
            />
            {openList.includes(index) && (
              <div>
                <DndListCollapsed>
                  {props.renderCollapsedItem(item, index)}
                </DndListCollapsed>
              </div>
            )}
          </li>
        ))}
      </ul>
    </DndProvider>
  );
};

export default SortedList;
