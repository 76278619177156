import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../../store';
import type { Action } from 'redux';
import { removeConstr } from './constraints/thunks';
import { resetFocus } from './focus/thunks';
import { removeAllGeometry } from './geometry/thunks';
import { removeAllGoals } from './goal/thunks';
import { uploadGS } from './groundStations/thunks';
import { uploadLO } from './launchOpportunities/thunks';
import { removeObj } from './objectives/thunks';
import { uploadPI } from './pointsOfInterest/thunks';
import { resetPopUp } from './popUp/thunks';
import { uploadRI } from './regionsOfInterest/thunks';
import { resetResultsThunk } from './results/thunks';
import { removeAllSO } from './satelliteOrbits/thunks';

export const resetStore =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(removeConstr());
    dispatch(resetFocus());
    dispatch(removeAllGeometry());
    dispatch(removeAllGoals());
    dispatch(uploadGS({ list: [] }));
    dispatch(uploadLO({ list: [] }));
    dispatch(removeObj());
    dispatch(uploadPI({ list: [] }));
    dispatch(resetPopUp());
    dispatch(uploadRI({ list: [] }));
    dispatch(resetResultsThunk());
    dispatch(removeAllSO());
  };
