import type { IApplication } from 'datacosmos/types/applications';
import React from 'react';
import Tag from '_atoms/Tag/Tag';
import Button from '_molecules/Button/Button';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import classNames from 'classnames';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type IProps = {
  app: IApplication;
  setIsAppOpened: React.Dispatch<React.SetStateAction<boolean>>;
  toggleAppInstall: (app: IApplication) => void;
  isInstalled: boolean;
  setSelectedInstalledApp: (app: IApplication) => void;
};

const UnopenedAppCard = ({
  app,
  toggleAppInstall,
  isInstalled,
  setSelectedInstalledApp,
}: IProps) => {
  const { sendInfo } = useAnalytics();
  const { translate } = useLocalisation();
  return (
    <div className="flex gap-4 text-sm p-4 text-item-contrast-inactive border-b-2 border-b-item-contrast last:border-b-0 last:border-b-transparent bg-surface cursor-pointer  dark:bg-surface-dark dark:text-item-dark-contrast">
      {/* left card section */}
      <div className="flex flex-col flex-grow">
        {app.appScreenshotUrl && (
          <div className="h-24 w-24">
            <img
              src={app.appScreenshotUrl}
              alt={app.name + ' app screenshot'}
              className="object-contain bg-cover w-full h-full"
            />
          </div>
        )}

        <div className="h-full mt-2 flex flex-col gap-2 justify-start">
          {/* Tags go here */}
          {app.tags.map((t) => (
            <Tag text={t} key={t} color="#F2F0ED" />
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <span className="text-[16px]">{app.name}</span>
        <a
          className="flex gap-2 items-center group"
          href={app.provider.url}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          rel="noreferrer"
        >
          <img
            src={app.provider.icon_url}
            alt={`${app.provider.name} logo`}
            className={classNames('object-contain block h-10 w-10', {
              'w-full': app.provider.name === '',
            })}
          />

          {app.provider.name !== '' && (
            <span className="group-hover:text-accent-900">
              {app.provider.name}
            </span>
          )}
        </a>
        <p>{app.shortDescription}</p>
        <div className="flex w-full justify-between items-center">
          <Button
            icon={isInstalled ? 'Uninstall' : 'Install'}
            text={
              isInstalled
                ? translate('datacosmos.applications.uninstall')
                : translate('datacosmos.applications.install')
            }
            onPress={() => toggleAppInstall(app)}
          />
          {isInstalled && (
            <Button
              text={translate('datacosmos.applications.global.buttons.open')}
              icon="Folder"
              onPress={() => {
                sendInfo({
                  type: 'Subscription apps open',
                  action: 'Click',
                  item: 'Subscription application',
                  module: 'DataCosmos',
                  additionalParams: {
                    appName: app.name,
                  },
                });

                setSelectedInstalledApp(app);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UnopenedAppCard;
