import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../reducers/rootReducer';
import { getHash } from '../../../../../../utils/common/getHash';
import type { IAnyKey } from '../../../../../../utils/getFormData';
import type { IResults } from '../../../../../../constants/results/actionTypes';
import { resetResults, updateResults } from './action';

/**
 * @param path === IResult.dep // [msd.geometryPage, ops.qwe]
 * @param hashOut === {points, mission, regions, geometry, satellites... }
 * @param dataOut === {files: [url1, url2], data: {historySteps, initData...}}
 * @param depsHashOut
 */
export const updateResultsThunk =
  (
    path: string,
    hashOut: { [key: string]: any } | undefined,
    dataOut: object | null,
    depsHashOut: object | null
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const results = state().results;

    let currentResult = results as IAnyKey;
    const splittedPath = path.split('.');
    splittedPath.forEach((path) => (currentResult = currentResult[path]));

    const deps = currentResult.deps;

    let hash: string;
    if (hashOut === undefined) {
      hash = currentResult.hash;
    } else {
      hash = getHash(JSON.stringify(hashOut));
      if (currentResult.hash === hash && !dataOut) {
        return undefined;
      }
    }

    let data;
    if (dataOut === undefined) {
      data = currentResult.data;
    } else {
      data = dataOut;
    }

    let depsHash: { [key: string]: { oldHash: string; newHash: string } };
    if (depsHashOut === undefined) {
      depsHash = currentResult.depsHash;
    } else if (depsHashOut === null) {
      depsHash = depsHashOut as {
        [key: string]: { oldHash: string; newHash: string };
      };
    } else {
      depsHash = { ...currentResult.depsHash, ...depsHashOut };
      if (data === null) {
        Object.keys(depsHash).forEach((key) => {
          depsHash[key].oldHash = '';
        });
      }
    }

    const newResults: IAnyKey = Object.assign({}, results);
    newResults[splittedPath[0]][splittedPath[1]] = {
      deps: deps,
      hash: hash,
      data: data,
      depsHash: depsHash,
    };

    dispatch(updateResults(newResults as IResults));

    if (currentResult.hash !== hash) {
      currentResult.deps.forEach((dep: string) => {
        dispatch(
          updateResultsThunk(dep, undefined, undefined, {
            [path]: { oldHash: currentResult.hash, newHash: hash },
          })
        );
      });
    }
  };

export const resetResultsThunk =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(resetResults());
  };
