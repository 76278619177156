import type {
  PermissionType,
  AttachedRoleAssignment,
} from 'api/administration/permissions';
import { Button } from 'opencosmos-ui';
import Modal from 'opencosmos-ui/src/core/Modal/Modal';
import { useState } from 'react';
import { OverlayContainer } from 'react-aria';
import type { ICreateRoleAttachment } from 'services/api/portal/administration/api/types';
import useRoles from 'services/api/portal/administration/hook/useRoles';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import AddRoleAttachment from '../../../Administration/Roles/components/AdministrationRolesList/AddRoleAttachment';

interface Props {
  roleAttachments: AttachedRoleAssignment[];
  grantedTo: string;
  parentRoleId: number;
  getResourceAttachmentsCallback: () => void;
  resourceType: string;
  resourceId: string;
}
const RoleAssignmentsThatAreGranted = ({
  roleAttachments,
  grantedTo,
  parentRoleId,
  getResourceAttachmentsCallback,
  resourceType,
  resourceId,
}: Props) => {
  const { translate } = useLocalisation();
  const [openAddAttachment, setOpenAddAttachment] = useState<boolean>(false);
  const [selectedPermission, setSelectedPermission] =
    useState<PermissionType>();
  const [selectedResourceId, setSelectedResourceId] = useState<string | number>(
    ''
  );
  const { availableRolesByType, deleteRoleAttachment, createRoleAttachment } =
    useRoles();

  const assignResourceAttachment = async (roleId: number) => {
    const postObj: ICreateRoleAttachment = {
      parentResourceType: resourceType,
      parentResourceId: resourceId,
      childRoleId: roleId,
      childResourceType: selectedPermission ?? '',
      childResourceId: selectedResourceId.toString(),
    };
    await createRoleAttachment(postObj, parentRoleId.toString());
    getResourceAttachmentsCallback();
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h4>{grantedTo}</h4>
        <Button
          intent="secondary"
          icon="Plus"
          onPress={() => {
            setOpenAddAttachment(true);
          }}
        >
          <span>Add role attachments</span>
        </Button>
      </div>
      {!roleAttachments?.length ? (
        <div>No roles found</div>
      ) : (
        roleAttachments.map((attachment) => (
          <div key={attachment.id} className="mt-2">
            <div className="grid grid-cols-3 !shadow-none gap-4 border-2 border-neutral-200 mb-1 px-2 bg-surface/50 dark:bg-surface-dark/50 rounded">
              <div className="flex justify-between gap-2 col-span-2 items-center h-fit">
                <div>
                  <div className="text-xs text-neutral-600 font-bold">
                    {attachment.childResourceId}
                  </div>
                  <div>{attachment.childResourceType}</div>
                </div>
                <div className="bg-[#5f6b7c] text-neutral-50 p-1 text-xs rounded">
                  {attachment.childRole.name}
                </div>
              </div>
              <div className="flex justify-end items-center">
                <Button
                  intent="warning"
                  icon="Trash"
                  onPress={async () => {
                    await deleteRoleAttachment(attachment.id, parentRoleId);
                    getResourceAttachmentsCallback();
                  }}
                >
                  <span>Delete</span>
                </Button>
              </div>
            </div>
          </div>
        ))
      )}
      <OverlayContainer>
        {openAddAttachment && (
          <Modal
            isOpen={openAddAttachment}
            onClose={() => {
              setOpenAddAttachment(false);
            }}
            className="w-2/5 h-max"
            title={translate('rolesPage.addAttachment')}
          >
            <AddRoleAttachment
              getAvailableRolesByType={availableRolesByType}
              setResourceId={setSelectedResourceId}
              setSelectedPermission={setSelectedPermission}
              selectedPermission={selectedPermission}
              resourceId={resourceId}
              assignRole={async (roleId) => {
                await assignResourceAttachment(roleId);
                setOpenAddAttachment(false);
              }}
            />
          </Modal>
        )}
      </OverlayContainer>
    </div>
  );
};

export default RoleAssignmentsThatAreGranted;
