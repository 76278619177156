import { useCalendarGrid, useLocale } from "react-aria";
import type { CalendarState, RangeCalendarState } from "react-stately";
import type { CalendarDate, DateDuration } from "@internationalized/date";
import { getWeeksInMonth, endOfMonth } from "@internationalized/date";
import CalendarCell from "./CalendarCell";
import moment from "moment";
import type { CellItemsByDate } from "../Calendar/Calendar";

interface ICalendarGridProps {
  state: RangeCalendarState | CalendarState;
  offset?: DateDuration;
  highlightedDates: CalendarDate[];
  cellItemsByDate?: CellItemsByDate[];
}

const CalendarGrid = ({
  state,
  offset = {},
  highlightedDates,
  cellItemsByDate,
}: ICalendarGridProps) => {
  const { locale } = useLocale();

  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    { startDate, endDate },
    state
  );

  moment.locale(locale);
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table
      {...gridProps}
      cellSpacing={0}
      cellPadding={0}
      className="w-full h-full"
    >
      <thead {...headerProps}>
        <tr data-testid="days-row">
          {weekDays.map((date) => (
            <th
              data-testid="day"
              key={date}
              className="text-[12px] font-normal items-center"
            >
              {date.substring(0, 3)}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className="h-full">
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex} data-testid="date-row">
            {state.getDatesInWeek(weekIndex, startDate).map((date, index) => {
              const key = `calendar-cell-${index}`;
              if (!date) return <td key={key} />;
              const isHighlighted = Boolean(
                highlightedDates.find((hd) => hd.toString() === date.toString())
              );
              return (
                <CalendarCell
                  key={key}
                  state={state}
                  date={date}
                  currentMonth={startDate}
                  isHighlighted={isHighlighted}
                  cellItemsByDate={cellItemsByDate}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CalendarGrid;
