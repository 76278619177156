import { AppTags } from 'datacosmos/types/applications';
import type { IAPIAppValue, IApplication } from 'datacosmos/types/applications';
import { btoaSafe } from 'utils/common/btoaSafe';
import OpenedAppCard from '../SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from '../SubscriptionApps/Common/UnopenedAppCard';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useCallback, useEffect, useState } from 'react';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import { LayerSourceType } from 'datacosmos/entities/layer';
import IconButton from '_molecules/IconButton/IconButton';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import Input from '_molecules/Input/Input';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import Select2 from '_molecules/Select2/Select2';
import { Popover2 } from '@blueprintjs/popover2';
import { Item } from 'react-stately';
import { submitWorkflow } from 'api/pdgs/service';
import { polygonToFeatureCollection } from 'datacosmos/utils/geojson';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';

import conidaLogo from 'public/images/datacosmos/conida-logo.png';
type Props = {
  app: IApplication;
};

export const cropImageApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: clientTranslate('datacosmos.applications.cropImg.title'),
  description: clientTranslate('datacosmos.applications.cropImg.description'),
  inputs: [
    {
      field: 'imageId',
      example: '',
    },
    {
      field: 'aoi',
      example: '',
    },
    {
      field: 'aoiName',
      example: '',
    },
    {
      field: 'targetProject',
      example: '',
    },
  ],
  values: {
    imageId: { value: null, isError: false, message: '' },
    aoi: { value: null, isError: false, message: '' },
    aoiName: { value: '', isError: false, message: '' },
    targetProject: { value: null, isError: false, message: '' },
  },
  provider: {
    id: 1,
    // If the name is blank, the logo will be full width.
    name: '',
    description:
      'Comisión Nacional de Investigación y Desarrollo Aeroespacial del Perú',
    url: 'https://www.gob.pe/conida',
    icon_url: conidaLogo,
  },
  shortDescription: clientTranslate(
    'datacosmos.applications.cropImg.shortDescription'
  ),
  renderer: (app: IApplication) => <CropImage app={app} />,
  appScreenshotUrl: '',
  tags: [AppTags.crop],
};

export const CropImage = ({ app }: Props) => {
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
    setApplicationAOIs: setCropImageAoi,
  } = useApplicationCatalog();
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { activePage, setActivePage } = useActivePage();

  const { removeLayersBySourceType } = useMapLayers();

  const { modifiableScenarios } = useProjects();

  const { translate } = useLocalisation();

  const { clickedStacLayer, layersContainStacItems } = useClickedStacItem({
    outlineClickedItem: true,
    onImageClick: (img) => {
      setValue('imageId', img.item.id);
    },
  });

  const [aoiName, setAoiName] = useState<string | undefined>();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setError = (key: string, message: string) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value: '', isError: true, message: message },
    });
  };

  useEffect(() => {
    if (!layersContainStacItems) {
      setValue('imageId', null);
    }
  }, [layersContainStacItems, setValue]);

  const inputs = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div className="flex flex-col gap-1">
          <span>
            {translate(
              'datacosmos.applications.cropImg.inputs.fullResImg.title'
            )}
          </span>
          <Input
            type="text"
            readOnly
            value={clickedStacLayer?.item.id}
            placeholder={translate(
              'datacosmos.applications.cropImg.inputs.fullResImg.placeholder'
            )}
          />
          {app.values.imageId?.isError && (
            <div>
              <small style={{ color: '#ff0000' }}>
                {app.values.imageId.message}
              </small>
            </div>
          )}
        </div>

        <div>
          <label htmlFor="aoi">
            {translate('datacosmos.applications.cropImg.inputs.aoi.title')}{' '}
          </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <Input
              type="text"
              onChange={(e) => {
                setValue('aoiName', e.target.value);
                setAoiName(e.target.value);
              }}
              value={aoiName}
              placeholder={translate(
                'datacosmos.applications.cropImg.inputs.aoi.title'
              )}
            />
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={(aoi) => {
                if (!aoi) {
                  return;
                }
                setCropImageAoi(aoi);
                setValue('aoi', aoi as GeoJSON.Polygon[]);
              }}
              buttonForApplications
              aoiName={aoiName}
            />

            <IconButton
              icon="Trash"
              size={24}
              onPress={() => {
                setCropImageAoi([]);
                setValue('aoi', null);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              iconClassName="stroke-item-contrast dark:stroke-item-dark-contrast"
            />
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <Select2
            items={modifiableScenarios}
            fill
            label={
              <div style={{ display: 'flex' }}>
                <span style={{ marginRight: '5px' }}>
                  {translate(
                    'datacosmos.applications.cropImg.inputs.targetProject.title'
                  )}
                </span>
                <Popover2
                  interactionKind="hover"
                  renderTarget={() => <div />}
                  content={
                    <div
                      style={{
                        lineBreak: 'auto',
                        padding: '7px',
                        lineHeight: '20px',
                        maxWidth: '200px',
                      }}
                      className="dark:bg-surface-dark dark:text-item-dark-contrast"
                    >
                      {translate(
                        'datacosmos.applications.cropImg.inputs.targetProject.title'
                      )}
                    </div>
                  }
                />
              </div>
            }
            onSelectionChange={(item) => {
              const foundProj = modifiableScenarios.find((s) => s.id === item);

              if (!foundProj) {
                return;
              }
              setValue('targetProject', item);
            }}
            selectedItemClassName="border-2 border-item"
          >
            {(item) => <Item key={item.id}>{item.title}</Item>}
          </Select2>
        </div>
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!clickedStacLayer) {
            return;
          }

          if (!clickedStacLayer.item.collection) {
            return;
          }

          if (clickedStacLayer.assetKey !== 'visual') {
            setError('imageId', 'Please select a full res image');
            return;
          }

          setIsSubmitting(true);
          await submitWorkflow({
            body: {
              name: 'crop-image-to-area-of-interest',
              inputs: {
                'uncropped-image-stac-collection':
                  clickedStacLayer.item.collection,
                'uncropped-image-stac-item': app.values.imageId.value as string,
                'uncropped-image-asset': clickedStacLayer.assetKey,
                'area-of-interest-name': app.values.aoiName.value as string,
                'datacosmos-projects': app.values.targetProject.value as string,
                'area-of-interest': JSON.stringify(
                  polygonToFeatureCollection(
                    (app.values.aoi.value as GeoJSON.Polygon[])[0]
                  )
                ),
              },
            },
          });
          setIsSubmitting(false);
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.submit'
        )}
        loading={isSubmitting}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(selectedApp) => {
        setSelectedInstalledApp(selectedApp);
        if (activePage === 'application') {
          setActivePage(undefined);
        }
      }}
    />
  );
};
