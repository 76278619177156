import { DATE_FORMAT } from 'constants/datetime';
import moment from 'moment';
import { useMemo, useState } from 'react';
import type { TaskingRequest } from 'api/tasking/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type {
  AoiLayersDisplayed,
  SwathLayersDisplayed,
  ToggleDisplayAllRequestSwathsOnMap,
  ToggleDisplayAoIOnMap,
} from './useTaskingRequestList';
import classNames from 'classnames';
import { Button, Icon, Tooltip } from 'opencosmos-ui';
import { CancelRequest } from '_organisms/CancelRequestPopup/CancelRequest';

type IProps = {
  request: TaskingRequest;
  toggleDisplayAoIOnMap: ToggleDisplayAoIOnMap;
  toggleDisplayAllRequestSwathsOnMap: ToggleDisplayAllRequestSwathsOnMap;
  shownAoiLayer?: AoiLayersDisplayed[0];
  shownActivitySwathsLayers: SwathLayersDisplayed;
  children?: React.ReactNode;
  showRequestIDs: boolean;
  refetchRequests: () => Promise<void>;
};

const RequestCard = ({
  request,
  toggleDisplayAoIOnMap,
  toggleDisplayAllRequestSwathsOnMap,
  shownAoiLayer,
  shownActivitySwathsLayers,
  children,
  showRequestIDs,
  refetchRequests,
}: IProps) => {
  const isShownAoi = typeof shownAoiLayer !== 'undefined';
  const isShownSwaths = shownActivitySwathsLayers.length !== 0;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isCancelRequestOpen, setIsCancelRequestOpen] =
    useState<boolean>(false);

  const { translate } = useLocalisation();

  const activityAmountByType = useMemo(() => {
    return request.activities.reduce<{ [type: string]: number }>(
      (acc, activity) => {
        if (!acc[activity.status]) {
          acc = { ...acc, [activity.status]: 1 };
        } else {
          acc[activity.status] = acc[activity.status] + 1;
        }
        return acc;
      },
      {}
    );
  }, [request.activities]);

  const areActivitiesInPast = useMemo(() => {
    return request.activities.some(
      (activity) => moment(moment.now()).diff(activity.start_date) >= 0
    );
  }, [request.activities]);

  return (
    <div>
      {showRequestIDs && (
        <span className="text-xs flex items-center justify-between text-item-contrast dark:text-item-dark-contrast">
          ID {request.id}
        </span>
      )}
      <div
        data-testid="tasking-request-card"
        aria-expanded={isExpanded}
        className={classNames('px-2', {
          'bg-item-hover dark:bg-item-dark-hover': isExpanded,
          'bg-item dark:bg-item-dark hover:bg-item-hover dark:hover:bg-item-dark-hover':
            !isExpanded,
        })}
      >
        <div
          data-testid="tasking-request-open-button"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          role="button"
          className={classNames(
            'flex p-2 py-4 gap-8 text-sm w-full border-b cursor-pointer',
            {
              'border-item': isExpanded,
              'border-transparent': !isExpanded,
            }
          )}
        >
          <div className="flex flex-col gap-1 justify-between w-full">
            <div className="flex items-center gap-2 w-full">
              <span className="break-all">{request.region_name}</span>
            </div>
            <div className="flex items-center gap-2 w-full">
              <span>{moment(request.updated_at).format(DATE_FORMAT)}</span>
            </div>

            <div className="flex items-center gap-1 w-full text-xs ">
              <span className="break-all">
                {translate(
                  `datacosmos.tasking.existing.requestType.${request.type}`
                )}{' '}
                -{' '}
                {translate(
                  `datacosmos.tasking.existing.requestStatus.${request.status}`
                )}
              </span>
              <div className="flex items-center gap-1">
                {activityAmountByType.length > 0 &&
                  Object.entries(activityAmountByType).map(([key, val]) => (
                    <span key={key}>
                      {key}: {val}
                    </span>
                  ))}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-0">
            <Tooltip
              content={
                isShownAoi
                  ? translate('datacosmos.tooltips.tasking.existing.removeAoi')
                  : translate('datacosmos.tooltips.tasking.existing.viewAoi')
              }
              isDisabled={!request}
            >
              <Button
                icon="AoiAdd"
                size={'lg'}
                onPress={() => {
                  toggleDisplayAoIOnMap(request);
                }}
                isDisabled={!request}
                data-testid="request-add-aoi"
                isMinimal
                isTransparent
              />
            </Tooltip>
            <Tooltip
              content={
                isShownSwaths
                  ? translate(
                      'datacosmos.tooltips.tasking.existing.removeSwath'
                    )
                  : translate('datacosmos.tooltips.tasking.existing.viewSwath')
              }
              isDisabled={!request?.activities?.length}
            >
              <Button
                icon="SwathMultiple"
                size={'lg'}
                onPress={() => {
                  toggleDisplayAllRequestSwathsOnMap(request);
                }}
                isDisabled={!request?.activities?.length}
                data-testid="request-add-all-swaths"
                isMinimal
                isTransparent
              />
            </Tooltip>
            {request.status !== 'FULFILLED' &&
              request.status !== 'CANCELLED' &&
              request.status !== 'FAILED' && (
                <Tooltip
                  content={translate(
                    'datacosmos.tooltips.tasking.existing.cancelRequest'
                  )}
                  isDisabled={!request || areActivitiesInPast}
                >
                  <Button
                    icon="small-cross"
                    size={'lg'}
                    onPress={() => {
                      setIsCancelRequestOpen(true);
                    }}
                    isDisabled={!request || areActivitiesInPast}
                    data-testid="delete-request"
                    isMinimal
                    isTransparent
                  />
                </Tooltip>
              )}
            <Icon icon={isExpanded ? 'ChevronUp' : 'ChevronDown'} size={24} />
          </div>
        </div>
        {isExpanded && (
          <div className="pb-2 hover:text-item-contrast dark:hover:text-item-dark-contrast text-item-contrast dark:text-item-dark-contrast text-sm flex flex-col gap-3 mt-2">
            {children}
          </div>
        )}
      </div>
      <CancelRequest
        isOpen={isCancelRequestOpen}
        setIsOpen={setIsCancelRequestOpen}
        request={request}
        refetchData={refetchRequests}
      />
    </div>
  );
};

export default RequestCard;
