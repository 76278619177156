import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumbs } from '@blueprintjs/core';
import { useMission } from 'services/Missions';
import { useApiOpsGetTerminalSessions } from 'services/api/ops/terminalSessions';
import DefaultLayout from 'layouts/DefaultLayout';
import NewTerminalSession from 'pages/ops/RTI/SessionManager/components/NewTerminalSession';
import { TerminalSessionItem } from 'pages/ops/RTI/SessionManager/components/TerminalSessionItem';
import { ops } from 'constants/mixpanelAnalytics';
import useOnLoadMetricWithUrlId from 'utils/hooks/analytics/useOnLoadMetricWithUrlId';
import mainS from '../index.module.scss';
import s from './index.module.scss';
import {
  Button,
  MenuTrigger,
  Popover,
  Switch,
  Text,
  Tooltip,
} from 'opencosmos-ui';
import classNames from 'classnames';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import useGatewayStatus from 'pages/ops/RTI/SessionManager/hooks/useGatewayStatus';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

const SessionManager = () => {
  const { currentMissionId } = useMission();

  const theme = useTheme();

  const history = useHistory();
  const { sessions, fetchTerminalSessions, setMissionSessions } =
    useApiOpsGetTerminalSessions(currentMissionId);
  const { gatewayStatus, isFetching } = useGatewayStatus(currentMissionId);
  const [filterDeleted, setFilterDeleted] = useState(true);

  useOnLoadMetricWithUrlId('mission', {
    action: 'Visit',
    item: 'RTI',
    module: 'OPS',
    type: ops.RTI.VISIT,
  });

  const handleSessionRename = (id: number, name: string) => {
    const index = sessions.findIndex((session) => session.id === id);
    sessions[index].name = name;
    setMissionSessions(sessions);
  };

  const handleConnectToSession = (sessionId: number) => {
    if (!currentMissionId) return;
    history.push(`/ops/mission/${currentMissionId}/rti/session/${sessionId}`);
  };

  const gatewayStatusMessage =
    gatewayStatus?.message ?? 'No status is available';

  const { hasPermission: isAllowedToReadSession } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'ops:rti:session:read',
      id: currentMissionId,
    },
  });

  const { hasPermission: isAllowedToWriteSession } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'ops:rti:session:write',
      id: currentMissionId,
    },
  });

  return (
    <DefaultLayout
      showMissionSelector
      light
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'RTI' }]} />
        </div>
      }
    >
      <div
        className={classNames(
          s.container,
          'bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast'
        )}
      >
        <Text className="text-lg dark:text-item-dark-contrast">
          Create new session
        </Text>
        <div
          className={classNames(
            s.newSessionContainer,
            'dark:bg-item-dark p-6 bg-header'
          )}
        >
          <NewTerminalSession
            handleConnectToSession={handleConnectToSession}
            writeAllSessionsPermission={Boolean(isAllowedToReadSession)}
            onSessionCreated={fetchTerminalSessions}
          />
        </div>

        <Text className="text-lg dark:text-item-dark-contrast">
          Gateway connection status
        </Text>
        <p>{isFetching ? 'loading...' : gatewayStatusMessage}</p>

        <Text className="text-lg dark:text-item-dark-contrast flex gap-1">
          <span>Existing sessions</span>
          <Tooltip delay={0} content="Reload list">
            <Button
              icon={'refresh'}
              isMinimal={true}
              onPress={fetchTerminalSessions}
            />
          </Tooltip>
          <MenuTrigger>
            <Tooltip delay={0} content="Filters">
              <Button icon={'settings'} isMinimal={true} />
            </Tooltip>
            <Popover placement={'bottom'}>
              <div className="bg-item dark:bg-item-dark p-2 dark:text-item-dark-contrast">
                <Switch
                  themeProvider={theme}
                  isSelected={filterDeleted}
                  label="Filter deleted"
                  onChange={(checked) => {
                    setFilterDeleted(checked);
                  }}
                />
              </div>
            </Popover>
          </MenuTrigger>
        </Text>
        <div
          className={classNames(
            s.newSessionContainer,
            'dark:bg-item-dark p-6 bg-header'
          )}
        >
          <ul className={classNames(s.list, 'flex flex-col gap-1')}>
            {sessions
              .sort((a, b) => a.id - b.id)
              .filter((item) => item.open || !filterDeleted)
              .map((session) => (
                <TerminalSessionItem
                  key={session.id}
                  session={session}
                  handleSessionRename={(name: string) =>
                    handleSessionRename(session.id, name)
                  }
                  handleConnectToSession={handleConnectToSession}
                  handleReloadSessions={fetchTerminalSessions}
                  readAllSessionsPermission={Boolean(isAllowedToReadSession)}
                  writeAllSessionsPermission={Boolean(isAllowedToWriteSession)}
                />
              ))}
          </ul>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default SessionManager;
