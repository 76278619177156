import type { IGeoJSONMetadata } from 'datacosmos/services/tilingApi';
import tilingApi from 'datacosmos/services/tilingApi';
import { useCallback, useState } from 'react';

export interface IHistogramData {
  [name: string]: number | number[] | null;
}

const useGeoJSONMetadata = (assetURL: string, contentType: string) => {
  const validAsset = contentType === 'application/geo+json';
  const [metadata, setMetadata] = useState<IGeoJSONMetadata>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchMetadata = useCallback(async () => {
    setIsFetching(true);

    const meta = await tilingApi.fetchMetadataForGeoJSON(assetURL);

    setMetadata(meta);
    setIsFetching(false);
  }, [assetURL]);

  return {
    metadata,
    isFetching,
    validAsset,
    fetchMetadata,
  };
};

export default useGeoJSONMetadata;
