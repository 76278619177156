import { DATE_FORMAT } from 'constants/datetime';
import moment from 'moment';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useMission } from 'services/Missions';
import SchedulingHeader from 'pages/ops/Scheduling/components/SchedulingHeader';
import { ActivityCard } from 'pages/ops/Scheduling/components/ActivityCard';
import type {
  Activity,
  ActivityType,
  ActivityStatus,
  ActivityWithRequestNote,
} from 'api/activities/service';
import type {
  FromToDate,
  UpdatedActivityMap,
  ViewMode,
} from 'pages/ops/Scheduling';
import ActivityCalendar from '_organisms/ActivityCalendar/ActivityCalendar';
import { useHistory, useRouteMatch } from 'react-router';
import type { TaskingRequest } from 'api/tasking/types';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import SchedulingTable, {
  DEFAULT_HIDDEN_COLUMNS,
} from '../SchedulingTable/SchedulingTable';
import useLocalStorage from 'utils/hooks/useLocalStorage';

type ScheduleActivityListProps = {
  activities: Activity[];
  requests: TaskingRequest[];
  fromToDate: FromToDate;
  setFromToDate: React.Dispatch<React.SetStateAction<FromToDate>>;
  loading: boolean;
  setCalendarModeCurrentMonth: (date: Date) => void;
  calendarModeCurrentMonth: Date | undefined;
  viewMode: ViewMode;
  setViewMode: (mode: ViewMode) => void;
  setModifiableActivities: React.Dispatch<React.SetStateAction<Activity[]>>;
  batchUpdateActivityStatus: () => Promise<void>;
  isUpdatingActivityStatus: boolean;
  refetchActivities: () => Promise<void>;
  updatedActivitiesMap: UpdatedActivityMap;
  setUpdatedActivitiesMap: Dispatch<SetStateAction<UpdatedActivityMap>>;

  isSaveChangesButtonEnabled: boolean;
  setIsSaveChangesButtonEnabled: Dispatch<SetStateAction<boolean>>;
};

export const ScheduleActivityList = ({
  activities,
  requests,
  fromToDate,
  setFromToDate,
  loading,
  setCalendarModeCurrentMonth,
  calendarModeCurrentMonth,
  viewMode,
  setViewMode,
  batchUpdateActivityStatus,
  isUpdatingActivityStatus,
  refetchActivities,
  updatedActivitiesMap,
  setUpdatedActivitiesMap,
  setIsSaveChangesButtonEnabled,
  isSaveChangesButtonEnabled,
}: ScheduleActivityListProps) => {
  const routeMatch = useRouteMatch<{ mission: string; activity: string }>(
    '/ops/mission/:mission/schedule/activity/:activity'
  );

  const history = useHistory();

  const { currentMissionId } = useMission();

  const [selectedStatuses, setSelectedStatuses] = useState<ActivityStatus[]>(
    []
  );

  const [selectedTypes, setSelectedTypes] = useState<ActivityType[]>([]);

  const [filtered, setFiltered] = useState<Activity[]>(activities);

  const [tableHiddenColumns, setTableHiddenColumns] = useLocalStorage(
    'table-hidden-columns',
    DEFAULT_HIDDEN_COLUMNS
  );

  // Used for displaying request notes in the table
  const activitiesWithRequestNotes: ActivityWithRequestNote[] = useMemo(
    () =>
      filtered.map(
        (a) =>
          ({
            ...a,
            request_note:
              requests.find((r) => a.tasking_request_ids.includes(r.id))
                ?.notes ?? '',
          } as ActivityWithRequestNote)
      ),
    [filtered, requests]
  );

  const groupedActivities = useMemo(() => {
    return filtered?.reduce<{ [date: string]: Activity[] }>((acc, value) => {
      return {
        ...acc,
        [moment.utc(value.start_date).format(DATE_FORMAT)]: [
          ...(acc[moment.utc(value.start_date).format(DATE_FORMAT)] || []),
          value,
        ],
      };
    }, {});
  }, [filtered]);

  const toggleHiddenColumns = useCallback(
    (column: string) => {
      if (tableHiddenColumns.includes(column)) {
        setTableHiddenColumns(tableHiddenColumns.filter((c) => c !== column));
      } else {
        setTableHiddenColumns([...tableHiddenColumns, column]);
      }
    },
    [setTableHiddenColumns, tableHiddenColumns]
  );

  const handleActivityClick = useCallback(
    (activity: Activity | undefined) => {
      if (!currentMissionId) {
        return;
      }

      if (!activity) {
        history.push(
          `/ops/mission/${currentMissionId}/schedule${history.location.search}`
        );
        return;
      }

      history.push(
        `/ops/mission/${currentMissionId}/schedule/activity/${activity.id}${location.search}`
      );
    },
    [currentMissionId, history]
  );

  useEffect(() => {
    setFiltered(
      activities
        ?.filter((activity) =>
          selectedStatuses.length > 0
            ? selectedStatuses.some((status) => status === activity.status)
            : true
        )
        ?.filter((activity) =>
          selectedTypes.length > 0
            ? selectedTypes.some((type) => type === activity.type.toString())
            : true
        )
    );
  }, [selectedStatuses, selectedTypes, fromToDate, activities]);

  const renderWithHeader = (content: React.ReactNode) => {
    return (
      <div
        style={{
          padding: '0 20px',
          height: '100%',
          overflowY: 'auto',
          flex: 1,
          paddingBottom: '75px',
        }}
        data-testid="schedule-activity-list"
      >
        <SchedulingHeader
          isUpdatingActivityStatus={isUpdatingActivityStatus}
          selectedStatuses={selectedStatuses}
          setActivityStatus={setSelectedStatuses}
          fromToDate={fromToDate}
          setFromToDate={setFromToDate}
          setSelectedTypes={setSelectedTypes}
          selectedTypes={selectedTypes}
          viewMode={viewMode}
          setViewMode={setViewMode}
          activities={activitiesWithRequestNotes}
          toggleHiddenColumns={toggleHiddenColumns}
          isSaveChangesButtonEnabled={isSaveChangesButtonEnabled}
          setIsSaveChangesButtonEnabled={setIsSaveChangesButtonEnabled}
          batchUpdateActivityStatus={batchUpdateActivityStatus}
          refetchActivities={refetchActivities}
          tableHiddenCols={tableHiddenColumns}
          isActivitySelected={routeMatch?.params.activity !== undefined}
        />

        {content}
      </div>
    );
  };

  const renderWithLoading = (content: React.ReactNode) => {
    if (loading) {
      return <Spinner />;
    }
    return content;
  };

  const renderListMode = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          maxHeight: 'inherit',
        }}
      >
        {Object.keys(groupedActivities ?? {})
          .sort(
            (a, b) =>
              moment(b, 'DD-MM-YYYY').toDate().getTime() -
              moment(a, 'DD-MM-YYYY').toDate().getTime()
          )
          .reverse()
          .map((date) => (
            <div
              key={date}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <span style={{ marginLeft: '50px' }}>{date}</span>
              {groupedActivities[date]
                .sort(
                  (a, b) =>
                    moment(a.start_date).toDate().getTime() -
                    moment(b.start_date).toDate().getTime()
                )
                .map((activity) => (
                  <ActivityCard
                    key={activity.id}
                    activity={activity}
                    handleActivityClick={handleActivityClick}
                    routeMatch={routeMatch}
                  />
                ))}
            </div>
          ))}
      </div>
    );
  };

  const renderCalendarMode = () => {
    return (
      <ActivityCalendar
        setCalendarModeCurrentMonth={setCalendarModeCurrentMonth}
        calendarModeCurrentMonth={calendarModeCurrentMonth}
        activities={filtered}
        requests={requests}
        onActivityClick={(activity) => {
          handleActivityClick(activity);
        }}
        selectedActivityId={routeMatch?.params.activity}
        onCellClick={(date) => {
          setFromToDate({
            from: moment.utc(date.toString()).startOf('day').toDate(),
            to: moment
              .utc(date.toString())
              .add(1, 'day')
              .startOf('day')
              .toDate(),
          });
          setViewMode('list');
        }}
      />
    );
  };

  const renderTableMode = () => {
    return (
      <SchedulingTable
        setUpdatedActivitiesMap={setUpdatedActivitiesMap}
        updatedActivitiesMap={updatedActivitiesMap}
        activities={activitiesWithRequestNotes}
        onActivitySelect={(activity) => {
          handleActivityClick(activity);
        }}
        expanded={routeMatch?.params.activity === undefined}
        hiddenColumns={tableHiddenColumns}
        requests={requests}
      />
    );
  };

  if (viewMode === 'table') {
    return renderWithHeader(renderWithLoading(renderTableMode()));
  }

  if (viewMode === 'calendar') {
    return renderWithHeader(renderWithLoading(renderCalendarMode()));
  }

  return renderWithHeader(renderWithLoading(renderListMode()));
};
