import { ProgressBar } from '@blueprintjs/core';
import { Icon } from 'opencosmos-ui';
import { DetailsItem } from 'pages/ops/shared/components/DetailsItem';
import React, { useState } from 'react';

export type UFTTransportReply = {
  average_bandwidth_bytes_second: number;
  chunk_byte_size: number;
  correct_bytes_received: number;
  correlation_id: number;
  file_id: number;
  file_name: string;
  last_packet_received_at: string;
  md5_received: boolean;
  number_of_correct_chunks_received: number;
  number_of_incorrect_packets_received: number;
  number_of_repeated_chunks_received: number;
  progress_percentage: number;
  time_elapsed_since_last_packet_s: number;
  total_file_size_bytes: number;
  total_number_of_chunks: number;
  transfer_duration_s: number;
  transfer_started_at: string;
};

type Props = {
  response: UFTTransportReply;
};

const UFTTransportInfoReplyInfo = ({ response }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className="flex flex-col gap-1 text-sm w-full bg-neutral-300 mb-2 p-2 cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <DetailsItem title="File name" value={response.file_name} />
      <DetailsItem
        title="Correlation ID"
        value={String(response.correlation_id)}
      />
      <DetailsItem title="File ID" value={String(response.file_id)} />
      <div className="flex flex-col gap-1">
        <DetailsItem
          title="Progress"
          value={`${response.progress_percentage}%`}
        />
        <ProgressBar
          className="mr-1 ml-1"
          value={response.progress_percentage / 100}
          intent="success"
          animate={false}
        />
      </div>

      <div className="w-full flex items-center justify-center">
        <Icon icon={isOpen ? 'ChevronUp' : 'ChevronDown'} size={16} />
      </div>

      {isOpen && (
        <div className="flex flex-col gap-1 text-xs">
          <DetailsItem
            title={'Average bandwidth (B/s)'}
            value={response.average_bandwidth_bytes_second.toString()}
          />
          <DetailsItem
            title={'Chunk size'}
            value={response.chunk_byte_size.toString()}
          />
          <DetailsItem
            title={'Correct bytes received'}
            value={response.correct_bytes_received.toString()}
          />
          <DetailsItem
            title={'Last packet received at'}
            value={response.last_packet_received_at.toString()}
          />
          <DetailsItem
            title={'MD5 received'}
            value={response.md5_received.toString()}
          />
          <DetailsItem
            title={'No. of correct chunks received'}
            value={response.number_of_correct_chunks_received.toString()}
          />
          <DetailsItem
            title={'No. of incorrect packets received'}
            value={response.number_of_incorrect_packets_received.toString()}
          />
          <DetailsItem
            title={'No. of repeated chunks received'}
            value={response.number_of_repeated_chunks_received.toString()}
          />
          <DetailsItem
            title={'Time elapsed since last packet (s)'}
            value={response.time_elapsed_since_last_packet_s.toString()}
          />
          <DetailsItem
            title={'Total file size (bytes)'}
            value={response.total_file_size_bytes.toString()}
          />
          <DetailsItem
            title={'Total no. of chunks'}
            value={response.total_number_of_chunks.toString()}
          />
          <DetailsItem
            title={'Transfer duration (s)'}
            value={response.transfer_duration_s.toString()}
          />
          <DetailsItem
            title={'Transfer started at'}
            value={response.transfer_started_at.toString()}
          />
        </div>
      )}
    </div>
  );
};

export default UFTTransportInfoReplyInfo;
