import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  CellContext,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";

type Props<T> = {
  /**
   * Data to display in the table. Can be any array of any object.
   * The table will automatically generate columns based on the keys of the object.
   * If the object contains nested objects, the table will generate columns for the nested objects as well, containing the leaf keys of the nested object.
   *
   * NOTE: This **must** be a stable reference to avoid infinite re-renders, e.g using `useMemo`, `useState`, etc.
   */
  data: T[];
  /**
   * The keys to exclude from the data. This is useful for excluding keys that are not needed in the table.
   * By default, this is an empty array.
   */
  excludeDataKeys?: string[];
  /**
   * Function to render a cell in the table. Exposes the cell context and the header id
   * @param cellContext - The cell context; contains the cell value and other useful information. This is exposed from the @tanstack/react-table library
   * @param headerId - The id of the header that the cell belongs to. Useful for conditional rendering of certain cells.
   */
  cellRenderer: (
    cellContext: CellContext<T, unknown>,
    headerId: string
  ) => JSX.Element;
  /**
   * Whether to enable row selection or not
   * By default, this is false
   */
  enableRowSelection?: boolean;
  /**
   * Whether to enable multi-row selection or not
   */
  enableMultiRowSelection?: boolean;
  /**
   * Function to call when a row is selected. Exposes the selected row data
   * If no rows are selected, this will be undefined
   * If a single row is selected, this will be the selected row
   * If multiple rows are selected, this will be an array of the selected rows
   * @param rowData - The selected object from the row. If multiple rows are selected, this will be an array of objects from the selected rows.
   **/
  onRowSelect?: (rowData: T | T[] | undefined) => void;
  /**
   * The columns to hide. This is an array of strings that represent the column ids to hide
   */
  hiddenColumns?: string[];
  /**
   * Whether to show the full header title including the full path to the leaf node or not. By default, this is false.
   * If this is true, the header title will be the full path to the leaf node, e.g. `parent.child.leaf`
   * If this is false, the header title will be the leaf node, e.g. `leaf`
   */
  showFullHeaderTitle?: boolean;
};

type ColumnConfig<T> = {
  cell: (p: CellContext<T, unknown>) => JSX.Element;
  id: string;
  header: string;
};

const Table = <
  T extends Record<string, string | number | boolean | T | Object>
>({
  excludeDataKeys = [],
  enableRowSelection = false,
  data: dataState,
  showFullHeaderTitle = false,
  ...props
}: Props<T>) => {
  const columnHelper = createColumnHelper<T>();

  const getCols = (data: T, key: string = ""): ColumnConfig<T>[] => {
    return Object.entries(data ?? {})
      .filter(([k]) => !excludeDataKeys.includes(k))
      .flatMap(([k, value]) => {
        if (
          !Array.isArray(value) &&
          typeof value === "object" &&
          value !== null
        ) {
          return getCols(value as T, key !== "" ? `${key}.${k}` : k.toString());
        }

        const uniqueId = key !== "" ? `${key}.${k}` : (k.toString() as any);
        return columnHelper.accessor(uniqueId, {
          cell: (p) => props.cellRenderer(p, uniqueId),
          id: uniqueId,
          header: showFullHeaderTitle ? uniqueId : k.toString(),
        }) as ColumnConfig<T>;
      });
  };

  const defaultCols = useMemo(() => getCols(dataState[0]), []);

  const getDefaultHiddenColsAsObj = useCallback(() => {
    const hiddenCols = props.hiddenColumns ?? [];
    return hiddenCols.reduce((acc, col) => {
      acc[col] = false;
      return acc;
    }, {} as { [key: string]: boolean });
  }, [props.hiddenColumns]);

  const [selectedRows, setSelectedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [visibleColumns, setVisibleColumns] = useState(
    getDefaultHiddenColsAsObj()
  );

  const table = useReactTable({
    state: {
      rowSelection: selectedRows,
      columnVisibility: visibleColumns,
    },
    onRowSelectionChange: setSelectedRows,
    onColumnVisibilityChange: setVisibleColumns,
    data: dataState,
    columns: defaultCols,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: enableRowSelection,
    enableMultiRowSelection: props.enableMultiRowSelection ?? false,
  });

  useEffect(() => {
    const selectedRowsData = Object.keys(selectedRows).map(
      (k) => table.getRowModel().rows[Number(k)].original as T
    );

    if (selectedRowsData.length === 0) {
      props.onRowSelect?.(undefined);
    } else if (selectedRowsData.length === 1) {
      props.onRowSelect?.(selectedRowsData[0]);
    } else {
      props.onRowSelect?.(selectedRowsData);
    }
  }, [selectedRows]);

  useEffect(() => {
    setVisibleColumns(getDefaultHiddenColsAsObj());
  }, [getDefaultHiddenColsAsObj]);

  return (
    <table className="text-left w-full">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="h-8 bg-header dark:bg-header-dark min-w-max p-1 "
          >
            {headerGroup.headers.map((header) => (
              <th
                {...{
                  key: header.id,
                  colSpan: header.colSpan,
                  className:
                    "py-[2px] px-1 relative text-center h-8 border border-item-contrast-inactive",
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={classNames(
              "even:bg-item bg-item-dark-contrast dark:even:bg-item-dark dark:bg-item-contrast h-8",
              "hover:bg-item-hover dark:hover:bg-item-dark-hover",
              {
                "bg-item-selected even:bg-item-selected dark:even:bg-item-dark-selected dark:bg-item-dark-selected":
                  row.getIsSelected(),
              }
            )}
            onClick={(e) => {
              row.getToggleSelectedHandler()(e);
            }}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                {...{
                  key: cell.id,
                  className: classNames(
                    "whitespace-nowrap border border-item-contrast-inactive p-0 h-8",
                    {
                      "!cursor-pointer": enableRowSelection,
                    }
                  ),
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * Get the leaf columns of an object. This function will return an array of strings that represent the leaf columns of the object.
 * @param data - The object to get the leaf columns from
 * @param key - The key of the object. This is used for recursive calls to get the nested leaf columns
 */
const getLeafColumnIds = <
  T extends Record<string, string | number | boolean | T | Object>
>(
  data: T,
  excludeKeys: string[] = [],
  key: string = ""
): string[] => {
  return Object.entries(data)
    .filter(([k]) => !excludeKeys.includes(k))
    .flatMap(([k, value]) => {
      if (
        !Array.isArray(value) &&
        typeof value === "object" &&
        value !== null
      ) {
        return getLeafColumnIds(
          value as T,
          excludeKeys,
          key !== "" ? `${key}.${k}` : k.toString()
        );
      }

      const uniqueId = key !== "" ? `${key}.${k}` : (k.toString() as any);
      return uniqueId;
    });
};

export { Table, getLeafColumnIds };
export type { CellContext };
