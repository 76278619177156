import type { IconName } from '@blueprintjs/core';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';
import type { MaybeElement } from '@blueprintjs/core/src/common/props';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../pages/msd/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IMapWrapper } from '../../../../declarations/mapDeclarations/Map';
import type { ReactElement } from 'react';
import React from 'react';
import type { IAnyKey } from '../../../../utils/getFormData';

export interface IContextMenu {
  text: string;
  subItems: IContextMenuSub[];
}

export interface IContextMenuSub {
  text: string;
  icon: IconName | string;
  clickEvent?: (event: React.MouseEvent<HTMLElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.Component | any;
  subItems?: IContextMenuSub[];
  childrenProps?: object;
}

export interface IMenuHandler {
  fn: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg: any[];
  dispatch: boolean;
}

export const addContextMenu =
  (
    map: IMapWrapper,
    menuHandlers: IMenuHandler[],
    collapse: false,
    googleMouseEvent: google.maps.MouseEvent & IAnyKey
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, store): Promise<void> => {
    if (map.drawingManager.getDrawingMode()) {
      map.drawingManager.isGroundStation = false;
      map.drawingManager.setDrawingMode(null);
      return undefined;
    }
    const { popUp } = store();
    if (popUp && popUp.visible) {
      return undefined;
    }

    let e = null;
    //NB: google map types doesnt have Mouse Event with ClientX|ClientY
    try {
      for (const key in googleMouseEvent) {
        if (
          googleMouseEvent[key].clientX !== undefined &&
          googleMouseEvent[key].clientY
        ) {
          e = googleMouseEvent[key];
        }
      }
    } catch (e) {
      console.warn('Event type: ', googleMouseEvent);
    }
    if (!e) {
      e = googleMouseEvent as unknown as MouseEvent;
    }
    let dispatchedMenuHandlers: IContextMenu[] = menuHandlers.map(
      (h): IContextMenu => {
        return h.dispatch ? dispatch(h.fn(...h.arg)) : h.fn(...h.arg);
      }
    );
    // dispatchedMenuHandlers = dispatchedMenuHandlers.map(item => {
    //   if (dispatchedMenuHandlers.length === 1) {
    //     return { ...item.subItems };
    //   }
    //   return item;
    // });
    const menuItems = dispatchedMenuHandlers.map(
      (item, index): ReactElement => {
        const subItems = item.subItems.map((sItem, sIndex): ReactElement => {
          const icon = sItem.icon as IconName | MaybeElement;
          return (
            <MenuItem
              key={sIndex}
              icon={icon}
              text={sItem.text}
              // @ts-ignore
              onClick={sItem.clickEvent}
            >
              {sItem.subItems !== undefined
                ? sItem.subItems.map((s, i) => {
                    const icon = s.icon as IconName | MaybeElement;
                    return (
                      <MenuItem
                        key={i}
                        icon={icon}
                        text={s.text}
                        // @ts-ignore
                        onClick={s.clickEvent}
                      />
                    );
                  })
                : null}
              {/*{sItem.children ? <Children {...sItem.childrenProps} /> : null}*/}
            </MenuItem>
          );
        });
        if (collapse && dispatchedMenuHandlers.length === 1) {
          return <>{subItems}</>;
        }
        return (
          <MenuItem key={index} text={item.text}>
            {subItems}
          </MenuItem>
        );
      }
    );
    ContextMenu.show(<Menu>{menuItems}</Menu>, {
      left: e.clientX,
      top: e.clientY,
    });
  };
