import type { ItemProps } from 'ui/ItemContainer';
import { ItemContainer } from 'ui/ItemContainer';
import Input from '_molecules/Input/Input';

export type TextInputProps = ItemProps & {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
};

export const TextInput = ({
  value,
  setValue,
  placeholder,
  ...itemProps
}: TextInputProps) => {
  return (
    <ItemContainer {...itemProps}>
      <Input
        className="color-item"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
    </ItemContainer>
  );
};
