import React, { useCallback } from 'react';
import { isString } from 'lodash/fp';
import s from '../index.module.scss';
import { Button, Icon, TextField } from 'opencosmos-ui';

export interface IProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  fullPath: string;
  value: string;
  disabled: boolean;
  path: string;
  meta?: string;
  showInfoMark?: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  handleMetaButtonClick: ((value: string) => void) | undefined;
  validateChange: Function;
  setDefaultValue: Function;
  setTooltipOpen: (open: boolean) => void;
  multiline: boolean;
}

const CommandStringInput = (props: IProps) => {
  const {
    inputRef,
    className,
    value,
    onChange,
    fullPath,
    disabled,
    validateChange,
    handleBlur,
    handleFocus,
    path,
    meta,
    showInfoMark,
    handleMetaButtonClick,
    setDefaultValue,
    setTooltipOpen,
    multiline,
  } = props;

  setDefaultValue(!isString(value));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateChange(e);
    onChange(e.target.value, fullPath);
  };

  const handleMetaClick = useCallback(
    () => handleMetaButtonClick?.(meta!),
    [handleMetaButtonClick, meta]
  );

  const infoIconStyles = meta
    ? [s.iconWithInput, s.iconWithInputExplore].join(' ')
    : s.iconWithInput;

  return (
    <div className="w-full flex items-center gap-2">
      {multiline ? (
        <textarea
          ref={inputRef as unknown as React.RefObject<HTMLTextAreaElement>}
          id={path || 'input_str'}
          name={path || 'input_str'}
          disabled={disabled}
          className={className}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onMouseOver={!disabled ? handleFocus : undefined}
          value={isString(value) ? value : ''}
          onChange={
            handleInputChange as unknown as React.ChangeEventHandler<HTMLTextAreaElement>
          }
          onMouseLeave={() => {
            setTooltipOpen(false);
          }}
          style={{
            height: '300px',
          }}
        />
      ) : (
        <TextField
          fill={true}
          id={path || 'input_str'}
          name={path || 'input_str'}
          isDisabled={disabled}
          className={className}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={isString(value) ? value : ''}
          onChange={(val: string) =>
            handleInputChange({
              target: { value: val },
            } as React.ChangeEvent<HTMLInputElement>)
          }
          inputMode="text"
          inputProps={{
            onMouseLeave: () => {
              setTooltipOpen(false);
            },
            onMouseOver: !disabled ? handleFocus : undefined,
            inputRef,
          }}
        />
      )}
      {showInfoMark && <Icon className={infoIconStyles} icon={'info-sign'} />}
      {meta && <Button onPress={handleMetaClick}>Explore</Button>}
    </div>
  );
};

export default CommandStringInput;
