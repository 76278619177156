import {
  isEqual,
  remove,
  sortBy,
  find,
  map,
  isEmpty,
  keys,
  pipe,
  isUndefined,
} from 'lodash/fp';
import useLocalStorage from '../../../../../../utils/hooks/useLocalStorage';
import CommandListSingleBody from './CommandListSingleBody';
import { FAVORITES_COMMANDS_GROUP } from '../../../../../../constants/ops/rti/oparate/constants';
import s from './index.module.scss';
import type { CommandDefinition } from 'api/telecommands/types';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { UnorderedList } from 'opencosmos-ui';
import { ListOrientation } from 'opencosmos-ui/src/core/UnorderedList/UnorderedList';
import CommandListGroupBody from './CommandListGroupBody';

export interface IProps {
  loading: boolean;
  availableCommands: CommandDefinition[];
  handleCommandClick: (cmd: CommandDefinition) => void;
}

export interface ICommandGroup {
  name: string;
  commands: (CommandDefinition | undefined)[]; //TODO: should these be allowed to be undefined?
}
interface IGroup {
  [key: string]: CommandDefinition[];
}

const DEFAULT_GROUP = 'Others';

const organiseCommands = (
  commands: CommandDefinition[],
  featuredCommands: (CommandDefinition | undefined)[]
): ICommandGroup[] => {
  const groups = commands.reduce((all: IGroup, cmd) => {
    const currentGroup = cmd.group ? cmd.group : DEFAULT_GROUP;

    if (isUndefined(all[currentGroup])) {
      all[currentGroup] = [];
    }

    all[currentGroup].push(cmd);

    return all;
  }, {});

  const sortedCommands = pipe(
    keys,
    map((gName) => ({
      name: gName,
      commands: groups[gName],
    })),
    sortBy('name'),
    (it: ICommandGroup[]) => it
  )(groups);

  return [
    { name: FAVORITES_COMMANDS_GROUP, commands: featuredCommands },
    ...sortedCommands,
  ];
};

const getFeaturedCommands = (
  featuredList: string[],
  commandList: CommandDefinition[]
) => {
  if (isEmpty(commandList)) return [];

  const mappedCommands = map(
    (cmdName) => find(['name', cmdName], commandList),
    featuredList
  );

  return sortBy('name', mappedCommands);
};

export const CommandList = (props: IProps) => {
  const [localStorage, setLocalStorage] = useLocalStorage<{
    commandsFeatures: string[];
  }>('rti_commands', {
    commandsFeatures: [],
  });

  const { commandsFeatures } = localStorage;

  const featuredCommands = getFeaturedCommands(
    commandsFeatures,
    props.availableCommands
  );
  const groups = organiseCommands(props.availableCommands, featuredCommands);

  const handleFeatureClick = (cmdName: string) => {
    if (commandsFeatures.includes(cmdName)) {
      const list = remove(isEqual(cmdName), commandsFeatures);
      setLocalStorage({ commandsFeatures: [...list] });
      return;
    }

    setLocalStorage({ commandsFeatures: [...commandsFeatures, cmdName] });
  };

  if (props.loading) {
    return (
      <div className={s.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <UnorderedList orientation={ListOrientation.Vertical}>
      {groups.map((group) => {
        return (
          <CommandListGroupBody
            key={group.name}
            groupName={group.name}
            defaultIcon="key-command"
          >
            {group.commands.map((cmd) => {
              if (cmd === undefined) {
                return null;
              }
              return (
                <CommandListSingleBody
                  key={cmd.name}
                  cmd={cmd}
                  isFeature={commandsFeatures.includes(cmd.name)}
                  handleFeatureClick={handleFeatureClick}
                  handleCommandClick={props.handleCommandClick}
                />
              );
            })}
          </CommandListGroupBody>
        );
      })}
    </UnorderedList>
  );
};
