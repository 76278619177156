import { Button, Select, ListBoxItem } from 'opencosmos-ui';
import { useState } from 'react';
import { getDownloadTypes } from '../Layers/LayerCards/helpers';
import type { DownloadType } from '../Layers/LayerCards/helpers';
import type { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import type { Layer } from 'datacosmos/entities/layer';
import type { CommonOpportunity } from 'api/tasking/helpers';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface Props {
  layer: Layer | undefined;
  defaultValue?: DownloadType['ext'];
  className?: string;
}

const DownloadLayerMenu = ({ layer, defaultValue, className }: Props) => {
  const [selectedDlType, setSelectedDlType] = useState<DownloadType['ext']>(
    defaultValue ?? 'geojson'
  );

  const { translate } = useLocalisation();

  return (
    <div className={className}>
      <Select
        items={getDownloadTypes(layer as OpportunityLayer<CommonOpportunity>)}
        selectedKey={selectedDlType}
        onSelectionChange={(k) => setSelectedDlType(k as DownloadType['ext'])}
      >
        {(item) => (
          <ListBoxItem id={item.ext} key={item.ext}>
            <div className="flex items-center justify-between gap-4">
              <span>
                {translate('datacosmos.rightClickMenu.download', {
                  ext: item.ext,
                })}
              </span>
              <Button
                isMinimal
                isTransparent
                icon="Download"
                onPress={() => item.handler()}
              />
            </div>
          </ListBoxItem>
        )}
      </Select>
    </div>
  );
};

export default DownloadLayerMenu;
