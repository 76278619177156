import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import {
  useTooltip,
  useTooltipTrigger,
  useOverlayPosition,
  OverlayContainer,
} from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';
import Icon from '_atoms/Icon/Icon';
import Overlay from '_atoms/Overlay/Overlay';
import type { ITooltipProps } from '_atoms/Tooltip/Tooltip';
import IconButton from '_molecules/IconButton/IconButton';

interface IDetailedTooltipProps extends ITooltipProps {
  description: string;
  iconClassName?: string;
}

const DetailedTooltip = (props: IDetailedTooltipProps) => {
  const state = useTooltipTriggerState(props);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);

  const { tooltipProps } = useTooltip(props, state);
  const { triggerProps } = useTooltipTrigger(
    { ...props, delay: 0 },
    state,
    triggerRef
  );

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: tooltipRef,
    placement: 'top end',
    isOpen: state.isOpen,
  });

  return (
    <>
      <button
        {...triggerProps}
        ref={triggerRef}
        onClick={() => {}}
        className="flex items-center gap-1"
      >
        {props.children}

        <Icon className={classNames(props.iconClassName)} icon="Info" />
      </button>

      {state.isOpen && (
        <OverlayContainer>
          <Overlay
            isOpen={state.isOpen}
            onClose={() => state.close()}
            {...positionProps}
            {...tooltipProps}
            overlayRef={tooltipRef}
            className="w-fit p-1 color-item max-w-xs shadow-sm shadow-item-contrast-text-field-stroke dark:shadow-item-contrast-selected rounded-none"
          >
            <div>
              <div
                className="flex items-center w-full relative justify-between"
                onMouseEnter={() => state.open(true)}
                onMouseLeave={() => state.close()}
              >
                {/* TODO: Spans should not have onClick without a button role */}
                <span
                  className="text-sm flex items-center cursor-pointer"
                  onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                >
                  {props.content}
                  <Icon
                    icon={isDescriptionOpen ? 'ChevronUp' : 'ChevronDown'}
                    className={classNames(props.iconClassName)}
                  />
                </span>
                <IconButton
                  iconClassName={classNames(props.iconClassName)}
                  icon="Close"
                  onPress={() => state.close(true)}
                />
              </div>

              {isDescriptionOpen && (
                <div
                  onMouseEnter={() => state.open(true)}
                  onMouseLeave={() => state.close()}
                  className="text-xs mt-1"
                >
                  {props.description}
                </div>
              )}
            </div>
          </Overlay>
        </OverlayContainer>
      )}
    </>
  );
};

export default DetailedTooltip;
