import { assoc, find } from 'lodash/fp';
import s from './index.module.scss';

import { v1 as uuid } from 'uuid';
import CommandArguments from '../../../RTI/Operate/components/CommandArguments';
import type {
  RadioTransformer,
  Schema,
  TransformerBody,
} from 'api/gateway/types';
import { Button, Group, Select, SelectValue, Tooltip } from 'opencosmos-ui';

interface IProps {
  defaultSelect: string;
  schemas: Schema[];
  cancelCreation: () => void;
  handleSaveClick: () => void;
  handleNewItemChange: (item: TransformerBody) => void;
  payload?: RadioTransformer;
}

const LibraryNewTransformer = (props: IProps) => {
  const currentTransformer = props.payload?.transformer;

  const handlePayloadChange = (path: string, value: unknown) => {
    if (!props.payload) {
      return;
    }

    const newPayload = assoc(`options.${path}`, value, props.payload);
    if (!newPayload) {
      return;
    }

    props.handleNewItemChange({
      ...newPayload,
    });
  };

  const handleTransformerChange = (transformer: string) => {
    props.handleNewItemChange({
      transformer: transformer,
      options: {},
      id: uuid(),
    });
  };

  return (
    <div className={s.libraryNewTransformer}>
      <div className={s.libraryNewTransformerWrapper}>
        <Select
          fill
          selectedKey={currentTransformer ?? props.defaultSelect}
          onSelectionChange={(key) => {
            handleTransformerChange(key.toString());
          }}
        >
          {props.schemas?.map((it: Schema) => (
            <SelectValue key={it.name} id={it.name}>
              {it.name}
            </SelectValue>
          ))}
        </Select>
        <Group>
          <Tooltip
            delay={0}
            content={
              props.payload?.transformer
                ? 'Confirm adding transformer'
                : 'Select a transformer'
            }
          >
            <Button
              icon="confirm"
              intent={'primary'}
              onPress={props.handleSaveClick}
              isDisabled={!props.payload?.transformer}
            />
          </Tooltip>
          <Tooltip delay={0} content="Cancel adding transformer">
            <Button icon="undo" onPress={props.cancelCreation} />
          </Tooltip>
        </Group>
      </div>
      {props.schemas && currentTransformer && (
        <CommandArguments
          commandProperties={
            find(['name', currentTransformer], props.schemas)?.schema
          }
          commandPayload={props.payload?.options}
          handleCommandPayloadChange={handlePayloadChange}
          handleCommandPayloadBlur={() => {}}
        />
      )}
    </div>
  );
};

export default LibraryNewTransformer;
