import React, { useRef } from 'react';
import {
  OverlayContainer,
  useOverlayPosition,
  useTooltip,
  useTooltipTrigger,
} from 'react-aria';
import type {
  AriaTooltipProps,
  TooltipTriggerProps,
} from '@react-types/tooltip';
import { useTooltipTriggerState } from 'react-stately';
import Overlay from '_atoms/Overlay/Overlay';
import type { Placement } from '@react-types/overlays/src/index';
import { zIndexValues } from 'opencosmos-ui/constants';

export interface ITooltipProps extends AriaTooltipProps, TooltipTriggerProps {
  children: React.ReactNode;
  content: string;
  onPress?: () => void;
  placement?: Placement;
}

const Tooltip = (props: ITooltipProps) => {
  const state = useTooltipTriggerState(props);
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { tooltipProps } = useTooltip(props, state);
  const { triggerProps } = useTooltipTrigger(props, state, triggerRef);

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: tooltipRef,
    placement: props.placement ?? 'top end',
    isOpen: state.isOpen,
  });

  return (
    <>
      <div {...triggerProps} ref={triggerRef} onClick={props.onPress}>
        {props.children}
      </div>

      {state.isOpen && (
        <OverlayContainer>
          <Overlay
            isOpen={state.isOpen}
            onClose={() => state.close()}
            {...tooltipProps}
            style={{ ...positionProps.style, zIndex: zIndexValues.tooltip }}
            overlayRef={tooltipRef}
            className="w-fit p-1 color-item rounded-none"
          >
            <span className="text-sm"> {props.content}</span>
          </Overlay>
        </OverlayContainer>
      )}
    </>
  );
};

export default Tooltip;
