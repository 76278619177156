import React, { useMemo, useState } from 'react';
import { find } from 'lodash/fp';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { last } from 'lodash';

import { useApiCloudStorageContent } from 'services/api/ops/cloudStorage';
import type { UnarmedCommand } from 'services/api/ops/realtimeTerminalSession';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import {
  copyCommandToEditor,
  pastCommandFromClipboard,
  saveCloudStoragePath,
} from 'actions/operate/actions';
import { CLOUD_FILE, SATELLITE_FILE } from 'constants/dragAndDrop/constants';
import { ops } from 'constants/mixpanelAnalytics';
import useOnLoadMetric from 'utils/hooks/analytics/useOnLoadMetric';
import { computePath } from 'utils/fileExplorer/satelliteUtils';
import type { INavigateToFile } from 'pages/ops/RTI/Operate/components/FileTransfer';
import FileTransfer from 'pages/ops/RTI/Operate/components/FileTransfer';
import type { ITransferAction } from 'pages/ops/shared/context/TransferProgressProvider';
import { useTransferProgress } from 'pages/ops/shared/context/TransferProgressProvider';
import type { ISatelliteStorageApi } from 'services/api/ops/satelliteStorage';
import {
  AVAILABLE_CLOUD_STORAGE,
  FTP_SATELLITE_SERVICES,
} from 'constants/fileExplorer/constants';
import { FILE_TRANSFER_TYPE_TEXT } from 'constants/ops/rti/oparate/constants';
import { removeFirstSlash } from 'utils/common/stringUtils';

import CollapseLibraryList from './CollapseLibraryList';
import LibraryFileExplorer from './LibraryFileExplorer';
import SatelliteFileExplorer from './SatelliteFileExplorer';
import ServiceSelector from './ServiceSelector';
import type { TrackingEvent } from 'utils/hooks/analytics/useAnalytics';
import type { CommandDefinition } from 'api/telecommands/types';

interface IProps {
  satelliteApi: ISatelliteStorageApi;
  missionId?: number;
  availableCommands: CommandDefinition[];
  setRightSideHidden: (value: boolean) => void;
  sendUnarmedCommand: UnarmedCommand;
  cancelSentCommand: Function;
}

const LibraryFileTab = ({
  missionId,
  availableCommands,
  setRightSideHidden,
  sendUnarmedCommand,
  satelliteApi,
  cancelSentCommand,
}: IProps) => {
  const {
    state: { cloudStorageSavedPath },
    dispatch,
  } = useOperate();

  const transferProgress = useTransferProgress();

  const memodDispatchTransferUpdate = useMemo(
    () => (action: ITransferAction) =>
      transferProgress?.dispatchTransferUpdate(action),
    [transferProgress]
  );
  const cloudApi = useApiCloudStorageContent(
    missionId,
    memodDispatchTransferUpdate
  );

  const trackingEvent: TrackingEvent = useMemo(
    () => ({
      action: 'Visit',
      item: `Mission ${missionId ?? 'n/a'} RTI File tab`,
      module: 'OPS',
      type: ops.RTI.FILE_TAB.VISIT,
      additionalParams: { mission: missionId },
    }),
    [missionId]
  );

  useOnLoadMetric(trackingEvent);

  const [isRefreshingCloudStorage, setRefreshingCloudStorage] = useState(false);
  const [isRefreshingAllowedCloudStorage, setRefreshingAllowedCloudStorage] =
    useState(false);
  const [isRefreshingSatelliteStorage, setRefreshingSatelliteStorage] =
    useState(false);
  const [
    isRefreshingAllowedSatelliteStore,
    setRefreshingAllowedSatelliteStorage,
  ] = useState(false);

  const handleCloudStorageDropItem = useMemo(
    () => (fromPath: string, toPath: string) => {
      const computedPath = computePath(toPath, fromPath);
      const service = FTP_SATELLITE_SERVICES.find(
        (item) => item.name === satelliteApi.currentService
      )?.transferName;

      const command = find(['name', 'FtpUpload'], availableCommands);
      if (!command) {
        return;
      }

      if (!service) {
        return;
      }

      setRightSideHidden(false);
      dispatch(
        pastCommandFromClipboard(command, {
          satelliteService: service,
          destination: computedPath.toPath,
          source: computedPath.fromPath,
        })
      );
    },
    [
      availableCommands,
      dispatch,
      satelliteApi.currentService,
      setRightSideHidden,
    ]
  );

  const handleSatelliteStorageDropItem =
    (commandName: string) => (fromPath: string, toPath: string) => {
      const computedPath = computePath(toPath, fromPath);
      const service = FTP_SATELLITE_SERVICES.find(
        (item) => item.name === satelliteApi.currentService
      )?.transferName;

      const command = find(['name', commandName], availableCommands);
      if (!command) {
        return;
      }

      if (!service) {
        return;
      }

      setRightSideHidden(false);
      dispatch(
        pastCommandFromClipboard(command, {
          satelliteService: service,
          destination: computedPath.toPath,
          source: computedPath.fromPath,
        })
      );
    };

  const setCloudStoragePath = useMemo(
    () => (path: string) => {
      dispatch(saveCloudStoragePath(path));
    },
    [dispatch]
  );

  const handleRefreshCloudStorageClick = () => {
    setRefreshingCloudStorage(true);
  };

  const handleRefreshSatelliteStorageClick = () => {
    setRefreshingSatelliteStorage(true);
  };

  const handleCancelFileTransfer = (
    id: string,
    transferType: string,
    transferComplete: boolean
  ) => {
    const isSatelliteFileTransfer =
      transferType === FILE_TRANSFER_TYPE_TEXT.SATELLITE_TO_CLOUD ||
      transferType === FILE_TRANSFER_TYPE_TEXT.CLOUD_TO_SATELLITE;

    if (!isSatelliteFileTransfer) {
      transferProgress.removeTransfer(id);
      return;
    }

    if (transferComplete) {
      transferProgress.removeTransfer(id);
      return;
    }

    const ftpStop = find(['name', 'FtpStop'], availableCommands);
    if (!ftpStop) return;
    dispatch(
      copyCommandToEditor(
        ftpStop,
        { satelliteService: 'piu_main_backdoor' },
        undefined
      )
    );
  };

  const navigateToFile: INavigateToFile = (file, wasDestinationClicked) => {
    let path = wasDestinationClicked ? file.destination : file.name;
    if (!path) return;
    let folderStructure = path.split('/');

    const notAlreadyNavigatingToFolder = last(folderStructure) !== '';
    if (notAlreadyNavigatingToFolder) {
      path =
        folderStructure.slice(0, folderStructure.length - 1).join('/') + '/';
    }

    const navigateCloud =
      (file.transferType === FILE_TRANSFER_TYPE_TEXT.SATELLITE_TO_CLOUD &&
        wasDestinationClicked) ||
      (file.transferType === FILE_TRANSFER_TYPE_TEXT.CLOUD_TO_SATELLITE &&
        !wasDestinationClicked);

    if (navigateCloud) {
      path = removeFirstSlash(path);
      folderStructure = path.split('/');

      const pathIgnoringCloudPrefix = folderStructure
        .slice(2, folderStructure.length)
        .join('/');
      cloudApi.setCurrentService?.(folderStructure[0]);
      cloudApi.setContentPath(pathIgnoringCloudPrefix);
      return;
    }

    const navigateSatellite =
      (file.transferType === FILE_TRANSFER_TYPE_TEXT.SATELLITE_TO_CLOUD &&
        !wasDestinationClicked) ||
      (file.transferType === FILE_TRANSFER_TYPE_TEXT.CLOUD_TO_SATELLITE &&
        wasDestinationClicked);
    if (navigateSatellite) {
      satelliteApi.setContentPath(path);
      return;
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <CollapseLibraryList
        stores={[
          {
            tagTitle: 'Cloud Storage',
            //TODO: Bp icon; replace with own custom eventually
            tagIcon: 'folder-shared',
            collapsed: true,
            handleRefreshClick: isRefreshingAllowedCloudStorage
              ? handleRefreshCloudStorageClick
              : undefined,
            children: (
              <ServiceSelector
                name="cloud"
                currentService={cloudApi.currentService}
                isFetching={cloudApi.isFetching}
                failedToFetch={false}
                options={AVAILABLE_CLOUD_STORAGE}
                setCurrentService={cloudApi.setCurrentService}
                currentPath={satelliteApi.contentPath}
                setFilePath={setCloudStoragePath}
              >
                <LibraryFileExplorer
                  simplifiedExplorer
                  missionId={missionId ?? 0}
                  setRefreshing={setRefreshingCloudStorage}
                  isRefreshing={isRefreshingCloudStorage}
                  setRefreshingAllowed={setRefreshingAllowedCloudStorage}
                  storedPath={cloudStorageSavedPath}
                  setStoredPath={setCloudStoragePath}
                  dragType={CLOUD_FILE}
                  acceptedDropType={SATELLITE_FILE}
                  onDropFile={handleCloudStorageDropItem}
                  uploadType={FILE_TRANSFER_TYPE_TEXT.PC_TO_CLOUD}
                  canCreateFolder
                  canUploadFile
                  {...cloudApi}
                />
              </ServiceSelector>
            ),
          },
          {
            tagTitle: 'Transfer Status',
            //TODO: Bp icon; replace with own custom eventually
            tagIcon: 'exchange',
            collapsed: true,
            children: (
              <FileTransfer
                activeTransfers={transferProgress.activeTransfersList}
                removeTransfer={handleCancelFileTransfer}
                navigateToFile={navigateToFile}
              />
            ),
          },
          {
            tagTitle: 'Satellite Storage',
            tagIcon: 'Satellite',
            handleRefreshClick: isRefreshingAllowedSatelliteStore
              ? handleRefreshSatelliteStorageClick
              : undefined,
            collapsed: true,
            children: (
              <SatelliteFileExplorer
                satelliteApi={satelliteApi}
                cancelSentCommand={cancelSentCommand}
                setRightSideHidden={setRightSideHidden}
                sendUnarmedCommand={sendUnarmedCommand}
                availableCommands={availableCommands}
                setRefreshingSatelliteStorage={setRefreshingSatelliteStorage}
                isRefreshingSatelliteStorage={isRefreshingSatelliteStorage}
                setRefreshingAllowedSatelliteStorage={
                  setRefreshingAllowedSatelliteStorage
                }
                handleLibraryDropItem={handleSatelliteStorageDropItem}
              />
            ),
          },
        ]}
      />
    </DndProvider>
  );
};

export default LibraryFileTab;
