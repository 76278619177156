import AlertsFilter from './AlertsFilter';
import s from '../LibraryTelemetry/index.module.scss';
import type { AlertRule } from 'api/alerts/types';
import TagHeader from 'components/common/TagHeader';

interface IProps {
  title: string;
  alerts: AlertRule[] | undefined;
  handleFilteredTelemetries: (values: AlertRule[]) => void;
}

const LibraryAlertsHeader = (props: IProps) => (
  <div className={s.variablesTitle}>
    <TagHeader
      title={
        <div className={s.libraryHeader}>
          <div className={s.filterContainer}>
            <span>{props.title}</span>
            <AlertsFilter
              alerts={props.alerts as []}
              handleFilteredValue={(alerts) =>
                props.handleFilteredTelemetries(alerts as AlertRule[])
              }
            />
          </div>
        </div>
      }
    />
  </div>
);

export default LibraryAlertsHeader;
