/**
 * Standard z-index values used throughout opencosmos-ui components.
 * While adding new ones, ensure to maintain standard differences, i.e., 200, 400, 600, 800, and so on.
 *
 * @property {number} default - Default z-index value (1)
 * @property {number} map - z-index value for map components (10)
 * @property {number} popover - z-index value for popovers (100)
 * @property {number} dialog - z-index value for dialogs (200)
 * @property {number} select - z-index value for select dropdowns (400)
 * @property {number} tooltip - z-index value for tooltips (600)
 * @property {number} toasts - z-index value for toast notifications (800)
 * @property {number} footer - z-index value for footer (100)
 *
 */

export const zIndexValues = {
  default: 1,
  map: 10,
  popover: 100,
  dialog: 200,
  select: 400,
  tooltip: 600,
  toasts: 800,
  footer: 1000,
};
