import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import { range } from 'lodash';
import { useEffect, useState } from 'react';
import { Tooltip } from 'ui/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button, DialogTrigger, Icon } from 'opencosmos-ui';
import Dialog from './ControlDialog';

type Props = {
  disabled: boolean;
  contrastSupportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
};

const ContrastControl = (props: Props) => {
  const { translate } = useLocalisation();
  const [contrastLock, setContrastLock] = useState(true);
  const [biasLock, setBiasLock] = useState(true);

  // TODO: Use statistics from selected layers, not from the latest image
  // that has been displayed
  const channelCount =
    tilingApi.statistics && Object.keys(tilingApi.statistics).length;

  const [contrast, setContrast] = useState<number[]>([]);
  const [contrastBias, setContrastBias] = useState<number[]>([]);

  let rContrast = 0;
  let gContrast = 0;
  let bContrast = 0;

  let rBias = 0.5;
  let gBias = 0.5;
  let bBias = 0.5;

  if (
    channelCount === 1 &&
    props.contrastSupportedLayers[0]?.options.contrast &&
    typeof props.contrastSupportedLayers[0]?.options.contrast !== 'number' &&
    props.contrastSupportedLayers[0]?.options.contrastBias &&
    typeof props.contrastSupportedLayers[0]?.options.contrastBias !== 'number'
  ) {
    rContrast = props.contrastSupportedLayers[0].options.contrast.r;
    gContrast = props.contrastSupportedLayers[0].options.contrast.g;
    bContrast = props.contrastSupportedLayers[0].options.contrast.b;

    rBias = props.contrastSupportedLayers[0].options.contrastBias.r;
    gBias = props.contrastSupportedLayers[0].options.contrastBias.g;
    bBias = props.contrastSupportedLayers[0].options.contrastBias.b;
  }

  useEffect(() => {
    if (
      typeof props.contrastSupportedLayers[0]?.options.contrast === 'number' &&
      props.contrastSupportedLayers[0]?.options.contrast === 1
    ) {
      setContrast([0, 0, 0]);
    }

    if (
      typeof props.contrastSupportedLayers[0]?.options.contrastBias ===
        'number' &&
      props.contrastSupportedLayers[0]?.options.contrastBias === 0.5
    ) {
      setContrastBias([0.5, 0.5, 0.5]);
    }
  }, [props.contrastSupportedLayers]);

  useEffect(() => {
    setContrast([rContrast, gContrast, bContrast]);
    setContrastBias([rBias, gBias, bBias]);
  }, [channelCount, rContrast, gContrast, bContrast, rBias, gBias, bBias]);

  const getLabelText = (i: number) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return '-';
    }
    if (i === 0) return 'R';
    if (i === 1) return 'G';
    if (i === 2) return 'B';
    return '';
  };

  /**
   * getIndexBasedContrastAmount returns the contrast amount for a specific channel index
   * R = 0, G = 1, B = 2
   *
   * This function uses the directly passed sliderValue to update the contrast amount for the currently
   * dragged slider. The other contrast amounts are taken from the contrast state. This avoids previous state
   * values being used when clicking on a value in the slider.
   * @param i channel index
   * @param sliderValue contrast slider value
   * @returns up to date contrast amount
   */
  const getIndexBasedContrastAmount = (
    mode: 'single' | 'multiple',
    i: number,
    sliderValue: number
  ) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return sliderValue;
    }

    if (mode === 'single') {
      return {
        r: sliderValue,
        g: sliderValue,
        b: sliderValue,
      };
    }

    if (i === 0) {
      return {
        r: sliderValue,
        g: contrast[1],
        b: contrast[2],
      };
    }
    if (i === 1) {
      return {
        r: contrast[0],
        g: sliderValue,
        b: contrast[2],
      };
    }
    if (i === 2) {
      return {
        r: contrast[0],
        g: contrast[1],
        b: sliderValue,
      };
    }

    return 0;
  };

  const saveContrastAmount = (
    mode: 'single' | 'multiple',
    index: number,
    val: number
  ) => {
    setContrast((prev) => {
      const newArr = prev.slice();
      newArr[index] = val;
      if (mode === 'single') {
        newArr[0] = val;
        newArr[1] = val;
        newArr[2] = val;
      }
      return newArr;
    });

    const contrastAmount = getIndexBasedContrastAmount(mode, index, val);
    props.replaceLayer(
      props.contrastSupportedLayers[0].cloneWithOptions({
        contrast: contrastAmount,
        isSettingContrast: true,
      })
    );
  };

  /**
   * getIndexBasedContrastBiasAmount returns the contrast bias amount for a specific channel index
   * R = 0, G = 1, B = 2
   *
   * This function uses the directly passed sliderValue to update the contrast bias amount for the currently
   * dragged slider. The other contrast bias amounts are taken from the contrast state. This avoids previous state
   * values being used when clicking on a value in the slider.
   * @param i channel index
   * @param sliderValue contrast bias slider value
   * @returns up to date contrast bias amount
   */
  const getIndexBasedContrastBiasAmount = (
    mode: 'single' | 'multiple',
    i: number,
    sliderValue: number
  ) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return sliderValue;
    }

    if (mode === 'single') {
      return {
        r: sliderValue,
        g: sliderValue,
        b: sliderValue,
      };
    }

    if (i === 0) {
      return {
        r: sliderValue,
        g: contrastBias[1],
        b: contrastBias[2],
      };
    }
    if (i === 1) {
      return {
        r: contrastBias[0],
        g: sliderValue,
        b: contrastBias[2],
      };
    }
    if (i === 2) {
      return {
        r: contrastBias[0],
        g: contrastBias[1],
        b: sliderValue,
      };
    }

    return 0;
  };

  const saveBiasAmount = (
    mode: 'single' | 'multiple',
    index: number,
    val: number
  ) => {
    setContrastBias((prev) => {
      const newArr = prev.slice();
      newArr[index] = val;
      if (mode === 'single') {
        newArr[0] = val;
        newArr[1] = val;
        newArr[2] = val;
      }
      return newArr;
    });

    const biasAmount = getIndexBasedContrastBiasAmount(mode, index, val);
    props.replaceLayer(
      props.contrastSupportedLayers[0].cloneWithOptions({
        contrastBias: biasAmount,
        isSettingContrast: true,
      })
    );
  };

  const contrastSlider = (mode: 'single' | 'multiple', index: number = 0) => {
    return (
      <RangeSlider
        className={mode === 'single' ? 'px-2' : ''}
        showScale={false}
        showValuesAboveHandles
        numberOfHandles={1}
        minValue={-20}
        step={0.5}
        maxValue={20}
        value={[contrast[index]]}
        onChange={(val) => {
          setContrast((prev) => {
            const newArr = prev.slice();
            newArr[index] = val[0];
            if (mode === 'single') {
              newArr[0] = val[0];
              newArr[1] = val[0];
              newArr[2] = val[0];
            }
            return newArr;
          });
          props.contrastSupportedLayers[0].setSettingContrastOn();
        }}
        onChangeEnd={(val) => {
          saveContrastAmount(mode, index, val[0]);
        }}
      />
    );
  };

  const biasSlider = (mode: 'single' | 'multiple', index: number = 0) => {
    return (
      <RangeSlider
        className={mode === 'single' ? 'px-2' : ''}
        showScale={false}
        showValuesAboveHandles
        numberOfHandles={1}
        minValue={0}
        step={0.1}
        maxValue={1}
        value={[contrastBias[index]]}
        onChange={(val) => {
          setContrastBias((prev) => {
            const newArr = prev.slice();
            newArr[index] = val[0];
            if (mode === 'single') {
              newArr[0] = val[0];
              newArr[1] = val[0];
              newArr[2] = val[0];
            }
            return newArr;
          });
          props.contrastSupportedLayers[0].setSettingContrastOn();
        }}
        onChangeEnd={(val) => {
          saveBiasAmount(mode, index, val[0]);
        }}
      />
    );
  };

  const renderContrastSlider = () => {
    if (contrastLock || channelCount === 1) {
      return contrastSlider('single');
    }

    if (channelCount) {
      return range(channelCount).map((i) => (
        <div key={i} className="px-2 gap-2 flex items-center">
          <span className="w-2 -translate-y-[1px]">{getLabelText(i)}</span>
          {contrastSlider('multiple', i)}
        </div>
      ));
    }

    return null;
  };

  const renderBiasSlider = () => {
    if (biasLock || channelCount === 1) {
      return biasSlider('single');
    }

    if (channelCount) {
      return range(channelCount).map((i) => (
        <div key={i} className="px-2 gap-2 flex items-center">
          <span className="w-2 -translate-y-[1px]">{getLabelText(i)}</span>
          {biasSlider('multiple', i)}
        </div>
      ));
    }

    return null;
  };

  return (
    <DialogTrigger>
      <Tooltip
        content={
          props.disabled
            ? `${translate('datacosmos.layers.contrast')}-${translate(
                'datacosmos.layers.layerOperationDisabled'
              )}`
            : translate('datacosmos.layers.contrast')
        }
      >
        <Button
          icon="ImageContrast"
          size="lg"
          className={`px-0 ${
            props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }`}
          isDisabled={props.disabled}
          isMinimal
          isTransparent
        />
      </Tooltip>
      <Dialog>
        <div data-testid="brightness-popup">
          {channelCount ? null : <span>Loading...</span>}
          <label className="flex px-2 gap-2 items-center">
            <Icon icon="ImageContrast" />
            {translate('datacosmos.layers.contrast')}
            <div className="flex-1" />
            {channelCount > 1 && (
              <Button
                icon={contrastLock ? 'lock' : 'unlock'}
                onPress={() => {
                  if (!contrastLock) {
                    saveContrastAmount('single', 0, contrast[0]);
                  }

                  setContrastLock(!contrastLock);
                }}
                isMinimal
                isTransparent
              />
            )}
          </label>
          {renderContrastSlider()}

          <label className="flex pt-2 px-2 gap-2 items-center">
            <Icon icon="ImageContrast" />
            Bias
            <div className="flex-1" />
            {channelCount > 1 && (
              <Button
                icon={biasLock ? 'lock' : 'unlock'}
                onPress={() => {
                  if (!biasLock) {
                    saveBiasAmount('single', 0, contrastBias[0]);
                  }

                  setBiasLock(!biasLock);
                }}
                isMinimal
                isTransparent
              />
            )}
          </label>
          {renderBiasSlider()}
        </div>
      </Dialog>
    </DialogTrigger>
  );
};

export default ContrastControl;
