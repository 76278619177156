import React from 'react';
import type { IBreadcrumbProps, IconName } from '@blueprintjs/core';
import { Breadcrumbs, NonIdealState } from '@blueprintjs/core';
import DefaultLayout from '../../../../../layouts/DefaultLayout';
import s from './index.module.scss';
import { IMAGE_PATHS } from '../../../../../constants/imagePaths';

interface IProps {
  breadcrumbs?: IBreadcrumbProps[];
  showEmptyData?: boolean;
  emptyDataTitle?: string;
  emptyDataIcon?: IconName;
  graphic?: string;
  children: React.ReactNode;
}

const AdministrationLayout = (props: IProps) => {
  const renderEmptyData = (
    <div className={s.noDataContainer}>
      <NonIdealState icon={props.emptyDataIcon} title={props.emptyDataTitle} />
    </div>
  );

  return (
    <DefaultLayout
      light
      leftHeader={
        props.breadcrumbs && (
          <div className={s.headerContainer}>
            <Breadcrumbs items={props.breadcrumbs} />
          </div>
        )
      }
    >
      <div className={s.body}>
        <div className={s.main}>
          {props.showEmptyData ? (
            renderEmptyData
          ) : (
            <>
              <div className={s.title}>
                {props.graphic && (
                  <img
                    src={IMAGE_PATHS.ILLUSTRATIONS + props.graphic + '.svg'}
                    alt={props.graphic}
                  />
                )}
              </div>
              <div className={s.container}>{props.children}</div>
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AdministrationLayout;
