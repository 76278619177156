import tilingApi from 'datacosmos/services/tilingApi';
import { useAuth } from 'services/auth/AuthWrapper';
import { useCallback, useState } from 'react';
import type { ICOGMetadataForRange } from 'datacosmos/services/tilingApi/cogMetadata';

export interface IHistogramData {
  [name: string]: number | number[] | null;
}

const useCOGMetadata = (
  assetURL: string,
  contentType: string,
  region?: GeoJSON.Feature<GeoJSON.Polygon>
) => {
  const validAsset =
    contentType.includes('image/tiff') &&
    contentType.includes('application=geotiff') &&
    contentType.includes('profile=cloud-optimized');
  const [metadata, setMetadata] = useState<ICOGMetadataForRange>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { token } = useAuth();

  const fetchMetadata = useCallback(
    async (overrideRegion?: GeoJSON.Feature<GeoJSON.Polygon>) => {
      setIsFetching(true);

      const meta = await tilingApi.fetchMetadataForCOG(
        assetURL,
        token,
        overrideRegion ?? region
      );

      setIsFetching(false);
      setMetadata(meta);
    },
    [assetURL, region, token]
  );

  const getHistogramColor = (data: string) => {
    if (data.includes('red')) return '#FF695E';
    if (data.includes('green')) return '#5ABE96';
    if (data.includes('blue')) return '#5a89be';
    return '#E79531';
  };

  return {
    metadata,
    getHistogramColor,
    isFetching,
    validAsset,
    fetchMetadata,
  };
};

export default useCOGMetadata;
