import { DateAndTimePicker, parseDate } from 'opencosmos-ui';

export interface IProps {
  handleBlur: () => void;
  handleFocus: () => void;
  handleChange: Function;
  disabled: boolean;
  fullPath: string;
  value: Date;
  setTooltipOpen: (open: boolean) => void;
}

const CommandStringDateInput = (props: IProps) => {
  const {
    handleChange,
    disabled,
    value,
    fullPath,
    setTooltipOpen,
    handleBlur,
    handleFocus,
  } = props;

  return (
    <DateAndTimePicker
      data-testid="date-time-picker"
      outputFormat="YYYY-MM-DDTHH:mm:ss.sss[Z]"
      useTextDateInput={true}
      isDisabled={disabled}
      value={parseDate(value, 'Etc/UTC')}
      granularity="second"
      onChange={(date) => {
        handleChange(date.toDate().toISOString(), fullPath);
      }}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

export default CommandStringDateInput;
