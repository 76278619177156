import type { IObjectivesActionTypes } from '../../../../../../constants/objectivesTable/actionTypes';
import {
  OBJECTIVES_REMOVE,
  OBJECTIVES_UPDATE,
} from '../../../../../../constants/objectivesTable/actionTypes';
import type { IOptimisationObjective } from '../../../../../../constants/objectivesTable/constants';

export function updateObjectives(
  objectives: IOptimisationObjective[]
): IObjectivesActionTypes {
  return {
    type: OBJECTIVES_UPDATE,
    objectives,
  };
}

export function removeObjectives(): IObjectivesActionTypes {
  return {
    type: OBJECTIVES_REMOVE,
  };
}
