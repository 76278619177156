import type {
  IPIState,
  IPointOfInterest,
} from '../../constants/pointsOfInterest/actionTypes';
import type {
  IGroundStation,
  IGSState,
} from '../../constants/groundStations/actionTypes';
import type {
  IRegionOfInterest,
  IRIState,
} from '../../constants/regionsOfInterest/actionTypes';
import type {
  IGeometryObject,
  IGeometryObjectState,
} from '../../pages/msd/shared/constants/geometry/interfaces';
import type {
  IGoal,
  IGoalReduceState,
} from '../../constants/goals/interfaceas';

export const generateName = (
  state:
    | IPIState
    | IGSState
    | IRIState
    | { list: IGeometryObjectState }
    | { list: IGoalReduceState }
    | any,
  subName: string
) => {
  let index = state.list.length + 1;
  let name = `${subName} ${index}`;
  while (
    !!state.list.find(
      (
        s:
          | IPointOfInterest
          | IGroundStation
          | IRegionOfInterest
          | IGeometryObject
          | IGoal
      ) => s.name === name
    )
  ) {
    index++;
    name = `${subName} ${index}`;
  }
  return name;
};
