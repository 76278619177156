import { setTerminalWorkSpace } from '../../../../../../../actions/operate/actions';
import type { ITransformResponseMappingBody } from './index';
import type { ICommandPileItem } from 'services/api/ops/realtimeTerminalSession';

export type TerminalWorkspaceTransformerData = {
  data: {
    armedCommand: ICommandPileItem | undefined;
    commandPileWorkingSpace: ICommandPileItem[];
    frozenPile: boolean;
  };
};

export default (dispatch: Function): ITransformResponseMappingBody => ({
  validator: null,
  transformer: (data) => {
    const d = data as TerminalWorkspaceTransformerData;

    const armedCommand = d.data.armedCommand;
    const commandPileWorkingSpace = d.data.commandPileWorkingSpace ?? [];
    const frozenPile = d.data.frozenPile ?? false;

    return {
      armedCommand,
      commandPileWorkingSpace,
      frozenPile,
    };
  },
  handler: (data) => {
    const d = data as TerminalWorkspaceTransformerData['data'];

    dispatch(
      setTerminalWorkSpace(
        d.armedCommand,
        d.commandPileWorkingSpace,
        d.frozenPile
      )
    );
  },
});
