import React, { useEffect, useState } from 'react';
import AlertsTable from './AlertsTable';
import MuteAlertForm from './MuteAlertForm';

import s from './index.module.scss';
import type { AlertManager } from 'services/api/telemetry/useAlertManager';

import type { Alert as ApiAlert } from 'api/alerts/types';
import { AlertPopup, Dialog, NonIdealState } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';

interface IProps {
  alertManagerApi: AlertManager;
}

const Alerts = (props: IProps) => {
  const {
    alerts,
    isFetching,
    isFetchingSilence,
    silenceMap,
    silenceRule,
    alertSilence,
    getAlertSilence,
    expireAlertSilence,
  } = props.alertManagerApi;

  const [currentAlertList, setCurrentAlertList] = useState<ApiAlert[]>([]);
  const [muteDialogOpen, setMuteDialogueOpen] = useState<boolean>(false);
  const [alertRuleToSilence, setAlertRuleToSilence] = useState<
    string | undefined
  >();

  const showAlertForRuleDeletion = (id: string) => {
    setMuteDialogueOpen(true);
    setAlertRuleToSilence(id);
    getAlertSilence(id);
  };

  // TODO: Filter or sort
  const alertList = currentAlertList;

  const renderAlertList = alertList.length ? (
    <AlertsTable
      list={alertList}
      showSilenceAlertPopup={showAlertForRuleDeletion}
      mutedAlerts={Object.keys(silenceMap)}
    />
  ) : (
    <NonIdealState icon={'feed'} title="No alerts" />
  );

  const renderAlertsOrLoading = isFetching ? (
    <div className={s.tableSpinner}>
      <Spinner />
    </div>
  ) : (
    renderAlertList
  );

  useEffect(() => {
    if (alerts) {
      setCurrentAlertList(alerts);
    }
  }, [alerts]);

  const cancelDialogueHandler = () => setMuteDialogueOpen(false);
  const muteDialogueHandler = (days: number) => {
    silenceRule(alertRuleToSilence, days);
    setMuteDialogueOpen(false);
  };

  const unmuteAlertHandler = () => {
    setMuteDialogueOpen(false);
    expireAlertSilence();
  };

  let muteAlertRuleText;
  if (alertSilence && alertSilence.status.state === 'pending') {
    muteAlertRuleText = `This alert rule is pending to be muted.`;
  } else {
    muteAlertRuleText = `This alert rule will remain muted until ${
      alertSilence?.endsAt ?? ''
    }. Do you want to unmute it?`;
  }

  return (
    <div className={s.libraryAlerts} style={{ height: '100%' }}>
      <AlertPopup
        cancelButtonText="Cancel"
        confirmButtonText="Unmute"
        icon={'notifications'}
        isOpen={alertSilence && muteDialogOpen}
        intent={'danger'}
        onCancel={cancelDialogueHandler}
        onConfirm={unmuteAlertHandler}
      >
        <p>{muteAlertRuleText}</p>
      </AlertPopup>
      <Dialog
        buttons={[]}
        hideCancelButton={true}
        title="Mute Alert Rule"
        isOpen={!alertSilence && muteDialogOpen}
        onClose={cancelDialogueHandler}
      >
        {isFetchingSilence ? (
          <Spinner />
        ) : (
          <div className={s.dialogueContainer}>
            <p className={s.textContainer}>
              How long would you like to mute this alert rule for?
            </p>
            <MuteAlertForm
              onCancelHandler={cancelDialogueHandler}
              onSubmitHandler={muteDialogueHandler}
            />
          </div>
        )}
      </Dialog>
      {/* <LibraryAlertsHeader
        title="Alerts"
        alerts={alerts}
        handleFilteredTelemetries={values => setFilteredAlertList(values)}
      /> */}
      <div className={s.alertsBody}>
        {/* {!alertGroupDoesntExist ? ( */}
        {renderAlertsOrLoading}
        {/* ) : (
          <>
            <NonIdealState
              icon={IconNames.NOTIFICATIONS}
              title="No alert rules"
            />
            <div className={s.libraryCardContainer}>
              <InitialAlertGroupCard
                handleEnable={enableAlerts}
                saving={isFetching}
              />
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default Alerts;
