import classNames from "classnames";
import React from "react";
import type { ListBoxItemProps } from "react-aria-components";

export enum ListOrientation {
  Horizontal,
  Vertical,
  HorizontalTwoColumns,
}

type Props = {
  /**
   * The orientation of the list
   * Can be either horizontal or vertical
   */
  orientation: ListOrientation;
  /**
   * The children of the list.
   * Can be either a single `<ListItem/>` or an array of `<ListItem/>`
   */
  children:
    | React.ReactElement<ListBoxItemProps>
    | React.ReactElement<ListBoxItemProps>[];
};

/**
 * UnorderedList is a component that can be used to display a list of items.
 * It can be either horizontal or vertical.
 */
const UnorderedList = (props: Props) => {
  return (
    <div className="text-sm">
      <ul
        className={classNames("gap-2", {
          "flex flex-col": props.orientation === ListOrientation.Vertical,
          flex: props.orientation === ListOrientation.Horizontal,
          "!shadow-none grid grid-flow-col w-32 grid-rows-2":
            props.orientation === ListOrientation.HorizontalTwoColumns,
        })}
      >
        {props.children}
      </ul>
    </div>
  );
};

export default UnorderedList;
