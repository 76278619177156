import { v1 as uuid } from 'uuid';
import type {
  IGeometryObject,
  IGeometryObjectState,
} from '../../../constants/geometry/interfaces';
import type { IGeometryActionTypes } from '../../../constants/geometry/actionTypes';
import {
  GEOMETRY_ADD,
  GEOMETRY_REMOVE,
  GEOMETRY_REMOVE_ALL,
  GEOMETRY_SUB_ID,
  GEOMETRY_UPDATE,
  GEOMETRY_UPLOAD,
} from '../../../constants/geometry/constants';

export function addGeometryObject(
  geometryObject: IGeometryObject
): IGeometryActionTypes {
  const id = geometryObject.id
    ? geometryObject.id
    : `${GEOMETRY_SUB_ID}-${uuid()}`;
  return {
    type: GEOMETRY_ADD,
    geometry: { ...geometryObject, id },
  };
}

export function updateGeometryObject(
  geometryObject: IGeometryObject
): IGeometryActionTypes {
  return {
    type: GEOMETRY_UPDATE,
    geometry: geometryObject,
  };
}

export function removeGeometryObject(
  geometryObject: IGeometryObject
): IGeometryActionTypes {
  return {
    type: GEOMETRY_REMOVE,
    geometry: geometryObject,
  };
}

export function removeAllGeometryObject(): IGeometryActionTypes {
  return {
    type: GEOMETRY_REMOVE_ALL,
  };
}

export function uploadGeometers(
  geometers: IGeometryObjectState
): IGeometryActionTypes {
  return {
    type: GEOMETRY_UPLOAD,
    geometers: geometers,
  };
}
