import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { Button } from 'opencosmos-ui';

type Props = {
  disabled: boolean;
  supportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
};

const ResetControls = (props: Props) => {
  return (
    <Button
      icon="Cross"
      size={'lg'}
      isMinimal
      isTransparent
      className={
        props.disabled
          ? 'cursor-not-allowed opacity-50 p-0'
          : 'cursor-pointer p-0'
      }
      onPress={() => {
        props.supportedLayers[0].setSettingContrastOn();
        props.supportedLayers[0].setSettingBrightnessOn();
        props.supportedLayers[0].setSettingSaturationOn();
        props.replaceLayer(
          props.supportedLayers[0].cloneWithOptions({
            brightness: 1,
            saturation: 1,
            contrast: 1,
            opacity: 1,
            contrastBias: 0.5,
          })
        );
      }}
    />
  );
};

export default ResetControls;
