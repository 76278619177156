import React from 'react';
import { startCase } from 'lodash/fp';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import TagHeader from '../../../../../../components/common/TagHeader';

import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Select, SelectValue } from 'opencosmos-ui';

interface IProps {
  handleHistoryTypeChange: (type: string) => void;
  historyCommandType?: string;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const CommandHistoryHeader = (props: IProps) => {
  const { sendInfo } = useAnalytics();
  return (
    <TagHeader
      className={s.historyTagContainer}
      title="Command History"
      //TODO: Bp icon; replace with own custom eventually
      icon="history"
    >
      <span
        className={
          s.commandHistoryHeaderButtons +
          ' flex flex-grow content-end justify-end w-full'
        }
      >
        {!props.collapsed && (
          <Select
            selectedKey={startCase(props.historyCommandType ?? 'Session')}
            onSelectionChange={(e) => {
              sendInfo({
                type: 'Command history type change',
                action: 'Change',
                item: 'Command history type',
                module: 'OPS',
                additionalParams: {
                  type: e,
                },
              });
              props.handleHistoryTypeChange(e.toString().toLowerCase());
            }}
          >
            <SelectValue id={'Session'}>Session</SelectValue>
            <SelectValue id={'Mission'}>Mission</SelectValue>
          </Select>
        )}
        <Button
          icon={props.collapsed ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
          minimal
          small
          onClick={() => {
            sendInfo({
              type: 'Command history collapse',
              action: 'Click',
              item: 'Command history collapse button',
              module: 'OPS',
            });
            props.setCollapsed(!props.collapsed);
          }}
        />
      </span>
    </TagHeader>
  );
};

export default CommandHistoryHeader;
