import { Link } from 'react-router-dom';
import logo from 'images/logo-centric-name-compact.svg';
import logoSmall from 'images/logo-monogram-black.svg';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { MailToButton } from '_organisms/MailToButton/MailToButton';
import { LOGO } from 'env';
import classNames from 'classnames';
import { UserProfileMenu } from './UserMenu';

export const LOGO_DEFAULT_LINK = '/';

export type NavbarProps = {
  left?: JSX.Element;
  middle?: JSX.Element;
  right?: JSX.Element;
  showLogo?: boolean;
  showFeedbackButton?: boolean;
  showUserMenu?: boolean;
  bgClassName?: string;
  hideThemesInUserMenu?: boolean;
};

export const Navbar = ({
  left,
  middle,
  right,
  showLogo = true,
  showFeedbackButton = true,
  showUserMenu = true,
  bgClassName,
  hideThemesInUserMenu = true,
}: NavbarProps) => {
  const { isTablet } = useWindowSize();
  const responsiveOCLogo = isTablet ? logoSmall : logo;
  const logoToDisplay = LOGO ? LOGO : responsiveOCLogo;

  const responsiveImgHeight = isTablet ? 'h-6' : 'h-[16px]';
  return (
    <div
      className={classNames(
        bgClassName,
        'flex w-full select-none h-[50px] app-navbar',
        {
          'dark:bg-header-dark bg-header-top': !bgClassName,
        }
      )}
    >
      <SectionNavbar className="justify-start">{left}</SectionNavbar>
      <SectionNavbar className="justify-center">
        <>
          {showLogo ? (
            <Link to={LOGO_DEFAULT_LINK} style={{ lineHeight: '0px' }}>
              <img
                className={`${
                  LOGO ? 'h-[32px]' : responsiveImgHeight
                } dark:invert`}
                src={logoToDisplay}
                alt="Open Cosmos"
              />
            </Link>
          ) : null}
          {middle}
        </>
      </SectionNavbar>
      <SectionNavbar className="justify-end">
        <>
          {right}
          {showFeedbackButton ? (
            <MailToButton subject="Send feedback" team="datacosmos" />
          ) : null}
          {showUserMenu ? (
            <UserProfileMenu hideThemesInUserMenu={hideThemesInUserMenu} />
          ) : null}
        </>
      </SectionNavbar>
    </div>
  );
};

const SectionNavbar = ({
  children,
  className,
}: {
  children?: JSX.Element;
  className: string;
}) => {
  return (
    <div className={`flex w-full items-center px-2 py-[2px] ${className}`}>
      {children}
    </div>
  );
};
