import { loadAndConvertToByteArray } from '../fileUpload';
import { debouncedFileDownload } from '../fileDownload';
import type {
  ICloudContent,
  IFolder,
} from '../../services/api/ops/cloudStorage';

export const toAsyncCloudContent = async (
  file: (File & { file?: ArrayBuffer }) | string
): Promise<ICloudContent> => {
  if (typeof file === 'string') {
    return { filePath: `${file}/`, mimeType: '' };
  }

  const encodedFile = file.file
    ? file.file
    : await loadAndConvertToByteArray(file);

  const { type, name } = file;

  return {
    fileContent: { file: encodedFile },
    filePath: name,
    mimeType: type ? `${type}` : '',
  };
};

export const toCloudContent = (fileContent: IFolder): ICloudContent => {
  const { name, file } = fileContent;

  return {
    fileContent: { file: file ?? '' },
    filePath: name,
    mimeType: '?mimeType=text/plain',
  };
};

export const downloadFileContent = (data: IFolder) => {
  if (!data.file) {
    return;
  }
  debouncedFileDownload(data.file, data.name, data.mimeType);
};
