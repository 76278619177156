import { Button } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import s from './index.module.scss';

interface IProps {
  handleSave: () => void;
  saving?: boolean;
  shouldDisableSaveButton: boolean;
}

const LibrarySaveCard = (props: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '30%',
        marginBottom: '20px',
        alignItems: 'center',
        fontSize: '14px',
        padding: '15px 25px',
      }}
      className="dark:bg-item-dark bg-item"
    >
      <span>There are pending changes to save</span>
      <Button
        icon="saved"
        className={s.librarySaveBtn}
        onPress={props.handleSave}
        intent={'primary'}
        style={{ width: '150px' }}
        isDisabled={props.shouldDisableSaveButton}
      >
        <span className="text-sm">
          {props.saving ? <Spinner size={12} /> : 'Save'}
        </span>
      </Button>
    </div>
  );
};

export default LibrarySaveCard;
