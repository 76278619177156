import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'pages/msd/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IPolygonWrapper } from 'declarations/mapDeclarations/Polygon';
import type {
  IPoint,
  IRegionOfInterest,
} from 'constants/regionsOfInterest/actionTypes';
import { updateRI } from 'pages/msd/shared/state/actions/regionsOfInterest/helpers';
import { selectLastSatelliteMode } from 'pages/msd/shared/utils/selectors/satellite';

type LatLng = google.maps.LatLng;

export const editing =
  (
    path: google.maps.MVCArray<LatLng>,
    vertex: number,
    index: number,
    region: IPolygonWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const stateData = state();

    const regionOfInterest: IRegionOfInterest = {
      astrum: stateData.astrum['current'].planet,
      id: region.id,
      name: region.name,
      paths: region
        .getPaths()
        .getArray()
        .map((path): IPoint[] => {
          return path.getArray().map((LatLng): IPoint => {
            return {
              lat: LatLng.lat(),
              lng: LatLng.lng(),
            };
          });
        }),
      orderIndex: region.orderIndex,
      satelliteMode: selectLastSatelliteMode(stateData),
      elevationAngle: region.elevationAngle,
    };
    dispatch(updateRI(regionOfInterest));
  };
