import { IconNames } from '@blueprintjs/icons';
import type { IBottomBarTab } from '../../../pages/ops/Library/components/BottomNavBar';

export const LIBRARY_BOTTOM_NAVBAR_TABS: IBottomBarTab[] = [
  {
    id: 'encoding',
    title: 'Encoding',
    icon: IconNames.HIGHLIGHT,
  },
  {
    id: 'telemetry',
    title: 'Telemetry',
    icon: IconNames.SCATTER_PLOT,
  },
  // !Disabled for now due to missing implementation
  // Relevant ticket: https://git.o-c.space/app/ui/app/-/issues/1135
  // {
  //   id: 'commands',
  //   title: 'Commands',
  //   icon: IconNames.KEY_COMMAND,
  // },
  {
    id: 'storage',
    title: 'Storage',
    icon: IconNames.FOLDER_CLOSE,
  },
];

export const DEFAULT_TAB_STATE = 'encoding';
