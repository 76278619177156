import type { ReactElement } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import type { IMapWrapper } from 'declarations/mapDeclarations/Map';
import type { IMarkerWrapper } from 'declarations/mapDeclarations/Marker';
import type {
  IGroundStation,
  IGSState,
} from 'constants/groundStations/actionTypes';
import type {
  IPIState,
  IPointOfInterest,
} from 'constants/pointsOfInterest/actionTypes';
import type { IDrawingOption } from 'services/mapService/mapDrawing';
import {
  groundStationDrawing,
  pointOfInterestDrawing,
  regionOfInterestDrawing,
  satelliteDrawing,
} from 'services/mapService/mapDrawing';
import {
  pointCompleted,
  regionCompleted,
} from 'services/mapService/drawingManager/events';
import type { IPolygonWrapper } from 'declarations/mapDeclarations/Polygon';
import type { IMenuHandler } from 'services/mapService/contextMenu/map/services';
import { addContextMenu } from 'services/mapService/contextMenu/map/services';
import type {
  IRegionOfInterest,
  IRIState,
} from 'constants/regionsOfInterest/actionTypes';
import { resetFocus } from 'pages/msd/shared/state/actions/focus/thunks';
import type {
  IMapProps,
  IMapDispatchProps,
  IMapOwnProps,
} from 'components/eventsMap/Map';
import Map from './Map';
import { MAP_TYPE } from 'constants/map/constants';
import type {
  ISatelliteData,
  ISTState,
} from 'constants/satelliteOrbits/actionTypes';
import { renderContextMenuButton } from 'utils/map/renderContextMenuButton';
import type {
  IInitData,
  IStepData,
} from 'pages/msd/shared/components/Simulation/Simulation';
import type { IDataZoom } from 'components/gridLayout/GridLayout';
import { getMapTypes } from 'services/mapService/contextMenu/menuItems/getMapTypes';
import { getAddPoint } from 'services/mapService/contextMenu/menuItems/getAddPoint';
import { getAddRegion } from 'services/mapService/contextMenu/menuItems/getAddRegion';
import { getFullScreen } from 'services/mapService/contextMenu/menuItems/getFullScreen';
import type { AppState } from 'pages/msd/shared/state/reducers/rootReducer';
import { getAddStation } from 'services/mapService/contextMenu/menuItems/getAddStation';
import loadScript from 'utils/LoadScript';
import { initMap } from 'utils/map/initMap';
import { loadGroundStations } from 'pages/msd/shared/state/actions/groundStations/thunks';
import type { IAstrum } from 'constants/austrum/interfaces';
import { ASTRUMS } from 'constants/API/constant';
import { filterByAstrum } from 'utils/common/filterByAstrum';
import type { ThunkDispatch } from 'redux-thunk';
import type { Action } from 'redux';
import type { IFocus } from 'constants/focus/actionTypes';
import { GOOGLE_API_KEY } from 'env';

declare let window: Window & { initMap: Function };

type OverlayType = google.maps.drawing.OverlayType;

interface IMapContainerState {
  map: IMapWrapper;
}

interface IOwnProps extends IMapOwnProps {
  mapId: string;
  initData?: IInitData;
  historySteps?: IStepData[];
  dataZoom?: IDataZoom;
  zoom?: number;
  onMapLoaded?: (map: IMapWrapper) => void;
  onChangeDrawingMode?: (mode: OverlayType) => void;
}

interface IStateProps {
  groundStations?: IGSState;
  pointsOfInterest?: IPIState;
  regionsOfInterest?: IRIState;
  satellites?: ISTState;
  focus?: IFocus;
  form?: any;
  astrum?: IAstrum;
}
interface IDispatchProps extends IMapDispatchProps {}

type IProps = IOwnProps & IStateProps & IDispatchProps;

function mapAddButton(
  controlDiv: Element,
  event: EventListenerOrEventListenerObject
): void {
  if (event) {
    controlDiv.removeEventListener('click', (): undefined => undefined);
    controlDiv.addEventListener('click', event);
  }
}

export class MapContainer extends Component<IProps, IMapContainerState> {
  public state: IMapContainerState = {
    map: null,
  };

  public componentDidMount(): void {
    const KEY_URI = `${GOOGLE_API_KEY ? `&key=${GOOGLE_API_KEY}` : ''}`;

    window.initMap = (): void => {
      const map = initMap(
        this.props.mapId,
        {
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
        },
        this.props.onChangeDrawingMode
      );
      this.setState({ map });
      if (this.props.onMapLoaded) {
        this.props.onMapLoaded(map);
      }
    };
    // @ts-expect-error
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=drawing${KEY_URI}`
      );
    } else {
      window.initMap();
    }
  }

  public componentWillUnmount(): void {
    if (this.state.map) {
      google.maps.event.clearInstanceListeners(this.state.map);
      this.setState({ map: null });
    }
  }

  private filterProps = (): IMapProps => {
    const {
      mapType,
      addContextMenu,
      groundStations,
      loadGroundStations,
      ...otherProps
    } = this.props;
    const { map } = this.state;
    let props: IMapProps = null;
    switch (mapType) {
      case MAP_TYPE.CONTROLLED: {
        props = {
          map,
          addContextMenu: (): Function => {
            const burgerButton = document.createElement('div');
            // @ts-expect-error
            burgerButton.index = 1;
            renderContextMenuButton(burgerButton);
            map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
              burgerButton
            );
            return (): void => {
              const menuItems: IMenuHandler[] = [
                {
                  fn: getMapTypes,
                  arg: [[ASTRUMS.MOON, ASTRUMS.EARTH], this.props.pageKey],
                  dispatch: true,
                },
                {
                  fn: getAddPoint,
                  arg: [map],
                  dispatch: true,
                },
                {
                  fn: getAddRegion,
                  arg: [map],
                  dispatch: true,
                },
                {
                  fn: getFullScreen,
                  arg: [map],
                  dispatch: false,
                },
              ];
              const boundContextMenu = addContextMenu.bind(
                null,
                map,
                menuItems,
                false
              );
              google.maps.event.clearListeners(map, 'rightclick');
              map.addListener('rightclick', boundContextMenu);
              mapAddButton(burgerButton, boundContextMenu);
            };
          },
          ...otherProps,
          satellites: null,
        };
        break;
      }
      case MAP_TYPE.SIMULATED: {
        const {
          pointsOfInterest,
          pointOfInterestDrawing,
          regionsOfInterest,
          regionOfInterestDrawing,
          satelliteDrawing,
          focus,
          form,
          astrum,
        } = this.props;
        props = {
          ...otherProps,
          map,
          focus,
          form,
          addContextMenu: (): Function => {
            const burgerButton = document.createElement('div');
            // @ts-expect-error
            burgerButton.index = 1;
            renderContextMenuButton(burgerButton);
            map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
              burgerButton
            );
            return (props: { satellites: ISTState }): void => {
              const { satellites } = props;
              console.debug(satellites);
              const menuItems: IMenuHandler[] = [
                {
                  fn: getMapTypes,
                  arg: [[], this.props.pageKey],
                  dispatch: true,
                },
              ];
              const boundContextMenu = addContextMenu.bind(
                null,
                map,
                menuItems,
                false
              );
              google.maps.event.clearListeners(map, 'rightclick');
              map.addListener('rightclick', boundContextMenu);
              mapAddButton(burgerButton, boundContextMenu);
            };
          },
          pointsOfInterest,
          pointOfInterestDrawing: (
            pointOfInterest: IPointOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            pointOfInterestDrawing(pointOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          regionsOfInterest,
          regionOfInterestDrawing: (
            regionOfInterest: IRegionOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            regionOfInterestDrawing(regionOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          satelliteDrawing,
          astrum,
        };
        break;
      }
      case MAP_TYPE.GROUND_SEGMENT: {
        const {
          groundStationDrawing,
          pointsOfInterest,
          pointOfInterestDrawing,
          regionsOfInterest,
          regionOfInterestDrawing,
          astrum,
        } = this.props;

        props = {
          ...otherProps,
          loadGroundStations,
          map,
          addContextMenu: (): Function => {
            const burgerButton = document.createElement('div');
            // @ts-expect-error
            burgerButton.index = 1;
            renderContextMenuButton(burgerButton);
            map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
              burgerButton
            );
            return (): void => {
              const menuItems: IMenuHandler[] = [
                {
                  fn: getMapTypes,
                  arg: [[ASTRUMS.EARTH], this.props.pageKey],
                  dispatch: true,
                },
                {
                  fn: getAddStation,
                  arg: [map],
                  dispatch: true,
                },
                {
                  fn: getFullScreen,
                  arg: [map],
                  dispatch: false,
                },
              ];
              const boundContextMenu = addContextMenu.bind(
                null,
                map,
                menuItems,
                false
              );
              google.maps.event.clearListeners(map, 'rightclick');
              map.addListener('rightclick', boundContextMenu);
              mapAddButton(burgerButton, boundContextMenu);
            };
          },
          groundStations,
          groundStationDrawing,
          pointsOfInterest,
          pointOfInterestDrawing: (
            pointOfInterest: IPointOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            pointOfInterestDrawing(pointOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          regionsOfInterest,
          regionOfInterestDrawing: (
            regionOfInterest: IRegionOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            regionOfInterestDrawing(regionOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          astrum,
        };
        break;
      }
      case MAP_TYPE.GROUND_STATIONS: {
        const { groundStations, groundStationDrawing, zoom } = this.props;

        if (zoom) map.setZoom(zoom);

        props = {
          ...otherProps,
          groundStations,
          groundStationDrawing,
          map,
        };
        break;
      }

      case MAP_TYPE.MODES: {
        const {
          groundStationDrawing,
          pointsOfInterest,
          pointOfInterestDrawing,
          regionsOfInterest,
          regionOfInterestDrawing,
          astrum,
        } = this.props;

        props = {
          ...otherProps,
          loadGroundStations,
          map,
          addContextMenu: (): Function => {
            const burgerButton = document.createElement('div');
            // @ts-expect-error
            burgerButton.index = 1;
            renderContextMenuButton(burgerButton);
            map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
              burgerButton
            );
            return (): void => {
              const menuItems: IMenuHandler[] = [
                {
                  fn: getFullScreen,
                  arg: [map],
                  dispatch: false,
                },
              ];
              const boundContextMenu = addContextMenu.bind(
                null,
                map,
                menuItems,
                false
              );
              google.maps.event.clearListeners(map, 'rightclick');
              map.addListener('rightclick', boundContextMenu);
              mapAddButton(burgerButton, boundContextMenu);
            };
          },
          groundStations,
          groundStationDrawing,
          pointsOfInterest,
          pointOfInterestDrawing: (
            pointOfInterest: IPointOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            pointOfInterestDrawing(pointOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          regionsOfInterest,
          regionOfInterestDrawing: (
            regionOfInterest: IRegionOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            regionOfInterestDrawing(regionOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          astrum,
        };
        break;
      }

      case MAP_TYPE.REAL_TIME: {
        const {
          pointsOfInterest,
          pointOfInterestDrawing,
          regionsOfInterest,
          regionOfInterestDrawing,
          satelliteDrawing,
          focus,
          form,
          astrum,
          //@ts-expect-error
          satelliteIconDrawing,
        } = this.props;

        const satellite = { valuesList: [0, 0, 0] };

        props = {
          map,
          focus,
          form,
          groundStations: {
            list: [
              {
                id: '1',
                name: 'Satellite',
                lat: satellite.valuesList[0],
                lon: satellite.valuesList[1],
                altitude: satellite.valuesList[2],
                elevationAngle: 20,
                select: true,
                optimize: false,
                custom: false,
                astrum: ASTRUMS.EARTH,
              },
            ],
          },
          groundStationDrawing: satelliteIconDrawing,
          pointsOfInterest,
          pointOfInterestDrawing: (
            pointOfInterest: IPointOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            pointOfInterestDrawing(pointOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          regionsOfInterest,
          regionOfInterestDrawing: (
            regionOfInterest: IRegionOfInterest,
            map: IMapWrapper,
            options?: IDrawingOption
          ): void =>
            regionOfInterestDrawing(regionOfInterest, map, {
              ...options,
              setEvents: false,
            }),
          satelliteDrawing,
          astrum,
        };
        break;
      }
    }

    props.mapType = mapType;
    return props;
  };

  public render(): ReactElement {
    const { map } = this.state;

    return (
      <div id={this.props.mapId}>
        {(() => {
          if (map) {
            const props = this.filterProps();

            return <Map {...props} pageKey={this.props.pageKey} map={map} />;
          }
          return undefined;
        })()}
      </div>
    );
  }
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  (state: AppState): IStateProps => ({
    groundStations: filterByAstrum<IGSState>(
      state.groundStations,
      state.astrum.current.planet
    ),
    pointsOfInterest: filterByAstrum<IPIState>(
      state.pointsOfInterest,
      state.astrum.current.planet
    ),
    regionsOfInterest: filterByAstrum<IRIState>(
      state.regionsOfInterest,
      state.astrum.current.planet
    ),
    satellites: filterByAstrum<ISTState>(
      state.satelliteOrbits,
      state.astrum.current.planet
    ),
    focus: state.focus,
    form: state.form,
    astrum: state.astrum.current,
  }),
  (
    dispatch: ThunkDispatch<AppState, null, Action<string>>
  ): IDispatchProps => ({
    loadGroundStations: (): void => dispatch(loadGroundStations()),
    groundStationDrawing: (
      groundStation: IGroundStation,
      map: IMapWrapper,
      options?: any
    ): void => dispatch(groundStationDrawing(groundStation, map, options)),
    pointOfInterestDrawing: (
      pointOfInterest: IPointOfInterest,
      map: IMapWrapper,
      options?: any
    ): void => dispatch(pointOfInterestDrawing(pointOfInterest, map, options)),
    regionOfInterestDrawing: (
      regionOfInterest: IRegionOfInterest,
      map: IMapWrapper,
      options?: any
    ): void =>
      dispatch(regionOfInterestDrawing(regionOfInterest, map, options)),
    satelliteDrawing: (
      satellite: ISatelliteData,
      map: IMapWrapper,
      options?: any
    ): void => dispatch(satelliteDrawing(satellite, map, options)),
    pointCompleted: (map: IMapWrapper, marker: IMarkerWrapper): void =>
      dispatch(pointCompleted(map, marker)),
    regionCompleted: (map: IMapWrapper, region: IPolygonWrapper): void =>
      dispatch(regionCompleted(map, region)),
    addContextMenu: (
      map: IMapWrapper,
      menuHandlers: IMenuHandler[],
      collapse: false,
      event: google.maps.MouseEvent
    ): void => dispatch(addContextMenu(map, menuHandlers, collapse, event)),
    resetFocus: (): void => dispatch(resetFocus()),
  })
)(MapContainer);
