import type { AttachedRoleAssignment } from 'api/administration/permissions';
import useRoles from 'services/api/portal/administration/hook/useRoles';
import { groupByParentResourceAndRole } from '../../utils/role';
import { Icon, Tooltip } from 'opencosmos-ui';
import AdministrationRoleSelect from '../AdministrationRoleSelect';
import type {
  ICreateRoleAttachment,
  IRole,
} from 'services/api/portal/administration/api/types';
import { mapAsync } from 'utils/common/asyncUtils';
interface Props {
  attachments: AttachedRoleAssignment[];
  getResourceAttachmentsCallback: () => void;
  availableRoles: IRole[];
}
const RoleAssignmentsThatGrantPermission = ({
  attachments,
  getResourceAttachmentsCallback,
  availableRoles,
}: Props) => {
  const { deleteRoleAttachment, createRoleAttachment } = useRoles();

  const assignResourceAttachment = async (
    parentId: string,
    roleIds: number[]
  ) => {
    const resourceType = availableRoles.find(
      (role) => role.id === roleIds[0]
    )?.resourceType;
    const matchedResource = attachments.find(
      (attachment) =>
        attachment.parentRoleId === Number(parentId) &&
        attachment.childResourceType === resourceType
    );
    if (matchedResource) {
      const postObj: ICreateRoleAttachment = {
        parentResourceType: matchedResource?.parentResourceType,
        parentResourceId: matchedResource?.parentResourceId?.toString(),
        childRoleId: roleIds[0],
        childResourceType: matchedResource?.childResourceType,
        childResourceId: matchedResource?.childResourceId?.toString(),
      };
      await createRoleAttachment(postObj, parentId);
      getResourceAttachmentsCallback();
    }
  };

  const handleDeleteRoleAssignments = async (
    parentId: string,
    assignmentIds: number[]
  ) => {
    await mapAsync((id: number) => deleteRoleAttachment(id, Number(parentId)))(
      assignmentIds
    );
    getResourceAttachmentsCallback();
  };

  return (
    <div>
      {attachments.length ? (
        groupByParentResourceAndRole(attachments).map((attachment) => (
          <div key={attachment.parentRole.id} className="mt-2">
            <div className="flex justify-between items-center mb-2 gap-4">
              <Tooltip
                content={`${attachment.parentRole.resourceType.toUpperCase()} : ${attachment?.parentResourceId?.toString()}`}
                placement="top"
                isDisabled={attachment.parentRole.resourceType === 'global'}
              >
                <div className="flex items-center min-w-[280px] gap-1">
                  <div className="bg-surface/50 dark:bg-surface-dark/50 border-2 rounded-full w-8 h-8 flex justify-center items-center">
                    <Icon icon="UserGroup" size={16} />
                  </div>
                  <span className="text-sm">{attachment.parentRole.name}</span>
                  <span className="text-xs text-neutral-600 font-bold">
                    - {attachment.parentRole.resourceType}
                  </span>
                </div>
              </Tooltip>
              <div className="flex items-center justify-between gap-2">
                <AdministrationRoleSelect
                  userId={attachment?.parentRole?.id?.toString()}
                  assignRole={assignResourceAttachment}
                  deleteRoleAssignments={handleDeleteRoleAssignments}
                  availableRoles={availableRoles}
                  currentAssignments={attachment?.attachedRoles.map((role) => ({
                    assignmentId: attachments?.find(
                      (resource) => resource.childRoleId === role.id
                    )?.id,
                    roleId: role.id,
                    roleName: role.name,
                    roleDescription: role.description,
                  }))}
                  isRoleAssignmentItemFormatted={true}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="font-bold mt-4">No role assignments found</div>
      )}
    </div>
  );
};
export default RoleAssignmentsThatGrantPermission;
