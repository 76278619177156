import { DateValue } from "react-aria-components";
import DateAndTimePickerSegments, {
  DateAndTimePickerSegmentsProps,
} from "./DateAndTimePickerSegments";
import DateAndTimePickerTextFieldInput, {
  DateAndTimePickerPropsTextFieldInput,
} from "./DateAndTimePickerTextField";

function DateAndTimePicker<T extends DateValue>(
  props:
    | DateAndTimePickerSegmentsProps<T>
    | DateAndTimePickerPropsTextFieldInput<T>
) {
  if (props.useTextDateInput === true) {
    return <DateAndTimePickerTextFieldInput {...props} />;
  }

  return <DateAndTimePickerSegments {...props} />;
}

export default DateAndTimePicker;
