import type { IGeometryObjectState } from '../../constants/geometry/interfaces';
import {
  GEOMETRY_ADD,
  GEOMETRY_REMOVE,
  GEOMETRY_REMOVE_ALL,
  GEOMETRY_UPDATE,
  GEOMETRY_UPLOAD,
} from '../../constants/geometry/constants';
import type { IGeometryActionTypes } from '../../constants/geometry/actionTypes';

const initialState: IGeometryObjectState = [];

export default function geometryReducer(
  state: IGeometryObjectState = initialState,
  action: IGeometryActionTypes
): IGeometryObjectState {
  switch (action.type) {
    case GEOMETRY_ADD: {
      return [...state, action.geometry];
    }
    case GEOMETRY_UPDATE: {
      return [
        ...state.map((geometry) => {
          if (geometry.id === action.geometry.id) {
            return action.geometry;
          }
          return geometry;
        }),
      ];
    }
    case GEOMETRY_REMOVE: {
      return [
        ...state.filter(
          (geometry): boolean => geometry.id !== action.geometry.id
        ),
      ];
    }
    case GEOMETRY_REMOVE_ALL: {
      return initialState;
    }
    case GEOMETRY_UPLOAD: {
      return action.geometers;
    }
    default: {
      return state;
    }
  }
}
