import ThemeProvider from 'datacosmos/stores/ThemeProvider';

export type ScreenProps = {
  children: JSX.Element | JSX.Element[];
};

export const Screen = ({ children }: ScreenProps) => {
  return (
    <ThemeProvider>
      <div
        className="flex flex-col color-header overflow-auto"
        style={{ width: '100vw', height: '100vh' }}
      >
        {children}
      </div>
    </ThemeProvider>
  );
};
