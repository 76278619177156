import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import BlendModeControl from './BlendModeControl';
import ColorControl from './ColorControl';
import OpacityControl from './OpacityControl';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import Button from '_molecules/Button/Button';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import BrightnessControl from './BrightnessControl';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Switch } from 'opencosmos-ui';
import { useTimeSeries } from 'datacosmos/stores/TimeSeriesProvider';
import Popover2 from '_atoms/Popover/Popover2';
import { Icon } from '_atoms/Icon/Icon';
import { usePixelMode } from 'datacosmos/stores/PixelModeProvider';
import SaturationControl from './SaturationControl';
import ContrastControl from './ContrastControl';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import { ENABLE_VECTOR_OPERATIONS } from 'env';
import VectorOperations from '../VectorOperations';
import ResetControls from './ResetControls';

interface IProps {
  layers: Layer[];
}

/**
 * CommonLayerControls are the controls used to change the selected layers
 * options. Note that there might be one or more selected layers that are
 * being edited at the same time.
 */
const CommonLayerControls = ({ layers }: IProps) => {
  const { replaceSelectedLayers, setLayersMenuOpen, replaceLayer, setLayers } =
    useMapLayers();
  const { isLayersComparisonToggled, toggleLayersComparison } = useMap();
  const { setAlgebraMenuOpen } = useActivePage();

  const { translate } = useLocalisation();

  const selectedLayers = layers.filter((l) => l.options.isSelected);

  const { sendInfo } = useAnalytics();

  const { isTimeSeriesToggled, toggleTimeSeries, isEnabled } = useTimeSeries();

  const themeProvider = useTheme();

  const {
    isEnabled: isClickModeEnabled,
    isDrawEnabled,
    isSingleImagePixelModeToggled,
    isLinePixelValuesModeToggled,
    togglePixelMode,
    isVsWavelengthToggled,
    isSpectralSignaturesToggled,
  } = usePixelMode();

  const isPixelModeEnabled = isDrawEnabled || isClickModeEnabled;

  const blendingModeSupportedLayers = selectedLayers.filter(
    (l) =>
      l.sourceType === LayerSourceType.ASSET ||
      l.sourceType === LayerSourceType.ASSET_OUTLINE ||
      l.sourceType === LayerSourceType.ALGEBRA_LAYER
  );

  const isColorChangable = (layer: Layer): layer is GeoJSONLayer<any> => {
    return (layer as any).setColor !== undefined;
  };

  const colorSupportedLayers = selectedLayers.filter(isColorChangable);

  let disableAll = false;

  if (selectedLayers.length === 0) {
    disableAll = true;
  }

  const showVectorOperationSelection =
    ENABLE_VECTOR_OPERATIONS && selectedLayers?.length > 1;

  const colorOperationsSupportedLayers = selectedLayers
    .filter((l) => l.sourceType === LayerSourceType.ASSET)
    .filter((l) =>
      l.sourceType === LayerSourceType.ASSET
        ? (l as SingleBandSTACLayer).assetKey !== 'overview' &&
          (l as SingleBandSTACLayer).assetKey !== 'thumbnail' &&
          !Object.values((l as SingleBandSTACLayer).item.assets).some((item) =>
            JSON.stringify(item).includes('classification:classes')
          )
        : true
    )
    .filter((l) => l.options.isSelected) as SingleBandSTACLayer[];

  return (
    <div className="border-b-[1px] dark:border-item-contrast text-sm">
      <div className="h-sub-header bg-surface flex items-end pl-4 pr-4 gap-2 dark:bg-surface-dark dark:text-item-dark-contrast">
        <BlendModeControl
          onChange={(value) => {
            sendInfo({
              type: `Blending mode set: ${value}`,
              action: 'Set',
              item: 'Layer controls - blending mode',
              module: 'DataCosmos',
              additionalParams: {
                blendingMode: value,
              },
            });
            replaceSelectedLayers(selectedLayers, {
              name: 'blendMode',
              value: value,
            });
          }}
          value={blendingModeSupportedLayers[0]?.options?.blendMode}
          disabled={disableAll || !(blendingModeSupportedLayers.length > 0)}
        />
        <Button
          text={translate('datacosmos.layers.bandAlgebra.title')}
          icon="BandAlgebra"
          onPress={() => {
            setLayersMenuOpen(false);
            setAlgebraMenuOpen(true);
          }}
          disabled={
            !layers
              .filter((l) => l.options.isSelected)
              .some((l) => l instanceof SingleBandSTACLayer)
          }
          fill
          className="min-w-fit"
        />
      </div>

      <div className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
        <div className="flex items-center w-full gap-2">
          <span className="whitespace-nowrap text-sm">
            {translate('datacosmos.layers.opacity')}
          </span>
          <div className="w-full">
            <OpacityControl
              value={selectedLayers[0]?.options.opacity}
              onChange={(value) => {
                sendInfo({
                  type: 'Opacity set',
                  action: 'Set',
                  item: 'Layer controls - opacity',
                  module: 'DataCosmos',
                  additionalParams: {
                    opacity: value,
                  },
                });
                replaceSelectedLayers(selectedLayers, {
                  name: 'opacity',
                  value,
                });
              }}
              disabled={disableAll}
            />
          </div>
        </div>

        <div className="flex items-center w-fit gap-2">
          <BrightnessControl
            disabled={
              disableAll ||
              selectedLayers.length > 1 ||
              colorOperationsSupportedLayers.length === 0
            }
            brightnessSupportedLayers={colorOperationsSupportedLayers}
            replaceLayer={replaceLayer}
          />

          <SaturationControl
            disabled={
              disableAll ||
              selectedLayers.length > 1 ||
              colorOperationsSupportedLayers.length === 0
            }
            saturationSupportedLayers={colorOperationsSupportedLayers}
            replaceLayer={replaceLayer}
          />

          <ContrastControl
            disabled={
              disableAll ||
              selectedLayers.length > 1 ||
              colorOperationsSupportedLayers.length === 0
            }
            contrastSupportedLayers={colorOperationsSupportedLayers}
            replaceLayer={replaceLayer}
          />

          <ColorControl
            color={colorSupportedLayers[0]?.options?.color}
            onChange={(colorCode) => {
              sendInfo({
                type: `Color code set: ${colorCode} `,
                action: 'Set',
                item: 'Layer controls - color',
                module: 'DataCosmos',
                additionalParams: {
                  color: colorCode,
                },
              });
              replaceSelectedLayers(selectedLayers, {
                name: 'color',
                value: colorCode,
              });
            }}
            disabled={disableAll || !(colorSupportedLayers.length > 0)}
          />

          <ResetControls
            disabled={
              disableAll ||
              selectedLayers.length > 1 ||
              colorOperationsSupportedLayers.length === 0
            }
            supportedLayers={colorOperationsSupportedLayers}
            replaceLayer={replaceLayer}
          />
        </div>
      </div>
      <div
        className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast"
        data-testid="layers-comparison-tool"
      >
        <span>{translate('datacosmos.layers.timeSeries')}</span>
        <Switch
          themeProvider={themeProvider}
          label={
            isTimeSeriesToggled
              ? translate('datacosmos.buttons.disable')
              : translate('datacosmos.buttons.enable')
          }
          isSelected={isTimeSeriesToggled}
          isDisabled={!isEnabled}
          onChange={() => {
            sendInfo({
              type: `Time series toggled ${isTimeSeriesToggled ? 'off' : 'on'}`,
              action: 'Toggle',
              item: 'Layer controls - time series',
              module: 'DataCosmos',
              additionalParams: {
                isTimeSeriesToggled: !isTimeSeriesToggled,
              },
            });
            if (isLayersComparisonToggled) toggleLayersComparison();
            toggleTimeSeries();
          }}
        />
      </div>
      <div className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
        <span>{translate('datacosmos.layers.layersComparison')}</span>
        <Switch
          themeProvider={themeProvider}
          label={
            isLayersComparisonToggled
              ? translate('datacosmos.buttons.disable')
              : translate('datacosmos.buttons.enable')
          }
          isSelected={isLayersComparisonToggled}
          isDisabled={layers?.length === 0 && !isLayersComparisonToggled}
          onChange={() => {
            sendInfo({
              type: `Layers comparison tool toggled ${
                isTimeSeriesToggled ? 'off' : 'on'
              }`,
              action: 'Toggle',
              item: 'Layer controls - layers comparison',
              module: 'DataCosmos',
              additionalParams: {
                isLayersComparisonToggled: !isLayersComparisonToggled,
              },
            });
            if (!isLayersComparisonToggled) {
              if (isTimeSeriesToggled) toggleTimeSeries();
              if (selectedLayers?.length > 0) {
                // Reorder layers on comparison enabled
                setLayers([
                  ...selectedLayers,
                  ...layers.filter((l) => !l.options.isSelected),
                ]);
              }
            }
            toggleLayersComparison(selectedLayers);
          }}
        />
      </div>

      <div className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
        <Popover2
          placement="bottom start"
          isDismissable={true}
          popupContent={
            <div className="p-1">
              <div className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
                <span>
                  {translate(
                    'datacosmos.layers.pixelOperations.singlePixelValue'
                  )}
                </span>
                <Switch
                  themeProvider={themeProvider}
                  label={
                    isPixelModeEnabled
                      ? translate('datacosmos.buttons.disable')
                      : translate('datacosmos.buttons.enable')
                  }
                  isSelected={isSingleImagePixelModeToggled}
                  isDisabled={!isPixelModeEnabled}
                  onChange={() => {
                    togglePixelMode('SingleImagePixel');
                  }}
                />
              </div>

              <div className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
                <span>
                  {translate(
                    'datacosmos.layers.pixelOperations.linePixelValues'
                  )}
                </span>
                <Switch
                  themeProvider={themeProvider}
                  label={
                    isPixelModeEnabled
                      ? translate('datacosmos.buttons.disable')
                      : translate('datacosmos.buttons.enable')
                  }
                  isSelected={isLinePixelValuesModeToggled}
                  isDisabled={!isPixelModeEnabled}
                  onChange={() => {
                    togglePixelMode('LinePixelData');
                  }}
                />
              </div>

              <div className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
                <span>
                  {translate('datacosmos.layers.pixelOperations.vsWavelength')}
                </span>
                <Switch
                  themeProvider={themeProvider}
                  label={
                    isPixelModeEnabled
                      ? translate('datacosmos.buttons.disable')
                      : translate('datacosmos.buttons.enable')
                  }
                  isSelected={isVsWavelengthToggled}
                  isDisabled={!isPixelModeEnabled}
                  onChange={() => {
                    togglePixelMode('vsWavelength');
                  }}
                />
              </div>

              <div className="h-sub-header bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
                <span>
                  {translate(
                    'datacosmos.layers.pixelOperations.pixelSpectralSignatures'
                  )}
                </span>
                <Switch
                  themeProvider={themeProvider}
                  label={
                    isPixelModeEnabled
                      ? translate('datacosmos.buttons.disable')
                      : translate('datacosmos.buttons.enable')
                  }
                  isSelected={isSpectralSignaturesToggled}
                  isDisabled={!isPixelModeEnabled}
                  onChange={() => {
                    togglePixelMode('spectralSignatures');
                  }}
                />
              </div>
            </div>
          }
        >
          <div className="w-full gap-1 flex items-center justify-between cursor-pointer">
            <span>{translate('datacosmos.layers.pixelOperations.title')}</span>
            <Icon
              icon="ChevronDown"
              className="stroke-item-contrast dark:stroke-item-dark-contrast"
            />
          </div>
        </Popover2>
      </div>

      {showVectorOperationSelection && (
        <VectorOperations selectedLayers={selectedLayers} />
      )}
    </div>
  );
};

export default CommonLayerControls;
