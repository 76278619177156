import React from 'react';
import AlertsRow from './AlertsRow';

import s from './index.module.scss';
import type { Alert } from 'api/alerts/types';

interface IProps {
  list: Alert[];
  mutedAlerts: string[];
  showSilenceAlertPopup: (id: string) => void;
}

const LibraryAlertsTable = ({
  list,
  mutedAlerts,
  showSilenceAlertPopup,
}: IProps) => {
  return (
    <table
      className={[s.tableContainer, 'dark:text-item-dark-contrast'].join(' ')}
    >
      <thead>
        <tr className="[&_th]:p-4 border-b border-item-contrast-inactive">
          <th>Name</th>
          <th>Severity</th>
        </tr>
      </thead>
      <tbody className="[&_td]:p-4">
        {list.map((alert) => (
          <AlertsRow
            key={alert.labels.alertname}
            alert={alert}
            silenceHandler={() =>
              showSilenceAlertPopup(alert.labels.alertname ?? '')
            }
            muted={mutedAlerts.includes(alert.labels.alertname ?? '')}
          />
        ))}
      </tbody>
    </table>
  );
};

export default LibraryAlertsTable;
