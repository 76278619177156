import type {
  SearchRequest,
  SearchResponse,
  SearchParams,
  STACItem,
} from 'api/stac/types';
import { hosts } from 'api/hosts';
import { apiFetchHandler } from 'api/clientFetch';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type SearchStacQuery = {
  'sat:platform_international_designator'?: { in: string[] };
};

export type SearchStacBody = {
  query: SearchStacQuery;
  limit: number | undefined;
};

/**
 * Handles requests to search for items in the DataCosmos catalogue service
 */
export const search = apiFetchHandler<
  SearchResponse,
  SearchParams,
  SearchRequest
>({
  host: hosts.data.v0,
  endpoint: ({ cursor }) =>
    cursor ? `/stac/search?cursor=${cursor}` : `/stac/search`,
  method: 'POST',
  errorMessage: clientTranslate('datacosmos.fetchErrors.stac.cannotSearch'),
  errorDescription: (e) => e.join('; '),
});

export type GetSingleFeatureParams = {
  collection: string;
  item: string;
};

export const getSingleFeature = apiFetchHandler<
  STACItem,
  GetSingleFeatureParams
>({
  host: hosts.data.v0,
  endpoint: ({ collection, item }) =>
    `/stac/collections/${collection}/items/${item}`,
  errorMessage: 'Could not get single feature',
  method: 'GET',
});
