import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useApiOpsGetTerminalSessions,
  type ITerminalSession,
} from '../../../../../services/api/ops/terminalSessions';
import type { IReply } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import useCommandHistory from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import { useMission } from '../../../../../services/Missions';
import { useTelecommandsApi } from '../../../../../services/api/ops/commands/useTelecommandsApi';
import { useMessageLog } from 'pages/ops/RTI/Operate/hooks/useMessageLog';
import { useParams } from 'react-router';
import { handleNewPacketFromWS } from 'pages/ops/RTI/Operate/utils/rtiCommands/terminalHandlers';
import { isNil } from 'lodash/fp';
import { useOperate } from './OperateProvider';
import {
  finishRequestingAvailableCommands,
  requestAvailableCommands,
  requestHistoryCommandList,
} from 'actions/operate/actions';
import { updateCommandHistory } from 'pages/ops/RTI/Operate/utils/rtiCommands/terminalHandlers/updateCommandHistory';
import { getHowManyCommandsFitIntoHistory } from 'pages/ops/RTI/Operate/components/CommandHistory/utilities';
import handleCommandList from 'pages/ops/RTI/Operate/utils/rtiCommands/terminalHandlers/handleCommandList';
import { useApiOpsConnectToTerminalSession } from '../../../../../services/api/ops/realtimeTerminalSession';
import moment from 'moment';
import type { CommandDefinition } from 'api/telecommands/types';

export type Props = {
  children: React.ReactNode;
};

export type TerminalSessionContextType = ReturnType<
  typeof useTerminalSessionProvider
>;
export const TerminalSessionContext = createContext<TerminalSessionContextType>(
  null as unknown as TerminalSessionContextType
);

export const useCurrentTerminalSession = () =>
  useContext(TerminalSessionContext);

/**
 * Instantiates a single new websocket connection to the terminal session.
 *
 * Moves logic from useCommandSession into a provider
 * The reason behind this change is that every time we used useCommandSession
 * we would create a new websocket connection, which is not ideal, and slowed
 * down the application substantially.
 *
 * By moving the logic into a provider, we can create a single websocket connection
 * which can then be reused by all components descended from the provider.
 */
const useTerminalSessionProvider = () => {
  const [currentSession, setCurrentSession] = useState<ITerminalSession>();
  const [availableCommands, setAvailableCommands] = useState<
    CommandDefinition[]
  >([]);
  const [noHistoryLeft, setNoHistoryLeft] = useState<boolean>(false);

  const { currentMissionId } = useMission();
  const {
    commandHistory,
    addCommandToHistory,
    handleNewHistoryCommandsAndReplies,
    addReplyToCommand,
    setCommandHistory,
    replaceCommandNote,
  } = useCommandHistory();

  const { fetchTerminalSession } =
    useApiOpsGetTerminalSessions(currentMissionId);

  const telecommandApi = useTelecommandsApi();
  const { addMessage, messages } = useMessageLog();
  const { session } = useParams<{ session: string }>();

  const { getCommandList } = telecommandApi;

  const {
    dispatch,
    state: { historyCommandType },
  } = useOperate();

  const handleNewMessage = useCallback(
    (data: IReply) => {
      const handled = !handleNewPacketFromWS(
        {
          dispatch,
          addCommandToHistory,
          setAvailableCommands,
          addNewMessageLogEvent: addMessage,
        },
        data
      );
      if (handled) addReplyToCommand(data);
    },
    [addCommandToHistory, addReplyToCommand, dispatch, addMessage]
  );

  const handleGetHistoryCommandList = useCallback(
    (timestamp: number, limit?: number) => {
      if (isNil(currentMissionId) || noHistoryLeft) return;

      const momentTime = moment
        .unix(timestamp / 1000)
        .utc()
        .subtract(1, 'millisecond');

      const unixTimestamp = Math.floor(parseInt(momentTime.format('X')));

      dispatch(requestHistoryCommandList());
      void updateCommandHistory(
        parseInt(session, 10),
        dispatch,
        handleNewHistoryCommandsAndReplies,
        () => {},
        telecommandApi,
        unixTimestamp,
        momentTime.milliseconds(),
        () => setNoHistoryLeft(true),
        limit
      );
    },
    [
      currentMissionId,
      dispatch,
      handleNewHistoryCommandsAndReplies,
      noHistoryLeft,
      session,
      telecommandApi,
    ]
  );

  useEffect(() => {
    void fetchTerminalSession(session).then((newSession) => {
      if (newSession) {
        setCurrentSession(newSession);
      }
    });
  }, [fetchTerminalSession, session, setCurrentSession]);

  useEffect(() => {
    if (!historyCommandType) {
      return;
    }
    setCommandHistory([]);
    const availableCommandSpace = getHowManyCommandsFitIntoHistory();
    handleGetHistoryCommandList(Number(new Date()), availableCommandSpace + 1);
  }, [handleGetHistoryCommandList, historyCommandType, setCommandHistory]);

  useEffect(() => {
    if (isNil(currentMissionId)) {
      return;
    }
    const handleGetCommands = async () => {
      dispatch(requestAvailableCommands());
      const data = await getCommandList();
      if (!data) {
        return;
      }
      const { transformer } = handleCommandList(setAvailableCommands);
      const transformedData = transformer(data) as CommandDefinition[];
      setAvailableCommands(transformedData);
      dispatch(finishRequestingAvailableCommands());
    };

    void handleGetCommands();
    const availableCommandSpace = getHowManyCommandsFitIntoHistory();
    handleGetHistoryCommandList(Number(new Date()), availableCommandSpace + 1);
  }, [currentMissionId, dispatch, getCommandList, handleGetHistoryCommandList]);

  const {
    isConnected,
    sendUnarmedCommand,
    updateWorkspace,
    fireCommand,
    cancelCallBackByTimer,
  } = useApiOpsConnectToTerminalSession(
    parseInt(session, 10),
    currentMissionId,
    handleNewMessage
  );

  return {
    currentSession,
    setCurrentSession,
    isConnected,
    sendUnarmedCommand,
    updateWorkspace,
    fireCommand,
    cancelCallBackByTimer,
    availableCommands,
    commandHistory,
    addCommandToHistory,
    handleGetHistoryCommandList,
    noHistoryLeft,
    messageLog: messages,
    replaceCommandNote,
  };
};

export const TerminalSessionProvider = ({ children }: Props) => {
  return (
    <TerminalSessionContext.Provider value={useTerminalSessionProvider()}>
      {children}
    </TerminalSessionContext.Provider>
  );
};
