import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash/fp';
import s from '../../index.module.scss';
import classNames from 'classnames';
import {
  Button,
  DateRangePicker,
  Group,
  parseDate,
  parseDateRange,
} from 'opencosmos-ui';
import type { DateValue } from '@internationalized/date';
import type { RangeValue } from '@react-types/shared';

interface IProps {
  stationIdList: number[];
  pickedDate: [Date | null, Date | null];
  setPickedDate: (value: [Date | null, Date | null]) => void;
  handleSearchClick: () => void;
  toggleMapVisibility: () => void;
  isGsMapVisible: boolean;
}

const GSSearchBar = (props: IProps) => {
  return (
    <div
      className={classNames(
        s.searchBarContainer,
        'dark:bg-header-dark bg-header'
      )}
    >
      <Group className={'flex items-center'}>
        <Button
          icon={props.isGsMapVisible ? 'list' : 'map'}
          intent={'primary'}
          onPress={props.toggleMapVisibility}
          className="h-8"
        >
          <span className="text-sm">
            {props.isGsMapVisible ? 'Hide map' : 'Show map'}
          </span>
        </Button>
        <DateRangePicker
          onChange={(value) => {
            props.setPickedDate([
              value.start.toDate('Etc/Utc'),
              value.end.toDate('Etc/Utc'),
            ]);
          }}
          value={
            parseDateRange(
              {
                start: props.pickedDate[0],
                end: props.pickedDate[1],
              },
              'Etc/UTC'
            ) as RangeValue<DateValue>
          }
          granularity="second"
          maxValue={parseDate(
            new Date(
              moment()
                .add(1, 'year')
                .toDate()
                .toLocaleString('en-US', { timeZone: 'Etc/UTC' })
            ),
            'Etc/Utc'
          )}
          minValue={parseDate(
            new Date(
              moment()
                .subtract(1, 'year')
                .toDate()
                .toLocaleString('en-US', { timeZone: 'Etc/UTC' })
            ),
            'Etc/Utc'
          )}
        />
        <Button
          intent={'primary'}
          isDisabled={isEmpty(props.stationIdList)}
          onPress={props.handleSearchClick}
          className="h-8"
        >
          <span className="text-sm">Refresh</span>
        </Button>
      </Group>
    </div>
  );
};

export default GSSearchBar;
