import React, { useState } from 'react';
import { Breadcrumbs } from '@blueprintjs/core';
import DefaultLayout from '../../../layouts/DefaultLayout';
import NavbarTimers from '../../../components/Timer/NavbarTimers';
import LibraryEncoding from './components/LibraryEncoding';
import LibraryTelemetry from './components/LibraryTelemetry';
import BottomNavBar from './components/BottomNavBar';
import {
  DEFAULT_TAB_STATE,
  LIBRARY_BOTTOM_NAVBAR_TABS,
} from '../../../constants/ops/library/constants';
import { ops } from '../../../constants/mixpanelAnalytics';
import LibraryStorage from './components/LibraryStorage';
import useOnLoadMetricWithUrlId from '../../../utils/hooks/analytics/useOnLoadMetricWithUrlId';
import mainS from '../RTI/index.module.scss';
import { TransferProgressProvider } from 'pages/ops/shared/context/TransferProgressProvider';

const LIBRARY_MAPPING = {
  encoding: <LibraryEncoding />,
  telemetry: <LibraryTelemetry />,
  commands: 'commands',
  storage: <LibraryStorage />,
} as const;
type LIBRARY_TABS = keyof typeof LIBRARY_MAPPING;

const Library = () => {
  const [selectedTab, setSelectedTab] =
    useState<LIBRARY_TABS>(DEFAULT_TAB_STATE);

  useOnLoadMetricWithUrlId('mission', {
    action: 'Visit',
    item: 'Library',
    module: 'OPS',
    type: ops.LIBRARY.VISIT,
  });

  const renderTab = LIBRARY_MAPPING[selectedTab];

  return (
    <TransferProgressProvider>
      <DefaultLayout
        shouldHideClock
        showMissionSelector
        light
        leftHeader={
          <div className={mainS.headerContainer}>
            <Breadcrumbs items={[{ text: 'Library' }]} />
          </div>
        }
        header={<NavbarTimers />}
      >
        {renderTab}
        <BottomNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab as (arg: LIBRARY_TABS) => unknown}
          tabList={LIBRARY_BOTTOM_NAVBAR_TABS}
        />
      </DefaultLayout>
    </TransferProgressProvider>
  );
};

export default Library;
