import { Endpoint } from 'api/gateway/types';
import { Button, Select, SelectValue, TextField } from 'opencosmos-ui';
import React, { useEffect, useState } from 'react';

export enum Connectors {
  UNKNOWN = 'Select',
  ANY = 'any',
  UMBILICAL = 'umbilical',
  BASEBAND = 'baseband',
}

const VALID_CONNECTORS = [
  Connectors.ANY,
  Connectors.BASEBAND,
  Connectors.UMBILICAL,
];

interface IProps {
  index: number;
  endpoint: Endpoint;
  showButtonIfLast: boolean;
  handleAddEndpoint: (newEndpoint: Endpoint) => void;
  handleModifyAppEndpoint: (modified: Endpoint, index: number) => void;
  handleConnectorDuplicates: (connector: Connectors) => boolean;
  handleDeleteEndpoint: (endpointIndex: number) => void;
  anyConnectorSelectedHandler: (selectedEndpoint: Endpoint) => void;
}

// const ConnectorSelect = Select.ofType<Connectors>();

const Endpoint = ({
  index,
  endpoint,
  showButtonIfLast,
  handleAddEndpoint,
  handleModifyAppEndpoint,
  handleConnectorDuplicates,
  handleDeleteEndpoint,
  anyConnectorSelectedHandler,
}: IProps) => {
  const [selectedConnector, setSelectedConnector] = useState<Connectors>(
    endpoint?.when.connector
      ? (endpoint.when.connector as Connectors)
      : Connectors.UNKNOWN
  );

  const [address, setAddress] = useState<string>(
    endpoint ? endpoint.endpoint : ''
  );

  useEffect(() => {
    handleModifyAppEndpoint(
      { endpoint: address, when: { connector: selectedConnector } },
      index
    );
    anyConnectorSelectedHandler({
      endpoint: address,
      when: { connector: selectedConnector },
    });
  }, [
    address,
    anyConnectorSelectedHandler,
    handleModifyAppEndpoint,
    index,
    selectedConnector,
  ]);

  // const connectorRenderer: ItemRenderer<Connectors> = (
  //   connector,
  //   { handleClick }
  // ) => {
  //   const selected = selectedConnector === connector;

  //   return (
  //     <MenuItem
  //       key={connector}
  //       onClick={handleClick}
  //       text={connector}
  //       active={selected}
  //       disabled={handleConnectorDuplicates(connector)}
  //     />
  //   );
  // };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '3.4fr 3.4fr 0.1fr ',
        gap: '10px',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'black',
          height: '1px ',
          width: '20px',
          left: -25,
          top: 40,
        }}
      />
      <div>
        <label htmlFor="connector-select">When using connector</label>
        <div onClick={(e) => e.stopPropagation()}>
          <Select
            id="connector-select"
            name="connector-select"
            fill
            onSelectionChange={(key) => {
              setSelectedConnector(key.toString() as Connectors);
            }}
            className="h-8"
            disabledKeys={VALID_CONNECTORS.filter((connector) => {
              return handleConnectorDuplicates(connector);
            })}
            selectedKey={selectedConnector}
          >
            {VALID_CONNECTORS.map((connector) => (
              <SelectValue id={connector} key={connector}>
                {connector}
              </SelectValue>
            ))}
          </Select>
        </div>
      </div>

      <TextField
        id="address"
        value={address}
        onChange={(val) => setAddress(val)}
        autoFocus
        label="Endpoint or address"
      />

      <Button
        icon={'Trash'}
        intent={'warning'}
        onPress={() => {
          handleDeleteEndpoint(index);
        }}
        className="h-8 mt-6"
      />

      {showButtonIfLast ? (
        <div style={{ height: '30px', paddingTop: '9px', display: 'flex' }}>
          <Button
            style={{ height: '30px' }}
            intent={'primary'}
            onPress={() => {
              handleAddEndpoint({
                endpoint: '',
                when: { connector: Connectors.UNKNOWN },
              });
            }}
            isDisabled={selectedConnector === Connectors.ANY}
          >
            <span className="text-sm">Add endpoint</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Endpoint;
