import React from 'react';
import { getOr, assoc, toNumber, size } from 'lodash/fp';
import type { IProperty } from '../../pages/ops/RTI/Operate/components/CommandArguments';
import type { ICommandGroupArgumentProps } from '../../pages/ops/RTI/Operate/components/CommandArguments/CommandGroupArgument';
import { showErrorMessage } from 'utils/common/CommonUtils';
import s from './ArrayField.module.scss';
import { Button } from 'opencosmos-ui';

interface IProps {
  className?: string;
  value: unknown;
  handleItemChange?: (value: unknown[], path: string) => void;
  renderGroup: (props: ICommandGroupArgumentProps) => React.ReactNode;
  handleDisable?: (value: boolean) => void;
  handleMetaButtonClick?: (value: string) => void;
  canBlur?: boolean;
  disabled?: boolean;
  property: IProperty;
  onBlur: () => void;
}

const ArrayField = (props: IProps) => {
  const { groupPath, path, optionalFields } = props.property;
  const fullPath = groupPath.length ? [...groupPath, path].join('.') : path;
  const currentArray = getOr([{}], fullPath, props.value) as unknown[];
  const isMaxLimitReached =
    (optionalFields.maxItems ?? Infinity) === size(currentArray);

  const handleAddNewItem = () => {
    if (isMaxLimitReached) {
      showErrorMessage('Cannot add more items!');
      return;
    }
    props.handleItemChange?.([...currentArray, {}], fullPath);
  };

  const handleRemoveItem = (index: number) => {
    const newArray = [...currentArray];
    newArray.splice(index, 1);
    props.handleItemChange?.(newArray, fullPath);
  };

  const generateHandleArrayItemChange =
    (arrayIndex: number) => (value: unknown, pth: string) => {
      const currentPath =
        toNumber(pth) || pth === '0' ? arrayIndex : `${arrayIndex}.${pth}`;

      props.handleItemChange?.(
        assoc(currentPath, value, currentArray),
        fullPath
      );
    };

  const renderedNodeArray = (arrayIndex: number) => {
    const groupLength = props.property.group.length;

    return (
      <div className={s.container}>
        {groupLength > 1 && (
          <Button
            isDisabled={props.disabled}
            icon={'remove'}
            intent={'warning'}
            onPress={() => handleRemoveItem(arrayIndex)}
          />
        )}
        <div className={s.field}>
          {props.property.group.map((item: IProperty) => {
            const currentPath = String(item.path ? item.path : arrayIndex);

            const inputProperties: ICommandGroupArgumentProps = {
              key: `${currentPath}_path`,
              property: { ...item, groupPath: [], path: currentPath },
              value: item.path ? currentArray[arrayIndex] : currentArray,
              onBlur: props.onBlur,
              handleDisable: props.handleDisable,
              handleMetaButtonClick: props.handleMetaButtonClick,
              canBlur: props.canBlur,
              disabled: props.disabled,
              onChange: generateHandleArrayItemChange(arrayIndex),
              hideInputPath: !item.path,
            };

            return groupLength === 1
              ? props.renderGroup({
                  ...inputProperties,
                  onRemove: () => handleRemoveItem(arrayIndex),
                })
              : props.renderGroup(inputProperties);
          })}
        </div>
      </div>
    );
  };

  return (
    <ul className={props.className}>
      {currentArray.map((_, index) => (
        <li key={`${index}.${path}`}>{renderedNodeArray(index)}</li>
      ))}
      <li>
        <Button
          isDisabled={props.disabled}
          icon={'add'}
          onPress={handleAddNewItem}
        />
      </li>
    </ul>
  );
};

export default ArrayField;
