import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import UploadRegion from 'datacosmos/components/UploadRegion';
import area from '@turf/area';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import { TaskingAoiCard } from 'datacosmos/components/Tasking/AreaOfInterest/TaskingAoiCard';
import type { IMapContext } from 'datacosmos/stores/MapProvider';
import Button from '_molecules/Button/Button';
import { Tooltip } from 'ui/Tooltip';
import { useMemo } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type AreaOfInterestProps = {
  regions: PolygonLayer[];
  replaceLayer: (layer: Layer) => void;
  removeLayer: (layerid: string) => void;
  addLayer: (...newLayers: Layer[]) => void;
  drawPolygon: IMapContext['drawPolygon'];
  editPolygon: IMapContext['editPolygon'];
  disableEditing: IMapContext['disableEditing'];
  isFetching: boolean;
  centerRegion: (region: PolygonLayer) => void;
  isRegionValid: boolean;
  disabled: boolean;
};

export const AreaOfInterest = ({
  regions,
  isFetching,
  replaceLayer,
  addLayer,
  drawPolygon,
  removeLayer,
  editPolygon,
  disableEditing,
  centerRegion,
  isRegionValid,
  disabled,
}: AreaOfInterestProps) => {
  const disableButtons = useMemo(
    () => isFetching || regions.length > 0,
    [isFetching, regions.length]
  );

  const { translate } = useLocalisation();
  return (
    <div className="w-full color-item mb-2">
      <div className="flex w-full items-center h-8">
        <span className="whitespace-nowrap mr-4 ml-2 font-bold">
          {translate('datacosmos.tasking.new.aoi')}
        </span>

        <Tooltip
          content={translate('datacosmos.tooltips.tasking.noMultiple')}
          disabled={!disableButtons}
          className="flex-1 flex"
        >
          <div className="flex-1 flex justify-evenly">
            <Button
              icon="AddAoi"
              text="Draw"
              size={24}
              disabled={disableButtons}
              onPress={async () => {
                const { polygon, polygonMetadata } = await drawPolygon();

                const m2 = area(polygon);
                addLayer(
                  PolygonLayerFactory(
                    LayerSourceType.TASKING_REGIONS,
                    translate('datacosmos.layers.names.region'),
                    polygon,
                    m2,
                    polygonMetadata,
                    { color: '#0000ff' }
                  )
                );
              }}
            />
            <UploadRegion
              aoiSourceType={LayerSourceType.TASKING_REGIONS}
              setAreaOfInterest={() => {}}
              buttonTitle={translate('datacosmos.tasking.new.upload')}
              buttonForTasking
              disableButton={disableButtons}
            />
          </div>
        </Tooltip>
      </div>

      {regions.length > 0 ? (
        <div className="flex flex-col max-h-40 overflow-y-auto">
          {regions.map((region: PolygonLayer) => {
            return (
              <TaskingAoiCard
                disabled={disabled}
                key={region.id}
                region={region}
                replaceLayer={replaceLayer}
                disableEditing={disableEditing}
                editPolygon={editPolygon}
                removeLayer={removeLayer}
                centerRegion={centerRegion}
                isRegionValid={isRegionValid}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
