import classNames from 'classnames';
import { Button, Dialog, Input } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import React from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  saveTLE: (line1: string, line2: string) => void | Promise<void>;
  isSavingTLE: boolean;
};

const GSAddTLEModal = ({ isOpen, setIsOpen, saveTLE, isSavingTLE }: Props) => {
  const [line1, setLine1] = React.useState<string>('');
  const [line2, setLine2] = React.useState<string>('');

  return (
    <Dialog
      buttons={[
        {
          onPress: async () => {
            await saveTLE(line1, line2);
            setIsOpen(false);
          },
          shown: true,
          text: isSavingTLE ? <Spinner size={22} /> : 'Save',
          intent: 'primary',
        },
      ]}
      title="Add new TLE"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className={classNames('flex flex-col gap-4')}>
        <Input
          type="text"
          label={{
            text: 'Line 1',
            position: 'top',
          }}
          onChange={(e) => setLine1(e.target.value)}
        />

        <Input
          type="text"
          label={{
            text: 'Line 2',
            position: 'top',
          }}
          onChange={(e) => setLine2(e.target.value)}
        />

        <Button
          onPress={async () => {
            await saveTLE(line1, line2);
            setIsOpen(false);
          }}
          className="!bg-accent-500 hover:!bg-accent-700"
        >
          {isSavingTLE ? <Spinner size={22} /> : 'Save'}
        </Button>
      </div>
    </Dialog>
  );
};

export default GSAddTLEModal;
