import { Callout } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { durationToReadableFormat } from '../../../../../../utils/common/dateUtils';
import s from './TelemetryRatesReplyInfo.module.scss';

interface ITelemetryRatesValue {
  origin: object;
  periodSeconds: number;
}

export interface ITelemetryRatesReplyInfo {
  origin: string;
  value: ITelemetryRatesValue[];
}

interface IProps {
  reply: ITelemetryRatesReplyInfo;
}

const parseValueItem = (item: ITelemetryRatesValue) => {
  const entries = Object.entries(item.origin)[0];

  if (!entries) {
    return { origin: '', type: '' };
  }

  const origin = `${entries[1] as string}`;
  const type = entries[0].split('ReportOrigin')[0];

  return { origin, type };
};

const TelemetryRatesReplyInfo = (props: IProps) => (
  <Callout className={s.calloutWrapper}>
    <div>
      <div className={s.divRow}>
        <div className={[s.divCol3, s.divTh].join(' ')}>Origin</div>
        <div className={[s.divCol1, s.divTh].join(' ')}>Type</div>
        <div className={[s.divCol1, s.divTh].join(' ')}>Value</div>
      </div>
      <div>
        {props.reply.value.map((item: ITelemetryRatesValue) => {
          const { origin, type } = parseValueItem(item);

          return (
            <Tooltip
              key={origin}
              className={s.tooltipContainer}
              content={origin}
            >
              <div className={s.divRow}>
                <div className={[s.divCol3, s.wrapEllipsisText].join(' ')}>
                  {origin}
                </div>
                <div className={[s.divCol1, s.textWrapper].join(' ')}>
                  {type}
                </div>
                <div className={[s.divCol1, s.textWrapper].join(' ')}>
                  {durationToReadableFormat(item.periodSeconds)}
                </div>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  </Callout>
);

export default TelemetryRatesReplyInfo;
