import { xor } from 'lodash';
import React, { useState } from 'react';
import { ActivityStatusList, ActivityTypeList } from 'api/activities/service';
import type { ActivityType, ActivityStatus } from 'api/activities/service';
import s from './index.module.scss';
import { Button, MultiSelect } from 'opencosmos-ui';
import classNames from 'classnames';

interface IProps {
  setSelectedStatuses: React.Dispatch<React.SetStateAction<ActivityStatus[]>>;
  selectedStatuses: ActivityStatus[];
  selectedTypes: ActivityType[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<ActivityType[]>>;
}

const SchedulingFilter = ({
  setSelectedStatuses,
  selectedStatuses,
  selectedTypes,
  setSelectedTypes,
}: IProps) => {
  const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);

  const getNoOfAppliedFilters = () => {
    let no = 0;
    selectedStatuses.length > 0 && no++;
    selectedTypes.length > 0 && no++;
    return no;
  };
  const areFiltersApplied =
    selectedStatuses.length > 0 || selectedTypes.length > 0;

  return (
    <div className={s.container}>
      <div className={s.filtersButtonWrapper}>
        <Button
          variant="square"
          icon={'Filter'}
          onPress={() => setAreFiltersOpen(!areFiltersOpen)}
        />
        {areFiltersApplied && (
          <div className={s.filtersCounter}>{getNoOfAppliedFilters()}</div>
        )}
      </div>

      {areFiltersOpen && (
        <div
          className={classNames(
            s.dropdownWrapper,
            'bg-item dark:bg-item-dark dark:text-item-dark-contrast '
          )}
        >
          <div className={s.dropdownItem}>
            <span>Activity status</span>
            <div>
              <MultiSelect
                fill
                items={ActivityStatusList.map((status) => ({
                  id: status,
                  value: status,
                }))}
                onSelectionChange={(item) => {
                  setSelectedStatuses((prev) =>
                    xor(prev, [item.value as ActivityStatus])
                  );
                }}
                defaultSelectedKeys={selectedStatuses}
              />
            </div>
          </div>

          <div className={s.dropdownItem}>
            <span>Activity type</span>
            <div>
              <MultiSelect
                fill={true}
                items={ActivityTypeList.map((type) => ({
                  id: type,
                  value: type,
                }))}
                onSelectionChange={(item) => {
                  setSelectedTypes((prev) =>
                    xor(prev, [item.value as ActivityType])
                  );
                }}
                defaultSelectedKeys={selectedTypes}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulingFilter;
