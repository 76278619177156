import React from 'react';
import { pipe } from 'lodash/fp';
import { splitLongStringByChunk } from '../../../../../../utils/common/stringUtils';
import { Pre } from '@blueprintjs/core';
import type { ITelemetryRatesReplyInfo } from '../../components/CommandReplyInfo/TelemetryRatesReplyInfo';
import TelemetryRatesReplyInfo from '../../components/CommandReplyInfo/TelemetryRatesReplyInfo';
import ExecuteTaskReplyPre from '../../components/CommandReplyInfo/ExecuteTaskReplyPre';
import ByteDisplay from '../../components/ByteDisplay';
import type { IFormattedSSHMessage } from 'pages/ops/RTI/Operate/components/CommandReplyInfo/SSHReplyInfo';
import SSHReplyInfo from 'pages/ops/RTI/Operate/components/CommandReplyInfo/SSHReplyInfo';
import type { FtpTransferUpdateResponse } from 'pages/ops/RTI/Operate/components/CommandReplyInfo/FTPTransferUpdateInfo';
import FTPTransferUpdateInfo from 'pages/ops/RTI/Operate/components/CommandReplyInfo/FTPTransferUpdateInfo';
import type { UFTTransportReply } from 'pages/ops/RTI/Operate/components/CommandReplyInfo/UFTTransportInfoReplyInfo';
import UFTTransportInfoReplyInfo from 'pages/ops/RTI/Operate/components/CommandReplyInfo/UFTTransportInfoReplyInfo';
import type { TelemetryGetSetResponse } from 'pages/ops/RTI/Operate/components/CommandReplyInfo/TelemetryGetSetReply';
import TelemetryGetSetReply from 'pages/ops/RTI/Operate/components/CommandReplyInfo/TelemetryGetSetReply';

export const renderStringArray = (arr: string[]) => (
  <ExecuteTaskReplyPre preList={arr} />
);

export const piuExecuteTaskHandler = (response: string) =>
  pipe(
    (str: string) => splitLongStringByChunk(str),
    renderStringArray
  )(response);

const renderTelemetryRates = (data: ITelemetryRatesReplyInfo) => (
  <TelemetryRatesReplyInfo reply={data} />
);

export const telemetryRatesReportHandler = (
  response: ITelemetryRatesReplyInfo
) => renderTelemetryRates(response);

export const stringResponseNewLineHandler = (response: string) => (
  <Pre>{response}</Pre>
);

export const byteResponseHandler = (response: string) => (
  <ByteDisplay base64Value={response} />
);

const renderSSHReply = (data: IFormattedSSHMessage) => {
  return <SSHReplyInfo data={data} />;
};

export const sshReplyHandler = (response: IFormattedSSHMessage) =>
  renderSSHReply(response);

export const ftpTransferUpdateHandler = (
  response: FtpTransferUpdateResponse
) => <FTPTransferUpdateInfo data={response} />;

export const uftTransportReplyHandler = (response: UFTTransportReply) => (
  <UFTTransportInfoReplyInfo response={response} />
);
export const telemetryGetSetReplyHandler = (
  response: TelemetryGetSetResponse
) => {
  return <TelemetryGetSetReply response={response} />;
};
