import React from 'react';
import { get } from 'lodash';

import { useApiGroundStationService } from 'pages/ops/GSScheduling/context/GroundStationProvider';
import { durationToReadableFormat } from 'utils/common/dateUtils';

import s from './bookPassModal.module.scss';
import { Button, Icon } from 'opencosmos-ui';
import classNames from 'classnames';

const ConfirmationOfBooking = () => {
  const {
    selectedPassBooked,
    passJustBooked,
    antennas,
    cancelAndCloseModal,
    selectedRadioLinks,
  } = useApiGroundStationService();

  if (!selectedPassBooked) {
    return (
      <div
        className={classNames(
          s.confirmContainer,
          'dark:text-item-dark-contrast'
        )}
      >
        <div className={s.failedBookingIcon}>
          <Icon icon={'error'} size={32} />
        </div>
        <div>
          <h3 className="bp4-heading">Could not book pass.</h3>
          <div>
            You can try again by going to the previous step and re-booking.
          </div>
        </div>
      </div>
    );
  }

  // @ts-expect-error - lodash assumes wrong resultant type
  const antennaBooked = get(
    antennas,
    `[${passJustBooked.antenna}].name`
  ) as string;
  // @ts-expect-error - lodash assumes wrong resultant type
  const siteBooked = get(
    antennas,
    `[${passJustBooked.antenna}].site.name`
  ) as string;

  return (
    <div
      className={classNames(s.confirmContainer, 'dark:text-item-dark-contrast')}
    >
      <div className={s.failedBookingIcon}>
        <Icon icon={'tick-circle'} size={32} stroke="#5ABE96" fill="#5ABE96" />
      </div>
      <div className={classNames(s.bookingConfirmation)}>
        <h3 className="dark:text-item-dark-contrast text-2xl">Pass booked!</h3>
        <table>
          <tbody>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Site</b>
              </td>
              <td className={s.confirmInfoColumn}>{siteBooked}</td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Antenna</b>
              </td>
              <td className={s.confirmInfoColumn}>{antennaBooked}</td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>From (UTC)</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {passJustBooked.window.start}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>To (UTC)</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {passJustBooked.window.end}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Duration</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {durationToReadableFormat(
                  passJustBooked.additionalInfo.duration
                )}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Max. Elevation (°)</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {passJustBooked.additionalInfo.maxElevation.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Radio links</b>
              </td>
              <td>
                {selectedRadioLinks
                  .map((srl) => `${srl.frequency} (${srl.direction})`)
                  .join(', ')}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={s.buttonContainer}>
          <Button
            onPress={cancelAndCloseModal}
            intent={'warning'}
            icon={'Trash'}
          >
            <span className="text-sm">Cancel booking</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationOfBooking;
