import { Button } from 'opencosmos-ui';
import s from './index.module.scss';

interface IProps {
  disabled?: boolean;
  text: string;
  onClick: () => void;
}

const CreateButton = (props: IProps) => (
  <div className={s.createButton}>
    <div>
      <Button
        isMinimal
        isDisabled={props.disabled}
        icon="Plus"
        onPress={props.onClick}
      >
        <span className="text-sm">{props.text}</span>
      </Button>
    </div>
  </div>
);

export default CreateButton;
