import { Button, RangeSlider } from 'opencosmos-ui';
import React, { useState } from 'react';

import s from './index.module.scss';

interface IProps {
  onSubmitHandler: Function;
  onCancelHandler: Function;
}

const MuteAlertForm = ({ onSubmitHandler, onCancelHandler }: IProps) => {
  const [daysToMuteAlert, setDaysToMuteAlert] = useState<number>(1);

  const clickHandler = () => {
    onSubmitHandler(daysToMuteAlert);
  };

  return (
    <>
      <RangeSlider
        minValue={1}
        maxValue={7}
        numberOfHandles={1}
        handleLabelFormatter={(value) => value + ' days'}
        showValuesAboveHandles={true}
        showScale={false}
        value={daysToMuteAlert}
        onChange={(num) => setDaysToMuteAlert(num as number)}
      />
      <div className={s.dialogueButtonContainer}>
        <Button
          //@ts-expect-error
          onPress={onCancelHandler}
        >
          <span className="text-sm">Cancel</span>
        </Button>
        <div className={s.dialogueChoiceButtons}>
          <Button intent={'primary'} onPress={clickHandler}>
            Mute
          </Button>
        </div>
      </div>
    </>
  );
};

export default MuteAlertForm;
