import { Button } from 'opencosmos-ui';
import React from 'react';

interface IProps {
  disabled?: boolean;
  onRemove?: () => void;
}

const GenericRemoveButton = (props: IProps) =>
  props.onRemove ? (
    <Button
      isDisabled={props.disabled}
      intent="warning"
      icon="remove"
      onPress={props.onRemove}
    />
  ) : null;

export default GenericRemoveButton;
