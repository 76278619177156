import React from 'react';
import classNames from 'classnames';
import { Switch } from 'opencosmos-ui';
import { useTheme } from 'datacosmos/stores/ThemeProvider';

export interface IProps {
  value: any;
  disabled: boolean;
  fullPath: string;
  defaultValue: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  required: boolean;
  setTooltipOpen: (open: boolean) => void;
}

const CommandPrimitiveBoolean = (props: IProps) => {
  const {
    value,
    onChange,
    handleBlur,
    handleFocus,
    disabled,
    fullPath,
    defaultValue,
    required,
    setTooltipOpen,
  } = props;

  const val = Boolean(value || defaultValue);

  const theme = useTheme();

  return (
    <div
      className="flex items-center gap-1"
      onMouseOut={() => {
        setTooltipOpen(false);
      }}
      onMouseOver={() => {
        setTooltipOpen(true);
      }}
    >
      <Switch
        label={val ? 'Enabled' : 'Disabled'}
        data-testid="cmd-primitive-boolean-switch"
        isDisabled={disabled}
        isSelected={val}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseOut={!disabled ? handleBlur : undefined}
        onMouseOver={!disabled ? handleFocus : undefined}
        onChange={() => onChange(!value, fullPath)}
        className={classNames({
          'border-2 border-red-500': required,
        })}
        themeProvider={theme}
      />
    </div>
  );
};

export default CommandPrimitiveBoolean;
