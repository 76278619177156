import { HeaderButton } from 'components/missionDesignPage/header/HeaderButton';
import { useHistory } from 'react-router';
import { useAuth } from 'services/auth/AuthWrapper';
import { getHost } from 'utils/common/CommonUtils';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import {
  AUTH0_LOGOUT_URI,
  ENABLE_DARK_MODE,
  ENABLE_USER_IMAGES,
  ENABLE_USER_TYPE_DISPLAY,
  KEYCLOAK_BASE_URL,
  TASKING_ENABLE,
} from 'env';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useQuery } from 'api/useQuery';
import {
  getRoleAssignments,
  type Organisation,
} from 'api/administration/service';
import { findHighestPriorityRole } from 'utils/auth/common';
import {
  Icon,
  MenuItem,
  Menu,
  Popover,
  SubmenuTrigger,
  Text,
  MenuTrigger,
} from 'opencosmos-ui';
import type { IMission } from 'services/Missions';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface UserProfileMenuProps {
  showMissionSelector?: boolean;
  currentMission?: IMission;
  currentOrganisation?: Organisation;
  isAllowedToUpdateOrganisation?: boolean;
  hideThemesInUserMenu?: boolean;
}
export const UserProfileMenu = ({
  showMissionSelector,
  currentMission,
  currentOrganisation,
  isAllowedToUpdateOrganisation,
  hideThemesInUserMenu,
}: UserProfileMenuProps) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    isAllowedToReadRoles,
    isAllowedToReadUsersRoles,
  } = useAuth();

  const getAssignmentsQuery = useQuery(getRoleAssignments, {
    initialData: [],
    params: user ? { userId: user.sub, resourceType: 'global' } : undefined,
    skip: !user?.sub,
  });

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const { setTheme, theme } = useTheme();

  const history = useHistory();

  const handleRolesLinkClick = () => {
    history.push('/portal/roles');
  };

  const handleUsersLinkClick = () => {
    history.push('/portal/users');
  };

  const handleOrdersLinkClick = () => {
    history.push('/data/orders');
  };

  const handleTaskingOverviewLinkClick = () => {
    history.push('/data/tasking/overview');
  };

  const handleOrganisationLinkClick = () => {
    if (currentMission?.organisation === undefined) return;
    history.push(`/portal/organisation/${currentMission.organisation}`);
  };

  const { hasPermission: isAllowedToViewTaskingOverview } = useCheckPermissions(
    {
      permissions: {
        type: 'global',
        actionScope: 'data:tasking:request:read:own',
      },
    }
  );

  const { hasPermission: isAllowedToAccessMatomo } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'admin:matomo',
    },
  });

  const { hasPermission: isAllowedToAccessGrafana } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'admin:grafana',
    },
  });

  const { hasPermission: isAllowedToAccessKeyCloak } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'admin:keycloak',
    },
  });

  let buttonContent = <Icon icon={'User'} size={32} />;
  let content = (
    <Menu
      onAction={(key) => {
        if (key === 'login') {
          sendInfo({
            type: 'Login',
            action: 'Click',
            item: 'Login button',
            module: 'DataCosmos',
          });
          void loginWithRedirect();
        }
      }}
    >
      <MenuItem>Anonym</MenuItem>
      <MenuItem id={'login'}>
        <span>Login</span>
      </MenuItem>
    </Menu>
  );
  if (isAuthenticated) {
    if (user) {
      buttonContent = ENABLE_USER_IMAGES ? (
        <img src={user.picture} className="h-[45px] w-[45px]" alt="avatar" />
      ) : (
        buttonContent
      );

      content = (
        <Menu
          onAction={(key) => {
            switch (key) {
              case 'orders':
                handleOrdersLinkClick();
                break;
              case 'roles':
                handleRolesLinkClick();
                break;
              case 'users':
                handleUsersLinkClick();
                break;
              case 'tasking_overview':
                handleTaskingOverviewLinkClick();
                break;
              case 'organisation':
                handleOrganisationLinkClick();
                break;
              case 'logout':
                sendInfo({
                  type: 'Logout',
                  action: 'Click',
                  item: 'Logout button',
                  module: 'DataCosmos',
                });
                logout(getHost() + AUTH0_LOGOUT_URI);
                break;
              default:
                break;
            }
          }}
          disabledKeys={['user']}
        >
          <MenuItem id={'user'}>
            <div className="flex items-center gap-2">
              <Icon icon={'User'} />
              <div>
                <span>{user.name}</span>
                {ENABLE_USER_TYPE_DISPLAY && (
                  <div className="text-sm text-item-contrast-inactive dark:text-item-dark-contrast-inactive flex gap-1 items-center">
                    {findHighestPriorityRole(getAssignmentsQuery?.data)}
                  </div>
                )}
              </div>
            </div>
          </MenuItem>

          {showMissionSelector &&
          currentOrganisation &&
          isAllowedToUpdateOrganisation ? (
            <MenuItem id={'organisation'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'Pin'} />
                <span>{currentOrganisation.name}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          <MenuItem id={'orders'}>
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={'ShoppingCart'} />
              <span>{translate('datacosmos.header.menu.orders')}</span>
            </div>
          </MenuItem>

          {ENABLE_DARK_MODE && !hideThemesInUserMenu ? (
            <SubmenuTrigger>
              <MenuItem id="share">
                <Icon icon="ChevronLeft" />
                <Text slot="label">
                  {translate('datacosmos.header.menu.theme.title')}
                </Text>
              </MenuItem>
              <Popover offset={0}>
                <Menu
                  aria-label={translate('datacosmos.header.menu.theme.title')}
                  selectionMode="single"
                  onSelectionChange={(values) => {
                    if (values === 'all') {
                      throw new Error('Unexpected selection of all items.');
                    }

                    setTheme(
                      values.keys().next().value as 'DARK' | 'LIGHT' | 'AUTO'
                    );
                  }}
                  selectedKeys={[theme]}
                >
                  <MenuItem id="DARK">
                    {translate('datacosmos.header.menu.theme.dark')}
                  </MenuItem>
                  <MenuItem id="LIGHT">
                    {translate('datacosmos.header.menu.theme.light')}
                  </MenuItem>
                  <MenuItem id="AUTO">
                    {translate('datacosmos.header.menu.theme.auto')}
                  </MenuItem>
                </Menu>
              </Popover>
            </SubmenuTrigger>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {TASKING_ENABLE && isAllowedToViewTaskingOverview ? (
            <MenuItem id={'tasking_overview'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'Calendar'} />
                <span>
                  {translate('datacosmos.header.menu.taskingOverview')}
                </span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToReadRoles ? (
            <MenuItem id={'roles'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'Star'} />
                <span>{translate('datacosmos.header.menu.rolesManager')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToReadUsersRoles ? (
            <MenuItem id={'users'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'UserGroup'} />
                <span>{translate('datacosmos.header.menu.users')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessGrafana ? (
            <MenuItem
              id={'grafana'}
              href={`${KEYCLOAK_BASE_URL}/monitoring/grafana/`}
              target="_blank"
            >
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'File'} />
                <span>{translate('datacosmos.header.menu.grafana')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessMatomo ? (
            <MenuItem
              id={'matomo'}
              href={`${KEYCLOAK_BASE_URL}/matomo/`}
              target="_blank"
            >
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'File'} />
                <span>{translate('datacosmos.header.menu.matomo')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessKeyCloak ? (
            <MenuItem
              id={'keycloak'}
              href={`${KEYCLOAK_BASE_URL}/admin/`}
              target="_blank"
            >
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={'File'} />
                <span>{translate('datacosmos.header.menu.keycloak')}</span>
              </div>
            </MenuItem>
          ) : (
            (null as unknown as JSX.Element)
          )}
          <MenuItem id={'logout'}>
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={'ChevronRightDouble'} />
              <span>{translate('datacosmos.header.menu.logout')}</span>
            </div>
          </MenuItem>
        </Menu>
      );
    }
  }

  return (
    <MenuTrigger>
      <HeaderButton>{buttonContent}</HeaderButton>
      <Popover
        placement="bottom left"
        className="w-max tour-profile"
        offset={0}
      >
        {content}
      </Popover>
    </MenuTrigger>
  );
};
