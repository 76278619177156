import type { SliderProps } from '@react-types/slider';
import React, { useRef } from 'react';
import { useNumberFormatter, useSlider } from 'react-aria';
import { useSliderState } from 'react-stately';
import { range } from 'lodash';
import type { NumberFormatOptions } from '@internationalized/number/dist/types';
import Thumb from './Thumb';
import classNames from 'classnames';
import NumberScale from '_atoms/NumberScale/NumberScale';

export interface IRangeSliderProps
  extends Omit<SliderProps<number[]>, 'label'>,
    Pick<
      React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >,
      'style'
    > {
  numberOfHandles: number;
  numberFormatterOptions?: NumberFormatOptions;
  label?: {
    text?: string;
    icon?: JSX.Element;
  };
  showValuesAboveHandles?: true;
  handleLabelFormatter?: (value: number) => string | number;
  showScale?: boolean;
  scaleLabelFormatter?: (value: number) => string | number;
  className?: string;
}

const RangeSlider = (props: IRangeSliderProps) => {
  const calculateHandlePositions = (min = 0, max = 0) => {
    return range(props.numberOfHandles).map((i) => {
      return Math.round((min + (max - min) / (props.numberOfHandles - 1)) * i);
    });
  };

  const { showScale = true } = props;
  const initialValue =
    props.value ??
    props.defaultValue ??
    calculateHandlePositions(props.minValue, props.maxValue);

  const trackRef = useRef<HTMLDivElement>(null);
  const numberFormatter = useNumberFormatter(props.numberFormatterOptions);
  const state = useSliderState({
    ...(props as unknown as SliderProps),
    numberFormatter,
    defaultValue: initialValue,
  });
  const { groupProps, trackProps, labelProps } = useSlider(
    {
      ...(props as unknown as SliderProps),
      'aria-label':
        typeof props.label === 'string' ? props.label + ' slider' : 'slider',
    },
    state,
    trackRef
  );

  const thumbs = range(props.numberOfHandles).map((i) => (
    <Thumb key={i} index={i} state={state} trackRef={trackRef} {...props} />
  ));

  return (
    <div
      {...groupProps}
      className={
        'relative flex-col items-center touch-none w-full ' + props.className
      }
    >
      {props.label && (
        <div className="flex self-stretch items-center gap-2 dark:text-item-dark-contrast text-item-contrast">
          {props.label.icon && props.label.icon}
          {props.label.text && (
            <label {...labelProps}>{props.label.text}</label>
          )}
        </div>
      )}
      <div
        {...trackProps}
        ref={trackRef}
        style={{
          position: 'relative',
          height: 30,
          width: ' 100%',
          cursor: props.isDisabled ? 'default' : 'pointer',
        }}
      >
        {props.numberOfHandles !== 1 && (
          <div
            {...trackProps}
            data-testid="slider-track"
            className={classNames('h-1 rounded-sm border-2 z-20', {
              'bg-item-contrast-inactive dark:bg-item-dark-contrast-inactive':
                props.isDisabled,
              'border-item-contrast dark:border-item-dark-contrast':
                !props.isDisabled,
            })}
            style={{
              position: 'absolute',
              left: `${state.getValuePercent(state.values[0]) * 100}%`,
              width: `${
                state.getValuePercent(
                  state.values[state.values.length - 1] * 100
                ) - state.getValuePercent(state.values[0] * 100)
              }%`,
              top: 13,
            }}
          />
        )}
        <div
          className={classNames('z-10', {
            // Inactive is being used for the background, when the element becomes
            // inactive, the main line color will match this inactive color.
            'bg-item-contrast-inactive dark:bg-item-dark-contrast-inactive':
              !props.isDisabled,
          })}
          style={{
            position: 'absolute',
            height: 3,
            top: 13,
            width: '100%',
          }}
        />
        {thumbs}
      </div>

      {showScale && (
        <NumberScale
          minimum={props.minValue ? props.minValue : 0}
          maximum={props.maxValue ? props.maxValue : 100}
          stepSize={props.step ? props.step : 1}
          formatter={props.scaleLabelFormatter}
        />
      )}
    </div>
  );
};

export default RangeSlider;
