import type {
  IContext,
  IStacApiSearch,
  StacItem,
  BandTypes,
} from 'datacosmos/types/stac-types';
import { AssetType, CustomFilterType } from 'datacosmos/types/stac-types';
import { isEmpty, uniqBy } from 'lodash';
import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import type { IFilter } from './imageCatalogApi';
import {
  isVectorImage,
  getImageBandAsset,
  isRasterImage,
} from 'datacosmos/utils/stac';
import type imageCatalogApi from 'datacosmos/services/imageCatalogApi';
import { ScenarioApi, APIError } from 'datacosmos/services/scenarioApi';
import { baseProjectRoute } from 'datacosmos/components/routePath';
import { useLocation, useRouteMatch } from 'react-router';
import { useFilters } from 'datacosmos/stores/FiltersProvider';
import { ItemBush } from 'datacosmos/utils/ItemBush';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

export const DEFAULT_ITEM_LIMIT = 50;

export type StacAdapterFilters = { filters: IFilter; AOI: GeoJSON.Polygon[] };

export type StacAdapter = ReturnType<typeof useStacAdapter>;

export const useStacAdapter = (
  api: ReturnType<typeof imageCatalogApi> | ScenarioApi
) => {
  //Context for fetched results (number fetched etc.)
  const [fetchedResultsContext, setFetchedResultsContext] =
    useState<IContext>();

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [fetchingFailed, setFetchingFailed] = useState<string | null>(null);

  const { sendInfo } = useAnalytics();

  const location = useLocation();
  /**
   * queries the STAC api, or scenario api, depending on the route, to get the results set in the filters
   * @param url pass a page URL to get the next page of the results. Do
   * not pass anything to get the first page.
   */
  const queryCounterRef = useRef(0);

  const { filters, assetTypeFilter, imageBandFilter } = useFilters();

  const [stacSearchPageCursor, setStacSearchPageCursor] = useState<
    string | null
  >();

  const queryFilter = useCallback(
    (items: StacItem[], filterType: string) => {
      let filteredResult: StacItem[] = [];

      if (!isEmpty(assetTypeFilter) && !isEmpty(imageBandFilter)) {
        filteredResult = assetTypeFilter.flatMap((assetType) => {
          return imageBandFilter.flatMap((image) => {
            if (assetType === AssetType.VECTOR.toString()) {
              return items.filter(
                (item) =>
                  isVectorImage(item) &&
                  getImageBandAsset(item, image as BandTypes)
              );
            }
            return items.filter(
              (item) =>
                isRasterImage(item) &&
                getImageBandAsset(item, image as BandTypes)
            );
          });
        });
      } else if (filterType === CustomFilterType.ASSET.toString()) {
        filteredResult = assetTypeFilter.flatMap((assetType) => {
          if (assetType === AssetType.VECTOR.toString()) {
            return items.filter((item) => isVectorImage(item));
          }
          return items.filter((item) => isRasterImage(item));
        });
      } else if (filterType === CustomFilterType.BAND.toString()) {
        let image = imageBandFilter.flatMap((img) =>
          items.filter((item) => getImageBandAsset(item, img as BandTypes))
        );
        image = uniqBy(image, 'id');
        filteredResult = image;
      }
      if (filteredResult.length < DEFAULT_ITEM_LIMIT) {
        setStacSearchPageCursor(undefined);
      }
      filteredResult = uniqBy(filteredResult, 'id');
      return filteredResult;
    },
    [assetTypeFilter, imageBandFilter, setStacSearchPageCursor]
  );

  const datacosmosRouteMatch = useRouteMatch<{ projectId: string }>(
    baseProjectRoute
  );

  const projectId = datacosmosRouteMatch?.params.projectId ?? '';
  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const fetchImages = useCallback(
    async (cursor?: string) => {
      if (!projectId) return;

      queryCounterRef.current++;
      const queryNumber = queryCounterRef.current;

      const isFirstPage = cursor === undefined;

      setIsFetching(true);
      setFetchingFailed(null);

      let images: IStacApiSearch | null | undefined = null;
      let collectionToQuery: string[] | undefined = undefined;
      let idsToQuery: string[] | undefined = undefined;
      const collectionAndStacIDofItem = urlParams.get('view-item');
      if (collectionAndStacIDofItem) {
        collectionToQuery = [collectionAndStacIDofItem?.split('/')[0]];
        idsToQuery = [collectionAndStacIDofItem?.split('/')[1]];
      }
      try {
        images = await api.fetchImages(
          projectId,
          (filters as StacAdapterFilters).filters,
          cursor,
          DEFAULT_ITEM_LIMIT,
          filters.AOI,
          collectionToQuery,
          idsToQuery
        );

        sendInfo({
          action: `STAC search`,
          item:
            api instanceof ScenarioApi ? 'Projects search' : 'Catalog search',
          type: 'STAC search',
          dimensions: {
            dimension4: projectId,
          },
          additionalParams: {
            filters,
            projectId,
          },
        });
      } catch (error) {
        setIsFetching(false);
        setFetchingFailed(
          error instanceof APIError && error.status === 403
            ? 'forbidden'
            : 'error'
        );
        return undefined;
      }

      if (queryNumber !== queryCounterRef.current) {
        // There has been another request meanwhile this was loading, lets not update
        setFetchingFailed(null);
        return null;
      }
      setIsFetching(false);

      if (images) {
        setFetchedResultsContext({
          matched: images.numberMatched,
          returned: images.numberReturned,
        });
        const nextPageCursor = images.links
          ?.find((link) => link.rel === 'next')
          ?.href.split('=')[1];

        setStacSearchPageCursor(nextPageCursor);

        if (isFirstPage) {
          if (!isEmpty(assetTypeFilter)) {
            images.features = queryFilter(
              images.features,
              CustomFilterType.ASSET
            );
          } else if (!isEmpty(imageBandFilter)) {
            images.features = queryFilter(
              images.features,
              CustomFilterType.BAND
            );
          }
          return images;
        }
      }

      return images;
    },
    [
      api,
      assetTypeFilter,
      filters,
      imageBandFilter,
      projectId,
      queryFilter,
      sendInfo,
      urlParams,
    ]
  );

  const setSearchResults = useCallback((items: StacItem[]) => {
    const itemBush = new ItemBush(9);
    itemBush.load(items);
    setIndexedScenarioItems(itemBush);
  }, []);

  const [indexedScenarioItems, setIndexedScenarioItems] = useState<ItemBush>(
    new ItemBush(9)
  );

  const getSearchResults = useCallback(
    () => indexedScenarioItems.all(),
    [indexedScenarioItems]
  );

  const loadNextPage = useCallback(async () => {
    if (!stacSearchPageCursor) return;
    const images = await fetchImages(stacSearchPageCursor);
    if (images) setSearchResults([...getSearchResults(), ...images.features]);
  }, [fetchImages, getSearchResults, stacSearchPageCursor, setSearchResults]);

  const clearSearchResults = useCallback(() => {
    setIndexedScenarioItems(new ItemBush(9));
  }, []);

  useEffect(() => {
    // Clear results when filters are changed
    clearSearchResults();
  }, [filters, clearSearchResults]);

  return {
    fetchImages,
    fetchedResultsContext,
    isFetching,
    setIsFetching,
    fetchingFailed,
    queryFilter,
    loadNextPage,
    hasMoreResults: Boolean(stacSearchPageCursor),
    clearSearchResults,
    getSearchResults,
    setStacSearchPageCursor,
    indexedScenarioItems,
    setIndexedScenarioItems,
    setSearchResults,
    projectId,
  };
};
