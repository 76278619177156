import React, { useState } from 'react';
import { Formik } from 'formik';
import Input from '_molecules/Input/Input';
import { postScenario, type Scenario } from 'api/scenarios/service';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useMission } from 'services/Missions';
import { Button, Dialog, ListBoxItem, Select } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';

type AddScenarioModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserScenarios: (scenario: Scenario) => void;
  setSelectedScenario: (scenarioId: string, redirect?: boolean) => void;
  redirectAfterCreation: boolean;
  user?: IUserWithPermissions;
  setIsProjectListOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddScenarioModal = ({
  isOpen,
  setIsOpen,
  setUserScenarios,
  setSelectedScenario,
  user,
  redirectAfterCreation,
  setIsProjectListOpen,
}: AddScenarioModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { translate } = useLocalisation();

  const { organisations } = useMission();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      buttons={[]}
      hideCancelButton
      title={translate('datacosmos.projectsDialog.createNewProject')}
    >
      <Formik
        initialValues={{
          name: '',
          description: '',
          organisation: '',
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          if (!user) return;

          if (values.name === '') {
            setErrors({
              name: translate('datacosmos.addNewProjectDialog.errors.name'),
            });
            return;
          }

          if (values.description === '') {
            setErrors({
              description: translate(
                'datacosmos.addNewProjectDialog.errors.description'
              ),
            });
            return;
          }

          if (values.organisation === '') {
            setErrors({
              organisation: translate(
                'datacosmos.addNewProjectDialog.errors.organisation'
              ),
            });
            return;
          }

          const selectedOrganisation = organisations?.find(
            (org) => org.name === values.organisation
          );
          if (!selectedOrganisation) {
            return;
          }
          setIsLoading(true);

          const { data: newScenario } = await postScenario({
            body: {
              name: values.name,
              description: values.description,
              organisation_id: selectedOrganisation?.id,
            },
          });

          if (newScenario) {
            setIsOpen(false);
            setIsProjectListOpen(false);
            resetForm();
            setUserScenarios(newScenario);
            setSelectedScenario(newScenario.id, redirectAfterCreation);
          }

          setIsLoading(false);
        }}
      >
        {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
          <div className="flex flex-col gap-4">
            <div>
              <Input
                type="text"
                name="name"
                value={values.name}
                label={{
                  text: translate('datacosmos.addNewProjectDialog.name'),
                  position: 'top',
                }}
                onChange={handleChange}
              />
              <small style={{ color: 'red' }}>{errors.name?.toString()}</small>
            </div>

            <div>
              <Select
                selectedKey={values.organisation}
                onSelectionChange={(val) => {
                  void setFieldValue('organisation', val);
                }}
                placeholder="Select"
                label="Organisation"
                fill
                name="organisation"
              >
                {organisations?.map((org) => (
                  <ListBoxItem id={org.name} key={org.id}>
                    {org.name}
                  </ListBoxItem>
                ))}
              </Select>
              <small style={{ color: 'red' }}>
                {errors.organisation?.toString()}
              </small>
            </div>
            <div>
              <Input
                type="textarea"
                name="description"
                value={values.description}
                label={{
                  text: translate('datacosmos.addNewProjectDialog.description'),
                  position: 'top',
                }}
                onChange={handleChange}
              />
              <small style={{ color: 'red' }}>
                {errors.description?.toString()}
              </small>
            </div>
            <div className="flex justify-end items-center gap-4 mt-4">
              <Button
                onPress={() => {
                  setIsOpen(false);
                }}
                size="lg"
                isMinimal
              >
                {translate('datacosmos.buttons.cancel')}{' '}
              </Button>
              <Button onPress={() => handleSubmit()} size="lg" isMinimal>
                {isLoading ? (
                  <Spinner size={20} />
                ) : (
                  translate('datacosmos.buttons.save')
                )}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Dialog>
  );
};
