import { useState, useMemo } from 'react';
import { useParams } from 'react-router';
import WarnAlertButton from 'components/buttons/WarnAlertButton';

import CommandInput from './CommandInput';

import s from './index.module.scss';
import AlertConfirm from 'components/common/AlertConfirm';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { useCommandCopyPaste } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandCopyPaste';
import type { CommandDefinition } from 'api/telecommands/types';
import { Button, Tooltip } from 'opencosmos-ui';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
interface IProps {
  className?: string;
  availableCommands: CommandDefinition[];
  handleSendUnarmedCommand: () => void;
  handleSendToPileCommand: () => void;
  selectedCommand?: CommandDefinition;
  isSendCommandDisabled: boolean;
  isTablet: boolean;
  handleSelectCommandEditorItem: (cmd: CommandDefinition) => void;
  isSessionOccupied: boolean;
  userIsActiveInCurrentMission: boolean;
  fetchActiveSessions: (abort?: { state: boolean }) => Promise<void>;
}

export const CommandEditor = (props: IProps) => {
  const { mission } = useParams<{ mission: string }>();

  const { sendInfo } = useAnalytics();

  const [isSessionWarningOpened, setIsSessionWarningOpened] =
    useState<boolean>(false);

  const [warningFor, setWarningFor] = useState<'direct' | 'pile'>();

  const { handleCopySelectedCommands, handlePasteCmd } = useCommandCopyPaste();

  const {
    state: { commandPileWorkingSpace },
  } = useOperate();

  const { hasPermission: isAllowedToOpenCommand } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: props.selectedCommand?.scope,
      id: mission,
    },
  });

  const buttonDisabled = useMemo(
    () =>
      !props.selectedCommand ||
      props.isSendCommandDisabled ||
      !isAllowedToOpenCommand,
    [isAllowedToOpenCommand, props.isSendCommandDisabled, props.selectedCommand]
  );

  const {
    state: { selectedMultiplePileItems, selectedPileItem },
  } = useOperate();

  return (
    <div
      className={[
        props.className,
        s.main,
        'bg-surface dark:bg-surface-dark dark:text-item-dark-contrast',
      ].join(' ')}
    >
      <div className="flex items-center w-full">
        <Tooltip
          content="Danger: Send directly to the satellite!"
          isDisabled={buttonDisabled}
        >
          <WarnAlertButton
            icon={'Satellite'}
            text={props.isTablet ? undefined : 'Direct send'}
            alertMessage="Attempted to send command to the satellite twice."
            disabled={buttonDisabled}
            className="mr-1"
            onClick={async () => {
              sendInfo({
                type: 'Direct send command from command editor',
                action: 'Click',
                item: 'Direct send command button',
                module: 'OPS',
              });
              await props.fetchActiveSessions();
              if (
                props.isSessionOccupied &&
                !props.userIsActiveInCurrentMission
              ) {
                setIsSessionWarningOpened(true);
                setWarningFor('direct');
              } else {
                props.handleSendUnarmedCommand();
              }
            }}
          />
        </Tooltip>
        <CommandInput
          className={[s.commandInput, s.commandInputTableWidth].join(' ')}
          availableCommands={props.availableCommands}
          handleSelectCommand={props.handleSelectCommandEditorItem}
          selectedCommand={props.selectedCommand}
        />
        <Tooltip
          content={
            selectedPileItem || selectedMultiplePileItems
              ? 'Copy command from pile'
              : 'Copy command'
          }
          isDisabled={buttonDisabled}
        >
          <Button
            isMinimal={true}
            icon={'duplicate'}
            onPress={() => {
              sendInfo({
                type: 'Copy command from command editor',
                action: 'Click',
                item: 'Copy command button',
                module: 'OPS',
              });
              handleCopySelectedCommands();
            }}
            className="mx-1"
          />
        </Tooltip>
        <Tooltip
          content={
            selectedPileItem || selectedMultiplePileItems
              ? 'Paste command from pile'
              : 'Paste command'
          }
          isDisabled={buttonDisabled}
        >
          <Button
            isMinimal={true}
            icon={'clipboard'}
            onPress={() => {
              sendInfo({
                type: 'Paste command from command editor',
                action: 'Click',
                item: 'Paste command button',
                module: 'OPS',
              });
              void handlePasteCmd(
                commandPileWorkingSpace?.findIndex(
                  (cmd) => cmd.id === selectedPileItem?.id
                ) ?? 0
              );
            }}
            className="mr-1"
          />
        </Tooltip>
        <Tooltip content="Add to command pile" isDisabled={buttonDisabled}>
          <Button
            intent="primary"
            data-testid="add-command-to-pile"
            icon={'add-to-artifact'}
            onPress={async () => {
              sendInfo({
                type: 'Add command to pile from command editor',
                action: 'Click',
                item: 'Add command to pile button',
                module: 'OPS',
              });
              await props.fetchActiveSessions();
              if (
                props.isSessionOccupied &&
                !props.userIsActiveInCurrentMission
              ) {
                setIsSessionWarningOpened(true);
                setWarningFor('pile');
              } else {
                props.handleSendToPileCommand();
              }
            }}
            isDisabled={buttonDisabled}
          >
            <span className="whitespace-nowrap text-sm">
              {props.isTablet ? undefined : 'Add to pile'}
            </span>
          </Button>
        </Tooltip>
      </div>

      <AlertConfirm
        isOpen={isSessionWarningOpened && !props.userIsActiveInCurrentMission}
        setIsOpen={setIsSessionWarningOpened}
        onConfirm={() => {
          if (warningFor === 'direct') {
            props.handleSendUnarmedCommand();
          }

          if (warningFor === 'pile') {
            props.handleSendToPileCommand();
          }
        }}
        message="The session is currently in use. Are you sure you want to send the command?"
      />
    </div>
  );
};

export default CommandEditor;
