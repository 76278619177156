import React from 'react';
import type { CSSProperties, ReactElement } from 'react';
import type {
  ICurve,
  ICurvePoints,
} from 'constants/missionDesignPage/interfaces';
import type { ISatelliteBall } from 'constants/satelliteBalls/actionTypes';
import { getQuadraticBezierCurves } from 'utils/missionDesignPage/getQuadraticBezierCurves';
import type { IGroup } from 'constants/groupSatelliteBalls/actionTypes';
import { computeSeparatedCurve } from 'utils/missionDesignPage/computeSeparatedCurve';
import { BOTTOM_CORVE_OFFSET, LOGO } from 'env';
import SatelliteBall from '../SatelliteBall';

interface IMissionDesignBottomProps {
  groups: IGroup[];
  chosenRoute: string;
  handleRouteChange: Function;
}

interface IMissionDesignBottomState {
  curves: ICurve[];
  groups: IGroup[];
  needUpdate: boolean;
}

const CURVE_OFFSET = Number(BOTTOM_CORVE_OFFSET);
const MAIN_YELLOW_COLOR = '#FFCC55';
const MAIN_WHITE_COLOR = '#FFFFFF';

class MissionDesignBottom extends React.Component<
  IMissionDesignBottomProps,
  IMissionDesignBottomState
> {
  public state: IMissionDesignBottomState = {
    curves: [],
    needUpdate: false,
    groups: [],
  };

  private recalculate = (
    isGroups: boolean = false,
    isChosenCurve: boolean = false,
    prevCurves: ICurve[] = []
  ) => {
    const { curves } = this.state;
    let needUpdate = isGroups || isChosenCurve;
    const newCurves = curves.map((c, index) => {
      if (prevCurves && prevCurves.length) {
        if (c.ref === prevCurves[index].ref && !isChosenCurve) {
          return c;
        }
      }
      needUpdate = true;
      return this.recalculateCurve(c, index, curves.length);
    });
    if (needUpdate) {
      const { chosenRoute } = this.props;
      const newGroups = this.props.groups.map((g, index): IGroup => {
        return {
          ...g,
          satelliteBalls: this.recalculateBalls(
            newCurves[index],
            g.satelliteBalls,
            chosenRoute === g.route
          ),
        };
      });
      this.setState({ curves: newCurves, groups: newGroups });
    }
  };

  private onRecalculate = () => {
    this.recalculate();
  };

  public componentDidMount(): void {
    const { groups, chosenRoute } = this.props;
    const curves = groups.map((group): ICurve => {
      return {
        ref: React.createRef<SVGSVGElement>(),
        padding: 0,
        path: '',
        strokeColor:
          chosenRoute === group.route ? MAIN_YELLOW_COLOR : MAIN_WHITE_COLOR,
        points: undefined,
        selected: chosenRoute === group.route,
      };
    });
    const stateGroups = groups.map((g) => g);

    window.addEventListener('resize', this.onRecalculate);

    this.setState({ curves, needUpdate: true, groups: stateGroups });
  }

  public componentDidUpdate(
    prevProps: Readonly<IMissionDesignBottomProps>,
    prevState: Readonly<IMissionDesignBottomState>
  ): void {
    const isCurves = this.state.curves !== prevState.curves;
    const isGroups = this.props.groups !== prevProps.groups;
    const isChosenCurve = this.props.chosenRoute !== prevProps.chosenRoute;
    if (isCurves || isGroups || isChosenCurve) {
      this.recalculate(isGroups, isChosenCurve, prevState.curves);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.onRecalculate);
  }

  private recalculateBalls = (
    curve: ICurve,
    satelliteBalls: ISatelliteBall[],
    currentCurve: boolean
  ): ISatelliteBall[] => {
    if (curve.points === undefined) {
      return satelliteBalls;
    }
    const { first, control, last } = curve.points;
    const divideOn = satelliteBalls.length + 1;
    return satelliteBalls.map((s, index): ISatelliteBall => {
      const t = (1 / divideOn) * (index + 1);
      const { x: left, y: top } = getQuadraticBezierCurves(
        first,
        control,
        last,
        t
      );
      const size = s.size;
      if (currentCurve) {
        size.width = 30;
        size.height = 30;
      }
      return {
        ...s,
        position: {
          left: left - s.size.width / 2,
          top: top - s.size.height / 2,
        },
      };
    });
  };

  private recalculateCurve = (
    curve: ICurve,
    position: number,
    curveNum: number
  ): ICurve => {
    if (curve.ref.current === null) {
      return curve;
    }

    const { groups, chosenRoute } = this.props;
    const { clientHeight: height, clientWidth: width } = curve.ref.current;
    const padding = curve.padding;

    let points: ICurvePoints = {
      first: {
        x: padding,
        y: height - padding,
      },
      control: {
        x: width / 2,
        y: height - padding - width * (curveNum / 2),
      },
      last: {
        x: width - padding,
        y: height - padding,
      },
    };
    const newPoints = computeSeparatedCurve(points, position, curveNum);
    const newControlX =
      ((points.first.x + points.last.x) * newPoints.control.x) /
      (newPoints.first.x + newPoints.last.x);
    const chosenCurveIndex = groups.findIndex((it) => it.route === chosenRoute);
    const shifter = position === chosenCurveIndex ? -CURVE_OFFSET : 0;
    points = {
      first: { y: newPoints.first.y + shifter, x: points.first.x },
      control: { y: newPoints.control.y + shifter, x: newControlX },
      last: { y: newPoints.last.y + shifter, x: points.last.x },
    };
    const strokeColor =
      chosenCurveIndex === position ? MAIN_YELLOW_COLOR : MAIN_WHITE_COLOR;
    const selected = chosenCurveIndex === position;

    const path = `M${points.first.x} ${points.first.y} Q ${points.control.x} ${points.control.y} ${points.last.x} ${points.last.y}`;
    return {
      ...curve,
      points,
      path,
      strokeColor,
      selected,
    };
  };

  public render(): React.ReactNode {
    const { chosenRoute, handleRouteChange } = this.props;
    const { curves, groups } = this.state;
    const increaseSize = 0;
    const decreaseSize = 0 / groups.length;
    return (
      <div className="mission-page-bottom">
        <div className="container">
          {groups.map((g, index) => {
            const style: CSSProperties = {
              width: `${
                100 / groups.length +
                (chosenRoute === g.route ? increaseSize : -decreaseSize)
              }%`,
              height: '100%',
            };
            const curve = curves[index];
            if (!curve) {
              return null;
            }

            const wrapperChangeChosenCurve = () => {
              handleRouteChange(g.redirectPath || g.route);
            };
            const unShifter =
              g.route === chosenRoute ? Number(CURVE_OFFSET) : 0;
            return (
              <div
                key={index + JSON.stringify(style)}
                className="svg-container"
                style={style}
              >
                {g.satelliteBalls.map(
                  (s): ReactElement => (
                    <SatelliteBall
                      key={`satellite_ball${s.text}`}
                      {...s}
                      handleChangeState={() => {}}
                      ballsState={g.satelliteBalls}
                    />
                  )
                )}
                <svg
                  ref={curve.ref}
                  width={'100%'}
                  height={'100%'}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id={`textpath_${g.label}`}
                    d={curve.path}
                    stroke={MAIN_YELLOW_COLOR}
                    strokeWidth={3}
                    fill="transparent"
                  />
                  {!LOGO && (
                    <text
                      fontSize={'24px'}
                      dx={
                        curve.ref.current
                          ? curve.ref.current.clientWidth / 2
                          : 0
                      }
                      dy={35 + unShifter}
                      lengthAdjust={'spacingAndGlyphs'}
                      stroke={curve.strokeColor}
                      fill={curve.strokeColor}
                      onClick={wrapperChangeChosenCurve}
                      cursor={'pointer'}
                      className={curve.selected ? 'bold' : undefined}
                      textAnchor={'middle'}
                    >
                      <textPath href={`#textpath_${g.label}`}>
                        {g.label}
                      </textPath>
                    </text>
                  )}
                </svg>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MissionDesignBottom;
