import { useState } from 'react';
import { useHistory } from 'react-router';
import type { Organisation } from 'api/administration/service';
import CreateButton from 'components/OrganisationConfiguration/CreateButton';
import OrganisationEditableText from 'components/OrganisationConfiguration/OrganisationEditableText';
import s from 'components/OrganisationConfiguration/index.module.scss';
import classNames from 'classnames';
import { Button } from 'opencosmos-ui';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';

interface IProps {
  chosenOrg: number | undefined;
  setChosenOrg: (value: number) => void;
  organisations: Organisation[];
  saveOrganisation: (value: string) => void;
}

const OrganisationList = (props: IProps) => {
  const history = useHistory();
  const [showNewOrganisation, setShowNewOrganisation] = useState(false);

  const handleEditClick = (organisationId: number) => {
    history.push(`/portal/organisation/${organisationId}`);
  };

  const { hasPermission: isAllowedToCreateOrganisation } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'organisation:create',
    },
  });

  const { hasPermission: isAllowedToUpdateOrganisation } = useCheckPermissions({
    permissions: props.organisations.map((org) => ({
      id: org.id,
      type: 'organisation',
      actionScope: 'organisation:update',
    })) as ICheckPermissions[],
  });

  return (
    <div
      className={classNames(
        s.organizationContainer,
        'dark:bg-surface-dark dark:text-item-dark-contrast'
      )}
    >
      <ul className={s.organizationListContainer}>
        <OrganisationEditableText
          text="New Organisation"
          showEditableText={showNewOrganisation}
          hideEditableText={() => setShowNewOrganisation(false)}
          handleSaveConfirm={props.saveOrganisation}
        />
        {props.organisations.map((item, i) => (
          <li
            key={item.id}
            className={
              props.chosenOrg === item.id ? s.highLightChosenItem : undefined
            }
          >
            <div
              className={classNames(
                s.organizationItem,
                'dark:text-item-dark-contrast flex items-center'
              )}
              role="button"
              tabIndex={0}
              onKeyDown={() => props.setChosenOrg(item.id)}
              onClick={() => props.setChosenOrg(item.id)}
            >
              {item.name}
              <Button
                isMinimal
                isDisabled={!isAllowedToUpdateOrganisation[i]}
                icon={'wrench'}
                onPress={() => handleEditClick(item.id)}
              />
            </div>
          </li>
        ))}
      </ul>
      <CreateButton
        disabled={!isAllowedToCreateOrganisation}
        text="Organisation"
        onClick={() => setShowNewOrganisation(true)}
      />
    </div>
  );
};

export default OrganisationList;
