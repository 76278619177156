import { useState, useEffect, useCallback } from 'react';
import { set, cloneDeep } from 'lodash';

import {
  NEW_DEFINITION,
  useAlerts,
} from '../../../../../services/api/telemetry/AlertsProvider';
import TagHeader from '../../../../../components/common/TagHeader';

import s from './index.module.scss';
import generalS from '../LibraryTelemetry/index.module.scss';
import { getGrafanaUrlForAlert } from './utilities';
import { useAuth } from '../../../../../services/auth/AuthWrapper';
import useUser from '../../../../../services/api/portal/administration/hook/useUser';
import type { AlertRule } from 'api/alerts/types';
import type { User, UserWithPermissions } from 'api/users/types';
import {
  Button,
  Dialog,
  Group,
  Select,
  SelectValue,
  TextField,
  Tooltip,
} from 'opencosmos-ui';

const CreateAlert = () => {
  const {
    currentAlert,
    requestAlertRuleChange,
    createNewAlertRule,
    errorCreatingAlert,
    alertGroupDoesntExist,
  } = useAlerts();

  const { user } = useAuth();
  const { getUsersById } = useUser();

  const [localAlert, setLocalAlert] = useState<AlertRule | undefined>(
    NEW_DEFINITION
  );
  const [expression, setExpression] = useState(currentAlert?.expr);
  const [reviewing, setReviewing] = useState(false);
  const [reviewUser, setReviewUser] = useState<UserWithPermissions | User>();
  const [dirty, setDirty] = useState(false);

  const dirtyReviewUser = (newAlertRule: AlertRule) => {
    if (
      dirty &&
      (localAlert?.annotations.reviewed === 'accepted' ||
        localAlert?.annotations.reviewed === 'rejected')
    ) {
      newAlertRule.annotations.reviewed = 'changed since review';
    }
  };

  const handleValueChange = (field: string, value: string | string[]) => {
    const newAlertRule = cloneDeep(localAlert);

    if (!newAlertRule) {
      return;
    }

    set(newAlertRule, field, value);
    setLocalAlert(newAlertRule);
    setDirty(true);
  };

  const renderField = (text: string, name: string, value: string) => {
    return (
      <div className={s.createField}>
        <TextField
          label={text}
          value={value}
          onChange={(val) => handleValueChange(name, val)}
          isDisabled={name === 'id' || alertGroupDoesntExist}
        />
      </div>
    );
  };

  const submitHandler = () => {
    const newAlert = cloneDeep(localAlert);

    if (!newAlert) {
      return;
    }

    newAlert.expr = expression ?? '';
    newAlert.id = newAlert.editing
      ? newAlert.id
      : (Math.random() * 10000000).toFixed(0);
    dirtyReviewUser(newAlert);
    setLocalAlert(newAlert);
    void requestAlertRuleChange(newAlert);
    setDirty(false);
  };

  const reviewHandler = () => {
    setReviewing(true);
  };

  const renderSelectField = (
    text: string,
    name: string,
    value: string,
    values: string[]
  ) => (
    <div className={s.createField}>
      <span>{text}</span>
      <div style={{ display: 'block' }}>
        <Select
          fill={true}
          isDisabled={alertGroupDoesntExist}
          onSelectionChange={(key) => {
            handleValueChange(name, key.toString());
          }}
        >
          {values.map((nameValue) => (
            <SelectValue key={nameValue} id={nameValue}>
              {nameValue}
            </SelectValue>
          ))}
        </Select>
      </div>
    </div>
  );

  const encoded = getGrafanaUrlForAlert(expression);

  let reviewText = 'Not reviewed';
  if (localAlert?.annotations.reviewed === 'rejected') {
    reviewText = 'Rejected by';
  } else if (localAlert?.annotations.reviewed === 'accepted') {
    reviewText = 'Accepted by';
  } else if (localAlert?.annotations.reviewed === 'changed since review') {
    reviewText = 'Changed since last reviewed by';
  }

  const reviewCancelHandler = () => {
    setReviewing(false);
  };

  const reviewRejectHandler = () => {
    setReviewing(false);

    const newAlertRule = cloneDeep(localAlert);

    if (!newAlertRule) {
      return;
    }

    newAlertRule.annotations.reviewed = 'rejected';
    newAlertRule.annotations.last_reviewer = user?.sub ?? '';
    setLocalAlert(newAlertRule);

    setReviewUser(user);

    void requestAlertRuleChange(newAlertRule);
  };

  const reviewAcceptHandler = () => {
    setReviewing(false);

    const newAlertRule = cloneDeep(localAlert);
    if (!newAlertRule) {
      return;
    }

    newAlertRule.annotations.reviewed = 'accepted';
    newAlertRule.annotations.last_reviewer = user?.sub ?? '';
    setLocalAlert(newAlertRule);
    setReviewUser(user);

    void requestAlertRuleChange(newAlertRule);
  };

  const updateReviewUser = useCallback(async () => {
    if (currentAlert?.annotations.last_reviewer) {
      const lastReviewer = await getUsersById(
        currentAlert.annotations.last_reviewer
      );
      setReviewUser(lastReviewer);
    } else {
      setReviewUser(undefined);
    }
  }, [currentAlert?.annotations.last_reviewer, getUsersById]);

  const disableSave = alertGroupDoesntExist || (localAlert?.editing && !dirty);

  useEffect(() => {
    setLocalAlert(cloneDeep(currentAlert));
    void updateReviewUser();
    setDirty(false);
  }, [currentAlert, updateReviewUser]);

  return (
    <div>
      <Dialog
        buttons={[]}
        hideCancelButton={true}
        title="Review Alert Rule"
        isOpen={reviewing}
        onClose={reviewCancelHandler}
      >
        <div className={s.dialogueContainer}>
          <p className={s.textContainer}>
            Do you want to mark this alert rule as reviewed? You will not be
            able to undo this.
          </p>
          <div className={s.dialogueButtonContainer}>
            <Button onPress={reviewCancelHandler}>
              <span className="text-sm">Cancel</span>
            </Button>
            <div className={s.dialogueChoiceButtons}>
              <Group className={'flex items-center'}>
                <Button intent={'warning'} onPress={reviewRejectHandler}>
                  Reject
                </Button>
                <Button intent={'secondary'} onPress={reviewAcceptHandler}>
                  Accept
                </Button>
              </Group>
            </div>
          </div>
        </div>
      </Dialog>
      <div className={generalS.variablesTitle}>
        <TagHeader
          title={
            <div className={generalS.libraryHeader}>
              <div className={generalS.filterContainer}>
                <span>
                  {localAlert?.editing
                    ? 'Editing Alert Rule'
                    : 'Creating Alert Rule'}
                </span>
              </div>
            </div>
          }
        />
      </div>
      <div className={s.createContainer}>
        <div className={`${s.createRow}`}>
          <Group
            className={`${s.expressionRow} invalid-label w-full flex flex-col`}
          >
            <label htmlFor="promql-expression">PromQL Expression</label>
            <div className="flex w-full items-center">
              <Tooltip
                content="View Grafana graphs for this expression"
                delay={0}
              >
                <Button
                  icon={'timeline-line-chart'}
                  onPress={() => window.open(encoded)}
                  className="h-8"
                />
              </Tooltip>
              <TextField
                fill={true}
                name="promql-expression"
                value={expression ?? localAlert?.expr}
                onChange={(val) => setExpression(val)}
                isDisabled={alertGroupDoesntExist}
              />
              {localAlert?.editing && (
                <Tooltip content="Cancel editing" delay={0}>
                  <Button icon={'Cross'} onPress={createNewAlertRule} />
                </Tooltip>
              )}
              <Tooltip
                content={localAlert?.editing ? 'Save' : 'Create'}
                delay={0}
              >
                <Button
                  icon={'Save'}
                  intent={'secondary'}
                  onPress={submitHandler}
                  isDisabled={disableSave}
                  className="h-8"
                >
                  <span className="text-sm">
                    {localAlert?.editing ? 'Save' : 'Create'}
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Group>
        </div>
        <div className={s.createRow}>
          {renderField('ID', 'id', localAlert?.id ?? '')}
          {renderField('Name', 'alert', localAlert?.alert ?? '')}
          {renderField('Duration', 'for', localAlert?.for ?? '')}
        </div>
        <div className={s.createRow}>
          {renderSelectField(
            'Severity',
            'labels.severity',
            localAlert?.labels.severity ?? '',
            ['Warning', 'Major', 'Critical']
          )}
          {renderSelectField(
            'Subsystem',
            'labels.system',
            localAlert?.labels.system ?? '',
            ['ops', 'comms', 'eps', 'obdh']
          )}
          {renderSelectField(
            'Team',
            'labels.team',
            localAlert?.labels.team ?? '',
            ['flight-team', 'ait', 'ground-segment-ops']
          )}
        </div>
        <div className={s.createRow}>
          {renderField(
            'Description',
            'annotations.description',
            localAlert?.annotations.description ?? ''
          )}
          {renderField(
            'Summary',
            'annotations.summary',
            localAlert?.annotations.summary ?? ''
          )}
        </div>
        {localAlert?.editing && (
          <div className={s.reviewContainer}>
            <div className={s.reviewUserContainer}>
              {reviewUser ? (
                <Tooltip
                  delay={0}
                  placement={'left'}
                  content={reviewUser.email}
                >
                  <div className={s.userInfo}>
                    <span className={s.userName}>{reviewText}</span>
                    <img
                      className={s.userIcon}
                      src={reviewUser.picture}
                      alt="avatar"
                    />
                    <span className={s.userName}>{user?.name}</span>
                  </div>
                </Tooltip>
              ) : (
                <span>Not reviewed</span>
              )}
            </div>

            <Tooltip content="Review this alert rule">
              <Button
                icon={'confirm'}
                intent={'primary'}
                onPress={reviewHandler}
                isDisabled={dirty}
              >
                Review
              </Button>
            </Tooltip>
          </div>
        )}
        <div>{errorCreatingAlert && <div>{errorCreatingAlert}</div>}</div>
      </div>
    </div>
  );
};

export default CreateAlert;
