import Modal from '_atoms/Modal/Modal';
import Button from '_molecules/Button/Button';
import React, { useMemo } from 'react';
import { OverlayContainer } from 'react-aria';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  AreaChart,
  Area,
  ReferenceLine,
  Legend,
} from 'recharts';
import { Tabs, TabList, Tab, TabPanel } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import DangerButton from '_molecules/Button/DangerButton';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { DetailsItem } from 'pages/ops/shared/components/DetailsItem';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import type {
  ICOGMetadataForRange,
  IHistogramData,
} from 'datacosmos/services/tilingApi/cogMetadata';

type Props = {
  /**
   * Whether or not the metadata is being fetched
   */
  isFetchingMetadata: boolean;
  /**
   * Whether or not the modal is open
   */
  isOpen: boolean;
  /**
   * Function to set the modal open state
   * @param isOpen state to set
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * Histogram data to display
   */
  histogramData?: IHistogramData[];
  /**
   * Title of the modal
   */
  modalTitle: string;
  /**
   * Properties data to display. This is the data that is displayed below the histogram
   */
  data: ICOGMetadataForRange;
  /**
   * Function to get the color of the histogram based on the color in the histogram data.
   * Data will usually be labeled with a certain color, such as `redX`, `redY`, `greenX`, `greenY`, etc.
   * This function should return the color based on the label.
   * @param data label of the data e.g. `red`, `green`, `blue`, etc.
   * @returns color of the histogram
   */
  getHistogramColor: (
    data: string
  ) => '#FF695E' | '#5ABE96' | '#5a89be' | '#E79531';
  /**
   * Handler function to get the statistics for the AOI. This function should draw the AOI on the map and then call the `fetchAssetsMetadata` function.
   */
  getStatisticsForAoiHandler: () => Promise<void>;

  /**
   * Handler function to remove the statistics AOI from the map.
   * This function should remove the AOI from the map and then call the `fetchAssetsMetadata` function.
   */
  removeStatisticsAoiHandler: () => void;
  /**
   * Whether or not the statistics AOI is present on the map
   */
  isStatisticsAoiPresent: boolean;

  /**
   * Element to display next to the title.
   * Can be used to render an action button, such as a screenshot action button.
   */
  titleElement?: React.ReactNode;

  /**
   * Optional ref to set. Useful for screenshots
   */
  popupRef?: React.RefObject<HTMLDivElement>;
  /**
   * Optional function to call when the modal is closed
   */
  onClose?: () => void;
};

/**
 * Popup to display the statistics of the assets. This popup contains a histogram and some summary statistics.
 * It also contains a button to draw an AOI on the map to get the statistics for that AOI.
 */
const StatisticsPopup = ({
  histogramData,
  modalTitle,
  data,
  getHistogramColor,
  getStatisticsForAoiHandler,
  isStatisticsAoiPresent,
  isOpen,
  setIsOpen,
  removeStatisticsAoiHandler,
  isFetchingMetadata,
  titleElement,
  popupRef,
  onClose,
}: Props) => {
  const { translate, translateWithExplicitFallback } = useLocalisation();

  const { isDarkmode } = useTheme();

  const isLoading = useMemo(() => isFetchingMetadata, [isFetchingMetadata]);

  const getTabLabels = () => {
    if (histogramData?.length && histogramData.length <= 0) {
      return [];
    }

    if (histogramData?.[0] === undefined) {
      return [];
    }

    return [
      ...new Set(
        Object.keys(histogramData[0]).map((k) => k.replace(/X|Y/, ''))
      ),
    ];
  };

  const withNoStatistics = (children: React.ReactNode) => {
    if (!histogramData) {
      return (
        <NonIdealState
          title={
            <span>
              {translate(
                'datacosmos.catalogAndItems.metadata.noStatistics.title'
              )}
            </span>
          }
          description={translate(
            'datacosmos.catalogAndItems.metadata.noStatistics.description'
          )}
          icon="info-sign"
          className="text-surface-contrast dark:text-surface-dark-contrast"
        />
      );
    }

    return children;
  };

  const withLoadingSpinner = (children: React.ReactNode) => {
    if (isLoading) {
      return <Spinner size={36} />;
    }

    return children;
  };

  const getBandSpecificHistogram = (bandNumber: number, bandName: string) => {
    return (
      <>
        <div className="flex justify-center">
          <AreaChart
            data={histogramData ?? []}
            height={330}
            width={450}
            barSize={'25px'}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={`${bandName}X`}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
              tickFormatter={(d: string) => Number(d).toFixed(2)}
              domain={[0, 'maxData']}
              type="number"
              allowDataOverflow={true}
              tickSize={3}
              tick={{ fontSize: 13 }}
              label={{
                value: translate(
                  'datacosmos.catalogAndItems.metadata.xAxisLabel'
                ),
                position: 'insideBottom',
                dy: 6,
                className: 'dark:fill-surface-dark-contrast',
              }}
            />
            <YAxis
              dataKey={`${bandName}Y`}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
              tickSize={1}
              tick={{ fontSize: 13 }}
              label={{
                value: translate(
                  'datacosmos.catalogAndItems.metadata.yAxisLabel'
                ),
                position: 'insideLeft',
                angle: -90,
                dy: 20,
                dx: -5,
                className: 'dark:fill-surface-dark-contrast',
              }}
            />
            <Area
              dataKey={`${bandName}Y`}
              stroke={getHistogramColor(bandName)}
              fill={getHistogramColor(bandName)}
              legendType="none"
            />
            <>
              <ReferenceLine
                x={data.getBandStatistics(bandNumber).mean}
                stroke={isDarkmode ? '#ffffff' : '#17202A'}
              />

              <Area
                name={`${translate(
                  'datacosmos.catalogAndItems.metadata.meanValue'
                )} ${data.getBandStatistics(bandNumber).mean}`}
                dataKey="null"
                stroke={isDarkmode ? '#ffffff' : '#17202A'}
                legendType="plainline"
              />
            </>
            <>
              <ReferenceLine
                x={Number(data.getBandStatistics(bandNumber).percentile_25)}
                stroke={'#8E44AD'}
              ></ReferenceLine>

              <Area
                name={`${translate(
                  'datacosmos.catalogAndItems.metadata.percentile25'
                )} ${data.getBandStatistics(bandNumber).percentile_25}`}
                dataKey="null"
                stroke="#8E44AD"
                legendType="plainline"
              />
            </>
            <>
              <ReferenceLine
                x={Number(data.getBandStatistics(bandNumber).percentile_75)}
                stroke={'#FFCA33'}
              ></ReferenceLine>

              <Area
                name={`${translate(
                  'datacosmos.catalogAndItems.metadata.percentile75'
                )} ${data.getBandStatistics(bandNumber).percentile_75}`}
                dataKey="null"
                stroke="#FFCA33"
                legendType="plainline"
              />
            </>

            <Legend
              wrapperStyle={{
                paddingTop: '14px',
                fontSize: '12px',
              }}
            />
            <ChartTooltip />
          </AreaChart>
        </div>

        <h3 className="text-data-dark border-b dark:text-data-text">
          {translate('datacosmos.catalogAndItems.metadata.summaryStatistics')}
        </h3>

        <div className="grid gap-4 !shadow-none w-max">
          <div className="col-start-1 col-end-2">
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.minPixelValue'
              )}
              value={String(data.getBandStatistics(bandNumber).min) ?? 'N/A'}
            />
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.maxPixelValue'
              )}
              value={String(data.getBandStatistics(bandNumber).max) ?? 'N/A'}
            />
          </div>

          <div className="col-start-2 col-end-3 pr-3 border-r border-primary" />

          <div className="col-start-3 col-end-4">
            <DetailsItem
              title={translate('datacosmos.catalogAndItems.metadata.meanValue')}
              value={
                data.getBandStatistics(bandNumber).mean?.toFixed(2) ?? 'N/A'
              }
            />
            <DetailsItem
              title={translate('datacosmos.catalogAndItems.metadata.stdValue')}
              value={
                data.getBandStatistics(bandNumber).std?.toFixed(2) ?? 'N/A'
              }
            />
            <DetailsItem
              title={translate('datacosmos.catalogAndItems.metadata.variance')}
              value={
                (
                  data.getBandStatistics(bandNumber).std *
                  data.getBandStatistics(bandNumber).std
                )?.toFixed(2) ?? 'N/A'
              }
            />
          </div>

          <div className="col-start-4 col-end-5 pr-3 border-r border-primary" />

          <div className="col-start-5 col-end-6">
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.percentile25'
              )}
              value={
                String(data.getBandStatistics(bandNumber).percentile_25) ??
                'N/A'
              }
            />
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.percentile75'
              )}
              value={
                String(data.getBandStatistics(bandNumber).percentile_75) ??
                'N/A'
              }
            />
          </div>
        </div>
      </>
    );
  };

  const getHistogram = () => {
    const labels = getTabLabels();

    // If there's only one label, don't show the tabs
    if (labels.length === 1) {
      return getBandSpecificHistogram(1, labels[0]);
    }

    return (
      <Tabs>
        <TabList>
          {labels.map((element, i) => (
            <>
              <Tab id={element} key={element}>
                {translateWithExplicitFallback(
                  `datacosmos.catalogAndItems.metadata.bandColors.${element}` as unknown as TemplateStringsArray,
                  element
                )}
              </Tab>
              <TabPanel id={element}>
                {getBandSpecificHistogram(i + 1, element)}
              </TabPanel>
            </>
          ))}
        </TabList>
      </Tabs>
    );
  };

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) onClose();
  };

  return (
    <OverlayContainer>
      {isOpen && (
        <Modal
          className="!h-auto"
          title={modalTitle}
          isDismissable={true}
          shouldCloseOnBlur={false}
          isOpen={true}
          onClose={handleClose}
          titleElement={titleElement}
          modalRef={popupRef}
        >
          {
            <>
              {withLoadingSpinner(withNoStatistics(getHistogram()))}

              {isStatisticsAoiPresent ? (
                <div>
                  <DangerButton
                    text={translate(
                      'datacosmos.buttons.removeAoiForStatistics'
                    )}
                    icon="Trash"
                    onPress={() => {
                      removeStatisticsAoiHandler();
                    }}
                    className="draw-aoi-btn"
                  />
                </div>
              ) : (
                <Button
                  text={translate('datacosmos.buttons.drawAoiForStatistics')}
                  icon="AddAoi"
                  onPress={async () => {
                    setIsOpen(false);
                    await getStatisticsForAoiHandler();
                    setIsOpen(true);
                  }}
                />
              )}
            </>
          }
        </Modal>
      )}
    </OverlayContainer>
  );
};

export default StatisticsPopup;
