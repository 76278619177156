import { Breadcrumbs2 } from '@blueprintjs/popover2';
import mainS from 'pages/ops/RTI/index.module.scss';
import { Column } from 'ui/ColumnLayout/Column';
import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Screen } from 'ui/Screen';
import { DetailsItem } from 'pages/ops/shared/components/DetailsItem';
import { MissionPageHeader } from 'components/missionDesignPage/header/MissionPageHeader';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Row } from 'ui/RowLayout/Row';
import { Header } from 'ui/Header';
import { usePdgsData } from './usePdgsData';
import React from 'react';
import StatusCardWithDates from '_organisms/StatusCardWithDates/StatusCardWithDates';
import { NonIdealState } from '@blueprintjs/core';
import type { PdgsWorkflow } from 'api/pdgs/types';
import { Button, DatePicker2, parseDate } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';

const PdgsScreen = () => {
  const {
    workflowsGroupedByDate,
    dateRangeFilter,
    setDateRangeFilter,
    selectedWorkflow,
    setSelectedWorkflow,
    loading,
    errors,
    handleWorkflowResubmit,
    isResubmitting,
    datacosmosItemUrl,
  } = usePdgsData();

  const withLoader = (content: React.ReactNode) => {
    if (loading) {
      return <Spinner />;
    }

    return <>{content}</>;
  };

  const withFetchFailed = (content: React.ReactNode) => {
    if (errors.length > 0) {
      return (
        <NonIdealState
          icon="error"
          description={errors.join('; ')}
          title="Could not fetch workflows"
        />
      );
    }

    return <>{content}</>;
  };

  const withNoWorkflowSelected = (content: React.ReactNode) => {
    if (!selectedWorkflow) {
      return (
        <NonIdealState
          icon="info-sign"
          description="Select a workflow to view it"
          title="No workflow selected"
        />
      );
    }

    return <>{content}</>;
  };

  const withNoOutputsAvailable = (content: React.ReactNode) => {
    if (selectedWorkflow?.phase !== 'Succeeded') {
      return (
        <NonIdealState
          icon="info-sign"
          description="Outputs will be available when the workflow has succeeded"
          title={
            <span className="dark:text-item-dark-contrast">
              No outputs available
            </span>
          }
          className="dark:text-item-dark-contrast"
        />
      );
    }

    return <>{content}</>;
  };

  const renderResubmitButton = (workflow: PdgsWorkflow) => {
    if (workflow.phase !== 'Failed' && workflow.phase !== 'Error') {
      return null;
    }

    if (isResubmitting) {
      return <Spinner size={16} />;
    }

    return (
      <Button
        isMinimal={true}
        isTransparent={true}
        icon="refresh"
        onPress={async () => {
          await handleWorkflowResubmit(workflow.name, workflow.inputs);
        }}
        data-testid="resubmit-workflow-button"
      />
    );
  };

  const renderWorkflowList = () => {
    return Object.entries(workflowsGroupedByDate ?? {}).map(
      ([date, workflows]) => (
        <React.Fragment key={date}>
          <Header size="h3">{date}</Header>
          {workflows.map((w) => (
            <StatusCardWithDates
              key={w.job_name}
              statusColorAsBackgroundColor={true}
              startDate={w.started_at}
              endDate={w.finished_at}
              fields={[w.job_name]}
              focused={selectedWorkflow?.job_name === w.job_name}
              onClick={() => {
                if (selectedWorkflow?.job_name === w.job_name) {
                  setSelectedWorkflow(undefined);
                } else {
                  setSelectedWorkflow(w);
                }
              }}
              status={w.phase}
              statusColorMap={{
                Error: '#FF695E',
                Pending: '#FFCC55',
                Running: '#E6E1DC',
                Succeeded: '#5ABE96',
                Failed: '#FF695E',
              }}
              actionElement={renderResubmitButton(w)}
            />
          ))}
        </React.Fragment>
      )
    );
  };

  const renderWorkflowDetails = () => {
    return (
      <>
        <Header size="h2">{selectedWorkflow?.job_name}</Header>
        <DetailsItem title="Template" value={selectedWorkflow?.name ?? ''} />
        <DetailsItem title="Phase" value={selectedWorkflow?.phase ?? ''} />
        <DetailsItem
          title="Failed Steps"
          value={selectedWorkflow?.failed_steps?.join(', ') ?? ''}
        />
        <DetailsItem
          title="Progress"
          value={selectedWorkflow?.progress ?? ''}
        />
        <DetailsItem
          title="Estimated duration"
          value={selectedWorkflow?.estimated_duration_s.toString() ?? ''}
        />
        <DetailsItem
          title="Started at"
          value={selectedWorkflow?.started_at ?? ''}
        />
        <DetailsItem
          title="Ended at"
          value={selectedWorkflow?.finished_at ?? ''}
        />

        {renderWorkflowInputs()}
        {withNoOutputsAvailable(renderWorkflowOutputs())}
      </>
    );
  };

  const renderWorkflowOutputs = () => {
    return (
      <>
        <Header size="h2">Outputs</Header>
        {selectedWorkflow?.outputs.map((o) => (
          <DetailsItem key={o.field} title={o.field} value={o.value} />
        ))}
        {datacosmosItemUrl && (
          <Button
            intent="primary"
            onPress={() => {
              window.open(datacosmosItemUrl, '_blank');
            }}
            fill
          >
            View item in DataCosmos
          </Button>
        )}
      </>
    );
  };

  const renderWorkflowInputs = () => {
    return (
      <>
        <Header size="h2">Inputs</Header>
        {selectedWorkflow?.inputs.map((i) => (
          <DetailsItem key={i.field} title={i.field} value={i.value} />
        ))}
      </>
    );
  };

  return (
    <Screen>
      <MissionPageHeader
        showMissionSelector
        leftHeader={
          <div className={mainS.headerContainer}>
            <Breadcrumbs2 items={[{ text: 'Payload processing' }]} />
          </div>
        }
      />
      <ColumnLayout colRatio={[3, 1.5]} cols={2} horizontalGap={4}>
        <Column style={{ padding: '20px', overflowY: 'auto' }}>
          <RowLayout rowRatio={[0.3, 4]} rows={2}>
            <Row>
              <ColumnLayout colRatio={[0.2, 3]} cols={2}>
                <Column>
                  <Header size="h2">Workflows</Header>
                </Column>

                <Column>
                  <Row justify="flex-end" style={{ alignItems: 'center' }}>
                    <DatePicker2
                      data-testid="start-time-picker"
                      value={dateRangeFilter?.start_time}
                      onChange={(start) => {
                        setDateRangeFilter((prev) => ({
                          start_time: start,
                          end_time: prev?.end_time,
                        }));
                      }}
                    />
                    <DatePicker2
                      data-testid="end-time-picker"
                      value={dateRangeFilter?.end_time}
                      onChange={(end) => {
                        setDateRangeFilter((prev) => ({
                          start_time: prev?.start_time,
                          end_time: end,
                        }));
                      }}
                    />
                  </Row>
                </Column>
              </ColumnLayout>
            </Row>
            <Row>
              <Column>
                {withLoader(withFetchFailed(renderWorkflowList()))}
              </Column>
            </Row>
          </RowLayout>
        </Column>
        <Column style={{ padding: '20px' }}>
          {withNoWorkflowSelected(renderWorkflowDetails())}
        </Column>
      </ColumnLayout>
    </Screen>
  );
};

export default PdgsScreen;
