import { useEffect, useState } from 'react';
import type { Telemetry } from 'api/telemetry/types';

import s from './index.module.scss';
import { Button, Group } from 'opencosmos-ui';
import classNames from 'classnames';

interface IProps {
  isLoading?: boolean;
  telemetryDefinition?: Telemetry;
  handleSaveButtonClick?: (id: Telemetry) => void;
  setShowNewDefinition?: (value: boolean) => void;
  highlightTelemetryDefinition?: (value: string | null) => void;
  handleDeleteClick?: (id: string | number) => void;
}

const LibraryTelemetryDefinitionsRow = (props: IProps) => {
  const [telemetry, setTelemetry] = useState<Telemetry>();
  const [isFiltering, setFiltering] = useState<boolean>(false);

  useEffect(() => {
    if (props.telemetryDefinition) {
      setTelemetry(props.telemetryDefinition);
    }
  }, [props.telemetryDefinition]);

  const renderEditableButtons = () => (
    <Group>
      <Button
        isMinimal
        icon={'Filter'}
        onPress={() => {
          setFiltering(!isFiltering);
          props.highlightTelemetryDefinition?.(telemetry?.name ?? null);
        }}
        className={classNames({
          'bg-item-selected dark:bg-item-dark-selected': isFiltering,
        })}
      />
    </Group>
  );

  const renderStaticFields = () => (
    <>
      <td className={s.tableIds}>
        <span>{telemetry?.frameId}</span>
      </td>
      <td className={s.tableNames}>
        <span>{telemetry?.frameName}</span>
      </td>
      <td className={s.tableLabels}>
        <span>{telemetry?.labels.join(', ')}</span>
      </td>
      <td>
        <span>{telemetry?.system}</span>
      </td>
    </>
  );

  if (props.isLoading) {
    return (
      <tr>
        <td className={s.tableIds}>
          <span>--</span>
        </td>
        <td className={s.tableNames}>
          <span>Dummy name</span>
        </td>
        <td className={s.tableLabels}>
          <span>Dummy label</span>
        </td>
        <td>
          <span>Dummy</span>
        </td>
        <td>
          <button />
          <button />
          <button />
        </td>
      </tr>
    );
  }

  if (!telemetry) return null;

  return (
    <tr key={props.telemetryDefinition?.frameId}>
      {renderStaticFields()}
      <td>{renderEditableButtons()}</td>
    </tr>
  );
};

export default LibraryTelemetryDefinitionsRow;
