import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Button, Checkbox, Icon, Input, Tooltip } from 'opencosmos-ui';
import classNames from 'classnames';
import { useTheme } from 'datacosmos/stores/ThemeProvider';

interface IProps {
  procedureName?: string;
  setProcedureName: (procedureName: string) => void;
  isFrozen: boolean;
  onFreezeToggle: () => void;
  isGridView: boolean;
  onPileViewToggle: () => void;
}

const CommandPileOptions = (props: IProps) => {
  const { isFrozen, onFreezeToggle, procedureName, setProcedureName } = props;
  const { isGridView, onPileViewToggle } = props;

  const { sendInfo } = useAnalytics();
  const themeProvider = useTheme();

  const frozenTooltipText = isFrozen
    ? 'Pile is frozen: commands are not removed on sending.'
    : 'Pile is not frozen: commands will be removed on sending.';

  return (
    <>
      <div style={{ width: '100%' }}>
        <Input
          type="text"
          placeholder="Procedure name"
          value={procedureName}
          onChange={(e) => {
            sendInfo({
              type: 'Procedure name change',
              action: 'Change/Type',
              item: 'Procedure name',
              module: 'OPS',
            });
            setProcedureName(e.target.value);
          }}
          className={classNames(
            s.procedureInput,
            'bg-item-dark-contrast dark:bg-item-dark'
          )}
        />
      </div>
      <div className="items-center flex">
        <Tooltip
          placement="top"
          content={isGridView ? 'Grid view' : 'Default view'}
          delay={0}
        >
          <Button
            size={'base'}
            icon={isGridView ? 'Grid' : 'List'}
            onPress={() => {
              sendInfo({
                type: 'Pile view change',
                action: 'Click',
                item: 'Pile view change button',
                module: 'OPS',
              });
              onPileViewToggle();
            }}
          />
        </Tooltip>
        <Tooltip placement="top" content={frozenTooltipText} delay={0}>
          <Button
            isActive={isFrozen}
            icon="snowflake"
            onPress={() => {
              sendInfo({
                type: `Pile freeze toggle: ${
                  isFrozen ? 'Frozen' : 'Unfrozen'
                } `,
                action: 'Click',
                item: 'Pile freeze toggle button',
                module: 'OPS',
                additionalParams: {
                  freezeStatus: isFrozen ? 'Frozen' : 'Unfrozen',
                },
              });
              onFreezeToggle();
            }}
          />
          {/* <Checkbox
            data-testid="pile-freeze-toggle"
            themeProvider={themeProvider}
            isSelected={isFrozen}
            // TODO: Bp icon; replace with own custom eventually
            onChange={() => {
              sendInfo({
                type: `Pile freeze toggle: ${
                  isFrozen ? 'Frozen' : 'Unfrozen'
                } `,
                action: 'Click',
                item: 'Pile freeze toggle button',
                module: 'OPS',
                additionalParams: {
                  freezeStatus: isFrozen ? 'Frozen' : 'Unfrozen',
                },
              });
              onFreezeToggle();
            }}
          >
            <div className="flex items-center gap-1">
              Freeze <Icon icon="snowflake" />
            </div>
          </Checkbox> */}
        </Tooltip>
      </div>
    </>
  );
};

export default CommandPileOptions;
