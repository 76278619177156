import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import s from './index.module.scss';
import { ShownCards } from 'pages/ops/Library/components/LibraryEncoding/types';
import type {
  GatewayNetworkConfig,
  GatewayRadioConfig,
  Schema,
} from 'api/gateway/types';
import { Button, Select, SelectValue } from 'opencosmos-ui';
import classNames from 'classnames';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import { IMAGE_PATHS } from 'constants/imagePaths';

interface IProps {
  graphic: string;
  title: string;
  networkProtocols: Schema[] | undefined;
  radioBands: string[];
  data: GatewayNetworkConfig[] | GatewayRadioConfig[];
  missionId: number | undefined;
  setShownCards: React.Dispatch<React.SetStateAction<ShownCards>>;
  setProtocolToEdit: React.Dispatch<React.SetStateAction<GatewayNetworkConfig>>;
  setRadioToEdit: React.Dispatch<React.SetStateAction<GatewayRadioConfig>>;
  setRadios: React.Dispatch<React.SetStateAction<GatewayRadioConfig[]>>;
  setProtocols: React.Dispatch<React.SetStateAction<GatewayNetworkConfig[]>>;
  deleteRadioSettingsByMission: (id: number, radio: string) => Promise<void>;
}

const LibraryHomeCard = ({
  networkProtocols,
  radioBands,
  graphic,
  title,
  data,
  missionId,
  setShownCards,
  setRadioToEdit,
  setProtocolToEdit,
  setProtocols,
  setRadios,
  deleteRadioSettingsByMission,
}: IProps) => {
  const [selectedProtocol, setSelectedProtocol] = useState<Schema | undefined>(
    networkProtocols?.[0]
  );
  const [selectedRadio, setSelectedRadio] = useState<string>(radioBands?.[0]);

  const { isDarkmode } = useTheme();

  const renderBottomContent = () => {
    if (networkProtocols) {
      return (
        <>
          <Select
            fill
            items={networkProtocols}
            onSelectionChange={(item) => {
              const foundProtocol = networkProtocols.find(
                (p) => p.name === item.toString()
              );

              if (foundProtocol) {
                setSelectedProtocol(foundProtocol);
              }
            }}
          >
            {(item) => <SelectValue id={item.name}>{item.name}</SelectValue>}
          </Select>
          <Button
            intent={'primary'}
            style={{ width: '150px' }}
            onPress={() =>
              setProtocols((prev) => [
                ...prev,
                {
                  mission_id: missionId,
                  protocol: selectedProtocol?.name ?? 'n/a',
                  mission_topology: {},
                  protocol_config: {},
                },
              ])
            }
            isDisabled={(data as unknown as GatewayNetworkConfig[]).some(
              (prot) =>
                selectedProtocol && prot.protocol === selectedProtocol.name
            )}
          >
            <span className="text-sm">Add</span>
          </Button>
        </>
      );
    }

    return (
      <>
        <Select
          fill
          onSelectionChange={(item) => {
            setSelectedRadio(item.toString());
          }}
        >
          {radioBands.map((radio) => (
            <SelectValue id={radio} key={radio}>
              {radio}
            </SelectValue>
          ))}
        </Select>
        <Button
          intent={'primary'}
          style={{ width: '150px' }}
          onPress={() =>
            setRadios((prev) => [
              ...prev,
              {
                mission_id: missionId,
                radio: selectedRadio,
                transformers: { uplink: [], downlink: [] },
              },
            ])
          }
          isDisabled={
            selectedRadio === '' ||
            (data as GatewayRadioConfig[]).some(
              (radio) => radio.radio === selectedRadio
            )
          }
        >
          <span className="text-sm">Add</span>
        </Button>
      </>
    );
  };

  const renderList = () => {
    if (networkProtocols) {
      return (data as GatewayNetworkConfig[]).map((d) => (
        <div className={s.homeCardListItem} key={d.uid}>
          <span className={s.itemName}>{d.protocol}</span>
          <div>
            <span className={s.itemInfo}>
              {Object.keys(d.mission_topology).length} network nodes
            </span>
            <Button
              className={s.itemButton}
              onPress={() => {
                setProtocolToEdit(d);
                setShownCards(ShownCards.NETWORK_PROTOCOLS);
              }}
            >
              <span className="text-sm">Edit</span>
            </Button>
          </div>
        </div>
      ));
    }
    return (data as GatewayRadioConfig[]).map((d) => (
      <div className={s.homeCardListItem} key={d.uid}>
        <span className={s.itemName}>{d.radio}</span>
        <div>
          <span className={s.itemInfo}>
            {Object.keys(d.transformers.uplink).length} uplink and{' '}
            {Object.keys(d.transformers.downlink).length} downlink transformers
          </span>
          <Button
            className={s.itemButton}
            onPress={() => {
              setRadioToEdit(d);
              setShownCards(ShownCards.RADIO_ENCODING);
            }}
          >
            <span className="text-sm">Edit</span>
          </Button>

          <Button
            icon={'trash'}
            intent={'warning'}
            style={{ marginLeft: '5px' }}
            onPress={async () => {
              try {
                await deleteRadioSettingsByMission(missionId, d.radio);
                setRadios((prev) => prev.filter((p) => p.uid !== d.uid));
              } catch (error) {
                //
              }
            }}
          />
        </div>
      </div>
    ));
  };
  return (
    <div
      style={{
        position: 'relative',
        width: '50%',
        minWidth: '900px',
        maxHeight: '350px',
      }}
    >
      <div
        className={classNames(
          s.libraryHomeCard,
          'bg-surface dark:bg-surface-dark'
        )}
        style={{
          marginBottom: '25px',
          zIndex: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span className={s.homeCardTitle}>{title}</span>
        <div className={s.homeCardListContainer}>{renderList()}</div>
        <div className={s.homeCardBottomContentContainer}>
          {renderBottomContent()}
        </div>
      </div>
      <img
        onError={(e: ChangeEvent<HTMLImageElement>) => {
          e.target.src = IMAGE_PATHS.ICONS + graphic + '.svg';
          e.target.style.filter = !isDarkmode ? 'invert(1)' : '';
        }}
        style={{
          filter: isDarkmode ? 'invert(1)' : '',
        }}
        src={IMAGE_PATHS.ILLUSTRATIONS + graphic + '.svg'}
        className={s.homeCardImage}
      />
    </div>
  );
};

export default LibraryHomeCard;
