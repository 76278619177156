import type { RefObject } from 'react';
import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import type { TaskingRequest } from 'api/tasking/types';
import type { Activity } from 'api/activities/service';
import { patchActivity } from 'api/activities/service';
import bbox from '@turf/bbox';
import union from '@turf/union';
import config from 'datacosmos/config';
import baseLayers from 'datacosmos/utils/map-layers';
import type { LatLngBoundsExpression } from 'leaflet';
import L from 'leaflet';
import 'leaflet-draw';
import type { BBox } from 'geojson';
import { unionizeFeatures } from 'datacosmos/utils/geojson';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { LayerSourceType } from 'datacosmos/entities/layer';
import type { CommonOpportunity, SwathControlData } from 'api/tasking/helpers';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import { postSwathSearch } from 'api/tasking/service';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { getSingleFeature } from 'api/stac/service';
import { useTheme } from 'datacosmos/stores/ThemeProvider';

const isActivityArray = (
  activity: Activity | Activity[] | undefined
): activity is Activity[] => {
  return Boolean((activity as unknown as Activity[]).map);
};

export const useActivitiesMap = (
  container: React.MutableRefObject<HTMLDivElement | null>,
  activity: Activity | Activity[] | undefined,
  request: TaskingRequest | undefined,
  selectedActivity?: Activity
) => {
  const requestCenter = useRef<BBox>();

  const mapProvider = useMap();
  const layers = useMapLayers();

  const { isDarkmode } = useTheme();

  const shouldShowThumbnailButton = useMemo(
    () =>
      !isActivityArray(activity) &&
      Boolean(activity?.parameters.stac) &&
      activity?.parameters.stac?.length &&
      activity.parameters.stac.length > 0,
    [activity]
  );
  const mapRef = useRef<L.Map | undefined>(undefined);

  const [shownThumbnail, setShownThumbnail] = useState<
    L.ImageOverlay | undefined
  >();

  // Initialize completely new leaflet instance independent of datacosmos providers
  const initNewMap = useCallback(
    (c: RefObject<HTMLDivElement> | undefined) => {
      if (!c?.current) return undefined;

      const map = (window.L as typeof L).map(c.current, {
        zoomControl: false,
        minZoom: config.map.minZoom,
        maxZoom: config.map.maxZoom,
        maxBounds: (window.L as typeof L).latLngBounds([-90, -210], [90, 210]),
        attributionControl: false,
      });

      (window.L as typeof L)
        .tileLayer(
          baseLayers.find((bl) =>
            isDarkmode ? bl.id === 'dark' : bl.id === 'light'
          )?.url ?? '',
          { maxZoom: config.map.maxZoom }
        )
        .addTo(map);

      map.setView(config.map.initialView, config.map.initialZoom);

      return map;
    },
    [isDarkmode]
  );

  const addAOIToMap = useCallback(() => {
    try {
      layers.removeEverythingFromMap();
      layers.addLayer(
        new GeoJSONLayer(
          LayerSourceType.TASKING_REGIONS,
          'AOI',
          request!.region,
          {} as CommonOpportunity,
          undefined
        )
      );
    } catch (e) {
      mapRef.current &&
        (window.L as typeof L).geoJSON(request?.region).addTo(mapRef.current);
    }
  }, [layers, request]);

  const addActivityAsSwathLayerToMap = useCallback(
    (a: Activity) => {
      if (!a.parameters.physical?.latest) {
        return;
      }

      if (a.parameters.physical.target) {
        layers.addLayer(
          new SwathLayer(
            LayerSourceType.TASKING_OPPORTUNITIES,
            a.id + '-target',
            a.parameters.physical.target.geojson,
            {} as CommonOpportunity,
            {
              color: a.id === selectedActivity?.id ? ' #999999' : '',
              opacity: a.id === selectedActivity?.id ? 20 : 0,
            }
          )
        );
      }

      layers.addLayer(
        new SwathLayer(
          LayerSourceType.TASKING_OPPORTUNITIES,
          a.id,
          a.parameters.physical.latest.geojson,
          {} as CommonOpportunity,
          { color: a.id === selectedActivity?.id ? '#FFCC55' : '#00FF00' }
        )
      );
    },
    [layers, selectedActivity?.id]
  );

  const addActivityToMap = (a: Activity) => {
    (window.L as typeof L)
      .geoJSON(a.parameters.physical?.target?.geojson, {
        style: { color: '#999999', opacity: 20 },
      })
      .addTo(mapRef.current!);

    (window.L as typeof L)
      .geoJSON(a.parameters.physical?.latest?.geojson, {
        style: { color: '#FFCC55' },
      })
      .addTo(mapRef.current!);
  };

  const initMap = useCallback(() => {
    if (mapRef.current) return;

    mapRef.current =
      mapProvider?.initialiseMap(container) ?? initNewMap(container);

    if (!mapRef.current) return;

    try {
      // If there's multiple activities (in the case of DataCosmos tasking overview)
      // unionize all activity features.
      const activityGeojson = isActivityArray(activity)
        ? unionizeFeatures(
            activity.map((a) => {
              if (!a.parameters.physical?.latest) {
                // No need to unionise if there's no swath
                // Throwing, since catch block handles no swath case
                throw new Error();
              }
              return a.parameters.physical.latest.geojson;
            })
          )
        : activity!.parameters.physical?.latest?.geojson;

      if (!activityGeojson) {
        // Throwing, since catch block handles no swath case
        throw new Error();
      }

      requestCenter.current =
        request && bbox(union(activityGeojson, request.region));
    } catch (e) {
      // Fall back to region only if there's no activities
      if (!request) return;
      if (Object.keys(request).includes('detail')) return;

      requestCenter.current = bbox(request.region);
    }

    requestCenter.current &&
      mapRef.current.fitBounds([
        [requestCenter.current[1], requestCenter.current[0]],
        [requestCenter.current[3], requestCenter.current[2]],
      ]);

    addAOIToMap();

    if (isActivityArray(activity)) {
      activity.map((a) => {
        try {
          addActivityAsSwathLayerToMap(a);
        } catch (e) {
          addActivityToMap(a);
        }
      });
    } else {
      try {
        activity && addActivityAsSwathLayerToMap(activity);
      } catch (e) {
        activity && addActivityToMap(activity);
      }
    }
  }, [
    activity,
    addAOIToMap,
    addActivityAsSwathLayerToMap,
    container,
    initNewMap,
    mapProvider,
    request,
  ]);

  const modifyActivitySwath = useCallback(
    async (activityToModify: Activity, controlData: SwathControlData) => {
      const updated = await postSwathSearch({
        body: {
          area_of_interest: {
            name: request!.region_name,
            geojson: {
              geometry: request!.region.geometry,
              properties: {},
              type: request!.region.type,
            },
          },
          instrument: {
            mission_id: activityToModify.mission_id,
            sensor_id: activityToModify.parameters.imager.name,
          },
          roll_angle: controlData.rotation,
          start: controlData.duration.start.toISOString(),
          stop: controlData.duration.end.toISOString(),
          project_id: '',
        },
      });

      if (!updated.data) return undefined;

      const found = layers.layers.find(
        (l) => l.getName() === activityToModify.id
      );

      const replacement = new SwathLayer(
        LayerSourceType.TASKING_OPPORTUNITIES,
        activityToModify.id,
        updated.data.footprint.geojson,
        {} as CommonOpportunity
      );

      if (found instanceof SwathLayer) {
        layers.replaceSpecificLayerWithAnother(replacement, found);
      }

      // Return updated swath
      return updated.data;
    },
    [layers, request]
  );

  const updateActivityWithNewSwathParams = async (
    activityToUpdate: Activity,
    swathData: SwathControlData
  ) => {
    await patchActivity({
      body: {
        end_date: swathData.duration.end.toISOString(),
        start_date: swathData.duration.start.toISOString(),
        parameters: {
          platform: {
            roll_angle: swathData.rotation,
          },
        },
      },
      params: {
        activityId: activityToUpdate.id,
        missionId: activityToUpdate.mission_id,
      },
    });
  };

  const getActivityStacItem = useCallback(async (act: Activity) => {
    if (!act.parameters.stac) {
      return undefined;
    }

    if (act.parameters.stac.length === 0) {
      return undefined;
    }

    const sortedProcessingLvls = act.parameters.stac.sort((a, b) => {
      return b.processing_level.localeCompare(a.processing_level);
    });

    const highestProcessingLvlItem = sortedProcessingLvls[0];

    const { data } = await getSingleFeature({
      params: {
        collection: highestProcessingLvlItem.collection_id,
        item: highestProcessingLvlItem.item_id,
      },
    });

    return data;
  }, []);

  const addStacItemThumbnailToMap = useCallback(async () => {
    if (shownThumbnail) {
      return;
    }

    if (!activity) {
      return;
    }

    if (isActivityArray(activity)) {
      return;
    }

    const item = await getActivityStacItem(activity);

    if (!item) {
      return;
    }

    const imageBounds = [
      [item.bbox[1], item.bbox[0]],
      [item.bbox[3], item.bbox[2]],
    ];

    const thumb = L.imageOverlay(
      item.assets.thumbnail.href,
      imageBounds as LatLngBoundsExpression
    ).addTo(mapRef.current!);

    setShownThumbnail(thumb);
  }, [activity, getActivityStacItem, shownThumbnail]);

  const removeStacItemThumbnailFromMap = useCallback(() => {
    if (shownThumbnail) {
      mapRef.current?.removeLayer(shownThumbnail);
      setShownThumbnail(undefined);
    }
  }, [shownThumbnail]);

  useEffect(() => {
    return () => {
      if (shownThumbnail) {
        mapRef.current?.removeLayer(shownThumbnail);
        setShownThumbnail(undefined);
      }
    };
  }, [activity, shownThumbnail]);
  const focusThumbnail = useCallback(() => {
    if (shownThumbnail) {
      mapRef.current?.fitBounds(shownThumbnail.getBounds());
    }
  }, [shownThumbnail]);

  return {
    mapRef,
    initMap,
    modifyActivitySwath,
    updateActivityWithNewSwathParams,
    addStacItemThumbnailToMap,
    shouldShowThumbnailButton,
    removeStacItemThumbnailFromMap,
    isThumbnailShown: Boolean(shownThumbnail),
    focusThumbnail,
  };
};
