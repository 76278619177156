import { UL } from '@blueprintjs/core';
import { useHistory } from 'react-router';
import type { Programme } from 'api/administration/service';
import { useAuth } from 'services/auth/AuthWrapper';
import s from './index.module.scss';
import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

interface IProps {
  programmesList: Programme[];
}

const AdministrationProgrammeList = (props: IProps) => {
  const { checkPermissions } = useAuth();
  const history = useHistory();

  const [programmesWithPermissions, setProgrammesWithPermissions] = useState<
    (Programme & { canAccess: boolean })[] | undefined
  >();

  const isAllowedToUpdateProgramme = useCallback(async () => {
    const canUpdate = await checkPermissions(
      props.programmesList.map((p) => ({
        id: p.id,
        type: 'programme',
        actionScope: 'programme:update',
      }))
    );

    setProgrammesWithPermissions(
      props.programmesList.map((p, i) => ({ ...p, canAccess: canUpdate[i] }))
    );

    return canUpdate;
  }, [checkPermissions, props.programmesList]);

  const handleProgrammeClick = (
    programme: (Programme & { canAccess: boolean }) | undefined
  ) => {
    if (!programme) {
      return;
    }

    if (!programme.canAccess) {
      return;
    }
    history.push(`/portal/programme/${programme.id}`);
  };

  useQuery({
    queryKey: ['programme:update'],
    queryFn: () => {
      return isAllowedToUpdateProgramme();
    },
  });

  return (
    <div>
      <span className={`${s.title} font-bold`}>Programmes</span>
      <UL className={s.listContainer}>
        {programmesWithPermissions?.map((programme) => (
          <li key={programme.id}>
            <div
              className={programme.canAccess ? undefined : s.disabledItem}
              role="button"
              tabIndex={0}
              onKeyDown={() => handleProgrammeClick(programme)}
              onClick={() => handleProgrammeClick(programme)}
            >
              {programme.name}
            </div>
          </li>
        ))}
      </UL>
    </div>
  );
};

export default AdministrationProgrammeList;
