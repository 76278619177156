import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Button } from "..";
import type { ButtonIconSize } from "../Button/Button";

type Props = {
  /**
   * The function to call when the user clicks the card.
   */
  onClick: React.MouseEventHandler<HTMLDivElement>;
  /**
   * The content to display on the collapsed card.
   * This is the text shown before the user expands the card.
   */
  cardContent: JSX.Element | JSX.Element[];
  /**
   * The information to display in the card.
   * Consists of a text and an icon.
   * The icon size is set by the `iconSize` prop.
   */
  info: {
    text?: string;
    iconSize: ButtonIconSize;
  };
  /**
   * Whether the information should be shown on hover or on click.
   */
  showInfoOn: "hover" | "click";
  className?: string;
  /**
   * Whether the card should be positioned absolutely.
   *
   */
  absoluteInfoPosition?: true;
};

const InfoCard = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={classNames(
        props.className,
        "flex flex-col mb-px p-2 cursor-pointer group",
        "hover:bg-item-hover hover:dark:bg-item-dark-hover hover:dark:text-item-dark-contrast",
        {
          relative: props.absoluteInfoPosition,
        }
      )}
      onClick={props.onClick}
    >
      <div className="flex gap-2 items-center" ref={containerRef}>
        <div>{props.cardContent}</div>

        <Button
          icon="Info"
          size={props.info.iconSize}
          onPress={() => {
            props.showInfoOn === "click" && setIsExpanded(!isExpanded);
          }}
          onHoverStart={() => {
            props.showInfoOn === "hover" && setIsExpanded(true);
          }}
          onHoverEnd={() => {
            props.showInfoOn === "hover" && setIsExpanded(false);
          }}
          isMinimal
          isTransparent
        />
      </div>
      {isExpanded && (
        <div
          className={classNames("mt-2", {
            "absolute color-item": props.absoluteInfoPosition,
          })}
          style={{
            top:
              props.absoluteInfoPosition && containerRef.current
                ? containerRef.current.clientHeight + 8
                : undefined,
            zIndex: 10001,
          }}
        >
          <p className="text-sm">{props.info.text}</p>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
