import { Menu } from "react-aria-components";

const MyMenu: typeof Menu = (props) => {
  return (
    <Menu
      {...props}
      className="py-2 rounded bg-surface text-surface-contrast dark:bg-surface-dark dark:text-surface-dark-contrast shadow-md border-item dark:border-item-dark border focus-visible:outline-1 focus-visible:outline-neutral-600 focus-visible:outline"
    />
  );
};

export default MyMenu;
