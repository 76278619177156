import { MenuItem, MenuItemProps } from "react-aria-components";
import { tv, VariantProps } from "tailwind-variants";

const menuItem = tv({
  base: [
    "grid grid-cols-[fit-content(100%)_1fr_fit-content(100%)] items-center leading-none gap-x-2 px-4 py-2",
    "[&>.icon]:row-start-1 [&>.icon]:row-span-2",
    "[&>[slot=label]]:row-start-1 [&>[slot=label]]:w-full",
    "[&>[slot=description]]:row-start-2 [&>[slot=description]]:text-sm [&>[slot=description]]:text-neutral-700",
    "[&>kbd]:row-start-1 [&>kbd]:col-start-3 [&>kbd]:row-span-2",
    "[&>.end]:col-start-3",
  ].concat(" "),
  variants: {
    isDisabled: {
      true: "text-item-contrast-inactive dark:text-item-dark-contrast-inactive cursor-default",
      false:
        "cursor-pointer focus-visible:outline-1 focus-visible:outline-neutral-600 focus-visible:outline",
    },
    isSelected: {
      true: "",
      false: "",
    },
    isLink: {
      true: "hover:no-underline hover:text-item-contrast dark:hover:text-item-dark-contrast",
      false: "",
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      isSelected: false,
      class: [
        "hover:bg-item-hover dark:hover:bg-item-dark-hover focus:bg-item-hover dark:focus:bg-item-dark-hover",
      ],
    },
    {
      isDisabled: false,
      isSelected: true,
      class: ["bg-item-selected dark:bg-item-dark-selected"],
    },
  ],
});

export type Props = MenuItemProps & VariantProps<typeof menuItem>;

const MyMenuItem: typeof MenuItem = (props) => {
  return (
    <MenuItem
      {...props}
      className={(params) =>
        menuItem({
          ...params,
          isLink: Boolean(props.href),
          class:
            typeof props.className === "function"
              ? props.className(params)
              : props.className,
        })
      }
    />
  );
};

export default MyMenuItem;
