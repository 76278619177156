import { DatePickerProps } from "./DatePicker";
import {
  parseDate as intParseDate,
  parseAbsolute,
} from "@internationalized/date";
import moment from "moment";

type Granularity = NonNullable<DatePickerProps["granularity"]>;

const granularityAmount: {
  [key in Granularity]: number;
} = {
  day: 10,
  hour: 13,
  minute: 16,
  second: 19,
};

export const parseTime = (
  date: Date | string | undefined,
  granularity: DatePickerProps["granularity"]
) => {
  if (!date || !granularity) {
    return undefined;
  }

  if (typeof date === "string") {
    return date.substring(0, granularityAmount[granularity]);
  }

  return intParseDate(
    date.toISOString().substring(0, granularityAmount[granularity])
  );
};

export const parseDate = (
  date: Date | string | undefined,
  timeZone: string
) => {
  if (!date) {
    return undefined;
  }

  if (typeof date === "string") {
    console.log("STRING DATE", date);
    return parseAbsolute(date, timeZone);
  }

  return parseAbsolute(date.toISOString(), timeZone);
};

export const parseDateRange = (
  range: {
    start: Date | null;
    end: Date | null;
  },
  timeZone?: string
) => {
  if (!timeZone) {
    return {
      start: intParseDate(moment(range.start).format("YYYY-MM-DD")),
      end: intParseDate(moment(range.end).format("YYYY-MM-DD")),
    };
  }

  return {
    start: parseAbsolute(range.start?.toISOString() ?? "", timeZone),
    end: parseAbsolute(range.end?.toISOString() ?? "", timeZone),
  };
};
