import { v1 as uuid } from 'uuid';
import type {
  IGoal,
  IGoalReduceState,
} from '../../../../../../constants/goals/interfaceas';
import type { IGoalsActionTypes } from '../../../../../../constants/goals/actionTypes';
import {
  GOAL_ADD,
  GOAL_REMOVE,
  GOAL_RESET,
  GOAL_SUB_ID,
  GOAL_UPDATE,
  GOAL_UPLOAD,
} from '../../../../../../constants/goals/constants';

export function addGoalObject(goalObject: IGoal): IGoalsActionTypes {
  const id = goalObject.id ? goalObject.id : `${GOAL_SUB_ID}-${uuid()}`;
  return {
    type: GOAL_ADD,
    goal: { ...goalObject, id },
  };
}

export function updateGoalObject(goalObject: IGoal): IGoalsActionTypes {
  return {
    type: GOAL_UPDATE,
    goal: goalObject,
  };
}

export function removeGoalObject(goalObject: IGoal): IGoalsActionTypes {
  return {
    type: GOAL_REMOVE,
    goal: goalObject,
  };
}

export function removeAllGoalsObject(): IGoalsActionTypes {
  return {
    type: GOAL_RESET,
  };
}

export function uploadGoals(goals: IGoalReduceState): IGoalsActionTypes {
  return {
    type: GOAL_UPLOAD,
    goals: goals,
  };
}
