import React from 'react';
import { Dialog, Spinner } from '@blueprintjs/core';
import type { IFolder } from '../../../../../../services/api/ops/cloudStorage';
import FileEditor from '../../../../../../components/FileEditor';
import s from './index.module.scss';

interface IProps {
  fileContent?: IFolder;
  isPreviewOpen?: boolean;
  handleUploadClick?: (file: any, isNotDownloadable?: boolean) => void;
  handlePreviewDialogClose: () => void;
}

const PreviewDialog = (props: IProps) => (
  <Dialog
    usePortal
    canOutsideClickClose
    className={s.filePreviewDialogBody}
    isOpen={props.isPreviewOpen}
    onClose={props.handlePreviewDialogClose}
  >
    <div className={s.previewDialogBody}>
      <FileEditor
        fileContent={props.fileContent}
        handleOnClose={props.handlePreviewDialogClose}
        handleUploadClick={(fileList) =>
          props.handleUploadClick(fileList, true)
        }
        renderEmptyData={
          <div className={s.filePreviewSpinnerContainer}>
            <Spinner />
          </div>
        }
      />
    </div>
  </Dialog>
);

export default PreviewDialog;
