import { join, map, pipe, split } from 'lodash/fp';
import utf8 from 'utf8';
import { stringToHex } from './stringUtils';

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(buffer);
  let binaryString = '';
  uint8Array.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return window.btoa(binaryString);
}

export const base64ToHex = (str: string) => {
  try {
    return pipe(atob, stringToHex)(str);
  } catch (e) {
    return undefined;
  }
};

export const hexToBase64 = (str: string) => {
  try {
    const byteArray = str
      .replace(/ /g, '')
      .replace(/\r|\n/g, '')
      .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
      .replace(/ +$/, '')
      .split(' ')
      .map((v) => parseInt(v, 16));

    return btoa(String.fromCharCode(...byteArray));
  } catch (e) {
    return undefined;
  }
};

export const base64ToBinary = (str: string) => {
  try {
    return pipe(
      atob,
      split(''),
      map((char) => {
        const bin = char.charCodeAt(0).toString(2);

        return '00000000'.substr(bin.length) + bin;
      }),
      join(' ')
    )(str);
  } catch (e) {
    return undefined;
  }
};

export const base64toUTF8 = (base64: string) => {
  try {
    const raw = atob(base64);

    return utf8.decode(raw);
  } catch (e) {
    return undefined;
  }
};

export const UTF8ToBase64 = (str: string) => {
  try {
    const raw = utf8.encode(str);

    return btoa(raw);
  } catch (e) {
    return undefined;
  }
};

export const binaryToBase64 = (str: string) => {
  try {
    const noWhiteSpaces = str.replace(/ /g, '');

    if (noWhiteSpaces.length % 8 !== 0) {
      return undefined;
    }

    let binString = '';
    for (let i = 0; i < noWhiteSpaces.length; i += 8) {
      const byte = noWhiteSpaces.substring(i, i + 8);
      binString += String.fromCharCode(parseInt(byte, 2));
    }

    return btoa(binString);
  } catch (e) {
    return undefined;
  }
};

export const base64ToDecimal = (str: string) => {
  try {
    return pipe(
      atob,
      map((char: string) => char.charCodeAt(0)),
      join(' ')
    )(str);
  } catch (e) {
    return undefined;
  }
};

export const decimalToBase64 = (str: string): string | undefined => {
  try {
    const numberArray = str.split(' ').map((char) => parseInt(char, 10));
    return btoa(String.fromCharCode(...numberArray));
  } catch (e) {
    return undefined;
  }
};

export enum BASE64_MODES {
  BASE64 = 'BASE64',
  HEX = 'HEX',
  BINARY = 'BINARY',
  DECIMAL = 'DECIMAL',
}

export const UTF8 = 'UTF8';

export const transformMapping = [
  {
    from: BASE64_MODES.BASE64,
    to: BASE64_MODES.HEX,
    func: base64ToHex,
  },
  {
    from: BASE64_MODES.HEX,
    to: BASE64_MODES.BASE64,
    func: hexToBase64,
  },
  {
    from: BASE64_MODES.BASE64,
    to: BASE64_MODES.BINARY,
    func: base64ToBinary,
  },
  {
    from: BASE64_MODES.BINARY,
    to: BASE64_MODES.BASE64,
    func: binaryToBase64,
  },
  {
    from: BASE64_MODES.BASE64,
    to: UTF8,
    func: base64toUTF8,
  },
  {
    from: UTF8,
    to: BASE64_MODES.BASE64,
    func: UTF8ToBase64,
  },
  {
    from: BASE64_MODES.BASE64,
    to: BASE64_MODES.DECIMAL,
    func: base64ToDecimal,
  },
  {
    from: BASE64_MODES.DECIMAL,
    to: BASE64_MODES.BASE64,
    func: decimalToBase64,
  },
];

export const findBase64Transformer = (fromMode: string, toMode: string) =>
  transformMapping.find(({ from, to }) => from === fromMode && to === toMode);

export function validateBase64(str: string): boolean {
  try {
    window.atob(str);
    return true;
  } catch {
    return false;
  }
}

export function validateHex(str: string): boolean {
  const noWhiteSpaces = str.replace(/ /g, '');

  if (noWhiteSpaces.length % 2 !== 0) return false;
  if (noWhiteSpaces.match(/[^0-9a-fA-F]/g)) return false;
  return true;
}

export function validateBinary(str: string): boolean {
  const noWhiteSpaces = str.replace(/ /g, '');

  if (noWhiteSpaces.length % 8 !== 0) return false;
  if (noWhiteSpaces.match(/[^01]/)) return false;
  return true;
}

export function validateDecimal(str: string): boolean {
  const numbers = str.split(' ');

  if (numbers.length === 1 && numbers[0] === '') return true;

  const foundInvalid = numbers.some((number) => {
    const num = parseInt(number);

    if (isNaN(num)) return true;
    if (num > 255 || num < 0) return true;

    return false;
  });
  return !foundInvalid;
}

export function validateUTF8(str: string): boolean {
  try {
    // Encode the string to UTF-8 and decode it back to verify if it's a valid UTF-8 string
    const encoded = new TextEncoder().encode(str);
    const decoded = new TextDecoder().decode(encoded);
    return str === decoded;
  } catch (e) {
    return false;
  }
}

export const validate = {
  [BASE64_MODES.BASE64]: validateBase64,
  [BASE64_MODES.HEX]: validateHex,
  [BASE64_MODES.BINARY]: validateBinary,
  [BASE64_MODES.DECIMAL]: validateDecimal,
  [UTF8]: validateUTF8,
};

export default {
  base64ToHex,
  base64ToBinary,
  base64toUTF8,
  base64ToDecimal,
  hexToBase64,
  binaryToBase64,
  UTF8ToBase64,
  decimalToBase64,
};
