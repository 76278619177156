import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import area from '@turf/area';
import circle from '@turf/circle';
import { CircleLayerFactory } from 'datacosmos/entities/circleLayer';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import React from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  drawCircle: (options?: { color: string; weight: number }) => Promise<{
    circleMetadata: unknown;
    center: GeoJSON.Point;
    radius: number;
  }>;

  addLayer: (...newLayers: Layer[]) => void;
}

const DrawCircle = (props: IProps) => {
  const { translate } = useLocalisation();
  return (
    <MenuItem
      className="dark:hover:text-neutral hover:text-accent-900"
      text={translate('datacosmos.rightClickMenu.circle')}
      icon={IconNames.CIRCLE}
      onClick={async () => {
        const { center, radius, circleMetadata } = await props.drawCircle();

        const circlePolygon = circle(center, radius);
        const areaInM2 = area(circlePolygon);

        props.addLayer(
          CircleLayerFactory(
            LayerSourceType.GEOMETRY_LAYER,
            translate('datacosmos.layers.names.circle'),
            circlePolygon,
            areaInM2,
            radius,
            center,
            circleMetadata
          )
        );
      }}
    />
  );
};

export default DrawCircle;
