import { clientTranslate } from 'utils/hooks/useLocalisation';
import type { IColorChangable, IGeoJSONModifiers } from './geojsonLayer';
import { DefaultGeoJSONModifiers } from './geojsonLayer';
import { getPopupContentWithArea } from './helpers';
import type { ILayerOptions } from './layer';
import { Layer, LayerSourceType } from './layer';

export function PolygonLayerFactory(
  sourceType: LayerSourceType,
  name: string,
  data: GeoJSON.GeoJSON,
  areaInM2: number,
  leafletLayerMetadata: any,
  options?: Partial<ILayerOptions & IGeoJSONLayerOptions>,
  modifiers?: IGeoJSONModifiers
) {
  return new PolygonLayer(
    sourceType,
    name,
    data,
    areaInM2,
    leafletLayerMetadata,
    options,
    modifiers
  );
}

export interface IGeoJSONLayerOptions {
  color: string;
  weight: number;
  fillOpacity: number;
  visible: boolean;
}

export const DefaultGeometryLayerOptions = {
  color: '#e4695e',
  weight: 1,
  fillOpacity: 0.5,
  visible: true,
};

export const KM2 = 1000000;
const ACCURACY_LIMIT = 10000000;

/**
 *Geometry layer is a GeoJSON layer for displaying user drawn shapes with measurements included
 */
export class PolygonLayer extends Layer implements IColorChangable {
  declare options: ILayerOptions & IGeoJSONLayerOptions;
  declare layerClass: string;
  name: string;
  data: GeoJSON.GeoJSON;
  areaInM2: number;
  leafletLayerMetadata: any;
  description: string | undefined;
  modifiers: IGeoJSONModifiers | undefined;

  /**
   * newDataCosmosLayer creates a new datacosoms layer
   */
  constructor(
    sourceType: LayerSourceType,
    name: string,
    data: GeoJSON.GeoJSON,
    areaInM2: number,
    leafletLayerMetadata: any,
    options?: Partial<ILayerOptions & IGeoJSONLayerOptions>,
    modifiers?: IGeoJSONModifiers
  ) {
    super(sourceType);

    this.name = name;
    this.data = data;
    this.areaInM2 = areaInM2;
    this.leafletLayerMetadata = leafletLayerMetadata;
    this.options = {
      ...this.options,
      ...DefaultGeometryLayerOptions,
      ...options,
    };
    this.modifiers = {
      ...DefaultGeoJSONModifiers,
      ...this.modifiers,
      ...modifiers,
    };
    this.layerClass = 'PolygonLayer';
  }
  setColor(color: string): void {
    this.options.color = color;
  }

  getName() {
    return this.name || this.id;
  }

  getDescription() {
    if (this.description) {
      return this.description;
    }

    if (this.sourceType === LayerSourceType.GEOMETRY_LAYER) {
      return null;
    }

    if (this.sourceType === LayerSourceType.TASKING_REGIONS) {
      return clientTranslate('datacosmos.layers.names.taskingAoi');
    }

    if (this.sourceType === LayerSourceType.APPLICATION_AOI) {
      return clientTranslate('datacosmos.layers.names.applicationAoi');
    }

    if (this.sourceType === LayerSourceType.AREA_OF_INTEREST) {
      return clientTranslate('datacosmos.layers.names.filtersAoi');
    }
    return clientTranslate('datacosmos.layers.names.area');
  }

  getMeasurements() {
    return 'Area ' + Number(this.areaInM2 / KM2).toFixed(2) + ' km²';
  }

  isAccurate() {
    return this.areaInM2 / KM2 < ACCURACY_LIMIT;
  }

  getPopupContent() {
    return getPopupContentWithArea(
      this.name,
      Number(this.areaInM2 / KM2).toFixed(2)
    );
  }

  cloneWithGeoJSON(data: GeoJSON.GeoJSON): this {
    const newObject = this.clone();
    newObject.data = data;
    return newObject;
  }

  cloneWithModifiers(modifiers: IGeoJSONModifiers) {
    const newObject = this.clone();
    newObject.modifiers = { ...newObject.modifiers, ...modifiers };
    return newObject;
  }

  cloneWithMeasurements(areaInM2: number): this {
    const newObject = this.clone();
    newObject.areaInM2 = areaInM2;
    return newObject;
  }

  cloneWithName(name: string) {
    const newObject = this.clone();
    newObject.name = name;
    return newObject;
  }

  cloneWithOptions(options: Partial<ILayerOptions & IGeoJSONLayerOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }

  getLeafletLayerMetadata() {
    return this.leafletLayerMetadata;
  }

  containsSTACItem() {
    return false;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  sza(): Number | undefined {
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
