import moment from 'moment';
import { IconNames } from '@blueprintjs/icons';
import type { IDateRangeShortcut } from '@blueprintjs/datetime/src/shortcuts';
import type { IBottomBarTab } from '../../../pages/ops/Library/components/BottomNavBar';

export const GS_TAB_MAPPING = {
  scheduling: { type: 'BOOKED', isCancelable: false },
  upcoming: { type: 'BOOKED', isCancelable: true },
  completed: { type: 'BOOKED', isCancelable: false },
  cancelled: { type: 'CANCELLED', isCancelable: false },
} as const;

export type TAB_STATE = keyof typeof GS_TAB_MAPPING;

export const GS_SCHEDULING_BOTTOM_NAVBAR_TABS: IBottomBarTab[] = [
  {
    id: 'scheduling',
    title: 'Scheduling',
    icon: IconNames.TIMELINE_EVENTS,
    permission: 'portal:gs:pass:write',
  },
  {
    id: 'upcoming',
    title: 'Upcoming',
    icon: IconNames.BOOKMARK,
    permission: 'portal:gs:pass:read',
  },
  {
    id: 'completed',
    title: 'Completed',
    icon: IconNames.ENDORSED,
    permission: 'portal:gs:pass:read',
  },
  {
    id: 'cancelled',
    title: 'Cancelled',
    icon: IconNames.DISABLE,
    permission: 'portal:gs:pass:read',
  },
];

export const STATION_ZOOM = 11;
export const MIN_ZOOM = 3;
export const TLE_MAX_LENGTH = 69;

export const DATE_CUSTOM_SHORTCUTS: IDateRangeShortcut[] = [
  {
    label: 'Yesterday',
    dateRange: [moment().subtract(1, 'day').toDate(), moment().toDate()],
  },
  {
    label: 'Last Three Days',
    dateRange: [moment().subtract(3, 'day').toDate(), moment().toDate()],
  },
  {
    label: 'Last week',
    dateRange: [moment().subtract(1, 'week').toDate(), moment().toDate()],
  },
  {
    label: 'Tomorrow',
    dateRange: [moment().toDate(), moment().add(1, 'day').toDate()],
  },
  {
    label: 'Next Three Days',
    dateRange: [moment().toDate(), moment().add(3, 'day').toDate()],
  },
  {
    label: 'Next week',
    dateRange: [moment().toDate(), moment().add(1, 'week').toDate()],
  },
];
