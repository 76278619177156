import { useParams } from 'react-router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import type {
  ICommandPileItem,
  UpdateWorkspace,
} from 'services/api/ops/realtimeTerminalSession';
import { preventDefaultPropagation } from 'utils/common/CommonUtils';
import TagHeader from 'components/common/TagHeader';
import type { PermissionScope } from 'api/administration/permissions';
import { useMission } from 'services/Missions';
import CommandPileItem from './CommandPileItem';
import CommandPileOptions from './CommandPileOptions';
import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useCommandCopyPaste } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandCopyPaste';
import type { CommandDefinition, JsonSchema } from 'api/telecommands/types';
import { useState } from 'react';
import AlertConfirm from 'components/common/AlertConfirm';
import { Button, Tooltip } from 'opencosmos-ui';
import classNames from 'classnames';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface IProps {
  availableCommands: CommandDefinition[];
  isFrozenPile: boolean;
  commandPileWorkingSpace?: ICommandPileItem[];
  armedCommand?: ICommandPileItem;
  handleFireCommandClick: () => void;
  handleUnarmCommandClick: () => void;
  selectedPileItem?: ICommandPileItem;
  handleArmedCommandPreviewClick: () => void;
  updateWorkspace: UpdateWorkspace;
  isTablet?: boolean;
  procedureName?: string;
  setProcedureName: (name: string) => void;
  isGridView: boolean;
  setIsGridView: (value: boolean) => void;
}

interface ICommandInfo {
  commandSchema: JsonSchema;
  commandScope?: PermissionScope;
  dangerous: boolean;
  dangerousMessage?: string;
}

export const CommandPileHeader = ({
  commandPileWorkingSpace,
  updateWorkspace,
  armedCommand,
  isFrozenPile,
  availableCommands,
  selectedPileItem,
  isTablet,
  handleArmedCommandPreviewClick,
  handleFireCommandClick,
  handleUnarmCommandClick,
  procedureName,
  setProcedureName,
  isGridView,
  setIsGridView,
}: IProps) => {
  const { mission } = useParams<{ mission: string }>();
  const { currentMission } = useMission();

  const { sendInfo } = useAnalytics();

  const [isPasteWarningOpened, setIsPasteWarningOpened] =
    useState<boolean>(false);

  const {
    handleCopyCommandPileAndArmedCommands,
    handlePasteCommandPileAndArmedCommands,
  } = useCommandCopyPaste();

  const handleFrozenPile = (isFrozen: boolean) => {
    if (!commandPileWorkingSpace) return;
    updateWorkspace(commandPileWorkingSpace, armedCommand, isFrozen);
  };

  const getCommandInfo = (name?: string): ICommandInfo => {
    const defaultInfo: ICommandInfo = {
      commandSchema: {},
      commandScope: 'ops',
      dangerous: false,
    };

    if (!name) return defaultInfo;

    const command = availableCommands.find((value) => value.name === name);

    return command
      ? {
          commandSchema: command.schema,
          commandScope: command.scope,
          dangerous: command.dangerous,
          dangerousMessage: command.dangerousMessage,
        }
      : defaultInfo;
  };

  const armedCommandInfo = getCommandInfo(armedCommand?.command);

  const { hasPermission: isAllowedToOpenArmedCommand } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: armedCommandInfo.commandScope,
      id: mission,
    },
  });

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className={s.commandPileFireContainer}>
          <TagHeader
            className={s.tagHeaderPileCommand}
            icon={'target'}
            title="Armed Command"
          >
            <div className="w-full flex flex-grow">
              <div />
              <div className={s.armedCommandMission}>
                {currentMission?.name}
              </div>

              <div className="flex items-center">
                <Tooltip
                  delay={0}
                  content="Copy Command Pile and Armed Command"
                >
                  <Button
                    // TODO: Bp icon; replace with own custom eventually
                    icon={'duplicate'}
                    onPress={handleCopyCommandPileAndArmedCommands}
                    data-testid="copy-cmd-btn"
                  />
                </Tooltip>
                <Tooltip
                  delay={0}
                  content="Paste Command Pile and Armed Command"
                >
                  <Button
                    // TODO: Bp icon; replace with own custom eventually
                    icon={'clipboard'}
                    onPress={() => {
                      if (
                        commandPileWorkingSpace?.length &&
                        commandPileWorkingSpace.length > 0
                      ) {
                        setIsPasteWarningOpened(true);
                        return;
                      }
                      handlePasteCommandPileAndArmedCommands();
                    }}
                    data-testid="paste-cmd-btn"
                  />
                </Tooltip>
              </div>
            </div>
          </TagHeader>
          {armedCommand ? (
            <CommandPileItem
              handleShiftSelect={() => {}}
              setShiftClickInitialIndex={() => {}}
              shiftClickInitialIndex={0}
              itemIndex={0}
              hoverPileItem={() => {}}
              className={classNames(
                'hover:bg-item-hover dark:hover:bg-item-dark-hover',
                {
                  'bg-item-selected dark:bg-item-dark-selected':
                    selectedPileItem?.id === armedCommand.id,
                }
              )}
              pileItem={armedCommand}
              commandSchema={armedCommandInfo.commandSchema}
              handleItemClick={handleArmedCommandPreviewClick}
              buttonsList={
                <>
                  <Button
                    isDisabled={!isAllowedToOpenArmedCommand}
                    intent="primary"
                    icon={'Satellite'}
                    onPress={(e) => {
                      sendInfo({
                        type: 'Command pile send armed command',
                        action: 'Click',
                        item: 'Command pile send armed command button',
                        module: 'OPS',
                      });
                      preventDefaultPropagation(handleFireCommandClick)()(e);
                    }}
                  >
                    <span className="text-sm">{!isTablet ? 'Send' : ''}</span>
                  </Button>
                  <Button
                    isDisabled={!isAllowedToOpenArmedCommand}
                    intent="secondary"
                    // TODO: Bp icon; replace with own custom eventually
                    icon={'undo'}
                    onPress={(e) => {
                      sendInfo({
                        type: 'Command pile unarm armed command',
                        action: 'Click',
                        item: 'Command pile unarm armed command button',
                        module: 'OPS',
                      });
                      preventDefaultPropagation(handleUnarmCommandClick)()(e);
                    }}
                  >
                    <span className="text-sm">{!isTablet ? 'Unarm' : ''}</span>
                  </Button>
                </>
              }
            />
          ) : null}
        </div>
      </DndProvider>

      <TagHeader
        // TODO: Bp icon; replace with own custom eventually
        icon={'key-command'}
        className={s.commandPileTagContainer}
        title="Command Pile"
      >
        <CommandPileOptions
          procedureName={procedureName}
          setProcedureName={setProcedureName}
          isFrozen={isFrozenPile}
          onFreezeToggle={() => handleFrozenPile(!isFrozenPile)}
          isGridView={isGridView}
          onPileViewToggle={() => setIsGridView(!isGridView)}
        />
      </TagHeader>

      <AlertConfirm
        isOpen={isPasteWarningOpened}
        setIsOpen={setIsPasteWarningOpened}
        message="You are about to overwrite existing commands in the pile. Continue pasting?"
        onConfirm={() => {
          handlePasteCommandPileAndArmedCommands();
        }}
      />
    </>
  );
};
