import type { StacItem } from 'datacosmos/types/stac-types';
import useStacInfo from 'datacosmos/utils/hooks/useStacInfo';
import { getThumbnailAssetKey } from 'datacosmos/utils/stac';
import { Button, Icon, Tooltip } from 'opencosmos-ui';
import { useMemo } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  currentImage: StacItem | undefined;
  setCurrentImage: (image: StacItem) => void;
  imagesToPurchase: StacItem[];
}

const ConfirmPurchaseImageCarousel = ({
  currentImage,
  setCurrentImage,
  imagesToPurchase,
}: IProps) => {
  const thumbnailKey = currentImage ? getThumbnailAssetKey(currentImage) : '';

  const { translate } = useLocalisation();

  const { sunElevationAngle, cloudCoverage } = useStacInfo(currentImage);

  const currentItemIndex = useMemo(
    () => imagesToPurchase.findIndex((img) => img.id === currentImage?.id),
    [currentImage?.id, imagesToPurchase]
  );

  return (
    <div
      data-testid="image-carousel"
      className="flex flex-col justify-center items-center"
    >
      <div className="w-40 h-40 xl:w-80 xl:h-80">
        <img
          src={
            thumbnailKey
              ? currentImage?.assets[thumbnailKey]?.href
              : '/images/datacosmos/img-placeholder.svg'
          }
          alt="Image thumbnail"
          data-testid="image-thumbnail"
          className="object-cover w-full h-full"
        />
      </div>

      <div className="flex items-center justify-between mt-2">
        <Button
          data-testid="previous-image"
          icon="ChevronLeft"
          onPress={() => {
            if (currentItemIndex <= 0) {
              setCurrentImage(imagesToPurchase[imagesToPurchase.length - 1]);
            } else {
              setCurrentImage(imagesToPurchase[currentItemIndex - 1]);
            }
          }}
          size={'lg'}
          isMinimal
          isTransparent
        />
        <div
          data-testid="image-info"
          className="text-center flex justify-between items-center flex-wrap gap-1"
        >
          <Tooltip
            content={translate(
              'datacosmos.tooltips.catalogAndItems.purchaseDialog.satelliteName'
            )}
          >
            <div className="flex items-center gap-1 justify-center">
              <Icon icon="Satellite" />
              {currentImage?.title()}
            </div>
          </Tooltip>
          <Tooltip
            content={translate(
              'datacosmos.tooltips.catalogAndItems.purchaseDialog.location'
            )}
          >
            <div className="flex items-center gap-1 justify-center">
              <Icon icon="Pin" />
              {currentImage?.location()}
            </div>
          </Tooltip>

          <Tooltip
            content={translate(
              'datacosmos.tooltips.catalogAndItems.purchaseDialog.date'
            )}
          >
            <div className="flex items-center gap-1 justify-center">
              <Icon icon="Calendar" className="" />
              {currentImage?.date()}
            </div>
          </Tooltip>
          <Tooltip
            content={translate(
              'datacosmos.tooltips.catalogAndItems.purchaseDialog.cloudCoverage'
            )}
          >
            <div className="flex items-center gap-1 justify-center">
              <Icon icon="Cloud" />
              {cloudCoverage}
            </div>
          </Tooltip>
          <Tooltip
            content={translate(
              'datacosmos.tooltips.catalogAndItems.purchaseDialog.sunElevationAngle'
            )}
          >
            <div className="flex items-center gap-1 justify-center">
              <Icon icon="Sun" />
              {sunElevationAngle}
            </div>
          </Tooltip>
        </div>
        <Button
          data-testid="next-image"
          icon="ChevronRight"
          onPress={() => {
            if (currentItemIndex === imagesToPurchase.length - 1) {
              setCurrentImage(imagesToPurchase[0]);
            } else {
              setCurrentImage(imagesToPurchase[currentItemIndex + 1]);
            }
          }}
          size={'lg'}
          isMinimal
          isTransparent
        />
      </div>
    </div>
  );
};

export default ConfirmPurchaseImageCarousel;
