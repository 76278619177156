import { get, pipe } from 'lodash/fp';
import handleCommandList from './handleCommandList';
import handleTerminalWorkspace from './handleTerminalWorkspace';
import handleHistoryNewItem from './handleHistoryNewItem';
import handleMessageLogEvent from './handleMessageLogEvent';
import type { IMessageLog } from 'pages/ops/RTI/Operate/hooks/useMessageLog';
import type {
  ICommandHistory,
  IReply,
} from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import type { CommandDefinition } from 'api/telecommands/types';

export type TransformResponseFunction = (data: unknown) => unknown;

export interface ITransformResponseMappingBody {
  validator: Function | null;
  transformer: TransformResponseFunction;
  handler: TransformResponseFunction;
}

export interface ITransformResponseMapping {
  [key: string]: ITransformResponseMappingBody;
}

interface IExternalFunc {
  dispatch: Function;
  addCommandToHistory: (command: ICommandHistory) => void;
  setAvailableCommands: (data: CommandDefinition[]) => void;
  addNewMessageLogEvent: (message: IMessageLog) => void;
}

// Mapping of received commands -> special side-effects
// Maps a command type to a handler
export const commandSessionMapping = ({
  dispatch,
  addCommandToHistory,
  setAvailableCommands,
  addNewMessageLogEvent,
}: IExternalFunc): ITransformResponseMapping => ({
  OpsAvailableCommandsAsJsonSchema: handleCommandList(setAvailableCommands),
  terminalWorkspace: handleTerminalWorkspace(dispatch),
  commandAcknowledgement: handleHistoryNewItem(addCommandToHistory),
  event: handleMessageLogEvent(addNewMessageLogEvent),
});

export const handleNewPacketFromWS = (fn: IExternalFunc, data: IReply) => {
  const type = data?.type;

  // Depending on the "message type" defined in commandSessionMapping
  const parser = get(type, commandSessionMapping(fn));

  if (!parser) return undefined;

  const { validator, transformer, handler } = parser;

  if (validator?.(data)) return undefined;

  pipe(transformer, handler)(data);

  return true;
};
