import React, { ReactNode } from "react";
import {
  Dialog,
  DialogProps,
  DialogTrigger,
  Heading,
  Modal,
  ModalOverlay,
} from "react-aria-components";
import Button from "../Button/Button";
import Icon, { IconName } from "../../icons/Icon";

interface AlertDialogProps extends Omit<DialogProps, "children"> {
  title: string;
  children: ReactNode;
  actionLabel: string;
  cancelLabel?: string;
  onAction?: () => void;
  isOpen?: boolean;
  onClose?: () => void;
  icon?: IconName;
}

function AlertDialog({
  title,
  cancelLabel,
  actionLabel,
  onAction,
  children,
  onClose,
  isOpen,
  ...props
}: AlertDialogProps) {
  return (
    <DialogTrigger isOpen={isOpen}>
      <ModalOverlay className="fixed inset-0 z-10 overflow-y-auto bg-black/25 flex min-h-full items-center justify-center p-4 text-center backdrop-blur">
        <Modal className="w-full max-w-md overflow-hidden rounded-2xl bg-item dark:bg-item-dark dark:text-item-dark-contrast p-6 text-left align-middle shadow-xl">
          <Dialog role="alertdialog" className="outline-none relative">
            {({ close }) => (
              <>
                <div className="flex items-center justify-between">
                  <Heading
                    slot="title"
                    className="text-xxl font-semibold leading-6 my-0"
                  >
                    {title}
                  </Heading>
                  <Icon
                    size={24}
                    icon={props.icon ? props.icon : "warning-sign"}
                    className="stroke-warning fill-warning"
                  />
                </div>
                <p className="mt-3 text-slate-500">{children}</p>
                <div className="mt-6 flex justify-end gap-2">
                  <Button
                    intent="primary"
                    onPress={() => {
                      onClose?.();
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    intent="warning"
                    onPress={() => {
                      onAction?.();
                      close();
                    }}
                  >
                    {actionLabel}
                  </Button>
                </div>
              </>
            )}
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}

export default AlertDialog;
