import type { NumberFieldStateOptions } from "@react-stately/numberfield";
import classNames from "classnames";
import React, { useRef } from "react";
import { useLocale, useNumberField } from "react-aria";
import { useNumberFieldState } from "react-stately";
import Button from "../Button/Button";

type NumberInputProps = Omit<NumberFieldStateOptions, "locale"> & {
  className?: string;
  /**
   * Whether or not the number input should fill the container it's in
   */
  fill?: true;
  onMouseOver?: React.MouseEventHandler<HTMLInputElement> | undefined;
  onMouseLeave?: React.MouseEventHandler<HTMLInputElement> | undefined;
  "data-testid"?: string;
};

/**
 * NumberInput is a component that only allows the user to input numbers.
 */
const NumberInput = (props: NumberInputProps) => {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    labelProps,
    groupProps,
    decrementButtonProps,
    incrementButtonProps,
    inputProps,
  } = useNumberField(props, state, inputRef);

  return (
    <div
      className={classNames({
        "w-full": props.fill,
        "w-fit": !props.fill,
      })}
    >
      {props.label && <label {...labelProps}>{props.label}</label>}
      <div {...groupProps} className="flex items-center w-full">
        <input
          {...inputProps}
          onMouseOver={props.onMouseOver}
          onMouseLeave={props.onMouseLeave}
          ref={inputRef}
          className={classNames(
            "w-full border-2 border-neutral-600 color-item",
            "pl-2 pr-2 h-8 text-sm border-r-0",
            {
              "bg-item-inactive dark:bg-item-inactive-dark": props.isDisabled,
            }
          )}
          onChange={(e) =>
            props.onChange?.(e.target.value as unknown as number)
          }
          data-testid={props["data-testid"]}
        />
        <div className="flex flex-col ">
          <Button
            {...incrementButtonProps}
            icon="ChevronUp"
            className="h-4 !w-6 flex items-center justify-center p-0 pl-auto  border-b-[1px] !box-border border-2 border-neutral-600"
            isDisabled={props.isDisabled}
            data-testid="number-input-increment"
          />
          <Button
            {...decrementButtonProps}
            icon="ChevronDown"
            className="h-4 !w-6 flex items-center justify-center p-0 pl-auto !box-border  border-t-[1px] border-2 border-neutral-600"
            isDisabled={props.isDisabled}
            data-testid="number-input-decrement"
          />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
