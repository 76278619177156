import React, { useState } from 'react';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import { format, useNewTimer } from 'components/Timer';
import GSEphemerisInfo from './GSEphemerisInfo';
import GSEphemerisHistory from './GSEphemerisHistory';
import type { Ephemeris, EphemerisParams } from 'api/ephemeris/types';
import s from '../../index.module.scss';
import { useMission } from 'services/Missions';
import GSAddTLEModal from './GSAddTLEModal';
import { Button, Dialog, Group, Icon } from 'opencosmos-ui';
import classNames from 'classnames';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface IProps {
  ephemeris: Ephemeris;
  ephemerisList: Ephemeris[];
  getEphemeris: (params: EphemerisParams) => void;
  saveTLE: (line1: string, line2: string) => void | Promise<void>;
  isSavingTLE: boolean;
}

const GSCurrentEphemeris = (props: IProps) => {
  const { currentMissionId } = useMission();

  const [isViewExpanded, setIsViewExpanded] = useState<boolean>(false);
  const [isHistoryOpen, setHistoryOpen] = useState<boolean>(false);
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false);
  const [isAddEphemerisOpen, setIsAddEphemerisOpen] = useState<boolean>(false);

  const timer = useNewTimer(getOr(moment.utc(), 'ephemeris.createdAt', props));

  const { hasPermission: isAllowedToReadEphemeris } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:ephemeris:read',
      id: currentMissionId,
    },
  });

  const { hasPermission: isAllowedToWriteEphemeris } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:ephemeris:write',
      id: currentMissionId,
    },
  });

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={s.gsEphemerisContainer}
        onClick={() => setIsViewExpanded(!isViewExpanded)}
        onKeyDown={(ev) =>
          ev.keyCode === 13 && setIsViewExpanded(!isViewExpanded)
        }
      >
        <div
          className={classNames(
            s.tagHeader,
            'dark:bg-item-dark bg-item dark:text-item-dark-contrast'
          )}
        >
          <span className={s.gsEphemerisTimer}>
            Ephemeris last updated {format(timer)}
          </span>
          {isViewExpanded && (
            <Group>
              <Button
                isDisabled={!isAllowedToReadEphemeris}
                onPress={() => {
                  setHistoryOpen(true);
                }}
              >
                <span className="text-sm">History</span>
              </Button>

              <Button
                isDisabled={!isAllowedToReadEphemeris}
                onPress={() => {
                  setInfoOpen(true);
                }}
              >
                <span className="text-sm">Info</span>
              </Button>

              <Button
                isDisabled={!isAllowedToWriteEphemeris}
                onPress={() => {
                  setIsAddEphemerisOpen(true);
                }}
              >
                <span className="text-sm">Add</span>
              </Button>
            </Group>
          )}
          <Icon
            className={s.infoIcon}
            icon={isViewExpanded ? 'cross' : 'wrench'}
          />
        </div>
      </div>
      <Dialog
        buttons={[]}
        hideCancelButton={true}
        title="History"
        isOpen={isHistoryOpen}
        onClose={() => setHistoryOpen(false)}
      >
        <GSEphemerisHistory
          className={`${s.ephemerisHistoryDialogBody}`}
          ephemerisList={props.ephemerisList}
          getEphemeris={props.getEphemeris}
        />
      </Dialog>
      <Dialog
        buttons={[]}
        hideCancelButton={true}
        title="Info"
        isOpen={isInfoOpen}
        onClose={() => setInfoOpen(false)}
      >
        <div>
          <GSEphemerisInfo ephemeris={props.ephemeris} />
        </div>
      </Dialog>

      <GSAddTLEModal
        isOpen={isAddEphemerisOpen}
        setIsOpen={setIsAddEphemerisOpen}
        saveTLE={props.saveTLE}
        isSavingTLE={props.isSavingTLE}
      />
    </>
  );
};

export default GSCurrentEphemeris;
