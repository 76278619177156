import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { rerouteHandler } from 'datacosmos/utils/useRerouteHandler';
import { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import type { Scenario } from 'api/scenarios/service';
import ManageScenarioModal from './ManageScenarioModal';
import { useLocalisation } from 'utils/hooks/useLocalisation';

/**
 * An intermediate page between the "home" screen and the scenarios page.
 * The scenarios are accessed via scenario specific URLs that do not make
 * sense when no scenario is selected. This selector is, on the other hand,
 * allowed to have an undefined scenario selected, and just redirects to
 * the scenario specific URL when one gets selected.
 */
const ProjectRedirectPage = () => {
  const location = useLocation();
  const { translate } = useLocalisation();

  const [currentProject] = useLocalStorage<Scenario | undefined>(
    'currentProject',
    undefined
  );

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { setCurrentScenario } = useProjects();

  useEffect(() => {
    if (currentProject) setCurrentScenario(currentProject);
  }, [currentProject, setCurrentScenario]);

  if (!currentProject) {
    return (
      <div>
        <ManageScenarioModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={translate('datacosmos.projectsDialog.selectProject')}
          canEscapeKeyClose={false}
          isCloseButtonShown={false}
          fromProjectRedirect={true}
          canOutsideClickClose={false}
        />
      </div>
    );
  }

  return (
    <Redirect
      from={location.pathname}
      to={{
        pathname: rerouteHandler(currentProject).pathname,
        search: rerouteHandler(currentProject).search,
      }}
      exact
    />
  );
};

export default ProjectRedirectPage;
