import React from "react";
import type { SliderProps, SliderThumbProps } from "react-aria-components";
import {
  Slider,
  SliderStateContext,
  SliderTrack,
  SliderThumb as RACSliderThumb,
} from "react-aria-components";
import { useEffect, useContext } from "react";
import classNames from "classnames";
import { Label } from "..";

interface MySliderProps<T> extends SliderProps<T> {
  label?: string;
  thumbLabels?: string[];
  children:
    | ReturnType<typeof SliderThumb>[]
    | ReturnType<typeof SliderThumb>[][]
    | ReturnType<typeof SliderThumb>;
  labelRenderer?: (value: number) => React.ReactNode;
  isReadOnly?: boolean;
}

function MultiSlider<T extends number | number[]>({
  label,
  thumbLabels,
  children,
  labelRenderer,
  isReadOnly,
  ...props
}: MySliderProps<T>) {
  const thumbs = Array.isArray(children) ? children : [children];
  const thumbsSortedByValue = [...thumbs.flat()].sort(
    (a, b) => a.props.value - b.props.value
  );

  return (
    <Slider
      {...props}
      value={
        isReadOnly
          ? (thumbsSortedByValue.map((t) => t.props.value) as T)
          : undefined
      }
    >
      <SliderTrack className={"w-full h-7 relative"}>
        {thumbsSortedByValue.map((c, i) => {
          return (
            <ThumbContainer
              thumbs={thumbsSortedByValue}
              labelRenderer={labelRenderer}
            >
              {React.cloneElement<ThumbProps>(c, {
                key: i,
                index: i,
                style: { zIndex: 100 + i },
              })}
            </ThumbContainer>
          );
        })}
        <div className="bg-item-contrast-inactive dark:bg-item-dark-contrast-inactive w-full absolute h-1" />
      </SliderTrack>
    </Slider>
  );
}

type CustomThumb = {
  value: number;
  trackIntentBefore?: "primary" | "success" | "warning" | "none";
};

type ThumbProps = SliderThumbProps & CustomThumb;

export function SliderThumb(props: ThumbProps) {
  return (
    <RACSliderThumb
      {...props}
      className={
        "rounded-full h-[12px] w-[12px] bg-item-dark dark:bg-item-dark-contrast top-[0.1rem] cursor-pointer"
      }
    />
  );
}

const ThumbContainer = ({
  children,
  labelRenderer,
  thumbs,
}: {
  children: ReturnType<typeof SliderThumb>;
  labelRenderer?: (value: number) => React.ReactNode;
  thumbs: ReturnType<typeof SliderThumb>[];
}) => {
  const context = useContext(SliderStateContext);

  const getSliderTrackWidthPercent = (index: number) => {
    if (thumbs.length === 1) {
      return context.getThumbPercent(0) * 100 + "%";
    }

    return (
      (context.getThumbPercent(index) - context.getThumbPercent(index - 1)) *
        100 +
      "%"
    );
  };

  const getSliderTrackLeft = (index: number) => {
    if (thumbs.length === 1) {
      return 0;
    }

    return context.getThumbPercent(index - 1) * 100 + "%";
  };

  useEffect(() => {
    context.setThumbValue(children.props.index, children.props.value);
  }, []);

  return (
    <>
      {React.Children.map(children, (c) => {
        const props = c.props as ThumbProps;
        return (
          <>
            <div
              className={classNames("z-50 h-1 absolute", {
                "bg-warning": props.trackIntentBefore === "warning",
                "bg-accent": props.trackIntentBefore === "primary",
                "bg-success": props.trackIntentBefore === "success",
                "bg-item-contrast-inactive dark:bg-item-dark-contrast":
                  props.trackIntentBefore === undefined ||
                  props.trackIntentBefore === "none",
              })}
              style={{
                width: getSliderTrackWidthPercent(c.props.index),
                left: getSliderTrackLeft(c.props.index),
              }}
            />
            <Label
              key={props.index}
              className="absolute text-xs"
              style={{
                left: `${context.getThumbPercent(c.props.index) * 100}%`,
                transform: "translateX(-50%) translateY(100%)",
              }}
            >
              {labelRenderer
                ? labelRenderer(context.values[c.props.index])
                : context.values[c.props.index]}
            </Label>
          </>
        );
      })}
      {children}
    </>
  );
};

export default MultiSlider;
