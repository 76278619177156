import { Icon as BlueprintIcon } from '@blueprintjs/core';
import { IconNames as BlueprintIconNames } from '@blueprintjs/icons';

import AddAoi from 'images/datacosmos/icons/AOI.svg?raw';
import Angle from 'images/datacosmos/icons/Angle.svg?raw';
import Apps from 'images/datacosmos/icons/Apps.svg?raw';
import Area from 'images/datacosmos/icons/Area.svg?raw';
import ArrowLeft from 'images/datacosmos/icons/Arrow_left.svg?raw';
import BandAlgebra from 'images/datacosmos/icons/Band_algebra.svg?raw';
import Brightness from 'images/datacosmos/icons/Brightness.svg?raw';
import Calculate from 'images/datacosmos/icons/Calculate.svg?raw';
import Calculator from 'images/datacosmos/icons/Calculator.svg?raw';
import CalculatorStrikethrough from 'images/datacosmos/icons/CalculatorStrikethrough.svg?raw';
import Calendar from 'images/datacosmos/icons/Calendar.svg?raw';
import Catalog from 'images/datacosmos/icons/Search_catalog.svg?raw';
import Center from 'images/datacosmos/icons/Center.svg?raw';
import ChevronDown from 'images/datacosmos/icons/Chevron_down.svg?raw';
import ChevronLeft from 'images/datacosmos/icons/Chevron_left.svg?raw';
import ChevronRight from 'images/datacosmos/icons/Chevron_right.svg?raw';
import ChevronUp from 'images/datacosmos/icons/Chevron_up.svg?raw';
import Clock from 'images/datacosmos/icons/Clock.svg?raw';
import Close from 'images/datacosmos/icons/Close.svg?raw';
import Cloud from 'images/datacosmos/icons/12px/Cloud.svg?raw';
import Collapse from 'images/datacosmos/icons/Collapse.svg?raw';
import Coverage from 'images/datacosmos/icons/Coverage.svg?raw';
import Edit from 'images/datacosmos/icons/Edit.svg?raw';
import Expand from 'images/datacosmos/icons/Expand.svg?raw';
import Eye from 'images/datacosmos/icons/Eye.svg?raw';
import EyeClosed from 'images/datacosmos/icons/Eye_closed.svg?raw';
import Features from 'images/datacosmos/icons/Features.svg?raw';
import File from 'images/datacosmos/icons/12px/File_text-1.svg?raw';
import FileTxt from 'images/datacosmos/icons/12px/File_text.svg?raw';
import Filter from 'images/datacosmos/icons/Filter.svg?raw';
import Folder from 'images/datacosmos/icons/Folder.svg?raw';
import Glint from 'images/datacosmos/icons/Glint.svg?raw';
import Grid from 'images/datacosmos/icons/Grid.svg?raw';
import HiRes from 'images/datacosmos/icons/HR.svg?raw';
import HiResAdded from 'images/datacosmos/icons/HR_added.svg?raw';
import HiResAdded_Light from 'images/datacosmos/icons/HR_added_light.svg?raw';
import Info from 'images/datacosmos/icons/Info.svg?raw';
import Install from 'images/datacosmos/icons/Download.svg?raw';
import Layers from 'images/datacosmos/icons/Layers.svg?raw';
import List from 'images/datacosmos/icons/List.svg?raw';
import MagnifyingGlass from 'images/datacosmos/icons/Zoom.svg?raw';
import Map from 'images/datacosmos/icons/Map.svg?raw';
import Moon from 'images/datacosmos/icons/Moon.svg?raw';
import More from 'images/datacosmos/icons/12px/More.svg?raw';
import Pin from 'images/datacosmos/icons/12px/Pin.svg?raw';
import Plus from 'images/datacosmos/icons/Plus.svg?raw';
import PlusSquare from 'images/datacosmos/icons/Add_layer.svg?raw';
import RollAngle from 'images/datacosmos/icons/RollAngle.svg?raw';
import Satellite from 'images/datacosmos/icons/12px/Satellite.svg?raw';
import Save from 'images/datacosmos/icons/Save.svg?raw';
import SensorType from 'images/datacosmos/icons/SensorType.svg?raw';
import Shop from 'images/datacosmos/icons/Shop.svg?raw';
import SingleSwath from 'images/datacosmos/icons/SingleSwath.svg?raw';
import StacAsset from 'images/datacosmos/icons/StacAsset.svg?raw';
import Sun from 'images/datacosmos/icons/Sun.svg?raw';
import SunAngle from 'images/datacosmos/icons/SunAngle.svg?raw';
import Swath from 'images/datacosmos/icons/Swath.svg?raw';
import Target from 'images/datacosmos/icons/Center_world.svg?raw';
import Tasking from 'images/datacosmos/icons/Tasking.svg?raw';
import Trash from 'images/datacosmos/icons/Trash.svg?raw';
import Uninstall from 'images/datacosmos/icons/Uninstall.svg?raw';
import Upload from 'images/datacosmos/icons/Upload.svg?raw';
import Saturation from 'images/datacosmos/icons/Saturation.svg?raw';
import Contrast from 'images/datacosmos/icons/Contrast.svg?raw';
import AddToCart from 'images/datacosmos/icons/Add-to-cart.svg?raw';
import InCart from 'images/datacosmos/icons/Added-to-cart.svg?raw';
import AddToProject from 'images/datacosmos/icons/Add-to-project.svg?raw';
import InProject from 'images/datacosmos/icons/Added-to-project.svg?raw';
import RemoveFromProject from 'images/datacosmos/icons/Remove-from-project.svg?raw';
import Price from 'images/datacosmos/icons/Price.svg?raw';
import ShareDots from 'images/datacosmos/icons/Share.svg?raw';

const icons = {
  AddAoi,
  Angle,
  Apps,
  Area,
  ArrowLeft,
  BandAlgebra,
  Brightness,
  Calculate,
  Calculator,
  CalculatorStrikethrough,
  Calendar,
  Catalog,
  Center,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clock,
  Close,
  Cloud,
  Collapse,
  Coverage,
  Edit,
  Expand,
  Eye,
  EyeClosed,
  Features,
  File,
  FileTxt,
  Filter,
  Folder,
  Glint,
  Grid,
  HiRes,
  HiResAdded_Light,
  HiResAdded,
  Info,
  Install,
  Layers,
  List,
  MagnifyingGlass,
  Map,
  Moon,
  More,
  Pin,
  Plus,
  PlusSquare,
  RollAngle,
  Satellite,
  Save,
  SensorType,
  Shop,
  SingleSwath,
  StacAsset,
  Sun,
  SunAngle,
  Swath,
  Target,
  Tasking,
  Trash,
  Uninstall,
  Upload,
  Saturation,
  Contrast,
  AddToCart,
  InCart,
  AddToProject,
  InProject,
  RemoveFromProject,
  Price,
  ShareDots,
};

export type IconName = keyof typeof icons | keyof typeof BlueprintIconNames;

export type IconSize = 8 | 12 | 16 | 20 | 24 | 32 | 64;

const defaultSize: IconSize = 16;

export type IconProps = {
  icon: IconName;
  size?: IconSize;
  className?: string;
  stroke?: string;
  inherit?: boolean;
  title?: string;
};

export const Icon = (props: IconProps) => {
  const size = props.size ?? defaultSize;
  const style = {
    minWidth: size,
    width: size,
    maxWidth: size,
    minHeight: size,
    height: size,
    maxHeight: size,
  };
  const svgData = icons[props.icon as keyof typeof icons];

  if ((svgData as typeof svgData | undefined) === undefined) {
    return (
      <BlueprintIcon
        title={props.title}
        htmlTitle={props.title}
        className={props.className}
        icon={BlueprintIconNames[props.icon as keyof typeof BlueprintIconNames]}
        color={props.stroke}
        style={style}
        size={props.size}
      />
    );
  }

  return (
    <svg
      style={style}
      className={props.className}
      stroke={props.stroke}
      aria-label={props.title}
      dangerouslySetInnerHTML={{ __html: svgData }}
    />
  );
};

export default Icon;
