/*
  Important info:
  - Input to the component must start without a slash
  - Files are separated by a single '/', handle OS-specific
    formatting outside of the component
  - All files need to be appended with a '/' (eg 'home/')
*/

import { memo } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { NonIdealState } from '@blueprintjs/core';

import FileExplorer from 'pages/ops/RTI/Operate/components/FileExplorer';
import SpinnerWithButton from 'components/spinners/SpinnerWithButton';

import type { IFileExplorerContextInput } from '../../context/FileExplorerProvider';
import { FileExplorerProvider } from '../../context/FileExplorerProvider';

const LibraryFileExplorer = (props: IFileExplorerContextInput) => {
  const {
    errorMessage,
    folderContent,
    fileContent,
    isFetching,
    putContent,
    deleteContent,
    getContent,
    contentPath,
    cancelRequest,
  } = props;

  if (isFetching)
    return (
      <SpinnerWithButton
        buttonText="Cancel"
        onButtonClick={() => {
          cancelRequest!();
        }}
      />
    );

  if (!folderContent)
    return (
      <NonIdealState
        icon={IconNames.FOLDER_OPEN}
        title="This storage is empty"
      />
    );

  return (
    <FileExplorer
      errorMessage={errorMessage}
      rootItem={props.rootItemName}
      simplifiedExplorer={props.simplifiedExplorer && !contentPath}
      currentPath={contentPath}
      fileContent={fileContent}
      acceptedDropType={props.acceptedDropType}
      disableDropzone={putContent === undefined}
      handleDeleteContent={deleteContent}
      handleReloadClick={getContent}
      folderContent={folderContent}
    />
  );
};

const LibraryFileExplorerWrapper = (props: IFileExplorerContextInput) => {
  return (
    <FileExplorerProvider {...props}>
      <LibraryFileExplorer {...props} />
    </FileExplorerProvider>
  );
};

export default memo(LibraryFileExplorerWrapper);
