import { useState } from 'react';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import type { IApplication } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { isEmpty } from 'lodash';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import type { Layer } from 'datacosmos/entities/layer';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import NumberInput from '_atoms/NumberInput/NumberInput';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';

const DATACOSMOS_IMG = '/images/datacosmos/';

interface IProps {
  app: IApplication;
}

/**
 * Fields
 */
const shadowLengthKey = 'shadowLength';
const sunZenithAngleKey = 'sunZenithAngle';

/**
 * Measure height app initial state.
 */
export const initialApplication: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Calculate object height by shadow',
  description:
    'Measure the height of an object given the length of its shadow together with the sun zenith angle associated with the image in which it appears',
  inputs: [
    {
      example: '50',
      field: 'shadowLength',
    },
    {
      example: '10.2',
      field: 'sunZenithAngle',
    },
  ],
  values: {
    shadowLength: { value: '0', isError: false, message: '' },
    sunZenithAngle: { value: '0', isError: false, message: '' },
  },
  provider: {
    id: 1,
    name: 'Open Cosmos',
    description: 'Making space accessible',
    url: 'https://www.open-cosmos.com',
    icon_url:
      'https://storage.googleapis.com/datacosmos-public/manual_outputs/AG/OpenCosmos.svg',
  },
  shortDescription:
    'Measure the height of an object given the length of its shadow',
  renderer: (app: IApplication) => <MeasureHeight app={app} />,
  appScreenshotUrl: DATACOSMOS_IMG + 'measure_height_screenshot.png',
  tags: [AppTags.measurement],
};

/**
 * MeasureHeight application renderer
 */
export function MeasureHeight({ app }: IProps) {
  const { layers } = useMapLayers();
  const { measure } = useMap();
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();
  const { translate } = useLocalisation();

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);

  const { activePage, setActivePage } = useActivePage();

  const sunZenithAngle = parseFloat(app.values.sunZenithAngle.value as string);
  const shadowLength = parseFloat(app.values.shadowLength.value as string);

  const stacLayer = layers.filter(
    (l) =>
      l instanceof OutlineLayer ||
      l instanceof SingleBandSTACLayer ||
      l instanceof BandAlgebraSTACLayer
  );

  const calculateObjectHeight = () => {
    const sunZenithAngleRad = (Math.PI * sunZenithAngle) / 180;
    const perpendicularToSunZenithAngleRad = Math.PI / 2 - sunZenithAngleRad;
    const height =
      shadowLength *
      (Math.sin(perpendicularToSunZenithAngleRad) /
        Math.sin(sunZenithAngleRad));

    if (isNaN(height)) {
      return undefined;
    } else {
      return parseFloat(height.toFixed(2));
    }
  };

  const setValue = (key: string, value: string | number | undefined) => {
    setInputData(app.name, {
      ...app.values,
      [key]: {
        value: value === undefined ? '' : `${value}`,
        isError: false,
        message: '',
      },
    });
  };

  const setError = (key: string, message: string) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value: '', isError: true, message: message },
    });
  };

  const safelySetNumber = (key: string, number: number) => {
    if (isNaN(number)) {
      setValue(key, undefined);
    } else {
      setValue(key, number);
    }
  };

  const setSZAFromTopSTACLayer = (topSTACLayers: Layer[]) => {
    if (!isEmpty(topSTACLayers) && topSTACLayers[0].sza()) {
      const withTwoDP = Number(topSTACLayers[0].sza()).toFixed(2);
      safelySetNumber(sunZenithAngleKey, Number(withTwoDP));
    } else {
      setError(
        sunZenithAngleKey,
        'SZA for top layer is not set - please enter a value manually'
      );
    }
  };

  const inputs = () => {
    return (
      <div className="flex flex-col gap-2">
        <div>
          <NumberInput
            label="Shadow length (meters)"
            fill
            placeholder="Shadow length"
            onChange={(val) => {
              safelySetNumber(shadowLengthKey, val);
            }}
            value={shadowLength}
          />
        </div>
        <div>
          <NumberInput
            placeholder="Shadow length"
            label="Sun-Zenith angle (deg)"
            fill
            onChange={(val) => {
              safelySetNumber(sunZenithAngleKey, val);
            }}
            value={sunZenithAngle}
          />
        </div>
        {app.values[sunZenithAngleKey].isError && (
          <div>
            <small style={{ color: '#ff0000' }}>
              {app.values[sunZenithAngleKey].message}
            </small>
          </div>
        )}
        <div>
          <NumberInput
            placeholder="Height of the object"
            value={calculateObjectHeight()}
            isDisabled={true}
            fill
            label="Height of the object (meters)"
          />
        </div>
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          const distance = await measure(false);
          const rounded = Math.round(distance * 10) / 10;
          safelySetNumber(shadowLengthKey, rounded);
          setSZAFromTopSTACLayer(stacLayer);
        }}
        submitButtonLabel={translate(
          'datacosmos.applications.global.buttons.measure'
        )}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(selectedApp) => {
        setSelectedInstalledApp(selectedApp);
        if (activePage === 'application') {
          setActivePage(undefined);
        }
      }}
    />
  );
}
