import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'pages/msd/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import { resetFocus } from 'pages/msd/shared/state/actions/focus/thunks';
import { setPolygonSpecify } from '../map/events';
import type { IMapWrapper } from 'declarations/mapDeclarations/Map';
import type { IContextMenu } from '../map/services';
import AddressField from 'components/inputField/AddressField';
import { fetchRI } from 'pages/msd/shared/state/actions/regionsOfInterest/thunks';
import {
  removeRI,
  updateRI,
} from 'pages/msd/shared/state/actions/regionsOfInterest/helpers';
import {
  resetPopUp,
  setPopUp,
} from 'pages/msd/shared/state/actions/popUp/thunks';
import type { IOSMFetchResult } from 'declarations/osm';
import PolygonForm from 'components/popUp/PolygonForm';
import type { IRegionOfInterest } from 'constants/regionsOfInterest/actionTypes';
import { ASTRUMS } from 'constants/API/constant';
import { FocusTypes } from 'constants/focus/actionTypes';

export const getAddRegion =
  (
    map: IMapWrapper
  ): ThunkAction<IContextMenu, AppState, null, Action<string>> =>
  (dispatch, store): IContextMenu => {
    const astrum = store().astrum['current'];
    const fetch = {
      text: 'Fetch',
      icon: 'search',
      clickEvent: (): void => {
        dispatch(
          setPopUp({
            classNames: ['short-container'],
            component: AddressField,
            visible: true,
            data: {},
            otherProps: {
              type: 'Region',
              secondProps: {
                component: PolygonForm,
                visible: true,
                classNames: ['short-container'],
                data: {},
                otherProps: {
                  type: FocusTypes.RI_SUB_ID,
                },
                functions: {
                  onSubmit: (regionOfInterest: IRegionOfInterest): void => {
                    dispatch(updateRI(regionOfInterest));
                  },
                  onClose: (regionOfInterest: IRegionOfInterest): void =>
                    dispatch(removeRI(regionOfInterest.id)),
                  addRegion: (): void => {
                    map.drawingManager.setDrawingMode(
                      google.maps.drawing.OverlayType.POLYGON
                    );
                  },
                },
              },
            },
            functions: {
              onSubmit: (result: IOSMFetchResult, id?: string) =>
                dispatch(fetchRI(result, map.drawingManager, false, id)),
              onClose: (): void => dispatch(resetPopUp()),
            },
          })
        );
      },
    };

    const contextMenu = {
      text: 'Add region',
      subItems: [
        {
          text: 'Draw',
          clickEvent: (): void => {
            dispatch(resetFocus());
            map.setDrawingPolygonMod();
          },
          icon: 'draw',
        },
        {
          text: 'Specify',
          clickEvent: (): void => {
            dispatch(resetFocus());
            dispatch(setPolygonSpecify(map.drawingManager));
          },
          icon: 'geolocation',
        },
      ],
    };
    if (astrum.planet === ASTRUMS.EARTH) {
      contextMenu.subItems.push(fetch);
    }
    return contextMenu;
  };
