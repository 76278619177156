import { NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Button from '_molecules/Button/Button';
import RequestCard from './RequestCard';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { useTaskingRequestList } from './useTaskingRequestList';
import ActivityCard from './ActivityCard';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';

type RequestsListProps = {
  setIsRequestListOpen: (value: boolean) => void;
  taskingRequestsHook: ReturnType<typeof useTaskingRequestList>;
  showRequestIDs: boolean;
};

export const RequestsList = ({
  setIsRequestListOpen,
  taskingRequestsHook,
  showRequestIDs,
}: RequestsListProps) => {
  const { translate } = useLocalisation();

  return (
    <>
      {taskingRequestsHook.taskingRequests?.length > 0 ? (
        <div className="text-item-contrast dark:text-item-dark-contrast flex flex-col items-center h-full content-center">
          <div className="flex flex-col gap-3 w-full pb-4">
            {taskingRequestsHook.taskingRequests?.map((request) => (
              <RequestCard
                key={request.id}
                request={request}
                toggleDisplayAoIOnMap={
                  taskingRequestsHook.toggleDisplayAoIOnMap
                }
                toggleDisplayAllRequestSwathsOnMap={
                  taskingRequestsHook.toggleDisplayAllRequestSwathsOnMap
                }
                shownAoiLayer={taskingRequestsHook.aoiLayersDisplayed.find(
                  (l) => l.metadata.id === request.id
                )}
                shownActivitySwathsLayers={taskingRequestsHook.swathLayersDisplayed.filter(
                  (l) => request.activities.some((a) => a.id === l.metadata.id)
                )}
                showRequestIDs={showRequestIDs}
                refetchRequests={taskingRequestsHook.refetch}
              >
                {request.activities.map((activity) => (
                  <ActivityCard
                    key={activity.id}
                    activity={activity}
                    request={request}
                    toggleDisplayAoIOnMap={
                      taskingRequestsHook.toggleDisplayAoIOnMap
                    }
                    toggleDisplayActivitySwathOnMap={
                      taskingRequestsHook.toggleDisplayActivitySwathOnMap
                    }
                    shownAoiLayer={taskingRequestsHook.aoiLayersDisplayed.find(
                      (l) => l.metadata.id === request.id
                    )}
                    shownActivitySwathsLayer={taskingRequestsHook.swathLayersDisplayed.find(
                      (l) => activity.id === l.metadata.id
                    )}
                    updateSwathLayer={taskingRequestsHook.updateSwathLayer}
                    refetchRequests={taskingRequestsHook.refetch}
                    removeSwathEdits={taskingRequestsHook.removeSwathEdits}
                  />
                ))}
                <Button
                  text={translate(
                    'datacosmos.tasking.existing.newRequestFromExisting'
                  )}
                  className="bg-surface dark:bg-surface-dark"
                  onPress={() => {
                    taskingRequestsHook.showAoIAsTaskingRegion(request);
                    setIsRequestListOpen(false);
                  }}
                />
              </RequestCard>
            ))}
          </div>
          <Button
            icon="Plus"
            size={24}
            text={translate(
              'datacosmos.tasking.existing.createNewTaskingRequest'
            )}
            fill
            onPress={() => setIsRequestListOpen(false)}
            className="text-start max-h-16 h-16 p-1 mb-5"
          />
        </div>
      ) : (
        !taskingRequestsHook.loading && (
          <div className="flex items-center w-full content-center flex-grow">
            <NonIdealState
              icon={IconNames.SEARCH}
              title={translate(
                'datacosmos.tasking.existing.errors.noRequests.title'
              )}
              description={translate(
                'datacosmos.tasking.existing.errors.noRequests.description'
              )}
              action={
                <Button
                  text={translate(
                    'datacosmos.tasking.existing.errors.newRequestBtn'
                  )}
                  onPress={() => setIsRequestListOpen(false)}
                />
              }
            />
          </div>
        )
      )}

      {taskingRequestsHook.loading ? (
        <div className="flex justify-center">
          <Spinner size={100} />
        </div>
      ) : null}
    </>
  );
};
