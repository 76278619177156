import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../pages/msd/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IGroundStation } from '../../../constants/groundStations/actionTypes';
import { setFocus } from '../../../pages/msd/shared/state/actions/focus/thunks';
import { updateGS } from '../../../pages/msd/shared/state/actions/groundStations/thunks';
import type { IMarkerWrapper } from '../../../declarations/mapDeclarations/Marker';
import { toaster } from 'toaster';
import { FocusTypes } from '../../../constants/focus/actionTypes';

export const setFocusGroundStation =
  (
    groundStationData: IGroundStation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(
      setFocus({
        type: FocusTypes.GS_SUB_ID,
        id: groundStationData.id,
        needScroll: true,
      })
    );
  };
export const switchSelectedGroundStation =
  (
    groundStation: IMarkerWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const groundStationData = state().groundStations.list.find(
      (gs): boolean => gs.id === groundStation.id
    );
    const isConstraints = state().constraints.constraints;
    if (isConstraints) {
      const message = 'Constraints table is blocked!';
      toaster.show({ icon: 'delete', intent: 'danger', message });
    } else {
      const { select } = groundStation;
      groundStation.select = !select;
      dispatch(updateGS({ ...groundStationData, select: !select }));
    }
  };
