import React, { useCallback } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { isEmpty, isNumber, toNumber } from 'lodash/fp';
import s from '../index.module.scss';
import { Button, Icon, NumberInput } from 'opencosmos-ui';

export interface IProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  meta?: string;
  fullPath: string;
  value: any;
  disabled: boolean;
  path?: string;
  showInfoMark?: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  validateChange: Function;
  handleMetaButtonClick: ((value: string) => void) | undefined;
  setDefaultValue: Function;
  setTooltipOpen: (open: boolean) => void;
}

const CommandPrimitiveNumber = (props: IProps) => {
  const {
    inputRef,
    className,
    value,
    onChange,
    fullPath,
    disabled,
    validateChange,
    handleBlur,
    handleFocus,
    path,
    meta,
    showInfoMark,
    handleMetaButtonClick,
    setDefaultValue,
    setTooltipOpen,
  } = props;

  setDefaultValue();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateChange(e);
    const { value } = e.target;
    const valueForSave = !isEmpty(value) ? toNumber(value) : value;
    onChange(valueForSave, fullPath);
  };

  const handleArrowNumberInputChange = (value: number) => {
    const fakeEvent: any = {
      target: {
        value,
        id: path,
        name: path,
      },
    };

    validateChange(fakeEvent);
    onChange(value, fullPath);
  };

  const handleMetaClick = useCallback(
    () => handleMetaButtonClick && meta && handleMetaButtonClick(meta),
    [meta]
  );

  const infoIconStyles = meta
    ? [s.iconWithInput, s.iconWithInputExplore].join(' ')
    : [s.iconWithInput, s.iconWithInputNumber].join(' ');

  return (
    <div className="flex w-full items-center gap-2">
      <NumberInput
        // ref={inputRef}
        fill
        className={className}
        isDisabled={disabled}
        onBlur={handleBlur}
        onMouseOver={() => {
          handleFocus();
        }}
        onMouseLeave={() => {
          setTooltipOpen(false);
        }}
        value={isNumber(value) ? value : undefined}
        onChange={(val: number) => {
          handleInputChange({
            target: { value: String(val) },
          } as React.ChangeEvent<HTMLInputElement>);
        }}
        data-testid="number-input"
      />
      {showInfoMark && (
        <Icon className={infoIconStyles} icon={IconNames.INFO_SIGN} />
      )}
      {meta && (
        <Button isDisabled={disabled} onPress={handleMetaClick}>
          Explore
        </Button>
      )}
    </div>
  );
};

export default CommandPrimitiveNumber;
