import React, { useState, useEffect, useCallback, useRef } from 'react';
import { assoc, find, isString } from 'lodash/fp';
import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import type {
  ICommandPileItem,
  MessageCallback,
} from 'services/api/ops/realtimeTerminalSession';

import {
  commandChangeDone,
  historySetCurrentCommand,
  setCommandCustomName,
  setCommandPayload,
  setCurrentlyEditingCommandPayload,
} from 'actions/operate/actions';
import BottomNavBar from 'pages/ops/Library/components/BottomNavBar';
import {
  PARAMETERS_RIGHT_TAB_STATE,
  REPLIES_RIGHT_TAB_STATE,
  OPERATE_RIGHT_BOTTOM_NAVBAR_TABS,
} from 'constants/ops/rti/oparate/constants';
import type {
  CommandHistoryPayload,
  ICommandHistory,
} from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import { TABLET_WIDTH } from 'constants/screen';

import RepliesInfo from './RepliesInfo';
import SideWrapper from './SideWrapper';
import Parameters from './Parameters';
import Metadata from './Metadata';

import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Button, TextField } from 'opencosmos-ui';

export const CUSTOM_COMMAND_FIELD_NAME = 'custom-command-name-input';

export interface IProps {
  isSideHidden: boolean;
  handleSideHiding: (value: boolean) => void;
  updateWorkspace?: (
    commandPile: object,
    armedCommand: object | undefined,
    isFrozen: boolean | undefined,
    cb?: MessageCallback | undefined
  ) => {
    sequenceId: number;
  };
  commandHistory: ICommandHistory[];
  replaceCommandNote: (commandUUID: string | number, note: string) => void;
}

const Variables = ({ commandHistory, updateWorkspace, ...props }: IProps) => {
  const {
    dispatch,
    state: {
      selectedCommand,
      commandPayload,
      commandCustomName,
      selectedPileItem,
      armedCommand,
      commandPileWorkingSpace,
      isCommandPreview,
      currentHistoryResponse,
      historyCommandPayload,
      historySelectedCommand,
      frozenPile,
      selectedMultiplePileItems,
    },
  } = useOperate();

  const { sendInfo } = useAnalytics();

  const [selectedTab, setSelectedTab] = useState(PARAMETERS_RIGHT_TAB_STATE);
  const [hasAnyParameterBlurred, setParameterBlur] = useState(false);
  const [currentCommandCustomName, setCurrentCommandCustomName] =
    useState(commandCustomName);
  const currentPreviewCommand = historySelectedCommand ?? selectedCommand;

  const sequenceId = currentHistoryResponse?.sequenceId;
  const hadCurrentHistoryResponse = Boolean(currentHistoryResponse);
  const responsesContentLength =
    currentHistoryResponse?.responsesContent.length;

  useEffect(() => {
    if (!hadCurrentHistoryResponse) return;
    const newHistory = find(['sequenceId', sequenceId], commandHistory);
    if (
      newHistory &&
      responsesContentLength !== newHistory.responsesContent.length
    ) {
      dispatch(historySetCurrentCommand(newHistory));
      setSelectedTab(REPLIES_RIGHT_TAB_STATE);
    }
  }, [
    commandHistory,
    dispatch,
    hadCurrentHistoryResponse,
    responsesContentLength,
    sequenceId,
  ]);

  const replaceCommandNote = (note: string) => {
    if (!currentHistoryResponse) return;
    props.replaceCommandNote(currentHistoryResponse.sequenceId, note);
  };

  useEffect(() => {
    setParameterBlur(false);

    if (
      selectedPileItem &&
      hasAnyParameterBlurred &&
      commandPileWorkingSpace &&
      selectedCommand
    ) {
      const oldIndex = commandPileWorkingSpace.findIndex(
        ({ id }) => id === selectedPileItem.id
      );

      const pileItem: ICommandPileItem = {
        command: selectedCommand.name ?? '',
        payload: commandPayload as CommandHistoryPayload,
        id: selectedPileItem.id,
        customName: currentCommandCustomName,
        arguments: undefined,
        procedureName: null,
      };

      const newPile: ICommandPileItem[] = [
        ...commandPileWorkingSpace.slice(0, oldIndex),
        pileItem,
      ];

      if (oldIndex + 1 < commandPileWorkingSpace.length) {
        commandPileWorkingSpace.slice(oldIndex + 1).forEach((item) => {
          newPile.push(item);
        });
      }

      updateWorkspace?.(newPile, armedCommand, frozenPile);
    }
  }, [
    armedCommand,
    commandPayload,
    commandPileWorkingSpace,
    currentCommandCustomName,
    frozenPile,
    hasAnyParameterBlurred,
    selectedCommand,
    selectedPileItem,
    updateWorkspace,
  ]);

  useEffect(() => {
    setParameterBlur(false);
  }, []);

  useEffect(() => {
    setCurrentCommandCustomName(commandCustomName);
  }, [commandCustomName]);

  useEffect(() => {
    if (!currentHistoryResponse) {
      setSelectedTab(PARAMETERS_RIGHT_TAB_STATE);
    }
  }, [currentHistoryResponse]);

  const handleTabChange = (tab: string) => {
    sendInfo({
      type: `Changed to tab ${tab} in command parameters`,
      action: 'Click',
      item: 'Tab change button',
      module: 'OPS',
      additionalParams: {
        clickedTab: tab,
      },
    });
    if (selectedTab === tab) {
      sendInfo({
        type: `Hidden tab ${tab} in command parameters`,
        action: 'Click',
        item: 'Tab change button',
        module: 'OPS',
        additionalParams: {
          clickedTab: tab,
        },
      });
      props.handleSideHiding(true);
      return;
    }

    setSelectedTab(tab);
  };

  const handleHiddenSideClick = (tab: string) => {
    sendInfo({
      type: `Clicked hidden side tab ${tab} in command parameters`,
      action: 'Click',
      item: 'Tab change button',
      module: 'OPS',
    });
    props.handleSideHiding(false);
    setSelectedTab(tab);
  };

  const setCommandChangeDone = useCallback(() => {
    historySelectedCommand ?? dispatch(commandChangeDone());
  }, [dispatch, historySelectedCommand]);

  const handleCommandBlur = useCallback(() => {
    if (selectedPileItem) {
      setParameterBlur(true);
    }
  }, [selectedPileItem]);

  const payloadChangeTimeout = useRef<NodeJS.Timeout | null>(null);
  const debouncedHandleCommandPayloadChange = useCallback(
    (path: string, value: unknown) => {
      const payload = assoc(
        path,
        value,
        commandPayload
      ) as CommandHistoryPayload;
      dispatch(setCommandPayload(payload));
      !selectedPileItem && dispatch(setCurrentlyEditingCommandPayload(payload));

      if (payloadChangeTimeout.current) {
        clearTimeout(payloadChangeTimeout.current);
      }

      payloadChangeTimeout.current = setTimeout(() => {
        handleCommandBlur();
      }, 400);
    },
    [commandPayload, dispatch, handleCommandBlur, selectedPileItem]
  );

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const debouncedHandleCommandCustomNameChange = useCallback(
    (name: string) => {
      return () => {
        setCurrentCommandCustomName(name);
        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
          dispatch(setCommandCustomName(name));
          handleCommandBlur();
        }, 400);
      };
    },
    [dispatch, handleCommandBlur]
  );

  if (props.isSideHidden) {
    return (
      <div className="flex flex-col gap-2">
        <Button
          isMinimal={true}
          icon="manually-entered-data"
          onPress={() => handleHiddenSideClick(PARAMETERS_RIGHT_TAB_STATE)}
        />
        <Button
          isMinimal={true}
          icon="chat"
          onPress={() => handleHiddenSideClick(REPLIES_RIGHT_TAB_STATE)}
        />
      </div>
    );
  }

  const getParametersIcon = () => {
    if (selectedPileItem) {
      return 'key-command';
    }

    if (isCommandPreview) {
      return 'locate';
    }

    return 'new-object';
  };

  const renderTab = () => {
    switch (selectedTab) {
      case 'parameters':
        return (
          <SideWrapper
            title={currentPreviewCommand?.name ?? 'No command'}
            icon={getParametersIcon()}
          >
            {selectedCommand?.dangerous && (
              <Callout
                icon={IconNames.WARNING_SIGN}
                intent={Intent.DANGER}
                style={{ borderRadius: 0 }}
              >
                {selectedCommand.dangerousMessage ??
                  'This command can be dangerous'}
              </Callout>
            )}
            {currentPreviewCommand && !selectedMultiplePileItems?.length && (
              <div className={s.commandCustomNameContainer}>
                <TextField
                  name={CUSTOM_COMMAND_FIELD_NAME}
                  fill={true}
                  label="Custom name"
                  isDisabled={isCommandPreview}
                  inputProps={{
                    placeholder: 'Name to use in the command pile (Optional)',
                  }}
                  value={
                    isString(currentCommandCustomName)
                      ? currentCommandCustomName
                      : ''
                  }
                  onChange={(n: string) => {
                    debouncedHandleCommandCustomNameChange(n)();
                  }}
                />
              </div>
            )}
            <Parameters
              selectedCommand={currentPreviewCommand}
              handleCommandPayloadChange={debouncedHandleCommandPayloadChange}
              handleCommandPayloadBlur={() => {}}
              commandPayload={
                (historyCommandPayload ??
                  commandPayload) as CommandHistoryPayload
              }
              setCommandChangeDone={setCommandChangeDone}
            />
          </SideWrapper>
        );
      case 'repliesInfo':
        return (
          <SideWrapper
            title={currentHistoryResponse?.command ?? 'No replies'}
            icon="history"
          >
            <RepliesInfo commandHistory={currentHistoryResponse} />
          </SideWrapper>
        );

      case 'metadata':
        return (
          <SideWrapper
            title={currentHistoryResponse?.command ?? 'No replies'}
            icon="annotation"
          >
            <Metadata
              replaceCommandNote={replaceCommandNote}
              currentHistoryResponse={commandHistory.find(
                (command) =>
                  command.sequenceId === currentHistoryResponse?.sequenceId
              )}
            />
          </SideWrapper>
        );
      default:
        return 'Tab not found';
    }
  };

  return (
    <div className={s.main}>
      <div
        data-testid="right-side-menu"
        className={`${s.content} select-text bg-surface dark:bg-surface-dark `}
      >
        {renderTab()}
      </div>
      <BottomNavBar
        iconOnlyOnWidth={TABLET_WIDTH}
        selectedTab={selectedTab}
        setSelectedTab={handleTabChange}
        tabList={OPERATE_RIGHT_BOTTOM_NAVBAR_TABS}
      />
    </div>
  );
};

export default Variables;
