import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { LineLayerFactory } from 'datacosmos/entities/lineLayer';
import type { LineString } from 'geojson';
import length from '@turf/length';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  drawPolyline: (options?: { color: string; weight: number }) => Promise<{
    polyline: GeoJSON.Feature<LineString>;
    polylineMetadata: unknown;
  }>;
  addLayer: (...newLayers: Layer[]) => void;
}

export const DrawPolyline = (props: IProps) => {
  const { translate } = useLocalisation();
  return (
    <MenuItem
      className="dark:hover:text-neutral hover:text-accent-900"
      text={translate('datacosmos.rightClickMenu.polyline')}
      icon={IconNames.Draw}
      onClick={async () => {
        const { polyline } = await props.drawPolyline();

        props.addLayer(
          LineLayerFactory(
            LayerSourceType.GEOMETRY_LAYER,
            translate('datacosmos.layers.names.polyline'),
            polyline,
            length(polyline, { units: 'meters' })
          )
        );
      }}
    />
  );
};
