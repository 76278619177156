import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../reducers/rootReducer';
import type { Action } from 'redux';
import { setFocusAction, resetFocusAction } from './action';
import type { IFocus } from '../../../../../../constants/focus/actionTypes';

export const setFocus =
  (focus: IFocus): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, store): Promise<void> => {
    const { focus: storeFocus } = store();
    if (storeFocus.withPopUp) {
      return undefined;
    }
    dispatch(setFocusAction(focus));
  };

export const resetFocus =
  (force = false): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, store): Promise<void> => {
    const { focus: storeFocus } = store();
    if (storeFocus.withPopUp && !force) {
      return undefined;
    }
    dispatch(resetFocusAction());
  };
