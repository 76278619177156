/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useCallback, useEffect, useState } from 'react';
import CommandArguments from 'pages/ops/RTI/Operate/components/CommandArguments';
import { NonIdealState } from '@blueprintjs/core';
import parseRefDefinitions from 'pages/ops/RTI/Operate/utils/rtiCommands/parseRefDefinitions';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import {
  setCommandPayload,
  setExploringStorageName,
  setSendCommandDisabled,
} from 'actions/operate/actions';
import { usePreviousState } from 'utils/hooks/usePreviousState';
import type { CommandHistoryPayload } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import type { CommandProperties } from 'api/gateway/types';
import type { CommandDefinition } from 'api/telecommands/types';

export interface IProps {
  selectedCommand?: CommandDefinition;
  commandPayload: object;
  handleCommandPayloadChange: (path: string, value: unknown) => void;
  handleCommandPayloadBlur: () => void;
  setCommandChangeDone: () => void;
}

const Parameters = ({
  selectedCommand,
  commandPayload,
  handleCommandPayloadChange,
  handleCommandPayloadBlur,
  setCommandChangeDone,
}: IProps) => {
  const {
    dispatch,
    state: {
      isCommandPreview,
      selectedPileItem,
      isCommandChanging,
      selectedMultiplePileItems,
    },
  } = useOperate();

  const [properties, setProperties] = useState<CommandProperties>();

  // Store previously clicked command pile item
  // for the purpose of purging previous command's non-string or non-number values
  const previousCmd = usePreviousState(selectedPileItem);

  /**
   * getPurgeCommandPayload purges previous command's payload
   * from all non-string, non-number values or empty objects
   * while leaving all non array values intact.
   */
  const getPurgedCommandPayload = useCallback(() => {
    if (!selectedPileItem?.payload) {
      return;
    }

    return Object.entries(selectedPileItem.payload).reduce((acc, curr) => {
      const k = curr[0];
      const v = curr[1];

      acc = { ...acc, [k]: v };

      if (Array.isArray(v)) {
        acc = {
          ...acc,
          [k]: v.filter((i) => {
            return (
              typeof i === 'string' ||
              typeof i === 'number' ||
              (typeof i === 'object' && Object.keys(i).length !== 0)
            );
          }),
        };
      }
      return acc;
    }, {});
  }, [selectedPileItem?.payload]);

  useEffect(() => {
    if (!selectedCommand) {
      return;
    }

    const schema = parseRefDefinitions(selectedCommand.schema);
    setProperties(schema);
    setCommandChangeDone();
  }, [selectedCommand, setCommandChangeDone]);

  useEffect(() => {
    if (!previousCmd || !selectedPileItem) {
      return;
    }

    // Check if previously selected command is different from currently selected command
    if (previousCmd?.id !== selectedPileItem?.id) {
      // Dispatch purged command payload after we change command
      dispatch(
        setCommandPayload(getPurgedCommandPayload() as CommandHistoryPayload)
      );
    }
  }, [
    commandPayload,
    dispatch,
    getPurgedCommandPayload,
    previousCmd,
    previousCmd?.id,
    selectedPileItem,
    selectedPileItem?.id,
  ]);

  if (selectedMultiplePileItems?.length) {
    return (
      <NonIdealState
        icon="key-command"
        title="Multiple commands selected"
        className="h-2/4"
      />
    );
  }

  if (!selectedCommand) {
    return (
      <NonIdealState
        icon="key-command"
        title="Choose command from list."
        className="h-2/4"
      />
    );
  }

  return (
    <CommandArguments
      commandProperties={properties}
      handleCommandPayloadChange={handleCommandPayloadChange}
      handleCommandPayloadBlur={handleCommandPayloadBlur}
      commandPayload={commandPayload}
      disabled={isCommandPreview}
      canBlur={Boolean(selectedPileItem)}
      canSetDefaultValue={!isCommandChanging}
      handleDisable={(value) => dispatch(setSendCommandDisabled(value))}
      handleMetaButtonClick={(value) =>
        dispatch(setExploringStorageName(value))
      }
    />
  );
};

export default Parameters;
