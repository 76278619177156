import {
  useReducer,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { get, has, isNil, last } from 'lodash';
import type {
  IActiveTransfer,
  IFileTransferMap,
} from 'pages/ops/RTI/Operate/utils/fileTransfer';
import type { IReply } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';

const initialState = {};

// Actions
export interface IStartTransferAction {
  type: 'start-transfer';
  name: string;
  destination?: string;
  transferType: string;
  id: string;
  transferImplementation?: string;
  skipWarningAbortTransfer?: boolean;
  stages?: string[];
}
export interface ICancelTransferAction {
  type: 'cancel-transfer';
  id: string;
}
export interface IUpdateTransferStatusAction {
  type: 'update-transfer-status';
  id: string;
  percentageComplete: number;
}
export interface IUpdateTransferStatusThroughReplyAction {
  type: 'update-transfer-status-through-reply';
  id: string;
  reply: IReply;
}

export interface IUpdateTransferStatusThroughReplyActionForNewCmd {
  type: 'update-new-cmd-transfer-status-through-reply';
  id: string;
  reply: IReply;
}
export interface IUpdateTransferWithCompletedPercentage {
  type: 'update-transfer-with-completed-percentage';
  id: string;
  reply: IReply;
}
export interface IUpdateTransferWithCurrentStage {
  type: 'update-transfer-with-current-stage';
  id: string;
  currentStage: string;
}
export interface IUpdateTransferWithStages {
  type: 'update-transfer-with-stages';
  id: string;
  stages: string[];
}
export interface IUpdateTransferWithListOfFilesAction {
  type: 'update-transfer-with-list-of-files';
  id: string;
  files: string[];
}
export interface IUpdateTransferWithError {
  type: 'update-transfer-with-error';
  id: string;
  error: string;
}
export interface IUpdateTransferCancelCallbackAction {
  type: 'update-cancel-callback';
  id: string;
  cancelCallback: Function;
}
export interface IStopAllTransfersOfType {
  type: 'stop-all-transfers-of-type';
  transferType: string;
}

// General action and state types
export type ITransferAction =
  | IStartTransferAction
  | ICancelTransferAction
  | IUpdateTransferStatusAction
  | IUpdateTransferStatusThroughReplyAction
  | IUpdateTransferWithListOfFilesAction
  | IUpdateTransferWithError
  | IUpdateTransferCancelCallbackAction
  | IStopAllTransfersOfType
  | IUpdateTransferStatusThroughReplyActionForNewCmd
  | IUpdateTransferWithCompletedPercentage
  | IUpdateTransferWithCurrentStage
  | IUpdateTransferWithStages;

export interface ITransferMap {
  [id: string]: IActiveTransfer;
}

const calculateTotalTransferProgress = (files: IFileTransferMap) => {
  const fileList = Object.entries(files);
  const numberOfFiles = fileList.length;
  return fileList.reduce((totalProgress, currentFile) => {
    const fileProgress = currentFile[1];
    const extraTotalProgress = fileProgress / numberOfFiles;
    return (totalProgress += extraTotalProgress);
  }, 0);
};

function reducer(state: ITransferMap, action: ITransferAction) {
  let transfer: IActiveTransfer;

  switch (action.type) {
    case 'start-transfer':
      return {
        [action.id]: {
          id: action.id,
          name: action.name,
          destination: action.destination,
          transferType: action.transferType,
          percent: 0,
          cancelCallback: undefined,
          transferImplementation: action.transferImplementation,
          files: {} as IFileTransferMap,
          skipWarningAbortTransfer: action.skipWarningAbortTransfer,
        } as IActiveTransfer,
        ...state,
      };

    case 'cancel-transfer':
      const newTransfers = { ...state };

      delete newTransfers[action.id];
      return newTransfers;

    case 'update-transfer-status':
      if (isNil(state[action.id])) {
        return state;
      }

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          percent: action.percentageComplete,
        } as IActiveTransfer,
      };

    case 'update-new-cmd-transfer-status-through-reply':
      if (isNil(state[action.id])) return state;

      const cmdReply = action.reply;

      const cmdFiles = get(cmdReply, 'data.files') as {
        cloud_path: string;
        file_name: string;
        file_size: number;
        metadata: null;
        satellite_path: string;
        stage: string;
        stage_progress_percentage: number;
        stage_status: 'pending' | 'ongoing' | 'succeeded' | 'failed';
        destination_path?: string;
        source_path?: string;
      }[];

      const percentage = cmdFiles.reduce((acc, file) => {
        acc = file.stage_progress_percentage;
        return acc;
      }, 0);

      const reducedFileName = cmdFiles.reduce((acc, file) => {
        acc = file.file_name;
        return acc;
      }, '');

      const filesAsPathProgressMap = cmdFiles.reduce((acc, file) => {
        if (acc && Object.keys(acc).length <= 0 && file.destination_path) {
          acc = { [file.destination_path]: file.stage_progress_percentage };
        } else {
          acc = {
            ...acc,
            [file.destination_path!]: file.stage_progress_percentage,
          };
        }

        return acc;
      }, {} as { [path: string]: number } | undefined);

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          percent: filesAsPathProgressMap
            ? calculateTotalTransferProgress(filesAsPathProgressMap)
            : percentage,
          files: filesAsPathProgressMap,
          name: reducedFileName,
        },
      };

    case 'update-transfer-with-completed-percentage':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          percent: 100,
        },
      };

    case 'update-transfer-with-current-stage':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          currentStage: action.currentStage,
        },
      };
    case 'update-transfer-with-stages':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          stages: action.stages,
        },
      };

    case 'update-transfer-status-through-reply':
      transfer = state[action.id];

      if (isNil(transfer)) {
        return state;
      }

      const { reply } = action;
      const replyHasSuccess = has(reply, 'data.success');

      if (replyHasSuccess) {
        const sourceName = get(
          action.reply,
          'data.success.source'
        ) as unknown as string;

        if (isNil(sourceName)) {
          return state;
        }

        const folderStructure = sourceName.split('/');
        const fileName = last(folderStructure);

        const isChunk = fileName ? has(transfer.files, fileName) : false;

        if (isChunk && fileName) {
          const files = { ...transfer.files, [fileName]: 100 };

          const totalTransferProgress = calculateTotalTransferProgress(files);
          return {
            ...state,
            [action.id]: {
              ...transfer,
              files,
              percent: totalTransferProgress,
            },
          };
        }

        return {
          ...state,
          [action.id]: {
            ...transfer,
            percent: 100,
          },
        };
      }

      const doesNotHaveTotal = isNil(
        get(action.reply, 'data.update.totalChunks')
      ); //usually first response with the full file name
      if (doesNotHaveTotal) {
        return state;
      }

      const percentageComplete =
        (get(action.reply, 'data.update.transferredChunks', 0) /
          get(action.reply, 'data.update.totalChunks', 1)) *
        100;

      const isDownloadingSpecificFile = has(action.reply, 'data.update.source');
      if (isDownloadingSpecificFile) {
        const sourceName = get(
          action.reply,
          'data.update.source'
        ) as unknown as string;

        if (isNil(sourceName)) {
          return state;
        }

        const folderStructure = sourceName.split('/');
        const fileName = last(folderStructure);
        const files = { ...transfer.files };
        if (fileName) files[fileName] = percentageComplete;
        const totalTransferProgress = calculateTotalTransferProgress(files);

        return {
          ...state,
          [action.id]: {
            ...transfer,
            files,
            percent: totalTransferProgress,
          },
        };
      }

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          percent: percentageComplete,
        } as IActiveTransfer,
      };

    case 'update-transfer-with-list-of-files':
      transfer = state[action.id];
      if (isNil(transfer)) {
        return state;
      }

      const files = {} as IFileTransferMap;
      action.files.map((fileName) => (files[fileName] = 0));

      return {
        ...state,
        [action.id]: {
          ...transfer,
          files,
        },
      };

    case 'update-cancel-callback':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          cancelCallback: action.cancelCallback,
        } as IActiveTransfer,
      };

    case 'update-transfer-with-error':
      transfer = state[action.id];
      if (isNil(transfer)) {
        return state;
      }

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          error: action.error,
        },
      };

    case 'stop-all-transfers-of-type':
      const newState: ITransferMap = {};

      Object.entries(state).map(([id, newTransfer]) => {
        const isTransferToStop =
          newTransfer.transferType === action.transferType;
        if (isTransferToStop) {
          newState[id] = { ...newTransfer, stopped: true };
        } else {
          newState[id] = newTransfer;
        }
      });

      return newState;

    default:
      throw new Error('No such file transfer action');
  }
}

export type IStorageProgressHook = ReturnType<typeof useInnerTransferProgress>;

export const FileProgressContext = createContext<IStorageProgressHook>(
  null as unknown as IStorageProgressHook
);

export const useTransferProgress = () =>
  useContext<IStorageProgressHook>(FileProgressContext);

const useInnerTransferProgress = () => {
  const [activeTransfers, dispatchTransferUpdate] = useReducer(
    reducer,
    initialState
  );

  const activeTransfersList = useMemo(
    () => Object.values(activeTransfers),
    [activeTransfers]
  );

  const removeTransfer = useCallback(
    (id: string) => {
      if (activeTransfers[id].cancelCallback) {
        activeTransfers[id].cancelCallback?.();
      }

      dispatchTransferUpdate({
        type: 'cancel-transfer',
        id,
      });
    },
    [activeTransfers]
  );

  return {
    activeTransfers,
    activeTransfersList,
    dispatchTransferUpdate,
    removeTransfer,
  };
};

type IPropsAndChildren = {
  children: JSX.Element;
};

export const TransferProgressProvider = ({ children }: IPropsAndChildren) => {
  const innerTransferProgress = useInnerTransferProgress();

  return (
    <FileProgressContext.Provider
      value={{
        ...innerTransferProgress,
      }}
    >
      {children}
    </FileProgressContext.Provider>
  );
};
