import { ActivityStatusList } from 'api/activities/types';
import type {
  ActivityStatus,
  Activity,
  ActivityWithRequestNote,
} from 'api/activities/types';
import classNames from 'classnames';
import type { CellContext } from 'opencosmos-ui';
import { ListBoxItem, Select, Table } from 'opencosmos-ui';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect } from 'react';
import type { UpdatedActivityMap } from '../..';
import type { TaskingRequest } from 'api/tasking/types';
import InputCellWithPopover from './InputCellWithPopover';

type Props = {
  activities: ActivityWithRequestNote[];
  onActivitySelect: (activity: Activity | undefined) => void;
  expanded?: boolean;
  hiddenColumns?: string[];
  updatedActivitiesMap: UpdatedActivityMap;
  setUpdatedActivitiesMap: Dispatch<SetStateAction<UpdatedActivityMap>>;
  requests: TaskingRequest[];
};

// Columns that should not be displayed in the table at all
export const EXCLUDED_COLUMNS = ['geojson'];
// Columns that should be hidden by default
export const DEFAULT_HIDDEN_COLUMNS = [
  'parameters.platform.roll_angle',
  'parameters.imager.mode',
  'parameters.imager.name',
  'parameters.imager.encoding',
  'parameters.imager.band_setup',
  'parameters.imager.line_period_us',
  'parameters.imager.number_of_lines',
  'parameters.imager.exposure_time_us',
  'parameters.imager.acquisition_padded',
  'parameters.imager.compression_factor',
  'parameters.imager.acquisition_end_date',
  'parameters.imager.acquisition_start_date',
  'parameters.imager.predicted_file_size_gb',
  'parameters.physical.target.ephemeris.type',
  'parameters.physical.target.ephemeris.attitude_law',
  'parameters.physical.target.ephemeris.data.epoch',
  'parameters.physical.target.ephemeris.data.line1',
  'parameters.physical.target.ephemeris.data.line2',
  'parameters.physical.target.midpoint.date',
  'parameters.physical.target.midpoint.sza_deg',
  'parameters.physical.target.midpoint.oza_deg',
  'parameters.physical.target.midpoint.sat_body_pos_m',
  'parameters.physical.target.midpoint.sat_body_vel_ms',
  'parameters.physical.target.midpoint.slant_range_m',
  'parameters.physical.target.midpoint.ground_velocity_ms',
  'parameters.physical.latest.ephemeris.type',
  'parameters.physical.latest.ephemeris.attitude_law',
  'parameters.physical.latest.ephemeris.data.epoch',
  'parameters.physical.latest.ephemeris.data.line1',
  'parameters.physical.latest.ephemeris.data.line2',
  'parameters.physical.latest.midpoint.date',
  'parameters.physical.latest.midpoint.sza_deg',
  'parameters.physical.latest.midpoint.oza_deg',
  'parameters.physical.latest.midpoint.sat_body_pos_m',
  'parameters.physical.latest.midpoint.sat_body_vel_ms',
  'parameters.physical.latest.midpoint.slant_range_m',
  'parameters.physical.latest.midpoint.ground_velocity_ms',
  'parameters.metrics.cloud_status',
];

const SchedulingTable = ({
  activities,
  onActivitySelect,
  expanded,
  hiddenColumns,
  updatedActivitiesMap,
  setUpdatedActivitiesMap,
}: Props) => {
  const getSelectIntent = (
    status: ActivityStatus
  ): 'none' | 'success' | 'warning' => {
    switch (status) {
      case 'COMPLETED':
        return 'success';
      case 'CANCELLED':
      case 'FAILED':
      case 'EXPIRED':
        return 'warning';
      default:
        return 'none';
    }
  };

  const renderStatusSelector = (
    ctx: CellContext<ActivityWithRequestNote, unknown>
  ) => {
    const updatedActivityStatus =
      updatedActivitiesMap[ctx.row.original.id]?.status ??
      (ctx.getValue() as ActivityStatus);

    return (
      <Select
        fill
        selectedKey={updatedActivityStatus}
        selectedItemProps={{
          className: classNames({
            'bg-neutral-300 dark:bg-neutral-600':
              updatedActivityStatus === 'PENDING' ||
              updatedActivityStatus === 'APPROVED',
            'bg-neutral-500 dark:bg-neutral-800':
              updatedActivityStatus === 'CONFIRMED' ||
              updatedActivityStatus === 'SCHEDULED' ||
              updatedActivityStatus === 'PROCESSING',
            'bg-success': updatedActivityStatus === 'COMPLETED',
            'bg-warning':
              updatedActivityStatus === 'CANCELLED' ||
              updatedActivityStatus === 'FAILED',
          }),
          intent: getSelectIntent(updatedActivityStatus),
        }}
        onSelectionChange={(activityStatus) => {
          setUpdatedActivitiesMap((prev) => ({
            ...prev,
            [ctx.row.original.id]: {
              ...prev[ctx.row.original.id],
              status: activityStatus as ActivityStatus,
            },
          }));
        }}
      >
        {ActivityStatusList.map((activityStatus) => (
          <ListBoxItem key={activityStatus} id={activityStatus}>
            {activityStatus}
          </ListBoxItem>
        ))}
      </Select>
    );
  };

  // Hack to prevent scrolling on the html element when the table is rendered
  // This is due to *something* going on with the <Select/> component
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, []);

  return (
    <div
      className={classNames('overflow-x-auto', {
        'w-[47.5vw]': !expanded,
        'w-full': expanded,
      })}
    >
      <Table
        data={activities}
        hiddenColumns={hiddenColumns}
        cellRenderer={(ctx, header) => {
          if (header === 'status') {
            return renderStatusSelector(ctx);
          }

          if (header === 'operator_notes') {
            return (
              <InputCellWithPopover
                inputFor="operator_notes"
                setUpdatedActivitiesMap={setUpdatedActivitiesMap}
                value={ctx.row.original.operator_notes}
                activityId={ctx.row.original.id}
              />
            );
          }

          if (header === 'request_note') {
            return (
              <InputCellWithPopover
                value={ctx.row.original.request_note}
                activityId={ctx.row.original.id}
                readOnly={true}
                // Disable the popover if the request note is empty
                isDisabled={ctx.row.original.request_note === ''}
              />
            );
          }

          return <span className="p-1">{String(ctx.getValue())}</span>;
        }}
        enableRowSelection={true}
        enableMultiRowSelection={false}
        showFullHeaderTitle={false}
        excludeDataKeys={EXCLUDED_COLUMNS}
        onRowSelect={(activity) => {
          // If the activity is an array, do nothing for now
          if (Array.isArray(activity)) {
            return;
          }

          onActivitySelect(activity);
        }}
      />
    </div>
  );
};

export default SchedulingTable;
