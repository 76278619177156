import { Dialog, OverlayArrow, type DialogProps } from 'react-aria-components';
import { Popover } from 'opencosmos-ui';

const ControlDialog: typeof Dialog = (props: DialogProps) => {
  return (
    <Popover className="w-64 p-1" placement="bottom end">
      <Dialog
        {...props}
        className="py-2 rounded bg-surface text-surface-contrast dark:bg-surface-dark dark:text-surface-dark-contrast shadow-md border-item dark:border-item-dark border focus-visible:outline-1 focus-visible:outline-neutral-600 focus-visible:outline"
      >
        <>
          <OverlayArrow>
            <svg
              width={12}
              height={12}
              viewBox="0 0 12 12"
              className="fill-surface stroke-item dark:fill-surface-dark dark:stroke-item-dark rotate-180 translate-y-[5px]"
            >
              <path d="M0 0 L6 6 L12 0" />
            </svg>
          </OverlayArrow>
          {props.children}
        </>
      </Dialog>
    </Popover>
  );
};

export default ControlDialog;
