import { Checkbox, type CheckboxProps } from "react-aria-components";

type Props = {
  themeProvider: {
    isDarkmode: boolean;
    setTheme: (theme: "LIGHT" | "DARK" | "AUTO") => void;
    theme: "LIGHT" | "DARK" | "AUTO";
  };
} & CheckboxProps;

const CustomCheckbox = ({ children, ...props }: Props) => {
  const theme = props.themeProvider;

  const getCheckboxFill = () => {
    if (props.isSelected) {
      return theme?.isDarkmode ? "#FFCC55" : "#FFC848";
    }

    if (props.isDisabled) {
      return theme?.isDarkmode ? "#303030" : "#F2F0ED";
    }

    return theme?.isDarkmode ? "#535353" : "#ECE9E5";
  };

  const checkboxTick = (
    <path
      transform="translate(7 7)"
      d={`M3.788 9A.999.999 0 0 1 3 8.615l-2.288-3a1 1 0 1 1
            1.576-1.23l1.5 1.991 3.924-4.991a1 1 0 1 1 1.576 1.23l-4.712
            6A.999.999 0 0 1 3.788 9z`}
    />
  );

  return (
    <Checkbox {...props}>
      {({ isIndeterminate }) => (
        <div className="flex items-center gap-1">
          <div
            className={`checkbox ${
              props.isDisabled ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <svg width={24} height={24} aria-hidden="true">
              {!isIndeterminate ? (
                <rect
                  x={props.isSelected ? 4 : 5}
                  y={props.isSelected ? 4 : 5}
                  width={props.isSelected ? 16 : 14}
                  height={props.isSelected ? 16 : 14}
                  fill={getCheckboxFill()}
                  stroke={props.isSelected ? "none" : "#8C8C8C"}
                  strokeWidth={1}
                />
              ) : (
                <polyline points="1 9 7 14 15 4" />
              )}
              {props.isSelected && checkboxTick}
            </svg>
          </div>
          <>{children}</>
        </div>
      )}
    </Checkbox>
  );
};

export default CustomCheckbox;
