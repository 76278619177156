import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
  addSatelliteOrbit,
  updateSatelliteOrbit,
  removeAllSatelliteOrbit,
} from './action';
import type { AppState } from '../../reducers/rootReducer';
import type { ISatelliteData } from '../../../../../../constants/satelliteOrbits/actionTypes';

export const addSO =
  (
    satelliteOrbit: ISatelliteData
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, store): Promise<void> => {
    const astrum = store().astrum.current;
    dispatch(addSatelliteOrbit({ ...satelliteOrbit, astrum: astrum.planet }));
  };

export const updateSO =
  (
    satelliteOrbit: ISatelliteData
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(updateSatelliteOrbit(satelliteOrbit));
  };

export const removeAllSO =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(removeAllSatelliteOrbit());
  };
