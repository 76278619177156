import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type {
  ICommandPileItem,
  UpdateWorkspace,
} from 'services/api/ops/realtimeTerminalSession';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { pileItemSelection, reorderCommandPile } from 'actions/operate/actions';
import CommandPileItem from './CommandPileItem';

import s from './index.module.scss';
import AlertConfirm from 'components/common/AlertConfirm';
import type { PermissionScope } from 'api/administration/permissions';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import CommandPileContextMenu from './CommandPileContextMenu';
import { Button, Icon, Tooltip } from 'opencosmos-ui';
import classNames from 'classnames';
import type { CommandDefinition, JsonSchema } from 'api/telecommands/types';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface IProps {
  availableCommands: CommandDefinition[];
  isFrozenPile: boolean;
  commandPileWorkingSpace?: ICommandPileItem[];
  armedCommand?: ICommandPileItem;
  handleFireCommandClick: () => void;
  handleUnarmCommandClick: () => void;
  handleArmCommandClick: (id: string) => void;
  handlePileCommandRemoveClick: (id: string) => void;
  handleArmCommandEditClick: (
    pileItem: ICommandPileItem,
    addToMultipleItemDrag: boolean
  ) => void;
  selectedPileItem?: ICommandPileItem;
  selectedMultiplePileItems?: ICommandPileItem[];
  handleArmedCommandPreviewClick: () => void;
  isCommandPreview?: boolean;
  handleSendUnarmedCommand: (
    pileItem: ICommandPileItem,
    removeItem: boolean,
    procedureName: string
  ) => Promise<void>;
  updateWorkspace: UpdateWorkspace;
  procedureName?: string;
  isGridView: boolean;
  scrollCommandHistoryToBottom: () => void;
  isSessionOccupied: boolean;
  userIsActiveInCurrentMission?: boolean;
  fetchActiveSessions: (abort?: { state: boolean }) => Promise<void>;
}

interface ICommandInfo {
  commandSchema: JsonSchema;
  commandScope?: PermissionScope;
  dangerous: boolean;
  dangerousMessage?: string;
}

const CommandPile = ({
  availableCommands,
  isFrozenPile,
  commandPileWorkingSpace = [],
  armedCommand,
  handleArmCommandClick,
  handlePileCommandRemoveClick,
  handleArmCommandEditClick,
  selectedPileItem,
  isGridView,
  updateWorkspace,
  handleSendUnarmedCommand,
  procedureName,
  scrollCommandHistoryToBottom,
  isSessionOccupied,
  userIsActiveInCurrentMission,
  fetchActiveSessions,
  selectedMultiplePileItems,
}: IProps) => {
  const { dispatch } = useOperate();
  const { mission } = useParams<{ mission: string }>();

  const { sendInfo } = useAnalytics();

  const [isSessionWarningOpened, setIsSessionWarningOpened] =
    useState<boolean>(false);

  const [clickedPileItem, setClickedPileItem] =
    useState<ICommandPileItem | null>(null);

  const [shiftClickInitialIndex, setShiftClickInitialIndex] = useState<
    number | null
  >(null);

  const handleHoverPileItem = (dragIndex: number, hoverIndex: number) => {
    if (selectedMultiplePileItems?.length !== 0) {
      dispatch(
        reorderCommandPile(hoverIndex, dragIndex, selectedMultiplePileItems)
      );
      return;
    }
    dispatch(reorderCommandPile(hoverIndex, dragIndex, selectedPileItem));
  };

  const handleShiftSelect = (initialIndex: number, endIndex: number) => {
    const selectedItems = commandPileWorkingSpace.slice(
      Math.min(initialIndex, endIndex),
      Math.max(initialIndex, endIndex) + 1
    );
    dispatch(pileItemSelection(selectedItems));
  };

  const getCommandInfo = useCallback(
    (name?: string): ICommandInfo => {
      const command = availableCommands.find((value) => value.name === name);
      return command
        ? {
            commandSchema: command.schema,
            commandScope: command.scope,
            dangerous: command.dangerous,
            dangerousMessage: command.dangerousMessage,
          }
        : {
            commandSchema: {},
            commandScope: 'ops',
            dangerous: false,
          };
    },
    [availableCommands]
  );

  // This use effect is a bit of a hack. It is used to update the workspace
  // with the value of the state after the latest dispatch.
  const shouldUpdateWorkspace = useRef(false);

  // Debounce is very large here but it shouldn't pose an issue since the
  // workspace is only updated after the user has finished dragging a command
  // or multiple commands. The workspace is updated 5 seconds upon finishing the
  // drag.
  // Other actions such as deleting or sending an unfrozen cmd will update the workspace
  // immediately.
  // The issue where command would get deselected after dragging them multiple times
  // originally occurred due to workspace state updating which would trigger
  // a rerender which I assume messed up sth with the drag and drop library.
  // TL:DR: This is a bit hacky but it works.
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!shouldUpdateWorkspace.current) {
      return;
    }

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      shouldUpdateWorkspace.current = false;
      updateWorkspace(commandPileWorkingSpace, armedCommand, isFrozenPile);
    }, 5000);
  }, [commandPileWorkingSpace, armedCommand, isFrozenPile, updateWorkspace]);

  const { hasPermission: canOpenCommand } = useCheckPermissions({
    permissions: commandPileWorkingSpace.map((item) => {
      return {
        actionScope: getCommandInfo(item.command).commandScope,
        id: mission,
        type: 'mission',
      };
    }) as ICheckPermissions[],
  });

  const handleDropPileItem = (dragIndex: number, hoverIndex: number) => {
    if (selectedMultiplePileItems?.length !== 0) {
      dispatch(
        reorderCommandPile(hoverIndex, dragIndex, selectedMultiplePileItems)
      );
      shouldUpdateWorkspace.current = true;
      return;
    }
    dispatch(reorderCommandPile(hoverIndex, dragIndex, selectedPileItem));

    shouldUpdateWorkspace.current = true;
  };

  const send = (pileItem: ICommandPileItem) => {
    if (isSessionOccupied && !userIsActiveInCurrentMission) {
      setIsSessionWarningOpened(true);
      setClickedPileItem(pileItem);
    } else {
      if (!procedureName) return;
      void handleSendUnarmedCommand(
        pileItem,
        !isFrozenPile,
        procedureName
      ).then(() => {
        scrollCommandHistoryToBottom();
      });
    }
  };

  // Leading edge debounce for starting user session
  const userSessionDebounceTimer = useRef<NodeJS.Timeout | null>(null);
  const debouncedStartUserSession = async () => {
    if (userSessionDebounceTimer.current) {
      return;
    }

    userSessionDebounceTimer.current = setTimeout(() => {
      userSessionDebounceTimer.current = null;
      // Debounce for 30 seconds
    }, 30000);

    await fetchActiveSessions();
  };

  if (commandPileWorkingSpace.length === 0) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={
          isGridView ? s.commandPileContainerGridView : s.commandPileContainer
        }
        data-testid="command-pile"
      >
        {commandPileWorkingSpace.map((pileItem, index) => {
          let editMode: boolean = false;
          if (selectedMultiplePileItems?.length) {
            const selectedItem = selectedMultiplePileItems?.find(
              (element) => element?.id === pileItem?.id
            );
            editMode = selectedItem !== undefined ? true : false;
          } else {
            editMode = selectedPileItem
              ? selectedPileItem.id === pileItem.id
              : false;
          }
          const { commandSchema, dangerous, dangerousMessage } = getCommandInfo(
            pileItem.command
          );
          const isAllowedToOpenPileItem = canOpenCommand[index];

          let className = isGridView
            ? s.commandPileItemCard__selected
            : s.commandPileItem__selected;

          if (!editMode) {
            className = '';
          }

          return (
            <CommandPileContextMenu pileItemIndex={index} key={pileItem.id}>
              <CommandPileItem
                handleShiftSelect={handleShiftSelect}
                shiftClickInitialIndex={shiftClickInitialIndex}
                setShiftClickInitialIndex={setShiftClickInitialIndex}
                cardView={isGridView}
                itemIndex={index}
                hoverPileItem={handleHoverPileItem}
                dropPileItem={handleDropPileItem}
                className={classNames(className, 'flex items-center', {
                  'bg-neutral-100 dark:bg-neutral-900':
                    index % 2 === 0 && !editMode,
                  'bg-item-selected dark:bg-item-dark-hover': editMode,
                  'bg-surface dark:bg-neutral-800':
                    index % 2 !== 0 && !editMode,
                })}
                pileItem={pileItem}
                commandSchema={commandSchema}
                handleItemClick={handleArmCommandEditClick}
                headButtonsList={
                  <div className={s.commandLeftHandSide}>
                    <Tooltip
                      content="Danger: Send directly to the satellite!"
                      isDisabled={!isAllowedToOpenPileItem}
                      delay={0}
                      className="flex-col justify-center items-center"
                    >
                      <Button
                        intent="warning"
                        size={'base'}
                        isDisabled={!isAllowedToOpenPileItem}
                        isMinimal={true}
                        icon={'Satellite'}
                        onPress={async () => {
                          sendInfo({
                            type: 'Command pile direct send',
                            action: 'Click',
                            item: 'Command pile direct send button',
                            module: 'OPS',
                          });

                          send(pileItem);
                          await debouncedStartUserSession();
                        }}
                        className="mr-1"
                        data-testid="send-command-directly-button"
                      />
                    </Tooltip>
                    {dangerous && (
                      <div className={s.dangerIconContainer}>
                        <Tooltip
                          content={
                            dangerousMessage ?? 'This command can be dangerous'
                          }
                          delay={0}
                        >
                          <Icon
                            // TODO: We're missing a DANGER icon in the Icon component
                            icon={'warning-sign'}
                            size={16}
                            stroke="#FF695E"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                }
                buttonsList={
                  <div className={s.commandRightHandSide}>
                    <Tooltip
                      content="Remove from command pile"
                      isDisabled={!isAllowedToOpenPileItem}
                      delay={0}
                    >
                      <Button
                        isMinimal={true}
                        size={'base'}
                        data-testid="remove-command-button"
                        isDisabled={!isAllowedToOpenPileItem}
                        icon={'Trash'}
                        intent="warning"
                        onPress={() => {
                          sendInfo({
                            type: 'Command pile remove',
                            action: 'Click',
                            item: 'Command pile remove button',
                            module: 'OPS',
                          });

                          if (pileItem.id) {
                            handlePileCommandRemoveClick(pileItem.id);
                          }
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      content="Arm command"
                      isDisabled={!isAllowedToOpenPileItem}
                    >
                      <Button
                        isMinimal={true}
                        size={'base'}
                        isDisabled={!isAllowedToOpenPileItem}
                        className={
                          isAllowedToOpenPileItem
                            ? s.commandButtonBlack
                            : undefined
                        }
                        icon={'CenterWorld'}
                        onPress={() => {
                          sendInfo({
                            type: 'Command pile arm command',
                            action: 'Click',
                            item: 'Command pile arm command button',
                            module: 'OPS',
                          });
                          if (pileItem.id) {
                            handleArmCommandClick(pileItem.id);
                          }
                        }}
                      />
                    </Tooltip>
                  </div>
                }
              />
            </CommandPileContextMenu>
          );
        })}
      </div>

      <AlertConfirm
        isOpen={isSessionWarningOpened && !userIsActiveInCurrentMission}
        setIsOpen={setIsSessionWarningOpened}
        onConfirm={() => {
          if (!clickedPileItem || !procedureName) return;
          void handleSendUnarmedCommand(
            clickedPileItem,
            !isFrozenPile,
            procedureName
          ).then(() => {
            scrollCommandHistoryToBottom();
          });
        }}
        message="The session is currently in use. Are you sure you want to send the command?"
      />
    </DndProvider>
  );
};

export default CommandPile;
