import { useRouteMatch, useHistory, useLocation } from 'react-router';
import { Breadcrumbs } from '@blueprintjs/core';
import DefaultLayout from 'layouts/DefaultLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import mainS from 'pages/ops/RTI/index.module.scss';
import { ScheduleActivityList } from 'pages/ops/Scheduling/components/ScheduleActivityList';
import { SchedulingLayout } from 'pages/ops/Scheduling/components/SchedulingLayout';
import { ScheduleActivityDetails } from 'pages/ops/Scheduling/components/ScheduleActivityDetails';
import moment from 'moment';
import { useQuery } from 'api/useQuery';
import type { Activity, ActivityStatus } from 'api/activities/service';
import {
  getActivitiesByMissionId,
  patchActivity,
} from 'api/activities/service';
import ThemeProvider from 'datacosmos/stores/ThemeProvider';
import type { TaskingRequest } from 'api/tasking/types';
import { getTaskingRequest } from 'api/tasking/service';

export type FromToDate = {
  from: Date | null;
  to: Date | null;
};

export type UpdatedActivityValue = {
  status?: ActivityStatus;
  operator_notes?: string;
};

export type UpdatedActivityMap = {
  [activityId: string]: UpdatedActivityValue;
};

export type ViewMode = 'list' | 'calendar' | 'table';

export const SchedulingInner = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();

  const [viewMode, setViewMode] = useState<ViewMode>('list');

  const [calendarModeCurrentMonth, setCalendarModeCurrentMonth] = useState<
    Date | undefined
  >();

  const [fromToDate, setFromToDate] = useState<FromToDate>(() => {
    const params = new URLSearchParams(search);
    const from = params.get('from');
    const to = params.get('to');
    return from && to
      ? {
          from: moment.utc(from).toDate(),
          to: moment.utc(to).toDate(),
        }
      : {
          from: moment.utc().startOf('day').toDate(),
          to: moment.utc().startOf('day').add(7, 'days').toDate(),
        };
  });

  const [isUpdatingActivityStatus, setIsUpdatingActivityStatus] =
    useState<boolean>(false);

  const urlMatch = useRouteMatch<{ mission: string }>({
    path: '/ops/mission/:mission/:route*',
    exact: false,
  });

  const urlMatchWithActivity = useRouteMatch<{
    mission: string;
    activity: string;
  }>('/ops/mission/:mission/schedule/activity/:activity');

  const missionId = urlMatch ? urlMatch.params.mission : '55';

  const {
    data: activities,
    refetch: refetchActivities,
    loading: isFetchingActivities,
  } = useQuery(getActivitiesByMissionId, {
    skip: !fromToDate.from || !fromToDate.to,
    params: {
      missionId: missionId,
      startDate:
        fromToDate.from && viewMode !== 'calendar'
          ? fromToDate.from.toISOString()
          : moment(calendarModeCurrentMonth).startOf('month').toISOString(),
      endDate:
        fromToDate.to && viewMode !== 'calendar'
          ? fromToDate.to.toISOString()
          : moment(calendarModeCurrentMonth).endOf('month').toISOString(),
    },
    initialData: [],
  });

  const [modifiableActivities, setModifiableActivities] =
    useState<Activity[]>(activities);

  const activitiesRequestIds = useMemo(() => {
    return [
      ...new Set(activities?.map((a) => a.tasking_request_ids[0])),
    ].filter((id) => id !== undefined);
  }, [activities]);

  const [requests, setRequests] = useState<TaskingRequest[]>([]);
  const [isFetchingRequests, setIsFetchingRequests] = useState<boolean>(false);

  const [updatedActivitiesMap, setUpdatedActivitiesMap] =
    useState<UpdatedActivityMap>(
      activities.reduce(
        (acc, activity) => ({
          ...acc,
          [activity.id]: {
            status: activity.status,
            operator_notes: activity.operator_notes,
          },
        }),
        {} as UpdatedActivityMap
      )
    );

  const [isSaveChangesButtonEnabled, setIsSaveChangesButtonEnabled] =
    useState<boolean>(false);
  const loading = useMemo(
    () => isFetchingRequests || isFetchingActivities,
    [isFetchingActivities, isFetchingRequests]
  );

  const fetchTaskingRequestById = useCallback(async (id: string) => {
    const { data } = await getTaskingRequest({ params: { requestId: id } });
    if (!data) {
      return undefined;
    }
    return data;
  }, []);

  const fetchAllActivitiesRequests = useCallback(async () => {
    setIsFetchingRequests(true);
    const requestPromises = activitiesRequestIds.map((id) =>
      fetchTaskingRequestById(id)
    );

    const reqs = (await Promise.all(requestPromises)).filter(
      (r) => r !== undefined
    ) as TaskingRequest[];

    setRequests(reqs);

    setIsFetchingRequests(false);
  }, [activitiesRequestIds, fetchTaskingRequestById]);

  const batchUpdateActivityStatus = async () => {
    setIsUpdatingActivityStatus(true);
    await Promise.all(
      Object.entries(updatedActivitiesMap).map(
        async ([activityId, { status, operator_notes }]) => {
          return await patchActivity({
            params: { missionId, activityId },
            body: { status, operator_notes },
          });
        }
      )
    );
    setUpdatedActivitiesMap({});
    setIsUpdatingActivityStatus(false);
  };

  useEffect(() => {
    void fetchAllActivitiesRequests();
  }, [fetchAllActivitiesRequests]);

  useEffect(() => {
    if (viewMode === 'calendar') {
      void refetchActivities();
    }
  }, [viewMode, refetchActivities, calendarModeCurrentMonth]);

  useEffect(() => {
    if (!fromToDate.from || !fromToDate.to) return;
    history.replace(
      `${pathname}?from=${fromToDate.from.toISOString()}&to=${fromToDate.to.toISOString()}`
    );
  }, [history, pathname, fromToDate]);

  useEffect(() => {
    setModifiableActivities(activities);
  }, [activities]);

  // Upon receiving updatedActivitiesMap, update the appropriate fields in modifiableActivities
  // to reflect the changes made by the user
  // Also, enable the save changes button
  useEffect(() => {
    if (Object.keys(updatedActivitiesMap).length > 0) {
      setModifiableActivities((prev) =>
        prev.map((activity) => {
          return {
            ...activity,
            status:
              updatedActivitiesMap[activity.id]?.status ?? activity.status,
            operator_notes:
              updatedActivitiesMap[activity.id]?.operator_notes ??
              activity.operator_notes,
          };
        })
      );
      setIsSaveChangesButtonEnabled(true);
    }
  }, [updatedActivitiesMap]);

  return (
    <DefaultLayout
      shouldHideClock
      showMissionSelector
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'Schedule' }]} />
        </div>
      }
    >
      <SchedulingLayout
        hideRight={
          viewMode === 'table' &&
          urlMatchWithActivity?.params.activity === undefined
        }
        left={
          <ScheduleActivityList
            setUpdatedActivitiesMap={setUpdatedActivitiesMap}
            setCalendarModeCurrentMonth={setCalendarModeCurrentMonth}
            calendarModeCurrentMonth={calendarModeCurrentMonth}
            setFromToDate={setFromToDate}
            fromToDate={fromToDate}
            activities={modifiableActivities}
            loading={loading}
            requests={requests}
            viewMode={viewMode}
            setViewMode={setViewMode}
            setModifiableActivities={setModifiableActivities}
            batchUpdateActivityStatus={batchUpdateActivityStatus}
            isUpdatingActivityStatus={isUpdatingActivityStatus}
            refetchActivities={refetchActivities}
            updatedActivitiesMap={updatedActivitiesMap}
            setIsSaveChangesButtonEnabled={setIsSaveChangesButtonEnabled}
            isSaveChangesButtonEnabled={isSaveChangesButtonEnabled}
          />
        }
        right={
          <ScheduleActivityDetails
            activities={modifiableActivities}
            missionId={missionId}
            refetchActivities={refetchActivities}
            requests={requests}
            setModifiableActivities={setModifiableActivities}
            updatedActivitiesMap={updatedActivitiesMap}
          />
        }
      />
    </DefaultLayout>
  );
};

export const Scheduling = () => {
  return (
    <ThemeProvider>
      <SchedulingInner />
    </ThemeProvider>
  );
};
