import { useQueries } from '@tanstack/react-query';
import { useAuth } from 'services/auth/AuthWrapper';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';

type CommonArgs = {
  enabled?: boolean;
};

type SinglePermArgs = CommonArgs & {
  permissions: ICheckPermissions;
};

type MultiPermArgs = CommonArgs & {
  permissions: ICheckPermissions[];
};

type SinglePermReturn = {
  hasPermission: boolean;
};

type MultiPermReturn = {
  hasPermission: boolean[];
};

function useCheckPermissions(args: SinglePermArgs): SinglePermReturn;
function useCheckPermissions(args: MultiPermArgs): MultiPermReturn;
function useCheckPermissions({
  permissions,
  enabled,
}: SinglePermArgs | MultiPermArgs): SinglePermReturn | MultiPermReturn {
  const { checkPermissions } = useAuth();

  const permmissionQueries = useQueries({
    queries: (Array.isArray(permissions) ? permissions : [permissions]).map(
      (p) => {
        return {
          queryKey: [p],
          queryFn: () => checkPermissions(p),
          enabled,
        };
      }
    ),
  });

  if (Array.isArray(permissions)) {
    return {
      hasPermission: permmissionQueries.map((q) => Boolean(q.data)),
    };
  }

  return {
    hasPermission: Boolean(permmissionQueries[0].data),
  };
}
export default useCheckPermissions;
