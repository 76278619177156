import { useCallback } from 'react';
import type {
  PermissionScope,
  PermissionType,
} from 'api/administration/permissions';
import { getAuthorisationResults } from 'api/administration/service';
import { usePermissionCache } from 'utils/context/PermissionCacheProvider';
import { useQuery } from '@tanstack/react-query';

export type ActionScope = PermissionScope | PermissionScope[];

export type ICheckPermissions =
  | {
      type: 'global' | undefined;
      actionScope: ActionScope | undefined;
      id?: undefined;
      routing?: boolean;
    }
  | {
      type: Exclude<PermissionType, 'global'> | undefined;
      actionScope: ActionScope | undefined;
      id: number | string | undefined;
      routing?: boolean;
    };

export const useAuthorisation = (
  userId: string | undefined,
  token: string | undefined
) => {
  const { cachePermission } = usePermissionCache();

  const checkSinglePermission = useCallback(
    async (perms: ICheckPermissions) => {
      const { type, actionScope, id } = perms;

      if (!token) {
        return false;
      }

      if (!perms) {
        return false;
      }

      if (!userId) {
        return false;
      }

      // const cached = getIsPermissionCached({ actionScope, type, id }, userId);

      // if (cached) {
      //   return retrievePermission({ actionScope, type, id }, userId);
      // }

      const { data: hasPerm, errors } = await getAuthorisationResults({
        params: {
          userId,
        },

        body: {
          subjectToken: token,
          intents: [
            {
              scope: actionScope as PermissionScope,
              resource: {
                id,
                type: type ?? 'global',
              },
            },
          ],
        },
      });

      if (errors.length > 0) {
        return false;
      }

      if (hasPerm?.every((p) => p)) {
        cachePermission(
          userId,
          { actionScope, type: type ?? 'global', id },
          true
        );
      }

      if (!hasPerm) {
        return false;
      }

      return hasPerm[0];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, userId]
  );

  const checkArrayPermissions = useCallback(
    async (perms: ICheckPermissions[]) => {
      if (!token) {
        return [];
      }

      if (perms.length === 0) {
        return [];
      }

      if (!userId) {
        return [];
      }

      const { data: hasPerm, errors } = await getAuthorisationResults({
        params: {
          userId,
        },

        body: {
          subjectToken: token,
          intents: perms.map(({ actionScope, type, id }) => ({
            scope: actionScope as PermissionScope,
            resource: {
              id: id ?? null,
              type: type ?? 'global',
            },
          })),
        },
      });

      if (errors.length > 0) {
        return [];
      }

      perms.forEach(({ actionScope, type, id }) => {
        if (hasPerm?.every((p) => p)) {
          cachePermission(
            userId,
            { actionScope, type: type ?? 'global', id },
            true
          );
        }
      });

      if (!hasPerm) {
        return [];
      }

      return hasPerm;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, userId]
  );

  type Overload = {
    (perms: ICheckPermissions[]): Promise<boolean[]>;
    (perms: ICheckPermissions): Promise<boolean>;
  };

  const checkPermissions: Overload = useCallback(
    async (perms) => {
      if (Array.isArray(perms)) {
        const p = await checkArrayPermissions(perms);
        return p;
      } else {
        const p = await checkSinglePermission(perms);
        return p;
      }
    },
    [checkArrayPermissions, checkSinglePermission]
  ) as Overload;

  const { data, isPending } = useQuery({
    queryKey: ['role:read', 'user:assignment:write', 'organisation:read:own'],
    queryFn: async () => {
      const hasPerms = checkPermissions([
        {
          type: 'global',
          actionScope: 'role:read',
        },
        {
          type: 'global',
          actionScope: 'user:assignment:write',
        },
        {
          type: 'global',
          actionScope: 'organisation:read:own',
        },
      ]);

      return hasPerms;
    },
    enabled: userId !== undefined && token !== undefined,
  });

  return {
    loading: isPending,
    checkPermissions,
    isAllowedToReadRoles: data?.[0] ?? false,
    isAllowedToReadUsersRoles: data?.[1] ?? false,
    isAllowedToOpenMissionSelector: data?.[2] ?? false,
  };
};
