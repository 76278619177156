import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
  addGeometryObject,
  removeAllGeometryObject,
  removeGeometryObject,
  updateGeometryObject,
  uploadGeometers,
} from './action';
import type { AppState } from 'pages/msd/shared/state/reducers/rootReducer';
import type {
  IGeometryObject,
  IGeometryObjectState,
} from 'pages/msd/shared/constants/geometry/interfaces';

export const addGeometry =
  (
    geometryObject: IGeometryObject
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, store) => {
    const astrum = store().astrum.current;
    dispatch(addGeometryObject({ ...geometryObject, astrum: astrum.planet }));
  };

export const updateGeometry =
  (
    geometryObject: IGeometryObject
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, store) => {
    const astrum = store().astrum.current;
    dispatch(
      updateGeometryObject({ ...geometryObject, astrum: astrum.planet })
    );
  };

export const removeGeometry =
  (
    geometryObject: IGeometryObject
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(removeGeometryObject(geometryObject));
  };

export const removeAllGeometry =
  (): ThunkAction<void, AppState, null, Action<string>> => (dispatch) => {
    dispatch(removeAllGeometryObject());
  };

export const uploadGeometersThunk =
  (
    geometers: IGeometryObjectState
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(uploadGeometers(geometers));
  };
