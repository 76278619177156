import React, { useState } from 'react';

import { Button, Group, TextField, Tooltip } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { useMission } from 'services/Missions';
import { useApiPostOpsNewTerminalSessions } from 'services/api/ops/terminalSessions';

interface IProps {
  handleConnectToSession: (sessionId: number) => void;
  onSessionCreated?: () => any;
  writeAllSessionsPermission: boolean;
}

const NewTerminalSession = (props: IProps) => {
  const [sessionName, setSessionName] = useState('');
  const [userAccessible, setUserAccessible] = useState(true);
  const { currentMissionId } = useMission();
  const postApiOpsNewTerminalSessions = useApiPostOpsNewTerminalSessions();
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    if (!currentMissionId) {
      return;
    }

    setLoading(true);
    const payload = {
      name: sessionName,
      missionId: currentMissionId.toString(),
      userAccessible: userAccessible,
    };

    const res = await postApiOpsNewTerminalSessions(payload);
    props.onSessionCreated?.();
    setLoading(false);
    setSessionName('');

    return res;
  };

  const handleSubmit = async () => {
    const res = await handleCreate();

    if (res) {
      props.handleConnectToSession(res.id);
    }
  };

  const handleInputChange = (value: string) => {
    setSessionName(value);
  };

  const handleVisibilityChange = () => {
    setUserAccessible(!userAccessible);
  };

  return (
    <Group className={'flex items-center'}>
      <TextField
        fill
        inputProps={{
          placeholder: 'Session name...',
        }}
        value={sessionName}
        onChange={handleInputChange}
      />
      {props.writeAllSessionsPermission && (
        <Tooltip
          delay={0}
          content="Control customer accessibility of the new session"
        >
          <Button
            icon={userAccessible ? 'eye-open' : 'eye-off'}
            className="h-8"
            onPress={handleVisibilityChange}
          />
        </Tooltip>
      )}
      <Button icon={'add-to-artifact'} onPress={handleCreate} className="h-8">
        {loading ? <Spinner /> : <span className="text-sm">Create</span>}
      </Button>
      <Button
        icon={'offline'}
        intent={'primary'}
        onPress={handleSubmit}
        className="h-8"
      >
        {loading ? (
          <Spinner />
        ) : (
          <span className="text-sm whitespace-nowrap">Create and connect</span>
        )}
      </Button>
    </Group>
  );
};

export default NewTerminalSession;
