import {
  useProjects,
  type ScenarioWithPermission,
} from 'datacosmos/stores/ProjectProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button } from 'opencosmos-ui';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useHistory } from 'react-router';
import { useMission } from 'services/Missions';
import { useMemo, useState } from 'react';
import { DeleteModal } from '../DeleteModal';
import type { Scenario } from 'api/scenarios/types';

interface ProjectDetailsProps {
  project: ScenarioWithPermission | Scenario;
  handler: (item: ScenarioWithPermission) => void;
  selectedScenario: ScenarioWithPermission | Scenario | undefined;
  handleEditProject: () => void;
  fromProjectRedirect?: boolean;
}

const ProjectDetails = ({
  project,
  handler,
  selectedScenario,
  fromProjectRedirect,
  handleEditProject,
}: ProjectDetailsProps) => {
  const { translate } = useLocalisation();
  const { sendInfo } = useAnalytics();
  const history = useHistory();

  const { organisations } = useMission();

  const mappedOrganisation = useMemo(() => {
    return organisations?.find(
      (org) =>
        org.id?.toString() ===
        project?.summaries?.['opencosmos:organisation_id']
    );
  }, [project, organisations]);

  const { loadUserScenarios, loadDetailedScenario } = useProjects();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-col grow h-full p-4 gap-2">
      <div className="flex flex-col gap-4">
        <h1 className="p-0 m-0 font-bold text-lg">{project.title}</h1>

        <span>
          {translate('datacosmos.projectsDialog.description')} :{' '}
          {project.description}
        </span>

        <div>
          {translate('datacosmos.projectsDialog.organisation')} :{' '}
          {mappedOrganisation?.name}
        </div>

        {/* TODO: Implement when available */}
        {/* <span>
        {translate('datacosmos.viewsDialog.sharedWith', { with: 'n/a' })}
      </span> */}
      </div>

      <div className="flex items-center justify-evenly gap-2 mt-1">
        <Button
          onPress={() => handler(project as ScenarioWithPermission)}
          icon="Folder"
          iconPlacement="left"
          isMinimal
          size="sm"
        >
          {translate('datacosmos.projectsDialog.openProject')}
        </Button>
        <Button
          onPress={() => {
            history.push(`/resource/project/${project.id}`);
          }}
          icon="ThreePointsHorizontal"
          iconPlacement="left"
          isDisabled={
            !(project as ScenarioWithPermission).hasSharePermission ||
            fromProjectRedirect
          }
          isMinimal
          size="sm"
        >
          {translate('datacosmos.projectsDialog.shareProject')}
        </Button>
        <div className="flex items-center gap-2">
          <Button
            icon="Pencil"
            iconPlacement="left"
            isMinimal
            onPress={async () => {
              sendInfo({
                type: 'Project edit',
                action: 'Click',
                item: 'Edit project button',
                module: 'DataCosmos',
              });
              await loadDetailedScenario();
              handleEditProject();
            }}
            isDisabled={
              !(project as ScenarioWithPermission).hasWritePermission ||
              fromProjectRedirect
            }
            size="sm"
          >
            {translate('datacosmos.projectsDialog.editProject')}
          </Button>
          <Button
            icon="Trash"
            iconPlacement="left"
            onPress={async () => {
              sendInfo({
                type: 'Project delete',
                action: 'Click',
                item: 'Project delete button',
                module: 'DataCosmos',
              });
              await loadDetailedScenario();
              setIsDeleteModalOpen(true);
            }}
            isMinimal
            isDisabled={
              !(project as ScenarioWithPermission).hasWritePermission ||
              fromProjectRedirect
            }
            size="sm"
          >
            {translate('datacosmos.projectsDialog.deleteProject')}
          </Button>
        </div>
      </div>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        scenario={selectedScenario}
        setUserScenarios={loadUserScenarios}
      />
    </div>
  );
};

export default ProjectDetails;
