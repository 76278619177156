import type {
  IModeGroup,
  ISelectedPayloads,
} from '../../../../../../constants/moduleData/types';
import { PAYLOAD_TYPE } from '../../../../../../constants/moduleData/types';
import type { IModuleSelectorActionTypes } from '../../../../../../constants/ui/moduleSelector/actionTypes';
import {
  PAYLOAD_UPDATE,
  CONFIRM_PAYLOAD_SELECTION,
  CANCEL_PAYLOAD_SELECTION,
  ADD_CUSTOM_PAYLOAD,
  UPDATE_CUSTOM_PAYLOAD,
  REMOVE_CUSTOM_PAYLOAD,
  SELECT_PAYLOAD,
  UNSELECT_PAYLOAD,
  MODULE_LOAD,
  MODULE_CLEAR,
} from '../../../../../../constants/ui/moduleSelector/actionTypes';

import { reject, isEqual } from 'lodash/fp';

export const InitialValueFactory = {
  payload: () => ({
    id: '',
    type: Object.keys(PAYLOAD_TYPE)[0],
    data: {
      unitPrice: '0',
      physical: {
        mass: 2,
        xLength: 0.1,
        yLength: 0.1,
        zLength: 0.1,
      },
      thermal: {
        minimumTemperature: 233.15,
        maximumTemperature: 353.15,
      },
      modes: {
        Off: {
          dataGenerationMode: { type: 'FIXED', data: { value: 0 } },
          dataConsumptionMode: { type: 'FIXED', data: { value: 0 } },
          powerConsumptionMode: { type: 'FIXED', data: { value: 0 } },
        },
      },
    },
  }),
  operationalMode: (): IModeGroup => ({
    dataGenerationMode: { type: 'FIXED', data: { value: 1 } },
    dataConsumptionMode: { type: 'FIXED', data: { value: 0 } },
    powerConsumptionMode: { type: 'FIXED', data: { value: 0.5 } },
  }),
};

interface IModuleSelector {
  current?: string;
  selectedPayloads: string[];
  customModules: ISelectedPayloads;
  moduleName: string;
  multipleSelectable: boolean;
}

const initialState: IModuleSelector = {
  current: undefined,
  selectedPayloads: [],
  customModules: {},
  moduleName: undefined,
  multipleSelectable: undefined,
};

function moduleSelectorReducer(
  state: IModuleSelector = initialState,
  action: IModuleSelectorActionTypes
): IModuleSelector {
  switch (action.type) {
    case MODULE_LOAD: {
      return {
        ...state,
        moduleName: action.payload.moduleName,
        selectedPayloads:
          action.payload.satelliteModules[action.payload.moduleName],
        multipleSelectable: action.payload.multipleSelectable,
      };
    }
    case MODULE_CLEAR: {
      return {
        current: undefined,
        selectedPayloads: [],
        customModules: {},
        moduleName: undefined,
        multipleSelectable: undefined,
      };
    }
    case PAYLOAD_UPDATE: {
      return {
        ...state,
        current: action.payloadName,
      };
    }
    case ADD_CUSTOM_PAYLOAD: {
      return {
        ...state,
        customModules: {
          ...state.customModules,
          [action.payload.id]: action.payload,
        },
        current: action.payload.id,
        selectedPayloads: [...state.selectedPayloads, action.payload.id],
      };
    }
    case UPDATE_CUSTOM_PAYLOAD: {
      const newPayloads = { ...state.customModules };
      delete newPayloads[action.oldName];
      newPayloads[action.payload.id] = action.payload;

      const newSelectedPayloads = reject(
        isEqual(action.oldName),
        state.selectedPayloads
      );
      newSelectedPayloads.push(action.payload.id);

      return {
        ...state,
        customModules: newPayloads,
        current: action.payload.id,
        selectedPayloads: newSelectedPayloads,
      };
    }
    case REMOVE_CUSTOM_PAYLOAD: {
      const newPayloads = { ...state.customModules };
      newPayloads[action.payloadName] && delete newPayloads[action.payloadName];

      return {
        ...state,
        customModules: newPayloads,
        current: undefined,
        selectedPayloads: reject(
          isEqual(action.payloadName),
          state.selectedPayloads
        ),
      };
    }
    case SELECT_PAYLOAD: {
      const newlySelectedPayloads = state.multipleSelectable
        ? [...state.selectedPayloads, action.payloadName]
        : [action.payloadName];
      return {
        ...state,
        selectedPayloads: newlySelectedPayloads,
      };
    }
    case UNSELECT_PAYLOAD: {
      return {
        ...state,
        selectedPayloads: reject(
          isEqual(action.payloadName),
          state.selectedPayloads
        ),
      };
    }
    case CONFIRM_PAYLOAD_SELECTION: {
      return {
        ...state,
        selectedPayloads: [...state.selectedPayloads, state.current],
      };
    }
    case CANCEL_PAYLOAD_SELECTION: {
      return {
        ...state,
        selectedPayloads: reject(
          isEqual(state.current),
          state.selectedPayloads
        ),
      };
    }
    default:
      return state;
  }
}

export default moduleSelectorReducer;
