import { Input, Popover, TextField } from 'opencosmos-ui';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { UpdatedActivityMap, UpdatedActivityValue } from '../..';

type Props = {
  /**
   * inputFor prop is used to specify which field in the activity object
   * the input is for. This is used to update the correct field in the
   * updatedActivitiesMap.
   */
  inputFor?: keyof UpdatedActivityValue;
  /**
   * value prop is the initial value of the input.
   */
  value: string;
  activityId: string;
  setUpdatedActivitiesMap?: Dispatch<SetStateAction<UpdatedActivityMap>>;
  readOnly?: boolean;
  isDisabled?: boolean;
};

const InputCellWithPopover = ({
  setUpdatedActivitiesMap,
  value,
  activityId,
  readOnly,
  inputFor,
  isDisabled,
}: Props) => {
  const [val, setVal] = useState<string | undefined>(String(value));

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const popoverTrigger = useRef<HTMLInputElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    if (val === value) {
      return;
    }

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      if (!inputFor) {
        return;
      }
      setUpdatedActivitiesMap?.((prev) => {
        return {
          ...prev,
          [activityId]: {
            ...prev[activityId],
            [inputFor]: val,
          },
        };
      });
    }, 500);
  }, [activityId, value, setUpdatedActivitiesMap, val, inputFor]);

  return (
    <>
      <TextField
        value={val}
        onChange={(v) => setVal(v)}
        inputProps={{
          inputRef: popoverTrigger,
          onClick: () => setIsPopoverOpen(!isPopoverOpen),
          className: 'bg-transparent border-none p-1 overflow-ellipsis',
          autoComplete: 'off',
        }}
        isReadOnly={readOnly ?? isPopoverOpen}
        isDisabled={isDisabled}
      />

      <Popover
        placement="bottom"
        triggerRef={popoverTrigger}
        className={'bg-item dark:bg-item-dark p-1 dark:text-item-dark-contrast'}
        isOpen={isPopoverOpen}
        isNonModal={true}
        style={{
          width: popoverTrigger.current?.clientWidth ?? 0,
        }}
      >
        <Input
          type="textarea"
          value={val}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setVal(e.target.value)}
          readOnly={readOnly}
        />
      </Popover>
    </>
  );
};

export default InputCellWithPopover;
