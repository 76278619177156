import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import { CommandList } from 'pages/ops/RTI/Operate/components/CommandList';
import type { UnarmedCommand } from 'services/api/ops/realtimeTerminalSession';
import type { IBottomBarTab } from 'pages/ops/Library/components/BottomNavBar';
import BottomNavBar from 'pages/ops/Library/components/BottomNavBar';
import {
  LEFT_TAB_STATES,
  MAP_TAG_TO_STORAGE,
} from 'constants/ops/rti/oparate/constants';
import { TABLET_WIDTH } from 'constants/screen';
import { useAlertManager } from 'services/api/telemetry/useAlertManager';
import { useApiSatelliteStorageContent } from 'services/api/ops/satelliteStorage';

import LibraryFileTab from './LibraryFileTab';
import TelemetriesTab from './TelemetriesTab';

import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import type { CommandDefinition } from 'api/telecommands/types';
import { Button } from 'opencosmos-ui';

const NON_NOTIFICATIONS_OPERATE_TABS: IBottomBarTab[] = [
  {
    id: LEFT_TAB_STATES.FILE,
    title: 'Files',
    icon: 'folder-close',
  },
  {
    id: LEFT_TAB_STATES.CMD,
    title: 'Commands',
    icon: 'key-command',
  },
];

export interface IProps {
  availableCommands: CommandDefinition[];
  missionId?: number;
  isSideHidden: boolean;
  areCommandsFetching?: boolean;
  exploringStorageName?: string;
  handleSideHiding: (value: boolean) => void;
  setExploringStorageName: (value: string) => void;
  handleSelectSidePanelCommand: (cmd: CommandDefinition) => void;
  sendUnarmedCommand: UnarmedCommand;
  cancelSentCommand: Function;
  setRightSideHidden: (value: boolean) => void;
}

function Library({
  exploringStorageName,
  handleSideHiding,
  setExploringStorageName,
  ...props
}: IProps) {
  const [selectedTab, setSelectedTab] = useState(LEFT_TAB_STATES.CMD);
  const alertManagerApi = useAlertManager();
  const { sendInfo } = useAnalytics();

  useEffect(() => {
    if (exploringStorageName) {
      setSelectedTab(get(exploringStorageName, MAP_TAG_TO_STORAGE) as string);
      handleSideHiding(false);
      setExploringStorageName('');
    }
  }, [exploringStorageName, handleSideHiding, setExploringStorageName]);

  const handleTabChange = (tab: string) => {
    if (selectedTab === tab) {
      sendInfo({
        type: `Library tab ${tab} hide`,
        action: 'Click',
        item: 'Library tab: ' + tab,
        additionalParams: {
          hiddenTab: tab,
        },
        module: 'OPS',
      });

      handleSideHiding(true);
      return;
    }

    sendInfo({
      type: `Library tab ${tab} click`,
      action: 'Click',
      item: 'Library tab: ' + tab,
      additionalParams: {
        clickedTab: tab,
      },
      module: 'OPS',
    });

    setSelectedTab(tab);
  };

  const handleHiddenSideClick = (tab: string) => {
    handleSideHiding(false);

    sendInfo({
      type: `Hidden library tab ${tab} click`,
      action: 'Click',
      item: 'Hidden side library tab: ' + tab,
      additionalParams: {
        clickedTab: tab,
      },
      module: 'OPS',
    });
    setSelectedTab(tab);
  };

  const satelliteApi = useApiSatelliteStorageContent(
    props.sendUnarmedCommand,
    props.availableCommands,
    props.setRightSideHidden,
    props.cancelSentCommand,
    props.missionId
  );

  const OPERATE_LEFT_BOTTOM_NAVBAR_TABS: IBottomBarTab[] = [
    ...NON_NOTIFICATIONS_OPERATE_TABS,
    {
      id: LEFT_TAB_STATES.TM,
      title: 'Alerts',
      icon: 'notifications',
      notifications: alertManagerApi.numberOfActiveAlerts,
    },
  ];

  const renderHiddenNavBar = (
    <div className="flex flex-col gap-2">
      {OPERATE_LEFT_BOTTOM_NAVBAR_TABS.map((tab) => (
        <Button
          isMinimal={true}
          key={tab.id}
          icon={tab.icon}
          onPress={() => handleHiddenSideClick(tab.id)}
        />
      ))}
    </div>
  );

  const renderLibraryBody = {
    file: (
      <LibraryFileTab
        satelliteApi={satelliteApi}
        missionId={props.missionId}
        availableCommands={props.availableCommands}
        sendUnarmedCommand={props.sendUnarmedCommand}
        setRightSideHidden={props.setRightSideHidden}
        cancelSentCommand={props.cancelSentCommand}
      />
    ),
    cmd: (
      <CommandList
        loading={Boolean(props.areCommandsFetching)}
        availableCommands={props.availableCommands}
        handleCommandClick={props.handleSelectSidePanelCommand}
      />
    ),
    tm: <TelemetriesTab alertManagerApi={alertManagerApi} />,
  };

  return props.isSideHidden ? (
    renderHiddenNavBar
  ) : (
    <div className={s.main + ' bg-surface dark:bg-surface-dark'}>
      <div className={s.content}>{get(selectedTab, renderLibraryBody)}</div>
      <BottomNavBar
        iconOnlyOnWidth={TABLET_WIDTH}
        selectedTab={selectedTab}
        setSelectedTab={handleTabChange}
        tabList={OPERATE_LEFT_BOTTOM_NAVBAR_TABS}
      />
    </div>
  );
}

export default Library;
