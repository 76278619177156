import type {
  IUserState,
  userTypes,
} from '../../../../../constants/user/actionTypes';
import { USER_SET } from '../../../../../constants/user/actionTypes';

const initialState: IUserState = null;

export default function userInformationReducer(
  state: IUserState = initialState,
  action: userTypes
): IUserState {
  switch (action.type) {
    case USER_SET: {
      return {
        ...action.userInformation,
      };
    }
    default: {
      return state;
    }
  }
}
