import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import {
  copyToClipBoard,
  showErrorMessage,
  showSuccessMessage,
} from 'utils/common/CommonUtils';
import { useAnalytics } from '../../../../../../utils/hooks/analytics/useAnalytics';
import type { ICommandPileItem } from 'services/api/ops/realtimeTerminalSession';
import { v1 as uuid } from 'uuid';
import { useCurrentTerminalSession } from 'pages/ops/RTI/Operate/context/TerminalSessionProvider';

export type PileItemWithIndex = ICommandPileItem & { indexInPile: number };

/**
 * useCommandCopyPaste provides the utility functions for copying
 * and pasting commands, command piles and armed commands based on
 * the `operate` state.
 */
export const useCommandCopyPaste = () => {
  const {
    state: {
      selectedMultiplePileItems,
      selectedPileItem,
      armedCommand,
      commandPileWorkingSpace,
      frozenPile,
    },
  } = useOperate();

  const { updateWorkspace } = useCurrentTerminalSession();

  const { sendInfo } = useAnalytics();

  const isFrozenPile = Boolean(frozenPile);

  /**
   * getSelectedItemsWithIndex returns the selected items with their index in the pile.
   * @returns The selected items with their index in the pile.
   */
  const getSelectedItemsWithIndex = () => {
    return selectedMultiplePileItems?.map((smpi) => ({
      ...smpi,
      indexInPile: commandPileWorkingSpace?.findIndex(
        (cmd) => cmd.id === smpi.id
      ),
    }));
  };

  /**
   * handleCopyCommands copies the selected commands or multiple selected commands to
   * the clipboard.
   */
  const handleCopySelectedCommands = () => {
    const selectedCommandsWithIndex = getSelectedItemsWithIndex();

    if (
      selectedCommandsWithIndex?.length &&
      selectedCommandsWithIndex?.length > 0
    ) {
      copyToClipBoard(
        JSON.stringify(
          {
            pileItem: selectedCommandsWithIndex,
          },
          null,
          2
        )
      );
      return;
    }
    copyToClipBoard(
      JSON.stringify(
        {
          pileItem: selectedPileItem,
        },
        null,
        2
      )
    );
  };

  /**
   * handleCopyCommandPileAndArmedCommands copies the command pile and armed commands
   * to the clipboard.
   */
  const handleCopyCommandPileAndArmedCommands = () => {
    sendInfo({
      type: 'Command pile copy',
      action: 'Click',
      item: 'Command pile copy button',
      module: 'OPS',
    });
    copyToClipBoard(
      JSON.stringify(
        {
          armedCommand: armedCommand,
          pileItems: commandPileWorkingSpace,
        },
        null,
        2
      )
    );
  };

  /**
   * handlePasteCommandPileAndArmedCommands pastes the command pile and armed commands from
   * the clipboard.
   */
  const handlePasteCommandPileAndArmedCommands = () => {
    sendInfo({
      type: 'Command pile paste',
      action: 'Click',
      item: 'Command pile paste button',
      module: 'OPS',
    });
    void navigator.clipboard.readText().then((payload) => {
      try {
        const parsedPayload = JSON.parse(payload) as Record<string, object>;
        const parsedArmedCommand = parsedPayload.armedCommand;
        const pileItems = parsedPayload.pileItems;

        updateWorkspace(pileItems, parsedArmedCommand, Boolean(isFrozenPile));
        showSuccessMessage('Command pile was pasted');
      } catch (e) {
        showErrorMessage('Could not parse command pile.');
      }
    });
  };

  /**
   * pasteSingleCmd pastes a single selected command to the workspace.
   * @param workspace - The workspace to paste the command to.
   * @param item - The command to paste.
   * @param index - The index of the command to paste.
   */
  const pasteSingleCmd = (
    workspace: ICommandPileItem[],
    item: PileItemWithIndex,
    index: number
  ) => {
    // Add the copied pile item to the workspace, with a new id to avoid double selection
    workspace.splice(index + 1, 0, { ...item, id: uuid() });
    updateWorkspace(workspace, armedCommand, isFrozenPile);
  };

  /**
   * pasteMultipleCmds pastes multiple selected commands to the workspace.
   * @param workspace - The workspace to paste the command to.
   * @param items - The commands to paste.
   * @param index - The index of the command to paste.
   */
  const pasteMultipleCmds = (
    workspace: ICommandPileItem[],
    items: PileItemWithIndex[],
    index: number
  ) => {
    // Add the copied pile item to the workspace, with a new id to avoid double selection
    workspace.splice(
      index + 1,
      0,
      ...items
        .map((item) => ({ ...item, id: uuid() }))
        // Sort by index in pile to keep the order of the copied commands
        .sort((a, b) => a.indexInPile - b.indexInPile)
    );
    updateWorkspace(workspace, armedCommand, isFrozenPile);
  };

  /**
   * handlePasteCmd pastes a selected command or multiple selected commands
   * from the clipboard to the workspace, under the selected command.
   * @param cmdIndex - The index of the selected command to paste under.
   */
  const handlePasteCmd = async (cmdIndex: number) => {
    const copiedCmdString = await navigator.clipboard.readText();

    const { pileItem } = JSON.parse(copiedCmdString) as {
      pileItem: PileItemWithIndex | PileItemWithIndex[];
    };

    if (!pileItem) {
      return;
    }

    // Clone the pile item to avoid changing the original one
    const workspaceWithPastedCmd = commandPileWorkingSpace ?? [];

    if (pileItem instanceof Array) {
      pasteMultipleCmds(workspaceWithPastedCmd, pileItem, cmdIndex);
      return;
    }

    pasteSingleCmd(workspaceWithPastedCmd, pileItem, cmdIndex);
  };

  return {
    handleCopySelectedCommands,
    handleCopyCommandPileAndArmedCommands,
    handlePasteCommandPileAndArmedCommands,
    handlePasteCmd,
  };
};
