import { useEffect, useState } from 'react';
import Icon from '_atoms/Icon/Icon';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { Tooltip } from 'ui/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button, DialogTrigger } from 'opencosmos-ui';
import Dialog from './ControlDialog';

type Props = {
  disabled: boolean;
  saturationSupportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
};

const SaturationControl = (props: Props) => {
  const { translate } = useLocalisation();
  const [saturation, setSaturation] = useState<number>(1);

  useEffect(() => {
    setSaturation(props.saturationSupportedLayers[0]?.options.saturation);
  }, [props.saturationSupportedLayers]);
  return (
    <DialogTrigger>
      <Tooltip
        content={
          props.disabled
            ? `${translate('datacosmos.layers.saturation')}-${translate(
                'datacosmos.layers.layerOperationDisabled'
              )}`
            : translate('datacosmos.layers.saturation')
        }
      >
        <Button
          icon="ImageSaturation"
          size="lg"
          className={`px-0 ${
            props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }`}
          isDisabled={props.disabled}
          isMinimal
          isTransparent
        />
      </Tooltip>
      <Dialog>
        <div data-testid="brightness-popup">
          <RangeSlider
            className="px-2"
            label={{
              text: translate('datacosmos.layers.saturation'),
              icon: <Icon icon="Saturation" />,
            }}
            showScale={false}
            showValuesAboveHandles
            numberOfHandles={1}
            minValue={0.1}
            step={0.1}
            maxValue={2}
            value={[saturation]}
            onChange={(val) => {
              setSaturation(val[0]);
            }}
            onChangeEnd={(val) => {
              props.replaceLayer(
                props.saturationSupportedLayers[0].cloneWithOptions({
                  saturation: val[0],
                  isSettingSaturation: true,
                })
              );
            }}
          />
        </div>
      </Dialog>
    </DialogTrigger>
  );
};

export default SaturationControl;
