import React from 'react';
import { Suggest } from '@blueprintjs/select';
import { MenuItem, PopoverPosition } from '@blueprintjs/core';
import Fuse from 'fuse.js';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import type { CommandDefinition } from 'api/telecommands/types';

interface IProps {
  className?: string;
  availableCommands: CommandDefinition[];
  selectedCommand: CommandDefinition | undefined;
  handleSelectCommand: (cmd: CommandDefinition) => void;
}

const CommandSuggest = Suggest.ofType<CommandDefinition>();

function renderInputValue(cmd: CommandDefinition) {
  return cmd.name ?? '';
}

function escapeRegExpChars(text: string) {
  // eslint-disable-next-line no-useless-escape
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens: React.ReactNode[] = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

const options: Fuse.FuseOptions<CommandDefinition> = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  keys: ['name'],
};

const filterCommands = (
  query: string,
  items: CommandDefinition[]
): CommandDefinition[] => {
  const fuse = new Fuse(items, options);
  const searchResults = fuse.search(query) as CommandDefinition[];
  return searchResults.slice(0, 10);
};

// TODO: Re-implement suggestions
function CommandInput(props: IProps) {
  const {
    state: { selectedMultiplePileItems },
  } = useOperate();
  return (
    <div className={props.className + ' w-full'}>
      <CommandSuggest
        selectedItem={
          selectedMultiplePileItems?.length ? null : props.selectedCommand
        }
        inputValueRenderer={renderInputValue}
        itemListPredicate={filterCommands}
        itemRenderer={(
          command: CommandDefinition,
          { handleClick, modifiers, query }
        ) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }

          if (!command.scope) {
            return null;
          }

          const text = `${command.name}`;
          return (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={command.name}
              onClick={handleClick}
              text={highlightText(text, query)}
            />
          );
        }}
        items={props.availableCommands}
        onItemSelect={(item) => {
          props.handleSelectCommand(item);
        }}
        popoverProps={{ minimal: true, position: PopoverPosition.TOP_LEFT }}
        fill
        closeOnSelect
        resetOnQuery
        openOnKeyDown={false}
        resetOnClose={false}
        resetOnSelect={false}
        noResults={<MenuItem disabled text="No results." />}
        inputProps={{ placeholder: 'Search for a command to send...' }}
      />
    </div>
  );
}

export default CommandInput;
