import useResource from 'pages/portal/shared/hooks/useResource';
import ResourceUsers from 'datacosmos/components/ResourcePages/ResourceUsers';
import ResourcePermissionsManager from 'pages/portal/shared/components/PermissionManager/ResourcePermissionsManager';
import { ROUTE_RESOURCE_TYPE_MAPPING } from 'constants/resourcePages/constants';
import type { PermissionType } from 'api/administration/permissions';

interface ResourceProps {
  resourceId: string;
  resourceType: string;
}
const ResourceLayout = ({ resourceId, resourceType }: ResourceProps) => {
  const typeOfResource =
    resourceType as keyof typeof ROUTE_RESOURCE_TYPE_MAPPING;

  const {
    availableRoles,
    resourceRoleAssignments,
    assignRole,
    deleteRoleAssignment,
    resourceRoleAttachments,
    getRoleAttachmentsByResourceTypeAndId,
  } = useResource(
    ROUTE_RESOURCE_TYPE_MAPPING[typeOfResource] as PermissionType,
    resourceId
  );

  return (
    <>
      <ResourceUsers
        currentId={resourceId}
        assignRole={assignRole}
        deleteRoleAssignments={(
          userId: string,
          assignmentIds: number[],
          showDeletedMessage?: boolean
        ) => deleteRoleAssignment(assignmentIds, showDeletedMessage)}
        availableRoles={availableRoles}
        roleAssignments={resourceRoleAssignments}
      />
      <div className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      {resourceRoleAttachments && (
        <ResourcePermissionsManager
          resourceAttachments={resourceRoleAttachments}
          resourceType="datacosmos_scenario"
          resourceId={resourceId}
          getAttachmentsCallback={() => getRoleAttachmentsByResourceTypeAndId()}
          availableRoles={availableRoles}
        />
      )}
    </>
  );
};

export default ResourceLayout;
