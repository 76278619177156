import { DetailsItem } from 'pages/ops/shared/components/DetailsItem';
import { ListBoxItem, Select } from 'opencosmos-ui';
import { useState } from 'react';
import { base64toUTF8 } from 'utils/common/base64Utils';

export type TelemetryPoint = {
  id?: number;
  booleanValue?: boolean;
  integerValue?: number | string;
  floatValue?: number;
  bytesValue?: string;
};

export type TelemetryGetSetResponse = {
  seqId: number;
  telemetryPoint: (TelemetryPoint | { compoundValue: TelemetryPoint[] }) & {
    timestamp?: string;
  };
};

type Props = {
  response: TelemetryGetSetResponse;
};

const TelemetryGetSetReply = ({ response }: Props) => {
  const getIsCompound = (
    value: (TelemetryPoint | { compoundValue: TelemetryPoint[] }) | undefined
  ) => {
    return (
      (value as { compoundValue: TelemetryPoint[] })?.compoundValue !==
      undefined
    );
  };

  const [showUtfByte, setShowUtfByte] = useState<boolean>(false);

  const getValueType = (
    value: TelemetryPoint | { compoundValue: TelemetryPoint[] } | undefined
  ) => {
    if (getIsCompound(value)) {
      return 'compound';
    }

    return Object.keys(value ?? {})
      .filter((key) => key.toLowerCase().includes('value'))
      .map((key) => {
        return key.replace(/Value/g, '');
      })[0];
  };

  const getSingleValue = (value: TelemetryPoint | undefined) => {
    return String(
      Object.entries(value ?? {})
        .filter(([key]) => key.toLowerCase().includes('value'))
        .find(([, v]) => v !== undefined)?.[1]
    );
  };

  const renderCompoundValue = (
    value: { compoundValue: TelemetryPoint[] } | undefined
  ) => {
    return (
      <div className="w-full">
        {value?.compoundValue.map((v, i) => {
          return (
            <div
              key={v.id}
              className="w-full flex justify-between odd:bg-surface p-1"
            >
              <span>Value {i}</span>
              <span>{getSingleValue(v)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSingleValue = (value: TelemetryPoint | undefined) => {
    return (
      <DetailsItem
        title={'Value'}
        value={
          showUtfByte
            ? String(
                base64toUTF8(
                  String(
                    Object.entries(value ?? {})
                      .filter(([key]) => key.toLowerCase().includes('value'))
                      .find(([, v]) => v !== undefined)?.[1]
                  )
                )
              )
            : String(
                Object.entries(value ?? {})
                  .filter(([key]) => key.toLowerCase().includes('value'))
                  .find(([, v]) => v !== undefined)?.[1]
              )
        }
      />
    );
  };

  const renderValues = (
    value: TelemetryPoint | { compoundValue: TelemetryPoint[] } | undefined
  ) => {
    if (!value) {
      return <div />;
    }

    if (getIsCompound(value)) {
      return renderCompoundValue(value as { compoundValue: TelemetryPoint[] });
    }

    return renderSingleValue(value as TelemetryPoint);
  };

  return (
    <div className="w-full bg-neutral-300 mb-2 flex flex-col gap-3 px-2">
      <div className="w-full flex items-center justify-between">
        <span className="ml-1">TelemetryGetSetResponse</span>
        <Select
          placeholder="Value format"
          onSelectionChange={(key) => {
            switch (key) {
              case 'utf-8':
                setShowUtfByte(true);
                break;
              case 'base64':
                setShowUtfByte(false);
                break;
            }
          }}
        >
          <ListBoxItem id="utf-8">UTF-8</ListBoxItem>
          <ListBoxItem id="base64">Base64</ListBoxItem>
        </Select>
      </div>
      <div className="flex flex-col gap-3">
        <DetailsItem title="Sequence id: " value={String(response.seqId)} />
        <DetailsItem
          title="Type: "
          value={getValueType(response.telemetryPoint) ?? 'Unknown'}
        />
        {renderValues(response.telemetryPoint)}
        <DetailsItem
          title="Timestamp"
          value={String(response.telemetryPoint?.timestamp)}
        />
      </div>
    </div>
  );
};

export default TelemetryGetSetReply;
