import {
  DatePickerProps,
  DateValue,
  ValidationResult,
} from "react-aria-components";
import {
  Dialog,
  Text,
  DatePicker,
  Button,
  Popover,
  Calendar,
  CalendarGrid,
  CalendarCell,
  Heading,
} from "react-aria-components";
import Icon from "../../icons/Icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { parseAbsolute } from "@internationalized/date";
import classNames from "classnames";
import { DatePickerState } from "react-stately";
import moment from "moment";

// Supported output formats for the text field input
export type ValidOutputFormats = "DD/MM/YYYY" | "YYYY-MM-DDTHH:mm:ss.sss[Z]";

export type DateAndTimePickerPropsTextFieldInput<T extends DateValue> =
  DatePickerProps<T> & {
    label?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    /**
     * useTextDateInput is a boolean that determines whether the date input should be a simple text field
     * or a segmented date input
     */
    useTextDateInput: true;
    /**
     * outputFormat is the format that the text field input will display the date in
     */
    outputFormat: ValidOutputFormats;
  };

// Date formats that will get parsed into ISO 8601
const VALID_DATE_FORMATS = [
  "YYYY-MM-DDTHH:mm:ssZ",
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
  "YYYY/MM/DD HH:mm:ss",
  "YYYY/MM/DD HH:mm:ss.SSS",
  "YYYY/MM/DD",
  "DD/MM/YYYY",
  "X", // Unix timestamp
];

function DateAndTimePickerTextFieldInput<T extends DateValue>({
  label,
  description,
  errorMessage,
  useTextDateInput,
  outputFormat,
  ...props
}: DateAndTimePickerPropsTextFieldInput<T>) {
  const DateInputRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<string>(
    props.value?.toDate("Etc/UTC").toISOString() ?? ""
  );

  const [error, setError] = useState<string | undefined>();

  const debounceTimer = useRef<number | null>(null);

  const debouncedOnChangeHandler = useCallback(
    (val: string, state: DatePickerState) => {
      if (debounceTimer.current !== null) {
        clearTimeout(debounceTimer.current);
      }

      setValue(val);

      debounceTimer.current = window.setTimeout(() => {
        try {
          state.setValue(val ? parseAbsolute(val, "Etc/UTC") : null);
          setError(undefined);
        } catch (error) {
          setError(`Unsupported format ${error}`);
        }
      }, 500);
    },
    []
  );

  const timer = useRef<number | null>(null);
  useEffect(() => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      VALID_DATE_FORMATS.map((f) => {
        const parsed = moment.utc(value, f, true);
        if (parsed.isValid()) {
          setValue(parsed.format(outputFormat));
          setError(undefined);
        }
      });
    }, 500);
  }, [value]);

  useEffect(() => {
    let timeZoneElement = document.querySelector("[data-type='timeZoneName']");

    if (timeZoneElement) {
      timeZoneElement.className += " absolute right-7";
    }
  }, []);

  return (
    <DatePicker {...props} className={"w-full bg-item dark:bg-item-dark"}>
      {({ state }) => {
        return (
          <>
            <div
              className={
                "flex p-1 gap-1 text-sm border-2 border-item w-full focus-within:border-accent-400 focus-within:shadow-md"
              }
              ref={DateInputRef}
            >
              <input
                placeholder={outputFormat}
                onChange={(e) => {
                  debouncedOnChangeHandler(e.target.value, state);
                }}
                value={value}
                className={classNames(
                  "w-full dark:text-item-dark-contrast bg-item dark:bg-item-dark px-1 bg-transparent focus:outline-none",
                  {
                    "bg-item-contrast-inactive cursor-not-allowed":
                      props.isDisabled,
                  }
                )}
                disabled={props.isDisabled}
              />

              <Button data-testid="calendar-trigger">
                <Icon
                  icon="ChevronDown"
                  className="stroke-item-contrast dark:stroke-item-dark-contrast"
                />
              </Button>
            </div>
            {description && <Text slot="description">{description}</Text>}
            <span className="text-sm text-warning">{error}</span>
            <Popover
              className={
                "!z-[9999999] bg-surface dark:bg-surface-dark dark:text-item-dark-contrast absolute"
              }
              triggerRef={DateInputRef}
            >
              <Dialog className="px-2">
                <Calendar
                  minValue={props.minValue}
                  onChange={(val) => {
                    setValue(
                      moment.utc(val.toDate("Etc/UTC")).format(outputFormat)
                    );
                  }}
                  value={state.value}
                >
                  <header className="flex justify-between p-1">
                    <Button slot="previous">
                      <Icon icon="ChevronLeft" />
                    </Button>
                    <Heading className="text-sm font-bold" />
                    <Button slot="next">
                      <Icon icon="ChevronRight" />
                    </Button>
                  </header>
                  <CalendarGrid className="text-center">
                    {(date) => (
                      <CalendarCell
                        date={date}
                        className={({ isSelected }) =>
                          classNames(
                            "w-[26px] h-fit max-h-20 min-h-full min-w-full p-1 flex flex-col text-[12px] hover:text-accent-900 dark:hover:text-accent hover:bg-item-selected dark:hover:bg-item-dark-selected aria-disabled:text-contrast-inactive aria-disabled:opacity-50 aria-disabled:pointer-events-none",
                            {
                              "bg-item-selected dark:bg-item-dark-selected":
                                isSelected,
                            }
                          )
                        }
                      />
                    )}
                  </CalendarGrid>
                </Calendar>{" "}
              </Dialog>
            </Popover>
          </>
        );
      }}
    </DatePicker>
  );
}

export default DateAndTimePickerTextFieldInput;
