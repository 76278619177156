import { useCallback, useEffect, useMemo, useState } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { ActivityDetailsCard } from 'pages/ops/Scheduling/components/DetailCards/ActivityDetailsCard';
import s from './index.module.scss';
import { RequestDetailsCard } from 'pages/ops/Scheduling/components/DetailCards/RequestDetailsCard';
import { ActivitiesMap } from 'pages/ops/Scheduling/components/ActivitiesMap';
import type { Activity } from 'api/activities/types';
import { patchActivity } from 'api/activities/service';
import type { TaskingRequest } from 'api/tasking/service';
import { useRouteMatch } from 'react-router';
import { Button, Tooltip } from 'opencosmos-ui';
import FullscreenMap from '../ActivitiesMap/FullscreenMap';
import type { UpdatedActivityMap } from '../..';

type ScheduleActivityDetailsProps = {
  activities: Activity[] | undefined;
  requests: TaskingRequest[];
  missionId: string;
  refetchActivities: () => Promise<void>;
  setModifiableActivities: (activities: Activity[]) => void;
  updatedActivitiesMap: UpdatedActivityMap;
};

export const ScheduleActivityDetails = ({
  activities,
  requests,
  missionId,
  refetchActivities,
  setModifiableActivities,
  updatedActivitiesMap,
}: ScheduleActivityDetailsProps) => {
  const urlMatch = useRouteMatch<{ activity?: string }>({
    path: '/ops/mission/:mission/schedule/activity/:activity',
    exact: false,
  });

  const selectedActivity = useMemo(() => {
    return activities?.find(({ id }) => id === urlMatch?.params.activity);
  }, [activities, urlMatch?.params.activity]);

  const request = requests.find(
    (r) => r.id === selectedActivity?.tasking_request_ids[0]
  );

  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);

  const [selectedStatus, setSelectedStatus] = useState<
    Activity['status'] | undefined
  >(
    updatedActivitiesMap[selectedActivity?.id ?? '']?.status ??
      selectedActivity?.status
  );

  const [operatorNotes, setOperatorNotes] = useState<string | undefined>(
    selectedActivity?.operator_notes
  );

  const updateActivityStatus = useCallback(async () => {
    if (!selectedActivity?.id) {
      return;
    }
    const { success } = await patchActivity({
      params: { missionId, activityId: selectedActivity.id },
      body: { status: selectedStatus },
    });

    if (success && selectedStatus) {
      setModifiableActivities(
        activities?.map((a) =>
          a.id === selectedActivity.id
            ? { ...a, status: selectedStatus ?? a.status }
            : a
        ) ?? []
      );
    }
  }, [
    activities,
    missionId,
    selectedActivity?.id,
    selectedStatus,
    setModifiableActivities,
  ]);

  const updateActivityWithNotes = useCallback(async () => {
    if (!selectedActivity) {
      return;
    }

    if (operatorNotes === undefined) {
      return;
    }

    await patchActivity({
      params: { missionId, activityId: selectedActivity.id },
      body: { operator_notes: operatorNotes },
    });

    await refetchActivities();
  }, [missionId, operatorNotes, refetchActivities, selectedActivity]);

  useEffect(() => {
    if (selectedActivity) {
      setSelectedStatus(selectedActivity.status);
      setOperatorNotes(selectedActivity.operator_notes);
    }
  }, [selectedActivity, selectedActivity?.status]);

  if (!selectedActivity) {
    return (
      <NonIdealState
        icon={'help'}
        title="No activity selected"
        description="Select an activity to view it here"
      />
    );
  }

  if (!request) {
    return (
      <NonIdealState
        icon={'error'}
        title="An error happened"
        description={'Could not find the request for this activity'}
      />
    );
  }

  const isEditable = (activity: Activity) => {
    return (
      activity.status !== 'CANCELLED' &&
      activity.status !== 'FAILED' &&
      activity.status !== 'EXPIRED' &&
      activity.status !== 'SCHEDULED'
    );
  };

  return (
    <div className={s.container} data-testid="activity-details">
      {request && (
        <ActivitiesMap
          request={request}
          activity={selectedActivity}
          mapElements={
            <Tooltip
              isDisabled={isEditable(selectedActivity)}
              content={
                'Failed, expired, canceled and scheduled activities cannot be edited'
              }
              delay={0}
            >
              <Button
                icon={'edit'}
                onPress={() => setIsMapOpen(isMapOpen ? false : true)}
                isDisabled={!isEditable(selectedActivity)}
              />
            </Tooltip>
          }
          aspectRatio="16/9"
          autoHeight
        />
      )}
      <ActivityDetailsCard
        activity={selectedActivity}
        updateActivityStatus={updateActivityStatus}
        setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus}
        operatorNotes={operatorNotes}
        updateActivityWithOperatorNotes={updateActivityWithNotes}
        setOperatorNotes={setOperatorNotes}
      />
      {request && (
        <RequestDetailsCard
          request={request}
          totalActivities={request.activities.length}
          refetchData={refetchActivities}
        />
      )}

      <FullscreenMap
        activities={activities?.filter((a) =>
          request?.activities.some((act) => a.id === act.id)
        )}
        request={request}
        isMapOpen={isMapOpen}
        setIsMapOpen={setIsMapOpen}
        activityToModify={selectedActivity}
        refetchActivities={refetchActivities}
      />
    </div>
  );
};
