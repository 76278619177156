import { useRef } from "react";
import { useCalendar, useLocale, VisuallyHidden } from "react-aria";
import { useCalendarState } from "react-stately";
import type { CalendarDate } from "@internationalized/date";
import { createCalendar, parseDate } from "@internationalized/date";
import type { CalendarStateOptions } from "@react-stately/calendar";
import moment from "moment";
import type { PressEvent } from "@react-types/shared";
import CalendarGrid from "../Common/CalendarGrid";
import Button from "../../../core/Button/Button";

export type CellItemsByDate = {
  id: string | number;
  date: Date;
  item: React.ReactNode;
};

type CalendarProps = Omit<
  Omit<CalendarStateOptions, "locale">,
  "createCalendar"
> & {
  /**
   * Dates to highlight in the calendar.
   */
  highligtedDates: Date[];
  /**
   * Items to display in the calendar cell for a given date.
   */
  cellItemsByDate?: CellItemsByDate[];
};

/**
 * Calendar is a component that displays a calendar.
 * It can be used to select a date.
 * It can also display items in the calendar cells.
 * It has a header with the month and year.
 * It allows for date highlighting.
 */
const Calendar = (props: CalendarProps) => {
  const { locale } = useLocale();

  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    visibleDuration: { months: 1 },
  });

  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state
  );
  let parsedHighlightedDates: CalendarDate[] = [];
  try {
    parsedHighlightedDates = props.highligtedDates.map((d) =>
      parseDate(moment(d).format("YYYY-MM-DD"))
    );
  } catch (error) {
    parsedHighlightedDates = [];
  }

  const getMonthName = (month: number) => {
    const date = new Date();
    date.setMonth(month - 1);
    return date.toLocaleString("en-US", { month: "long" });
  };

  return (
    <div {...calendarProps} ref={ref} className="h-full">
      <div>
        <div className="flex justify-between py-2 border-b-[1px] border-b-contrast-inactive text-sm">
          <VisuallyHidden>
            <h2>{calendarProps["aria-label"]}</h2>
          </VisuallyHidden>
          <div className="flex justify-between w-full">
            <Button
              onPress={(event) =>
                prevButtonProps.onPress?.(event as unknown as PressEvent)
              }
              icon="ChevronLeft"
              size={"lg"}
              data-testid="btn-prev"
            />
            <span aria-hidden>
              {getMonthName(state.visibleRange.start.month)},{" "}
              {state.visibleRange.start.year}
            </span>
            <Button
              onPress={(event) =>
                nextButtonProps.onPress?.(event as unknown as PressEvent)
              }
              icon="ChevronRight"
              size={"lg"}
              data-testid="btn-next"
            />
          </div>
        </div>
      </div>
      <div className="flex m-1 h-full">
        <CalendarGrid
          state={state}
          highlightedDates={parsedHighlightedDates}
          cellItemsByDate={props.cellItemsByDate}
        />
      </div>
    </div>
  );
};

export default Calendar;
