import type { AttachedRoleAssignment } from 'api/administration/permissions';
import type { IRole } from 'services/api/portal/administration/api/types';

export const getRoleAttachmentBody = (
  attachment: AttachedRoleAssignment,
  parentRoleId: number
) => {
  return {
    parentResourceType: attachment.parentResourceType,
    childRoleId: attachment.childRoleId,
    parentRoleId: parentRoleId,
    childResourceType: attachment.childResourceType,
    childResourceId: attachment.childResourceId.toString(),
    id: attachment.id,
    createdAt: attachment.createdAt,
  };
};

interface GroupedRole {
  id: number;
  parentRole: IRole;
  parentResourceId: number;
  attachedRoles: IRole[];
}

export const groupByParentResourceAndRole = (
  arr: AttachedRoleAssignment[]
): GroupedRole[] => {
  return arr.reduce(
    (
      result: GroupedRole[],
      { parentRole, childRole, id, parentResourceId }: AttachedRoleAssignment
    ) => {
      const existingGroup = result.find(
        (group) =>
          group.parentResourceId === parentResourceId &&
          group.parentRole.id === parentRole.id
      );

      if (existingGroup) {
        existingGroup.attachedRoles.push({ ...childRole });
      } else {
        result.push({
          id: id,
          parentRole: parentRole,
          parentResourceId: parentResourceId,
          attachedRoles: [{ ...childRole }],
        });
      }

      return result;
    },
    []
  );
};
