import type {
  CommandHistoryPayload,
  ICommandHistory,
} from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import type { ICommandPileItem } from 'services/api/ops/realtimeTerminalSession';
import type { CommandDefinition } from 'api/telecommands/types';

export const SET_TERMINAL_WORKSPACE = 'SET_TERMINAL_WORKSPACE';

export const REORDER_COMMAND_PILE = 'REORDER_COMMAND_PILE';

export const SELECT_SIDE_PANEL_COMMAND = 'SELECT_SIDE_PANEL_COMMAND';

export const COMMAND_CHANGE_DONE = 'COMMAND_CHANGE_DONE';

export const SET_SEND_COMMAND_DISABLED = 'SET_SEND_COMMAND_DISABLED';

export const SET_EXPLORING_STORAGE_NAME = 'SET_EXPLORING_STORAGE_NAME';

export const CLEAR_CURRENT_PAYLOAD = 'CLEAR_CURRENT_PAYLOAD';
export const SET_COMMAND_PAYLOAD = 'SET_COMMAND_PAYLOAD';

export const SET_CURRENTLY_EDITING_COMMAND_PAYLOAD =
  'SET_CURRENTLY_EDITING_COMMAND_PAYLOAD';

export const CLEAR_COMMAND_CUSTOM_NAME = 'CLEAR_COMMAND_CUSTOM_NAME';
export const SET_COMMAND_CUSTOM_NAME = 'SET_COMMAND_CUSTOM_NAME';

export const ARMED_COMMAND_PREVIEW_OPEN = 'ARMED_COMMAND_PREVIEW_OPEN';
export const ARMED_COMMAND_PREVIEW_CLOSE = 'ARMED_COMMAND_PREVIEW_CLOSE';

export const COPY_COMMAND_TO_EDITOR = 'COPY_COMMAND_TO_EDITOR';

export const PILE_COMMAND_REMOVE = 'PILE_COMMAND_REMOVE';
export const PILE_ITEM_OPEN = 'PILE_ITEM_OPEN';
export const PILE_ITEM_CLOSE = 'PILE_ITEM_CLOSE';
export const SELECTED_PILE_ITEM = 'SELECTED_PILE_ITEM';

export const HISTORY_COMMAND_OPEN = 'HISTORY_COMMAND_OPEN';
export const HISTORY_COMMAND_CLOSE = 'HISTORY_COMMAND_CLOSE';
export const REQUEST_HISTORY_COMMAND_LIST = 'REQUEST_HISTORY_COMMAND_LIST';
export const FINISH_HISTORY_COMMAND_LIST = 'FINISH_HISTORY_COMMAND_LIST';
export const REQUEST_AVAILABLE_COMMANDS = 'REQUEST_AVAILABLE_COMMANDS';
export const FINISH_AVAILABLE_COMMANDS = 'FINISH_AVAILABLE_COMMANDS';
export const SET_HISTORY_COMMAND_TYPE = 'SET_HISTORY_COMMAND_TYPE';
export const LAST_HISTORY_COMMAND_REACHED = 'LAST_HISTORY_COMMAND_REACHED';
export const HISTORY_SET_CURRENT_RESPONSE = 'HISTORY_SET_CURRENT_RESPONSE';

export const SELECT_COMMAND_FROM_SUGGESTION = 'SELECT_COMMAND_FROM_SUGGESTION';

export const PAST_COMMAND_FROM_CLIPBOARD = 'PAST_COMMAND_FROM_CLIPBOARD';

export const SAVE_CLOUD_STORAGE_PATH = 'SAVE_CLOUD_STORAGE_PATH';
export const SAVE_SATELLITE_STORAGE_PATH = 'SAVE_SATELLITE_STORAGE_PATH';

export const SET_PROCEDURE_NAME = 'SET_PROCEDURE_NAME';

export const TOGGLE_LEFT_PANEL = 'TOGGLE_LEFT_PANEL';
export const TOGGLE_RIGHT_PANEL = 'TOGGLE_RIGHT_PANEL';
export const TOGGLE_COMMAND_HISTORY_COLLAPSE =
  'TOGGLE_COMMAND_HISTORY_COLLAPSE';
export const HISTORY_UPDATE_COMPLETED_STATUS =
  'HISTORY_UPDATE_COMPLETED_STATUS';

export interface IOperateSetTerminalWorkSpace {
  type: typeof SET_TERMINAL_WORKSPACE;
  payload: {
    armedCommand?: ICommandPileItem;
    commandPileWorkingSpace: ICommandPileItem[];
    frozenPile: boolean;
  };
}

export interface IOperateReorderCommandPile {
  type: typeof REORDER_COMMAND_PILE;
  payload: {
    fromIndex: number;
    toIndex: number;
    selectedElements: ICommandPileItem[] | undefined | ICommandPileItem;
  };
}

export interface IOperateSelectSidePanelCommand {
  type: typeof SELECT_SIDE_PANEL_COMMAND;
  payload: {
    command: CommandDefinition;
  };
}

export interface IOperateArmedCommandPreviewOpen {
  type: typeof ARMED_COMMAND_PREVIEW_OPEN;
  payload: {
    armedCommand: ICommandPileItem;
    command: CommandDefinition;
  };
}

export interface IOperateCopeCommandToEditor {
  type: typeof COPY_COMMAND_TO_EDITOR;
  payload: {
    selectedCommand: CommandDefinition;
    commandPayload: object;
    commandCustomName: string | undefined;
  };
}

export interface IOperateArmedCommandPreviewClose {
  type: typeof ARMED_COMMAND_PREVIEW_CLOSE;
}

export interface IOperateCommandChangeDone {
  type: typeof COMMAND_CHANGE_DONE;
}

export interface IOperateSetSendCommandDisabled {
  type: typeof SET_SEND_COMMAND_DISABLED;
  payload: {
    disabled: boolean;
  };
}

export interface IOperateSetExploringStorageName {
  type: typeof SET_EXPLORING_STORAGE_NAME;
  payload: {
    storageName: string;
  };
}

export interface IOperateClearCurrentPayload {
  type: typeof CLEAR_CURRENT_PAYLOAD;
}

export interface IOperateSetCommandPayload {
  type: typeof SET_COMMAND_PAYLOAD;
  payload: {
    commandPayload: CommandHistoryPayload;
  };
}

export interface IOperateSetCurrentlyEditingCommandPayload {
  type: typeof SET_CURRENTLY_EDITING_COMMAND_PAYLOAD;
  payload: {
    currentylEditingCommandPayload: CommandHistoryPayload;
  };
}

export interface IOperateClearCommandCustomName {
  type: typeof CLEAR_COMMAND_CUSTOM_NAME;
}

export interface IOperateSetCommandCustomName {
  type: typeof SET_COMMAND_CUSTOM_NAME;
  payload: {
    commandCustomName: string;
  };
}

export interface IOperatePileCommandRemove {
  type: typeof PILE_COMMAND_REMOVE;
}

export interface IOperatePileItemOpen {
  type: typeof PILE_ITEM_OPEN;
  payload: {
    pileItem: ICommandPileItem;
    selectedCommand: CommandDefinition;
  };
}

export interface IOperatePileItemSelection {
  type: typeof SELECTED_PILE_ITEM;
  payload: {
    pileItemsSelected: ICommandPileItem[] | undefined;
  };
}

export interface IOperatePileItemClose {
  type: typeof PILE_ITEM_CLOSE;
}

export interface IOperateHistoryCommandOpen {
  type: typeof HISTORY_COMMAND_OPEN;
  payload: {
    history: ICommandHistory;
    command?: CommandDefinition;
  };
}

export interface IOperateHistoryUpdateCompleteStatus {
  type: typeof HISTORY_UPDATE_COMPLETED_STATUS;
  payload: {
    history: ICommandHistory;
  };
}

export interface IOperateHistoryCommandClose {
  type: typeof HISTORY_COMMAND_CLOSE;
}

export interface IOperateRequestHistoryCommandList {
  type: typeof REQUEST_HISTORY_COMMAND_LIST;
}

export interface IOperateFinishHistoryCommandList {
  type: typeof FINISH_HISTORY_COMMAND_LIST;
}

export interface IOperateRequestAvailableCommands {
  type: typeof REQUEST_AVAILABLE_COMMANDS;
}

export interface IOperateFinishAvailableCommands {
  type: typeof FINISH_AVAILABLE_COMMANDS;
}

export interface IOperateSetHistoryCommandType {
  type: typeof SET_HISTORY_COMMAND_TYPE;
  payload: {
    type: string;
  };
}

export interface IOperateLastHistoryCommandReached {
  type: typeof LAST_HISTORY_COMMAND_REACHED;
}

export interface IOperateHistorySetCurrentResponse {
  type: typeof HISTORY_SET_CURRENT_RESPONSE;
  payload: {
    history: ICommandHistory;
  };
}

export interface IOperateSelectCommandFromSuggestion {
  type: typeof SELECT_COMMAND_FROM_SUGGESTION;
  payload: {
    selectedCommand: CommandDefinition;
  };
}

export interface IOperatePastCommandFromClipboard {
  type: typeof PAST_COMMAND_FROM_CLIPBOARD;
  payload: {
    selectedCommand: CommandDefinition;
    commandPayload: CommandHistoryPayload;
  };
}

export interface IOperateSaveCloudStoragePath {
  type: typeof SAVE_CLOUD_STORAGE_PATH;
  payload: {
    path: string;
  };
}

export interface IOperateSaveSatelliteStoragePath {
  type: typeof SAVE_SATELLITE_STORAGE_PATH;
  payload: {
    path: string;
  };
}

export interface IOperateSetProcedureName {
  type: typeof SET_PROCEDURE_NAME;
  payload: {
    procedureName: string;
  };
}

export interface IOperateToggleLeftPanel {
  type: typeof TOGGLE_LEFT_PANEL;
  payload: {
    isLeftPanelHidden: boolean;
  };
}

export interface IOperateToggleRightPanel {
  type: typeof TOGGLE_RIGHT_PANEL;
  payload: {
    isRightPanelHidden: boolean;
  };
}

export interface IOperateToggleCommandHistoryCollapse {
  type: typeof TOGGLE_COMMAND_HISTORY_COLLAPSE;
  payload: {
    isCommandHistoryCollapsed: boolean;
  };
}

export type IOperateActionTypes =
  | IOperateHistoryCommandOpen
  | IOperateHistoryCommandClose
  | IOperateSetTerminalWorkSpace
  | IOperateSelectSidePanelCommand
  | IOperateClearCurrentPayload
  | IOperateArmedCommandPreviewOpen
  | IOperateArmedCommandPreviewClose
  | IOperatePileCommandRemove
  | IOperateCopeCommandToEditor
  | IOperatePileItemOpen
  | IOperatePileItemSelection
  | IOperatePileItemClose
  | IOperateSelectCommandFromSuggestion
  | IOperatePastCommandFromClipboard
  | IOperateSetCommandPayload
  | IOperateClearCommandCustomName
  | IOperateSetCommandCustomName
  | IOperateCommandChangeDone
  | IOperateSetSendCommandDisabled
  | IOperateSetExploringStorageName
  | IOperateRequestHistoryCommandList
  | IOperateFinishHistoryCommandList
  | IOperateRequestAvailableCommands
  | IOperateFinishAvailableCommands
  | IOperateSetHistoryCommandType
  | IOperateLastHistoryCommandReached
  | IOperateHistorySetCurrentResponse
  | IOperateSaveCloudStoragePath
  | IOperateSaveSatelliteStoragePath
  | IOperateSetProcedureName
  | IOperateSetCurrentlyEditingCommandPayload
  | IOperateReorderCommandPile
  | IOperateToggleLeftPanel
  | IOperateToggleRightPanel
  | IOperateToggleCommandHistoryCollapse
  | IOperateHistoryUpdateCompleteStatus;
