import React from "react";
import { Separator } from "react-aria-components";

const MySeparator: typeof Separator = React.forwardRef((props, ref) => {
  return (
    <Separator
      {...props}
      ref={ref}
      className="border-solid border-0 border-b border-item-hover my-1 "
    />
  );
});

export default MySeparator;
