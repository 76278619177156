import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../pages/msd/shared/state/reducers/rootReducer';
import type { Action } from 'redux';

import type { IGroundStation } from '../../constants/groundStations/actionTypes';
import {
  addGroundStation,
  removeGroundStation,
  updateGroundStation,
} from './groundStation/services';
import {
  groundStationDefine,
  groundStationFocused,
  groundStationOptimized,
  groundStationSelected,
} from './groundStation/options';
import {
  setFocusGroundStation,
  switchSelectedGroundStation,
} from './groundStation/events';

import type { IPointOfInterest } from '../../constants/pointsOfInterest/actionTypes';
import {
  addPointOfInterest,
  updatePointOfInterest,
  removePointOfInterest,
} from './pointOfInterest/services';
import {
  pointOfInterestDefine,
  pointOfInterestFocused,
} from './pointOfInterest/options';
import { setFocusPointOfInterest } from './pointOfInterest/events';

import type { IRegionOfInterest } from '../../constants/regionsOfInterest/actionTypes';
import {
  addRegionOfInterest,
  updateRegionOfInterest,
  removeRegionOfInterest,
} from './regionOfInterest/services';
import {
  regionOfInterestDefine,
  regionOfInterestFocused,
} from './regionOfInterest/options';

import type { IMapWrapper } from '../../declarations/mapDeclarations/Map';
import { setFocusRegionOfInterest } from './regionOfInterest/events';
import { addContextMenu as pointContextMenu } from './contextMenu/pointsOfInterest/services';
import { addContextMenu as regionContextMenu } from './contextMenu/regionsOfInterest/services';
import type { IPolygonWrapper } from '../../declarations/mapDeclarations/Polygon';
import { setPathsEvents } from './regionOfInterest/paths/services';
import {
  MAP_ACTION_ADD,
  MAP_ACTION_REMOVE,
  MAP_ACTION_UPDATE,
} from '../../constants/map/constants';
import {
  addContextMenu as groundStationContextMenu,
  addContextMenuWithoutRedux,
} from './contextMenu/groundStations/services';
import type { ISatelliteData } from '../../constants/satelliteOrbits/actionTypes';
import { satelliteDefine } from './satellites/options';
import {
  addSatellite,
  removeSatellite,
  updateSatellite,
} from './satellites/services';
import { color } from '../../constants/chart/constants';

export interface IDrawingOption {
  focus?: boolean;
  setEvents?: boolean;
  visible?: boolean;
}

export function groundStationDrawing(
  groundStationData: IGroundStation,
  map: IMapWrapper,
  options: IDrawingOption = {
    focus: false,
    setEvents: true,
  }
): ThunkAction<void, AppState, null, Action<string>> {
  return (dispatch): object => {
    return {
      [MAP_ACTION_ADD]: (): void => {
        const res = map.groundStations.find(
          (groundStation): boolean => groundStation.id === groundStationData.id
        );
        if (!res) {
          const { select, optimize } = groundStationData;
          let option = groundStationDefine();
          if (select) {
            option = groundStationSelected();
          } else if (optimize) {
            option = groundStationOptimized();
          }
          const groundStation = addGroundStation(
            groundStationData,
            map,
            option
          );

          if (options.setEvents) {
            const boundSetFocus = (): void =>
              dispatch(setFocusGroundStation(groundStationData));
            const boundSwitchSelected = (): void =>
              dispatch(switchSelectedGroundStation.bind(null, groundStation)());

            groundStation.addListener('click', boundSetFocus);
            groundStation.addListener('dblclick', boundSwitchSelected);

            if (groundStationData.custom) {
              const boundContextMenu = (
                googleMouseEvent: google.maps.MouseEvent
              ): void =>
                dispatch(
                  groundStationContextMenu.bind(
                    null,
                    groundStation,
                    map
                  )(googleMouseEvent)
                );
              groundStation.addListener('rightclick', boundContextMenu);
            } else {
              const boundContextMenu = (
                googleMouseEvent: google.maps.MouseEvent
              ): void =>
                dispatch(
                  groundStationContextMenu.bind(
                    null,
                    groundStation,
                    map
                  )(googleMouseEvent)
                );
              groundStation.addListener('rightclick', boundContextMenu);
            }
          }
        }
      },
      [MAP_ACTION_UPDATE]: (): void => {
        const res = map.groundStations.find(
          (groundStation): boolean => groundStation.id === groundStationData.id
        );
        // TODO: temp all selected
        if (res) {
          const { select, optimize } = groundStationData;
          let option = groundStationDefine();
          if (select) {
            option = groundStationSelected();
          } else if (optimize) {
            option = groundStationOptimized();
          }
          if (options.focus) {
            option = groundStationFocused();
          }

          updateGroundStation(groundStationData, res, map, option);
        }
      },
      [MAP_ACTION_REMOVE]: (): void => {
        const index = map.groundStations.findIndex(
          (groundStation): boolean => groundStation.id === groundStationData.id
        );
        if (index !== -1) {
          removeGroundStation(index, map);
        }
      },
    };
  };
}

// used for unconnected to redux store components
export const groundStationDrawingWithoutRedux =
  (handleOnClick: Function, handleZoomChange: Function) =>
  (
    groundStationData: IGroundStation,
    map: IMapWrapper,
    options?: IDrawingOption
  ) => {
    map.addListener('zoom_changed', () => handleZoomChange(map.getZoom()));

    return {
      [MAP_ACTION_ADD]: (): void => {
        const { select } = groundStationData;
        const option = select ? groundStationSelected() : groundStationDefine();
        const groundStation = addGroundStation(groundStationData, map, option);

        if (options.setEvents) {
          groundStation.addListener('click', () =>
            handleOnClick(groundStationData)
          );
          const boundContextMenu = (
            googleMouseEvent: google.maps.MouseEvent
          ): void =>
            addContextMenuWithoutRedux.bind(
              null,
              groundStation,
              map
            )(groundStationData, googleMouseEvent);
          groundStation.addListener('rightclick', boundContextMenu);
        }
      },
      [MAP_ACTION_UPDATE]: (): void => {
        const res = map.groundStations.find(
          (groundStation): boolean => groundStation.id === groundStationData.id
        );

        if (res) {
          const option = groundStationData.select
            ? groundStationSelected()
            : groundStationDefine();

          updateGroundStation(groundStationData, res, map, option);
        }
      },
      [MAP_ACTION_REMOVE]: (): void => {
        const index = map.groundStations.findIndex(
          (groundStation): boolean => groundStation.id === groundStationData.id
        );
        if (index !== -1) {
          removeGroundStation(index, map);
        }
      },
    };
  };

export function pointOfInterestDrawing(
  pointOfInterestData: IPointOfInterest,
  map: IMapWrapper,
  options: IDrawingOption = {
    focus: false,
    setEvents: true,
  }
): ThunkAction<void, AppState, null, Action<string>> {
  return (dispatch): object => {
    return {
      [MAP_ACTION_ADD]: (): void => {
        const res = map.pointsOfInterest.find(
          (pointOfInterest): boolean =>
            pointOfInterest.id === pointOfInterestData.id
        );
        if (!res) {
          const option = pointOfInterestDefine();
          const pointOfInterest = addPointOfInterest(
            pointOfInterestData,
            map,
            option
          );

          if (options.setEvents) {
            const boundContextMenu = (
              googleMouseEvent: google.maps.MouseEvent
            ): void =>
              dispatch(
                pointContextMenu.bind(
                  null,
                  pointOfInterest,
                  map
                )(googleMouseEvent)
              );
            const boundSetFocus = (): void =>
              dispatch(setFocusPointOfInterest(pointOfInterestData));

            pointOfInterest.addListener('click', boundSetFocus);
            pointOfInterest.addListener('rightclick', boundContextMenu);
          }
        }
      },
      [MAP_ACTION_UPDATE]: (): void => {
        const res = map.pointsOfInterest.find(
          (pointOfInterest): boolean =>
            pointOfInterest.id === pointOfInterestData.id
        );
        if (res) {
          const option = options.focus
            ? pointOfInterestFocused()
            : pointOfInterestDefine();
          updatePointOfInterest(pointOfInterestData, res, map, option);
        }
      },
      [MAP_ACTION_REMOVE]: (): void => {
        const index = map.pointsOfInterest.findIndex(
          (pointOfInterest): boolean =>
            pointOfInterest.id === pointOfInterestData.id
        );
        if (index !== -1) {
          removePointOfInterest(index, map);
        }
      },
    };
  };
}

export function regionOfInterestDrawing(
  regionOfInterestData: IRegionOfInterest,
  map: IMapWrapper,
  options: IDrawingOption = {
    focus: false,
    setEvents: true,
  }
): ThunkAction<void, AppState, null, Action<string>> {
  return (dispatch, store): object => {
    const { focus } = store();
    return {
      [MAP_ACTION_ADD]: (): void => {
        const res = map.regionsOfInterest.find(
          (regionOfInterest): boolean =>
            regionOfInterest.id === regionOfInterestData.id
        );
        if (!res) {
          const option = regionOfInterestDefine();
          const regionOfInterest = addRegionOfInterest(
            regionOfInterestData,
            map,
            focus.withPopUp ? { ...option, editable: false } : option
          );

          if (options.setEvents) {
            const boundContextMenu = (
              event: google.maps.PolyMouseEvent
            ): void =>
              dispatch(
                regionContextMenu.bind(null, regionOfInterest, map)(event)
              );
            const boundSetFocus = (): void =>
              dispatch(setFocusRegionOfInterest(regionOfInterestData));
            const boundSetPathsEvents = (region: IPolygonWrapper): void =>
              dispatch(setPathsEvents(region));

            boundSetPathsEvents(regionOfInterest);
            regionOfInterest.addListener('rightclick', boundContextMenu);
            regionOfInterest.addListener('click', boundSetFocus);
          }
        }
      },
      [MAP_ACTION_UPDATE]: (): void => {
        const res = map.regionsOfInterest.find(
          (regionOfInterest): boolean =>
            regionOfInterest.id === regionOfInterestData.id
        );
        if (res) {
          const option = options.focus
            ? regionOfInterestFocused()
            : regionOfInterestDefine();
          const regionOfInterest = updateRegionOfInterest(
            regionOfInterestData,
            res,
            map,
            focus.withPopUp ? { ...option, editable: false } : option
          );

          const boundSetPathsEvents = (region: IPolygonWrapper): void =>
            dispatch(setPathsEvents(region));
          boundSetPathsEvents(regionOfInterest);
        }
      },
      [MAP_ACTION_REMOVE]: (): void => {
        const index = map.regionsOfInterest.findIndex(
          (regionOfInterest): boolean =>
            regionOfInterest.id === regionOfInterestData.id
        );
        if (index !== -1) {
          removeRegionOfInterest(index, map);
        }
      },
    };
  };
}

export function satelliteDrawing(
  satelliteData: ISatelliteData,
  map: IMapWrapper,
  options: IDrawingOption = {
    focus: false,
    setEvents: true,
  }
): ThunkAction<void, AppState, null, Action<string>> {
  return (): object => {
    return {
      [MAP_ACTION_ADD]: (): void => {
        const res = map.satellites.find(
          (satellite): boolean => satellite.id === satelliteData.id
        );
        if (!res) {
          const option = satelliteDefine(google, {
            strokeColor: color[map.satellites.length],
          });
          const satellite = addSatellite(satelliteData, map, option);
          satellite.setVisible(satelliteData.isShow);
          if (options.setEvents) {
            //TODO move events to events file
            satellite.addListener('mouseover', (): void => {
              satellite.setOptions({ strokeWeight: 3 });
            });
            satellite.addListener('mouseout', (): void => {
              satellite.setOptions({ strokeWeight: 1 });
            });
          }
        }
      },
      [MAP_ACTION_UPDATE]: (): void => {
        const res = map.satellites.find(
          (satellite): boolean => satellite.id === satelliteData.id
        );
        if (res) {
          const satellite = updateSatellite(satelliteData, res, map);
          satellite.setVisible(satelliteData.isShow);
        }
      },
      [MAP_ACTION_REMOVE]: (): void => {
        const index = map.satellites.findIndex(
          (satellite): boolean => satellite.id === satelliteData.id
        );
        if (index !== -1) {
          removeSatellite(index, map);
        }
      },
    };
  };
}
