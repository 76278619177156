import React, { useRef, type ReactElement } from 'react';
import type { AriaOverlayProps } from '@react-aria/overlays';
import type { AriaDialogProps } from '@react-types/dialog';
import {
  FocusScope,
  OverlayContainer,
  mergeProps,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import { Tabs, Button, TabList, Tab, TabPanel } from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';

type BaseModalProps = AriaOverlayProps &
  AriaDialogProps & {
    children: React.ReactNode;
    title?: string;
  };
const BaseModal = (props: BaseModalProps) => {
  const { children, title } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      {...underlayProps}
      className="fixed inset-0 w-full flex justify-center items-center bg-item-contrast/50"
      style={{ zIndex: 10 }}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(dialogProps, modalProps, overlayProps)}
          ref={ref}
          className="bg-surface !opacity-100 dark:bg-surface-dark dark:text-item-dark-contrast pt-3 pb-3 pl-6 pr-6"
        >
          {title ? (
            <h4 {...titleProps} className="font-bold">
              {title}
            </h4>
          ) : (
            <div />
          )}
          <hr />
          <div className="flex items-center gap-2">{children}</div>
        </div>
      </FocusScope>
    </div>
  );
};

interface Option {
  name: string;
  icon?: IconName;
  onClick?: () => void;
}

interface OptionWithTabs {
  optionType: string;
  formats: {
    name: string;
    icon?: IconName;
    onClick?: () => void;
  }[];
}

type Props = {
  /**
   * Whether the modal is open or not.
   */
  isOpen: boolean;
  /**
   * Callback to set the open state of the modal.
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * The options to display in the modal.
   * Each option has a name, an optional icon, and a onClick callback.
   */
  options: Option[] | OptionWithTabs[];
  /**
   * The title of the modal.
   */
  title?: string;
  /**
   * Whether the options to display is a list or under tabs.
   */
  showOptionsUnderTabs?: boolean;
  /**
   * The handler to execute if options do not have onClick callback defined.
   */
  onClickHandler?: (name: string) => void;
  /**
   * Optional additional content to display other than options.
   * Has to be <div></div> and default position is bottom.
   */
  additionalContent?: {
    position: 'top' | 'bottom';
    content: ReactElement;
  };
};

// TODO: Restrict imports from openapp eslint: importRestrictedPaths/noRestrictedPaths
/**
 * OptionDialog is a modal that displays a list of selectable options.
 */
const OptionsDialog = ({
  options,
  isOpen,
  setIsOpen,
  title,
  showOptionsUnderTabs,
  onClickHandler,
  additionalContent,
}: Props) => {
  return isOpen ? (
    <OverlayContainer>
      <BaseModal
        isOpen={isOpen}
        isDismissable={true}
        onClose={() => {
          setIsOpen(false);
        }}
        title={title}
      >
        <div>
          {additionalContent?.position === 'top' && additionalContent.content}
          {showOptionsUnderTabs ? (
            <Tabs className={'my-2'}>
              <TabList>
                {(options as OptionWithTabs[]).map((o) => (
                  <Tab key={o.optionType} id={o.optionType} fill>
                    <div className="w-full">{o.optionType}</div>
                  </Tab>
                ))}
              </TabList>
              {(options as OptionWithTabs[]).map((o) => (
                <TabPanel
                  key={o.optionType}
                  id={o.optionType}
                  className={'p-1 flex justify-between items-center gap-3'}
                >
                  {o.formats.map((format) => (
                    <Button
                      key={format.name + format.icon}
                      icon={format.icon}
                      onPress={() => {
                        setIsOpen(false);
                        if (format.onClick) {
                          format.onClick();
                          return;
                        }
                        if (onClickHandler) {
                          onClickHandler(format.name);
                        }
                      }}
                    >
                      {format.name}
                    </Button>
                  ))}
                </TabPanel>
              ))}
            </Tabs>
          ) : (
            (options as Option[]).map((o) => (
              <Button
                key={o.name + o.icon}
                icon={o.icon}
                onPress={() => {
                  setIsOpen(false);
                  if (o.onClick) {
                    o.onClick();
                  }
                }}
              >
                {o.name}
              </Button>
            ))
          )}
          {additionalContent?.position === 'bottom' &&
            additionalContent.content}
        </div>
      </BaseModal>
    </OverlayContainer>
  ) : null;
};

export default OptionsDialog;
