import { useParams } from 'react-router-dom';
import ProjectResources from 'datacosmos/components/ResourcePages/ProjectResources';
import ViewResources from 'datacosmos/components/ResourcePages/ViewResources';
import ApplicationResources from 'datacosmos/components/ResourcePages/ApplicationResources';
import CollectionResources from 'datacosmos/components/ResourcePages/CollectionResources';
import ItemResources from 'datacosmos/components/ResourcePages/ItemResources';
import NotFoundPage from 'pages/shared/NotFoundPage';
import { useAuth } from 'services/auth/AuthWrapper';
import NoPermissionsPage from 'pages/shared/NoPermissionsPage';
import { useCallback } from 'react';
import type {
  PermissionScope,
  PermissionType,
} from 'api/administration/permissions';

const GenericResourcePage = () => {
  const { resource_type, resource_id } = useParams<{
    resource_type: string;
    resource_id: string;
  }>();

  const { checkPermissions } = useAuth();

  const isAllowedToPerformAction = useCallback(
    (
      type: Exclude<PermissionType, 'global'>,
      scope: PermissionScope,
      id: string
    ): boolean => {
      let isAllowedToAccess: boolean = false;
      void checkPermissions({
        actionScope: scope,
        type: type,
        id: id,
      })
        .then((res) => {
          isAllowedToAccess = res;
        })
        .catch(() => {});
      return isAllowedToAccess;
    },
    [checkPermissions]
  );

  const renderResources = () => {
    switch (resource_type) {
      case 'project':
        return (
          <ProjectResources
            resourceId={resource_id}
            resourceType={resource_type}
          />
        );
      case 'view':
        return (
          <ViewResources
            resourceId={resource_id}
            resourceType={resource_type}
          />
        );
      case 'application':
        if (
          !isAllowedToPerformAction(
            'datacosmos_app',
            'data:app:write',
            resource_id
          )
        ) {
          return <NoPermissionsPage />;
        }
        return (
          <ApplicationResources
            resourceId={resource_id}
            resourceType={resource_type}
          />
        );
      case 'collection':
        if (
          !isAllowedToPerformAction(
            'datacosmos_stac_collection',
            'data:stac_collection:write',
            resource_id
          )
        ) {
          return <NoPermissionsPage />;
        }
        return (
          <CollectionResources
            resourceId={resource_id}
            resourceType={resource_type}
          />
        );
      case 'item':
        if (
          !isAllowedToPerformAction(
            'datacosmos_stac_item',
            'data:stac_collection:write',
            resource_id
          )
        ) {
          return <NoPermissionsPage />;
        }
        return (
          <ItemResources
            resourceId={resource_id}
            resourceType={resource_type}
          />
        );
      default:
        return <NotFoundPage />;
    }
  };

  return renderResources();
};

export default GenericResourcePage;
