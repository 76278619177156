import React from 'react';
import {
  Button,
  Icon,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  Separator,
} from 'opencosmos-ui';

interface IProps {
  handleRenameSessionClick: () => void;
  handleDeleteSessionClick: () => void;
  handleChangeUserAccessibleClick: () => void;
  userAccessible: boolean;
  writeAllSessionsPermission: boolean;
}

const SessionSettingPopover = (props: IProps) => (
  <MenuTrigger>
    <Button icon={'ChevronDown'} isMinimal={true} className="h-7">
      <Icon icon="settings" />
    </Button>

    <Popover placement={'bottom'}>
      <Menu
        onAction={(action) => {
          switch (action) {
            case 'rename':
              props.handleRenameSessionClick();
              break;
            case 'make_customer_accessible':
              props.handleChangeUserAccessibleClick();
              break;
            case 'delete':
              props.handleDeleteSessionClick();
              break;
          }
        }}
      >
        <MenuItem id={'rename'}>
          <Icon icon="edit" />
          <span>Rename</span>
        </MenuItem>
        {props.writeAllSessionsPermission && (
          <MenuItem id="make_customer_accessible">
            <Icon icon={props.userAccessible ? 'eye-off' : 'eye-open'} />
            <span>
              {`Make ${props.userAccessible ? 'in' : ''}accessible to customer`}
            </span>
          </MenuItem>
        )}
        <Separator />
        <MenuItem id={'delete'} className={'text-warning'}>
          <span>Delete</span>
          <Icon icon="Trash" />
        </MenuItem>
      </Menu>
    </Popover>
  </MenuTrigger>
);

export default SessionSettingPopover;
