import type { IAsset } from '../../types/stac-types';
import { Button } from 'opencosmos-ui';
import { Tooltip } from 'ui/Tooltip';
import useCOGMetadata from 'datacosmos/utils/hooks/useCOGMetadata';
import useGeoJSONMetadata from 'datacosmos/utils/hooks/useGeoJSONMetadata';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useRef, useState } from 'react';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';

import StatisticsPopup from '_organisms/StatisticsPopup/StatisticsPopup';

import { LayerSourceType } from 'datacosmos/entities/layer';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';

import area from '@turf/area';

import IconButton from '_molecules/IconButton/IconButton';
import { screenshot } from 'utils/screenshot';
import { downloadFilUrlAsExtension } from 'datacosmos/download/geojson';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { Feature, Polygon } from 'geojson';

const AssetMetadataButton = (props: { data: IAsset }) => {
  const { data } = props;

  const [aoi, setAoi] = useState<Feature<Polygon>>();

  const cogMeta = useCOGMetadata(data.href, data.type ?? '');
  const geoJSONMeta = useGeoJSONMetadata(data.href, data.type ?? '');
  const { translate } = useLocalisation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      {(cogMeta.validAsset || geoJSONMeta.validAsset) && (
        <Tooltip
          content={translate(
            'datacosmos.catalogAndItems.metadata.showStatistics'
          )}
          className="text-item-contrast dark:text-item-dark-contrast"
        >
          <Button
            className="h-full text-accent bg-transparent px-2 py-1 hover:bg-item-hover dark:bg-transparent dark:text-accent dark:bg-transparent dark:hover:bg-item-dark-hover flex justify-start gap-2 text-sm items-center"
            icon={
              cogMeta.isFetching || geoJSONMeta.isFetching
                ? 'circle'
                : 'scatter-plot'
            }
            onPress={async () => {
              if (cogMeta.validAsset) {
                await cogMeta.fetchMetadata(aoi);
              }
              if (geoJSONMeta.validAsset) {
                await geoJSONMeta.fetchMetadata();
              }
              setIsModalOpen(true);
            }}
            isMinimal
          />
        </Tooltip>
      )}
      {cogMeta.validAsset && (
        <COGMetadataView
          key={`cog-metadata-view-${data.title ?? data.href}`}
          data={cogMeta}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setAoi={async (region) => {
            setAoi(region);
            await cogMeta.fetchMetadata(region);
          }}
        />
      )}
      {geoJSONMeta.validAsset && (
        <GeoJSONMetadataView
          data={geoJSONMeta}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
export default AssetMetadataButton;

const COGMetadataView = (props: {
  data: ReturnType<typeof useCOGMetadata>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setAoi: (aoi: Feature<Polygon> | undefined) => void;
}) => {
  const { data, isModalOpen, setIsModalOpen, setAoi } = props;

  const { layers, removeLayersBySourceType, addLayer } = useMapLayers();
  const { drawPolygon } = useMap();

  const statisticsAoi = useMemo(
    () =>
      layers.find((l) => l.sourceType === LayerSourceType.STATISTICS_AOI) as
        | PolygonLayer
        | undefined,
    [layers]
  );

  const statisticsPopupRef = useRef<HTMLDivElement>(null);

  const getStatisticsForAoi = async () => {
    const { polygon, polygonMetadata } = await drawPolygon();

    const aoi = PolygonLayerFactory(
      LayerSourceType.STATISTICS_AOI,
      translate('datacosmos.layers.names.statisticsAoi'),
      polygon,
      area(polygon),
      polygonMetadata
    );
    addLayer(aoi);
    setAoi(polygon);
  };

  const handleStatisticsAoiDelete = () => {
    removeLayersBySourceType(LayerSourceType.STATISTICS_AOI);
    setAoi(undefined);
  };

  const { translate } = useLocalisation();

  if (typeof data.metadata === 'undefined') {
    return null;
  }

  return (
    <StatisticsPopup
      getHistogramColor={data.getHistogramColor}
      getStatisticsForAoiHandler={getStatisticsForAoi}
      histogramData={data.metadata?.histogramData()}
      isFetchingMetadata={data.isFetching}
      isOpen={isModalOpen}
      modalTitle={translate('datacosmos.catalogAndItems.metadata.statistics')}
      data={data.metadata}
      removeStatisticsAoiHandler={handleStatisticsAoiDelete}
      setIsOpen={setIsModalOpen}
      isStatisticsAoiPresent={statisticsAoi !== undefined}
      titleElement={
        <IconButton
          icon="Camera"
          onPress={async () => {
            if (!statisticsPopupRef.current) {
              return;
            }

            const imgString = await screenshot(statisticsPopupRef.current, {
              mimeType: 'image/png',
              ignore: ['draw-aoi-btn', 'remove-aoi-btn', 'modal-title'],
            });

            downloadFilUrlAsExtension(
              imgString,
              `band_statistics_${new Date().toISOString()}`,
              'png'
            );
          }}
        />
      }
      popupRef={statisticsPopupRef}
      onClose={handleStatisticsAoiDelete}
    />
  );
};

const GeoJSONMetadataView = (props: {
  data: ReturnType<typeof useGeoJSONMetadata>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return <div>GeoJSON Metadata</div>;
};
