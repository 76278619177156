import type { IMapWrapper } from '../../../../declarations/mapDeclarations/Map';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../pages/msd/shared/state/reducers/rootReducer';
import type { Action } from 'redux';
import type { IPolygonWrapper } from '../../../../declarations/mapDeclarations/Polygon';
import { removeRegionOfInterest, updateRegionOfInterest } from './events';
import type { IconName } from '@blueprintjs/icons';
import type { MaybeElement } from '@blueprintjs/core/src/common/props';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';
import type { ReactElement } from 'react';
import React from 'react';
import type { IContextMenuSub } from '../map/services';
import type { IAnyKey } from '../../../../utils/getFormData';

export const addContextMenu =
  (
    regionOfInterest: IPolygonWrapper,
    map: IMapWrapper,
    googleMouseEvent?: google.maps.PolyMouseEvent & IAnyKey
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, store): ReactElement => {
    const { popUp } = store();
    if (popUp && popUp.visible) {
      return undefined;
    }

    if (map.drawingManager.getDrawingMode()) {
      map.drawingManager.setDrawingMode(null);
      return undefined;
    }
    const menuHandlers: IContextMenuSub[] = [
      {
        text: 'Update region',
        icon: 'inbox-update',
        clickEvent: (): void =>
          dispatch(updateRegionOfInterest(regionOfInterest, map)),
      },
      {
        text: 'Remove region',
        icon: 'delete',
        clickEvent: (): void =>
          dispatch(removeRegionOfInterest(regionOfInterest)),
      },
    ];
    const menuItems = menuHandlers.map((item, index): ReactElement => {
      const icon = item.icon as IconName | MaybeElement;
      return (
        <MenuItem
          key={index}
          icon={icon}
          text={item.text}
          onClick={item.clickEvent as any}
        />
      );
    });

    if (googleMouseEvent) {
      let e = null;
      //NB: google map types doesnt have Mouse Event with ClientX|ClientY
      try {
        for (const key in googleMouseEvent) {
          if (
            googleMouseEvent[key].clientX !== undefined &&
            googleMouseEvent[key].clientY
          ) {
            e = googleMouseEvent[key];
          }
        }
      } catch (e) {
        console.warn('Event type: ', googleMouseEvent);
      }
      if (!e) {
        e = googleMouseEvent.ra;
      }
      ContextMenu.show(
        <Menu>{menuItems}</Menu>,
        { left: e.clientX, top: e.clientY },
        (): void => {}
      );

      return undefined;
    } else {
      return <Menu>{menuItems}</Menu>;
    }
  };
