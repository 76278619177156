import { useState } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { Button, Dialog, Alert, Intent } from '@blueprintjs/core';
import { isNil } from 'lodash';

import Alerts from 'pages/ops/Library/components/Alerts';
import { preventDefaultPropagation } from 'utils/common/CommonUtils';
import MuteAlertForm from 'pages/ops/Library/components/Alerts/MuteAlertForm';

import CollapseLibraryList from './CollapseLibraryList';

import s from 'pages/ops/Library/components/LibraryAlerts/index.module.scss';
import type { AlertManager } from 'services/api/telemetry/useAlertManager';

interface IProps {
  alertManagerApi: AlertManager;
}

const TelemetriesTab = ({ alertManagerApi }: IProps) => {
  const [muteDialogOpen, setMuteDialogueOpen] = useState<boolean>(false);

  const muteMissionAlertsHandler = preventDefaultPropagation(() =>
    setMuteDialogueOpen(true)
  )();

  const cancelDialogueHandler = () => setMuteDialogueOpen(false);
  const muteDialogueHandler = (days: number) => {
    alertManagerApi.silenceMission(days);
    setMuteDialogueOpen(false);
  };

  const unmuteMissionHandler = () => {
    alertManagerApi.expireMissionSilence();
    setMuteDialogueOpen(false);
  };

  return (
    <>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Unmute"
        icon={IconNames.NOTIFICATIONS}
        isOpen={alertManagerApi.missionSilence && muteDialogOpen}
        intent={Intent.DANGER}
        onCancel={cancelDialogueHandler}
        onConfirm={unmuteMissionHandler}
      >
        <p>
          This mission will remain muted until{' '}
          {alertManagerApi.missionSilence?.endsAt}. Do you want to unmute it?
        </p>
      </Alert>
      <Dialog
        title="Mute Mission Alerts"
        icon={IconNames.NOTIFICATIONS}
        isOpen={!alertManagerApi.missionSilence && muteDialogOpen}
        onClose={cancelDialogueHandler}
      >
        <div className={s.dialogueContainer}>
          <p className={s.textContainer}>
            Warning: this action will cause no alerts to be triggered for this
            mission. How long would you like to mute this mission for?
          </p>
          <MuteAlertForm
            onCancelHandler={cancelDialogueHandler}
            onSubmitHandler={muteDialogueHandler}
          />
        </div>
      </Dialog>
      <CollapseLibraryList
        stores={[
          {
            tagTitle: 'Alerts',
            tagIcon: IconNames.FEED,
            collapsed: true,
            children: <Alerts alertManagerApi={alertManagerApi} />,
            tagContent: (
              <Button
                icon={IconNames.NOTIFICATIONS}
                onClick={muteMissionAlertsHandler}
                minimal
                small
                active={!isNil(alertManagerApi.missionSilence)}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default TelemetriesTab;
