import React, { useCallback, useEffect, useMemo } from 'react';
import { Spinner } from '@blueprintjs/core';
import type {
  CommandHistoryPayload,
  ICommandHistory,
} from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { setHistoryCommandType } from 'actions/operate/actions';
import CommandHistoryHeader from './CommandHistoryHeader';
import CommandHistoryItem from './CommandHistoryItem';
import s from './index.module.scss';
import type { CommandDefinition } from 'api/telecommands/types';
import { useVirtualizer } from '@tanstack/react-virtual';

interface IProps {
  availableCommands: CommandDefinition[];
  commandHistory: ICommandHistory[];
  currentHistoryItem?: ICommandHistory;
  handleCommandCopyToEditorClick: (
    command: string,
    payload: CommandHistoryPayload,
    commandCustomName: string | undefined
  ) => void;
  handleHistoryCommandClick: (reply: ICommandHistory) => void;
  handleGetHistoryCommandList: (date: number) => void;
  isTablet?: boolean;
  noHistoryLeft: boolean;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  commandHistoryContainerRef: React.RefObject<HTMLDivElement>;
}

const CONTAINER_HEIGHT = 'calc(100% - 34px)';

const CommandHistory = ({
  collapsed,
  setCollapsed,
  commandHistory,
  availableCommands,
  isTablet,
  currentHistoryItem,
  handleCommandCopyToEditorClick,
  handleHistoryCommandClick,
  handleGetHistoryCommandList,
  noHistoryLeft,
  commandHistoryContainerRef,
}: IProps) => {
  const {
    dispatch,
    state: { isHistoryFetching, historyCommandType, lastHistoryCommandReached },
  } = useOperate();

  const sortedCommandHistory = useMemo(() => {
    return commandHistory.sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);
      return bDate.getTime() - aDate.getTime();
    });
  }, [commandHistory]);

  const rowVirtualizer = useVirtualizer({
    count: sortedCommandHistory.length,
    estimateSize: () => 50,
    getScrollElement: () => commandHistoryContainerRef.current,
    paddingEnd: 100,
    paddingStart: -50,
  });

  const loadOlderCommandHistory = useCallback(() => {
    if (
      isHistoryFetching ||
      lastHistoryCommandReached ||
      sortedCommandHistory.length === 0
    ) {
      return;
    }
    const lastCommand = sortedCommandHistory[sortedCommandHistory.length - 1];
    if (lastCommand.createdAt) {
      handleGetHistoryCommandList(Number(new Date(lastCommand.createdAt)));
    }
  }, [
    handleGetHistoryCommandList,
    isHistoryFetching,
    lastHistoryCommandReached,
    sortedCommandHistory,
  ]);

  /**
   * passedLoadNextItemsThreshold returns true if the element has been scrolled upwards past the threshold at which we should load the next items (i.e. trigger the infinite scroll)
   * @param event triggered on the UI element where the infinite scroll is happening
   * @returns true if the element has been scrolled up far enough to trigger "load next"
   */
  const passedLoadNextItemsThreshold = (
    event: React.UIEvent<HTMLDivElement>
  ): boolean => {
    const totalHeightOfElement = event.currentTarget.scrollHeight;
    const heightOfVisiblePart = event.currentTarget.clientHeight;
    const heightOfNonVisiblePart = totalHeightOfElement - heightOfVisiblePart;
    // with 1.0 modifier, reload was not triggered on HR screens even at maximal scroll
    const loadNextItemsThreshold = 0.8 * heightOfNonVisiblePart;

    const distanceScrolledFromBottom = event.currentTarget.scrollTop;
    return distanceScrolledFromBottom >= loadNextItemsThreshold;
  };

  // By default, @tanstack/react-virtual doesn't support reverse scrolling.
  // This is a workaround to make reverse scrolling work by inverting the scroll direction.
  useEffect(() => {
    const parentElement = commandHistoryContainerRef.current;
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();
      const currentTarget = e.currentTarget as HTMLElement;

      if (currentTarget) {
        currentTarget.scrollTop -= e.deltaY;
      }
    };
    parentElement?.addEventListener('wheel', handleScroll, {
      passive: false,
    });
    return () => {
      parentElement?.removeEventListener('wheel', handleScroll);
    };
  }, [commandHistoryContainerRef]);

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <div className="h-full w-full">
      <CommandHistoryHeader
        historyCommandType={historyCommandType}
        handleHistoryTypeChange={(type) =>
          dispatch(setHistoryCommandType(type))
        }
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      {collapsed ? null : (
        <div
          style={{
            height: CONTAINER_HEIGHT,
            width: `100%`,
            overflow: 'auto',
            // In order to have proper inverted scrolling, we need to flip the container
            // and its children.
            transform: 'scaleY(-1)',
          }}
          className={`w-full overflow-auto ${s.commandHistoryContainer}`}
          id="command-history-container"
          data-testid="command-history-container"
          ref={commandHistoryContainerRef}
          onScroll={(e) => {
            if (passedLoadNextItemsThreshold(e)) {
              loadOlderCommandHistory();
            }
          }}
        >
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 50,
                left: 0,
                width: '100%',
              }}
            >
              {virtualItems.map((virtualRow) => (
                <CommandHistoryItem
                  data-index={virtualRow.index}
                  itemMeasureRef={rowVirtualizer.measureElement}
                  key={virtualRow.key}
                  availableCommands={availableCommands}
                  isTablet={isTablet}
                  history={sortedCommandHistory[virtualRow.index]}
                  currentHistoryItemId={currentHistoryItem?.sequenceId ?? 0}
                  handleCommandCopyToEditorClick={
                    handleCommandCopyToEditorClick
                  }
                  handleHistoryCommandClick={handleHistoryCommandClick}
                  style={{
                    // Considering the container is flipped, we need to flip the items as well.
                    transform: `translateY(${virtualItems[0]?.start}px) scaleY(-1)`,
                  }}
                />
              ))}
            </div>
          </div>
          {noHistoryLeft && (
            <div
              className={s.noHistoryLeftText}
              // Considering the container is flipped, we need to flip the items as well.
              style={{
                transform: 'scaleY(-1)',
              }}
            >
              Reached the end of the command log.
            </div>
          )}
          {isHistoryFetching && (
            <div style={{ marginTop: 15 }}>
              <Spinner size={20} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CommandHistory;
