import React from "react";
import { VisuallyHidden, useFocusRing, useSwitch } from "react-aria";
import { useToggleState } from "react-stately";
import type { SwitchProps } from "@react-types/switch";

type Props = SwitchProps & {
  /**
   * The label to be displayed next to the switch.
   */
  label: React.ReactNode;
  themeProvider: {
    isDarkmode: boolean;
    setTheme: (theme: "LIGHT" | "DARK" | "AUTO") => void;
    theme: "LIGHT" | "DARK" | "AUTO";
  };
  className?: string;
  onMouseOver?: React.MouseEventHandler<HTMLInputElement> | undefined;
  onMouseOut?: React.MouseEventHandler<HTMLInputElement> | undefined;
  "data-testid"?: string;
};

/**
 * A Switch is used to toggle between two mutually exclusive states, such as on/off or true/false.
 */
const Switch = (props: Props) => {
  const state = useToggleState(props);
  const ref = React.useRef(null);
  const { labelProps, ...inputProps } = useSwitch(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const { isDarkmode } = props.themeProvider;

  const getPillFill = () => {
    if (isDarkmode) {
      return state.isSelected ? "#CCA344" : "#535353";
    }

    return state.isSelected ? "#E09C00" : "#ECE9E5";
  };

  const getHandleFill = () => {
    if (isDarkmode) {
      return state.isSelected ? "#ffcc55" : "#8C8C8C";
    }

    return state.isSelected ? "#FFC848" : "#8C8C8C";
  };

  return (
    <label
      {...labelProps}
      style={{
        display: "flex",
        alignItems: "center",
        opacity: props.isDisabled ? 0.4 : 1,
      }}
      className={props.className}
    >
      <VisuallyHidden>
        <input
          {...inputProps}
          {...focusProps}
          ref={ref}
          onMouseOut={props.onMouseOut}
          onMouseOver={props.onMouseOver}
          data-testid={props["data-testid"]}
          onChange={(e) => {
            props.onChange?.(e.target.checked);
          }}
          onBlur={props.onBlur}
        />
      </VisuallyHidden>
      <svg width={40} height={24} aria-hidden="true" style={{ marginRight: 4 }}>
        <rect x={4} y={4} width={32} height={16} rx={8} fill={getPillFill()} />
        <circle
          cx={state.isSelected ? 28 : 12}
          cy={12}
          r={5}
          fill={getHandleFill()}
        />
        {isFocusVisible && (
          <rect
            x={1}
            y={1}
            width={38}
            height={22}
            rx={11}
            fill="none"
            stroke={isDarkmode ? "#ffcc55" : "#FFC848"}
            strokeWidth={2}
          />
        )}
      </svg>
      {props.label}
    </label>
  );
};

export default Switch;
