import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import area from '@turf/area';
import { LayerSourceType, type Layer } from 'datacosmos/entities/layer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import type { Polygon } from 'geojson';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  drawRectangle: (options?: { color: string; weight: number }) => Promise<{
    rectangle: GeoJSON.Feature<Polygon>;
    rectangleMetadata: unknown;
  }>;
  addLayer: (...newLayers: Layer[]) => void;
}

export const DrawRectangle = (props: IProps) => {
  const { translate } = useLocalisation();
  return (
    <MenuItem
      className="dark:hover:text-neutral hover:text-accent-900"
      text={translate('datacosmos.rightClickMenu.rectangle')}
      icon={IconNames.RECTANGLE}
      onClick={async () => {
        const { rectangle, rectangleMetadata } = await props.drawRectangle();
        const m2 = area(rectangle);

        props.addLayer(
          PolygonLayerFactory(
            LayerSourceType.GEOMETRY_LAYER,
            translate('datacosmos.layers.names.rectangle'),
            rectangle,
            m2,
            rectangleMetadata
          )
        );
      }}
    />
  );
};
