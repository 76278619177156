import moment from 'moment';
import { DATETIME_FORMAT } from '../../../../../../../constants/datetime';
import type { IMessageLog } from '../../../hooks/useMessageLog';
import type { ITransformResponseMappingBody } from './index';
import type { IReply } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';

export default (
  addNewMessageLogEvent: (message: IMessageLog) => void
): ITransformResponseMappingBody => ({
  validator: null,
  transformer: (data) => {
    const d = data as IReply;

    return {
      description: d.data.description,
      level: d.data.level,
      time: moment(new Date(d.data.time as string)).format(DATETIME_FORMAT),
    };
  },
  handler: (data) => {
    const d = data as IMessageLog;

    addNewMessageLogEvent(d);
  },
});
