import { Icon, Tooltip } from 'opencosmos-ui';
import s from './index.module.scss';

interface IProps {
  sessionConnections: number;
}

const SessionConnectionTooltip = (props: IProps) => {
  const { sessionConnections } = props;
  return (
    <Tooltip
      delay={0}
      className={s.activity}
      content={`${sessionConnections} active connections`}
      placement={'right'}
    >
      <Icon
        stroke={sessionConnections > 0 ? '#FFCC55' : '#B5AFA6'}
        icon={'pulse'}
      />
    </Tooltip>
  );
};

export default SessionConnectionTooltip;
