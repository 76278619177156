import type { Activity } from 'api/activities/service';
import { Button, Select, SelectValue, AlertDialog } from 'opencosmos-ui';
import {
  CompositeDetailsItem,
  DetailsItem,
  JSONDetailsItem,
} from 'pages/ops/shared/components/DetailsItem';
import Textarea from '_atoms/Textarea/Textarea';
import { useState } from 'react';

type ActivityDetailsCardProps = {
  activity: Activity;
  updateActivityStatus: () => Promise<void>;
  setSelectedStatus: (status: Activity['status']) => void;
  selectedStatus: Activity['status'] | undefined;
  updateActivityWithOperatorNotes: () => void;
  operatorNotes: string | undefined;
  setOperatorNotes: (note: string) => void;
};

const ACTIVITY_STATUSES: Activity['status'][] = [
  'PENDING',
  'APPROVED',
  'CONFIRMED',
  'SCHEDULED',
  'PROCESSING',
  'COMPLETED',
  'EXPIRED',
  'FAILED',
  'CANCELLED',
];

export const ActivityDetailsCard = ({
  activity,
  updateActivityStatus,
  setSelectedStatus,
  selectedStatus,
  operatorNotes,
  updateActivityWithOperatorNotes,
  setOperatorNotes,
}: ActivityDetailsCardProps) => {
  const [shouldOpenCancelDialog, setShouldOpenCancelDialog] =
    useState<boolean>(false);
  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between items-center">
        <h2>Activity</h2>

        <div className="flex items-center gap-2">
          <span>Status: </span>
          <Select
            onSelectionChange={(key) => {
              setSelectedStatus(key.toString() as Activity['status']);
            }}
            selectedKey={selectedStatus}
          >
            {ACTIVITY_STATUSES.map((status) => (
              <SelectValue id={status} key={status}>
                {status}
              </SelectValue>
            ))}
          </Select>
          <Button
            className="w-32 h-5"
            intent={'warning'}
            onPress={() => {
              if (selectedStatus === 'CANCELLED') {
                setShouldOpenCancelDialog(true);
                return;
              }
              void updateActivityStatus();
            }}
            isDisabled={activity.status === 'CANCELLED'}
          >
            <span className="text-sm">Update status</span>
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-between gap-3">
        <DetailsItem title="ID: " value={activity.id} />
        <DetailsItem title="Status: " value={activity.status} />
        <DetailsItem title="Mission ID: " value={activity.mission_id} />
        <DetailsItem
          title="Imager ID:"
          value={activity.parameters.imager.name}
        />

        <DetailsItem
          title="Priority: "
          value={activity?.priority?.toString()}
        />

        <DetailsItem title="Start Time: " value={activity.start_date} />
        <DetailsItem title="End Time: " value={activity.end_date} />
        <DetailsItem
          title="Roll Angle: "
          value={`${Number(activity.parameters.platform.roll_angle).toFixed(
            2
          )} deg`}
        />

        <DetailsItem
          title="Observation Zenith Angle:"
          value={`${Number(
            activity.parameters?.physical?.latest?.midpoint.oza_deg
          ).toFixed(2)} deg`}
        />
        <DetailsItem
          title="Sun Zenith Angle: "
          value={`${Number(
            activity.parameters?.physical?.latest?.midpoint.sza_deg
          ).toFixed(2)} deg`}
        />
        <DetailsItem title="Coverage: " value="-" />

        <DetailsItem
          title="STAC Processing Level: "
          value={
            activity.parameters.stac
              ? // Here I am assuming that processing levels will always be listed in order
                // from least to most processed.
                `${
                  activity.parameters.stac[activity.parameters.stac.length - 1]
                    .processing_level
                }(${
                  activity.parameters.stac[activity.parameters.stac.length - 1]
                    .stac_link_time_event_processed
                })`
              : 'N/A'
          }
        />
        {activity.parameters.imager && (
          <JSONDetailsItem title="Imager" value={activity.parameters.imager} />
        )}
        {activity.parameters.platform && (
          <JSONDetailsItem
            title="Platform"
            value={activity.parameters.platform}
          />
        )}
        {activity.parameters.ground_processing && (
          <JSONDetailsItem
            title="Ground processing"
            value={activity.parameters.ground_processing}
          />
        )}
        {activity.parameters.onboard_processing && (
          <JSONDetailsItem
            title="Onboard Processing"
            value={activity.parameters.onboard_processing}
          />
        )}
        {activity.parameters.stac && (
          <JSONDetailsItem title="STAC" value={activity.parameters.stac} />
        )}

        <CompositeDetailsItem
          title="Operator notes: "
          element={
            <div>
              <Textarea
                value={operatorNotes}
                onChange={(e) => {
                  setOperatorNotes(e.target.value);
                }}
                className="bg-item dark:bg-item-contrast"
              />
              <Button
                intent="primary"
                onPress={() => {
                  updateActivityWithOperatorNotes();
                }}
                className="w-32 h-6"
              >
                <span className="text-sm">Submit</span>
              </Button>
            </div>
          }
        />

        <AlertDialog
          actionLabel="Confirm"
          title="Warning"
          onAction={() => {
            void updateActivityStatus();
            setShouldOpenCancelDialog(false);
          }}
          onClose={() => {
            setShouldOpenCancelDialog(false);
          }}
          isOpen={shouldOpenCancelDialog}
        >
          <span>Are you sure you want to cancel activity {activity.id}?</span>
        </AlertDialog>
      </div>
    </div>
  );
};
