import { isEmpty } from 'lodash/fp';
import Fuse from 'fuse.js';
import s from './index.module.scss';
import type { AlertRule } from 'api/alerts/types';
import { Group, Icon, TextField } from 'opencosmos-ui';
import classNames from 'classnames';

interface IProps {
  alerts: AlertRule[];
  handleFilteredValue?: (alerts: AlertRule[]) => void;
}

const options: Fuse.FuseOptions<AlertRule> = {
  shouldSort: true,
  threshold: 0.3,
  keys: ['labels.system', 'id', 'alert'],
};

const AlertsFilter = (props: IProps) => {
  const handleFilterChange = (filterString: string) => {
    const fuse = new Fuse(props.alerts, options);
    const result = fuse.search(filterString);

    const filteredTelemetries =
      isEmpty(result) || !filterString.length ? null : result;
    props.handleFilteredValue?.(filteredTelemetries as AlertRule[]);
  };

  return (
    <Group className={'flex w-full items-center gap-1'}>
      <Icon icon="Filter" />
      <TextField
        fill={true}
        className={classNames(s.alertsFilter)}
        onChange={(val) => handleFilterChange(val)}
        inputProps={{
          placeholder: 'Filter alert rules...',
          className: 'h-5 px-2 w-full',
        }}
      />
    </Group>
  );
};

export default AlertsFilter;
