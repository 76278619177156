import type { IOptimisationObjective } from '../../../../../constants/objectivesTable/constants';
import type { IObjectivesActionTypes } from '../../../../../constants/objectivesTable/actionTypes';
import {
  OBJECTIVES_REMOVE,
  OBJECTIVES_UPDATE,
} from '../../../../../constants/objectivesTable/actionTypes';

const initialState: IOptimisationObjective[] = [];

export default function constraintsReducer(
  state: IOptimisationObjective[] = initialState,
  action: IObjectivesActionTypes
): IOptimisationObjective[] {
  switch (action.type) {
    case OBJECTIVES_UPDATE: {
      return [...action.objectives];
    }
    case OBJECTIVES_REMOVE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
