import classNames from 'classnames';
import { Button } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import s from './index.module.scss';

interface IProps {
  handleEnable: () => void;
  saving?: boolean;
}

const LibrarySaveCard = (props: IProps) => {
  return (
    <div className="dark:bg-item-dark bg-item p-6">
      <span>Alerts are not enabled for this mission</span>
      <Button
        icon="saved"
        className={classNames(s.librarySaveBtn)}
        onPress={props.handleEnable}
        intent={'primary'}
      >
        {props.saving ? (
          <Spinner size={12} />
        ) : (
          <span className="text-sm">Enable</span>
        )}
      </Button>
    </div>
  );
};

export default LibrarySaveCard;
