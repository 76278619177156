import React from 'react';
import { isUndefined } from 'lodash/fp';
import { Select, SelectValue } from 'opencosmos-ui';

export interface IProps {
  canSetDefaultValue: boolean;
  fullPath: string;
  value: string;
  disabled: boolean;
  enumValue: string[];
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  setTooltipOpen: (open: boolean) => void;
  className?: string;
}

const CommandStringEnumInput = (props: IProps) => {
  const {
    value,
    onChange,
    fullPath,
    enumValue,
    disabled,
    canSetDefaultValue,
    handleBlur,
    handleFocus,
    className,
  } = props;

  if (!value && canSetDefaultValue && !isUndefined(canSetDefaultValue)) {
    onChange(enumValue[0], fullPath);
  }

  return (
    <Select
      fill
      isDisabled={disabled}
      selectedKey={value}
      onSelectionChange={(val) => {
        onChange(val, fullPath);
      }}
      className={className}
      onBlur={handleBlur}
      onFocus={handleFocus}
      popoverProps={{ placement: 'bottom' }}
      data-testid="enum-select"
    >
      {enumValue.map((item: string) => (
        <SelectValue key={item} id={item}>
          {item}
        </SelectValue>
      ))}
    </Select>
  );
};

export default CommandStringEnumInput;
