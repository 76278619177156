import { useCallback } from 'react';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { useCurrentTerminalSession } from 'pages/ops/RTI/Operate/context/TerminalSessionProvider';
import {
  clearCommandCustomName,
  clearCurrentPayload,
  hideRightPanel,
  pileCommandRemove,
  pileItemClose,
  pileItemOpen,
  pileItemSelection,
  showRightPanel,
} from 'actions/operate/actions';
import { find, get, remove } from 'lodash/fp';
import type { ICommandPileItem } from 'services/api/ops/realtimeTerminalSession';

export const useCommandPileHandlers = () => {
  const {
    dispatch,
    state: {
      commandPileWorkingSpace,
      armedCommand,
      frozenPile,
      selectedPileItem,
      selectedMultiplePileItems,
    },
  } = useOperate();

  const { updateWorkspace, availableCommands } = useCurrentTerminalSession();

  const handlePileCommandRemove = useCallback(
    (id: string | undefined) => {
      if (!commandPileWorkingSpace) return;
      const pile = remove(['id', id], commandPileWorkingSpace);
      updateWorkspace(pile, armedCommand, Boolean(frozenPile));

      if (get('id', selectedPileItem) === id) {
        dispatch(pileCommandRemove());
      }
    },
    [
      armedCommand,
      commandPileWorkingSpace,
      dispatch,
      frozenPile,
      selectedPileItem,
      updateWorkspace,
    ]
  );

  const handleArmCommand = useCallback(
    (id: string) => {
      if (!commandPileWorkingSpace) return;

      const newarmedCommand = find(['id', id], commandPileWorkingSpace);
      const pile = remove(['id', id], commandPileWorkingSpace);
      if (armedCommand) {
        pile.push(armedCommand);
      }
      dispatch(clearCurrentPayload());
      dispatch(clearCommandCustomName());
      updateWorkspace(pile, newarmedCommand, Boolean(frozenPile));
    },
    [
      armedCommand,
      commandPileWorkingSpace,
      dispatch,
      frozenPile,
      updateWorkspace,
    ]
  );

  const handleEditableMultiplePileItemSelect = useCallback(
    (pileItem: ICommandPileItem, addToMultipleItemDrag: boolean) => {
      if (
        addToMultipleItemDrag &&
        !selectedPileItem &&
        !selectedMultiplePileItems
      ) {
        dispatch(hideRightPanel());
      }

      if (addToMultipleItemDrag) {
        const tempSelectedItems = selectedMultiplePileItems?.length
          ? [...selectedMultiplePileItems]
          : [];
        if (tempSelectedItems?.includes(pileItem)) {
          tempSelectedItems?.splice(tempSelectedItems.indexOf(pileItem), 1);
          dispatch(pileItemSelection(tempSelectedItems));
          return;
        }
        if (!tempSelectedItems?.length && selectedPileItem) {
          tempSelectedItems?.push(selectedPileItem);
        }
        tempSelectedItems?.push(pileItem);
        dispatch(pileItemSelection(tempSelectedItems));
        if (
          selectedMultiplePileItems?.length &&
          tempSelectedItems?.length === 1
        ) {
          const command = find(['name', pileItem.command], availableCommands);
          if (command) {
            dispatch(pileItemOpen(pileItem, command));
          }
        } else {
          dispatch(pileItemClose());
        }
      }
    },
    [dispatch, selectedPileItem, selectedMultiplePileItems, availableCommands]
  );

  const handleEditablePileItemSelect = useCallback(
    (pileItem: ICommandPileItem, addToMultipleItemDrag: boolean) => {
      if (!addToMultipleItemDrag) {
        //single selection logic
        dispatch(showRightPanel());
        if (get('id', selectedPileItem) === pileItem?.id) {
          dispatch(pileItemClose());
          dispatch(pileItemSelection([]));
          return;
        }
        const command = find(['name', pileItem?.command], availableCommands);
        if (command) {
          dispatch(pileItemOpen(pileItem, command));
          dispatch(pileItemSelection([]));
        }
      }
      handleEditableMultiplePileItemSelect(pileItem, addToMultipleItemDrag);
    },
    [
      availableCommands,
      dispatch,
      handleEditableMultiplePileItemSelect,
      selectedPileItem,
    ]
  );

  const handleDeleteSelectedPileItems = useCallback(() => {
    if (!commandPileWorkingSpace) {
      return;
    }

    const selectedCmds = [
      ...(selectedMultiplePileItems ?? []),
      selectedPileItem,
    ].reduce((acc, item) => {
      if (acc.map((a) => a.id).includes(item?.id)) {
        return acc;
      }
      if (!item) {
        return acc;
      }
      return [...acc, item];
    }, [] as ICommandPileItem[]);

    const newPile = commandPileWorkingSpace.filter(
      (cmd) => !selectedCmds.map((sc) => sc.id).includes(cmd.id)
    );

    updateWorkspace(newPile, armedCommand, Boolean(frozenPile));
  }, [
    armedCommand,
    commandPileWorkingSpace,
    frozenPile,
    selectedMultiplePileItems,
    selectedPileItem,
    updateWorkspace,
  ]);

  return {
    handlePileCommandRemove,
    handleArmCommand,
    handleEditablePileItemSelect,
    handleDeleteSelectedPileItems,
  };
};
