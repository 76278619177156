import { useEffect, useMemo, useRef, useState } from 'react';
import type { IPopoverProps } from '_atoms/Popover/Popover';
import SelectItem from '_atoms/SelectItem/SelectItem';
import type { InputProps } from '_molecules/Input/Input';
import Input from '_molecules/Input/Input';
import { Popover } from 'opencosmos-ui';
import { zIndexValues } from 'opencosmos-ui/constants';

interface ISuggestionInputProps extends InputProps {
  suggestionItems: string[];
  onSuggestionClick: (value: string) => void;
  popoverProps: Partial<IPopoverProps>;
}

const SuggestionInput = (props: ISuggestionInputProps) => {
  const textInputValueArray = (props.value as string).split(/[\s+-\\*]/);

  const [isSuggestionApplied, setIsSuggestionApplied] =
    useState<boolean>(false);

  const [isClickedOutside, setIsClickedOutside] = useState<boolean>(false);

  const triggerRef = useRef<HTMLInputElement>(null);

  const isPopoverOpen = useMemo(() => {
    return (
      textInputValueArray[textInputValueArray.length - 1].match(
        /(\S*\d+\S*)|([a-zA-Z])/
      ) !== null &&
      textInputValueArray[textInputValueArray.length - 1].length > 0 &&
      !isSuggestionApplied &&
      !isClickedOutside
    );
  }, [textInputValueArray, isSuggestionApplied, isClickedOutside]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setIsClickedOutside(true);
    }
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isPopoverOpen]);

  return (
    <>
      <div ref={triggerRef}>
        <Input
          {...props}
          onChange={(e) => {
            if (props.onChange) {
              isSuggestionApplied && setIsSuggestionApplied(false);
              setIsClickedOutside(false);
              props.onChange(e);
            }
          }}
        />
      </div>
      <Popover
        triggerRef={triggerRef}
        placement="bottom right"
        className={`!z-[${zIndexValues.popover}] bg-item dark:bg-item-hover`}
        isOpen={isPopoverOpen}
      >
        <div className="flex-col">
          {props.suggestionItems
            .filter((b) =>
              b.startsWith(textInputValueArray[textInputValueArray.length - 1])
            )
            .map((b) => (
              <SelectItem
                text={b}
                key={b}
                onClick={() => {
                  setIsSuggestionApplied(true);
                  props.onSuggestionClick(
                    (props.value as string).replace(/(\w+)$/, b)
                  );
                }}
              />
            ))}
        </div>
      </Popover>
    </>
  );
};

export default SuggestionInput;
