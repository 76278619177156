import { useRef } from 'react';
import type { AriaDialogProps } from '@react-aria/dialog';
import type { AriaOverlayProps } from '@react-aria/overlays';
import {
  FocusScope,
  mergeProps,
  OverlayContainer,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import Button from '_molecules/Button/Button';
import IconButton from '_molecules/IconButton/IconButton';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { IconName } from 'ui/Icon';
import type { PressEvent } from '@react-types/shared';
import { Spinner } from '@blueprintjs/core';
import { zIndexValues } from 'opencosmos-ui/constants';

type Btn = {
  text: string;
  onPress: (e: PressEvent) => Promise<void> | void;
  icon?: IconName;
  shown: boolean;
  showLoadingIndicator?: boolean;
};
interface IDialogProps extends AriaDialogProps, AriaOverlayProps {
  children: JSX.Element | JSX.Element[];
  buttons: Btn[];
  title?: string;
  hideCancelButton?: boolean;
}

const Modal = (props: IDialogProps) => {
  const { children, title, onClose } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  const { translate } = useLocalisation();
  return (
    <div
      {...underlayProps}
      className="fixed inset-0 w-full flex justify-center items-center bg-surface/50 dark:bg-surface-dark/50"
      style={{ zIndex: zIndexValues.dialog }}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(dialogProps, overlayProps, modalProps)}
          ref={ref}
          className="color-surface !opacity-100 w-1/2 h-[80vh] overflow-auto"
        >
          <div className="h-12 color-header flex justify-between items-center p-2 w-full">
            {title ? <h4 {...titleProps}>{title}</h4> : <div />}
            <IconButton icon="Close" onPress={() => onClose?.()} size={24} />
          </div>
          <div className="p-4">{children}</div>
          <div className="pt-8 flex flex-col justify-end w-full p-4 gap-1">
            {props.buttons
              .filter((b) => b.shown)
              .map((b) => (
                <Button
                  key={b.text}
                  text={b.showLoadingIndicator ? <Spinner size={16} /> : b.text}
                  onPress={async (e) => {
                    await b.onPress(e);
                    onClose?.();
                  }}
                  className="color-item h-12 w-full"
                />
              ))}
            {!props.hideCancelButton && (
              <Button
                text={translate('datacosmos.buttons.cancel')}
                onPress={() => onClose?.()}
                className="color-item h-12"
              />
            )}
          </div>
        </div>
      </FocusScope>
    </div>
  );
};

const Dialog = (props: IDialogProps) => {
  return (
    <OverlayContainer>{props.isOpen && <Modal {...props} />}</OverlayContainer>
  );
};

export default Dialog;
