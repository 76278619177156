import React, { useEffect, useState } from 'react';
import { Button, Dialog } from '@blueprintjs/core';
import type { IFolderTreeItem } from './FolderTree';
import TagHeader from '../../../../../../components/common/TagHeader';
import { crc32ChecksumToHex } from '../../../../../../utils/fileExplorer/crc32Utils';
import { EntryType } from '../../../../../../constants/fileExplorer/constants';
import s from './index.module.scss';
import { base64ToHex } from 'utils/common/base64Utils';

interface IProps {
  isPropertiesOpen: boolean;
  handlePropertiesDialogClose: () => void;
  handleCalculateCRCClick: (fileName: string) => void;
  content: IFolderTreeItem;
  calculatedChecksum?: number;
}

const PropertiesDialog = (props: IProps) => {
  const [isCalculation, setCalculation] = useState(false);

  useEffect(() => {
    setCalculation(false);
  }, [props.calculatedChecksum]);

  if (!props.content) return null;

  const { name, path, lastModified, size, type, checksum, md5 } = props.content;

  const chosenChecksum = checksum || props.calculatedChecksum;

  const handleCalculateButtonClick = () => {
    if (!name) return;
    setCalculation(true);
    props.handleCalculateCRCClick(name);
  };

  const handleOnClose = () => {
    setCalculation(false);
    props.handlePropertiesDialogClose();
  };

  return (
    <Dialog
      usePortal
      canOutsideClickClose
      className={s.propertiesDialog}
      isOpen={props.isPropertiesOpen}
      onClose={handleOnClose}
    >
      <div className={s.propertiesDialogBody}>
        <TagHeader className={s.propertyHeaderIcon} title="Properties" />
        <div className={s.container}>
          <div className={s.propertyContainer}>
            <span className={s.propertiesTitle}>Name:</span>
            <span>{name}</span>
          </div>
          <div className={s.propertyContainer}>
            <span className={s.propertiesTitle}>Path:</span>
            <span>{path}</span>
          </div>
          <div className={s.propertyContainer}>
            <span className={s.propertiesTitle}>Type:</span>
            <span>{type}</span>
          </div>
          <div className={s.propertyContainer}>
            <span className={s.propertiesTitle}>Last Modified:</span>
            <span>{lastModified}</span>
          </div>
          {size && (
            <div className={s.propertyContainer}>
              <span className={s.propertiesTitle}>Size:</span>
              <span>{size}</span>
            </div>
          )}
          {type === EntryType.FILE && (
            <>
              <div className={s.propertyContainer}>
                <span className={s.propertiesTitle}>CRC32:</span>
                {chosenChecksum ? (
                  <span>{chosenChecksum}</span>
                ) : (
                  <Button
                    loading={isCalculation}
                    text="Calculate"
                    onClick={handleCalculateButtonClick}
                  />
                )}
              </div>
              {chosenChecksum && (
                <div className={s.propertyContainer}>
                  <span className={s.propertiesTitle}>CRC32 HEX:</span>
                  <span>{crc32ChecksumToHex(chosenChecksum)}</span>
                </div>
              )}

              <div className={s.propertyContainer}>
                <span className={s.propertiesTitle}>MD5 HEX:</span>
                <span>{base64ToHex(md5 ?? '')}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default PropertiesDialog;
