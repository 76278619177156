import {
  DatePickerProps,
  DateValue,
  FieldError,
  ValidationResult,
} from "react-aria-components";
import {
  Dialog,
  Text,
  DatePicker,
  Button,
  Popover,
  Calendar,
  CalendarGrid,
  CalendarCell,
  Heading,
  DateInput,
  DateSegment,
} from "react-aria-components";
import Icon from "../../icons/Icon";
import { useEffect, useRef } from "react";
import classNames from "classnames";

export type DateAndTimePickerSegmentsProps<T extends DateValue> =
  DatePickerProps<T> & {
    label?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    /**
     * useTextDateInput is a boolean that determines whether the date input should be a simple text field
     * or a segmented date input
     */
    useTextDateInput: false;
  };

function DateAndTimePickerSegments<T extends DateValue>({
  description,
  ...props
}: DateAndTimePickerSegmentsProps<T>) {
  const DateInputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeZoneElement = document.querySelector("[data-type='timeZoneName']");

    if (timeZoneElement) {
      timeZoneElement.className += " absolute right-7";
    }
  }, []);

  return (
    <DatePicker {...props} className={"w-full"}>
      {({ state }) => {
        return (
          <>
            <div
              className={
                "flex p-1 gap-1 text-sm border-2 border-item w-full focus-within:border-accent-400 focus-within:shadow-md"
              }
              ref={DateInputRef}
            >
              <DateInput className={"w-full flex"}>
                {(segment) => (
                  <span>
                    <DateSegment segment={segment} />
                  </span>
                )}
              </DateInput>

              <Button data-testid="calendar-trigger">
                <Icon
                  icon="ChevronDown"
                  className="stroke-item-contrast dark:stroke-item-dark-contrast"
                />
              </Button>
            </div>
            {description && <Text slot="description">{description}</Text>}
            <FieldError>{props.errorMessage}</FieldError>
            <Popover
              className={
                "!z-[9999999] bg-surface dark:bg-surface-dark dark:text-item-dark-contrast absolute"
              }
              triggerRef={DateInputRef}
            >
              <Dialog className="px-2">
                <Calendar minValue={props.minValue} value={state.value}>
                  <header className="flex justify-between p-1">
                    <Button slot="previous">
                      <Icon icon="ChevronLeft" />
                    </Button>
                    <Heading className="text-sm font-bold" />
                    <Button slot="next">
                      <Icon icon="ChevronRight" />
                    </Button>
                  </header>
                  <CalendarGrid className="text-center">
                    {(date) => (
                      <CalendarCell
                        date={date}
                        className={({ isSelected }) =>
                          classNames(
                            "w-[26px] h-fit max-h-20 min-h-full min-w-full p-1 flex flex-col text-[12px] hover:text-accent-900 dark:hover:text-accent hover:bg-item-selected dark:hover:bg-item-dark-selected aria-disabled:text-contrast-inactive aria-disabled:opacity-50 aria-disabled:pointer-events-none",
                            {
                              "bg-item-selected dark:bg-item-dark-selected":
                                isSelected,
                            }
                          )
                        }
                      />
                    )}
                  </CalendarGrid>
                </Calendar>{" "}
              </Dialog>
            </Popover>
          </>
        );
      }}
    </DatePicker>
  );
}

export default DateAndTimePickerSegments;
