import React, { useState } from 'react';
import {
  remove,
  startCase,
  map,
  reduce,
  filter,
  pipe,
  uniq,
  isEmpty,
  difference,
} from 'lodash/fp';
import { intersection } from 'lodash';
import TagHeader from 'components/common/TagHeader';

import s from '../../index.module.scss';
import type { GS } from 'api/groundStation/types';
import { Accordion, Button, Checkbox, Group, Tooltip } from 'opencosmos-ui';
import { useTheme } from 'datacosmos/stores/ThemeProvider';

interface IProps {
  groundStations: GS[];
  currentFocusId: number;
  currentStationIdList: number[];
  currentZoom: number;
  setCurrentFocusId: (id: number) => void;
  handleGSDetailZoomClick: (stationId: number) => void;
  handleProviderCheckBoxChange: (id: any, provider: string) => void;
  handleGSCheckBoxChange: (id: any) => void;
}

const combineStationByProvider = (stationList: GS[]) => {
  return pipe(
    map('providerName'),
    uniq,
    reduce((acc: any, provider: any) => {
      const stationByProvider = filter(['providerName', provider], stationList);

      return [...acc, { provider, stations: stationByProvider }];
    }, [])
  )(stationList);
};

export const isAllCheckboxActive = (
  stations: GS[],
  stationIdList: number[]
) => {
  if (isEmpty(stationIdList)) {
    return false;
  }

  return pipe(
    map('id'),
    (ids) => difference(ids, stationIdList),
    isEmpty
  )(stations);
};

export const isAnyCheckboxActive = (
  stations: GS[],
  stationIdList: number[],
  provider: string
) => {
  const isAllActive = isAllCheckboxActive(stations, stationIdList);

  const relevantStations = stations.filter(
    (station) => station.providerName === provider
  );

  const containsStationsFromThisProvider = !pipe(
    map('id'),
    (ids) => intersection(ids, stationIdList),
    isEmpty
  )(relevantStations);

  return !isEmpty(stationIdList) || isAllActive
    ? containsStationsFromThisProvider
    : false;
};

export const getIndeterminateState = (
  stations: GS[],
  stationIdList: number[],
  provider: string
) => {
  return (
    isAnyCheckboxActive(stations, stationIdList, provider) &&
    !isAllCheckboxActive(stations, stationIdList)
  );
};

const GSList = (props: IProps) => {
  const theme = useTheme();
  const [openList, setOpenList] = useState<string[]>([]);

  const checkIfOpen = (providerName: string) => {
    return openList.includes(providerName);
  };

  const handleItemClick = (provider: string) => {
    if (openList.includes(provider)) {
      const newItems = remove((it) => it === provider, openList);
      setOpenList([...newItems]);
      return;
    }
    setOpenList([...openList, provider]);
  };

  return (
    <div className={s.gslistContainer}>
      <TagHeader icon="globe" title="Ground Stations Network" />
      <div className={s.gslistWrapper}>
        <ul>
          {combineStationByProvider(props.groundStations).map((item: any) => (
            <li
              role="presentation"
              key={item.provider}
              className="bg-item dark:bg-item-dark-selected dark:text-item-dark-contrast"
            >
              <div className={s.itemWrapper}>
                <div className={s.collapseWrapper}>
                  <Accordion
                    labelText={
                      <div
                        className="w-full flex items-center gap-1"
                        onClick={() => handleItemClick(item.provider)}
                      >
                        <Checkbox
                          themeProvider={theme}
                          isSelected={isAllCheckboxActive(
                            item.stations,
                            props.currentStationIdList
                          )}
                          onChange={() =>
                            props.handleProviderCheckBoxChange(
                              item.stations,
                              item.provider
                            )
                          }
                        />
                        {startCase(item.provider)}
                      </div>
                    }
                    isOpen={checkIfOpen(item.provider)}
                  >
                    {item.stations.map((station: GS) => (
                      <div key={station.id} className={s.collapseItemWrapper}>
                        <div className={s.collapseItem}>
                          <div className={s.gsListCheckboxContainer}>
                            <Checkbox
                              themeProvider={theme}
                              key={station.name}
                              isSelected={props.currentStationIdList.includes(
                                station.id
                              )}
                              onChange={() =>
                                props.handleGSCheckBoxChange(station.id)
                              }
                            />
                            <span
                              role="presentation"
                              onClick={() =>
                                props.handleGSCheckBoxChange(station.id)
                              }
                            >
                              {station.name}{' '}
                              <Tooltip
                                delay={0}
                                content={`Available antennas: ${station.antennas.length}`}
                              >
                                <span>[{station.antennas.length}]</span>
                              </Tooltip>
                            </span>
                          </div>
                          <div className={s.gsListButtonContainer}>
                            <Group className={'flex items-center'}>
                              <Button
                                icon={'locate'}
                                onPress={() => {
                                  props.setCurrentFocusId(station.id);
                                }}
                              />
                            </Group>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Accordion>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GSList;
