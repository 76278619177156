import type { StacItem } from 'datacosmos/types/stac-types';
import ConfirmPurchaseImageCard from './ConfirmPurchaseImageCard';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { Price } from 'api/orders/types';
import { Spinner } from '@blueprintjs/core';

interface IProps {
  currentImage: StacItem | undefined;
  imagesToPurchase: StacItem[] | undefined;
  setCurrentImage: (image: StacItem) => void;
  totalPrice: Price | undefined;
  isFetchingPrice: boolean;
  itemsWithPrice: { item: StacItem; price: Price }[];
}

const ConfirmPurchaseItemList = ({
  currentImage,
  itemsWithPrice,
  setCurrentImage,
  totalPrice,
  isFetchingPrice: isLoadingPrice,
}: IProps) => {
  const { translate } = useLocalisation();
  const withNoPrice = (children: React.ReactNode) => {
    if (!totalPrice) {
      return (
        <span>
          {translate('datacosmos.catalogAndItems.purchaseDialog.noPrice')}
        </span>
      );
    }

    return children;
  };

  const withPriceLoading = (children: React.ReactNode) => {
    if (isLoadingPrice) {
      return <Spinner size={24} />;
    }

    return children;
  };

  return (
    <div
      className="flex flex-col gap-2 w-full text-center justify-between"
      style={{ height: 'inherit' }}
    >
      {withPriceLoading(
        <>
          <table
            data-testid="images-to-purchase-list"
            className="table-fixed w-full"
          >
            <thead
              data-testid="images-to-purchase-header"
              className="mb-2 border-b-2 border-b-item-contrast-text-field-stroke block w-full"
            >
              <tr className="table w-full">
                <th>
                  {translate('datacosmos.catalogAndItems.purchaseDialog.title')}
                </th>
                <th>
                  {translate(
                    'datacosmos.catalogAndItems.purchaseDialog.location'
                  )}
                </th>
                <th>
                  {translate('datacosmos.catalogAndItems.purchaseDialog.date')}
                </th>
                <th>
                  {translate('datacosmos.catalogAndItems.purchaseDialog.price')}
                </th>
              </tr>
            </thead>

            <tbody
              data-testid="images-to-purchase-container"
              className="overflow-y-auto block h-min w-full"
            >
              {itemsWithPrice?.map((iwp) => (
                <ConfirmPurchaseImageCard
                  currentImage={currentImage}
                  image={iwp.item}
                  onClick={() => {
                    setCurrentImage(iwp.item);
                  }}
                  key={iwp.item.id}
                  price={iwp.price}
                />
              ))}
            </tbody>
          </table>

          <div className="flex flex-col gap-1 items-center">
            <span>
              {translate('datacosmos.catalogAndItems.purchaseDialog.subtotal', {
                subtotal: totalPrice?.value,
                currency: totalPrice?.currency,
              })}
            </span>
            <span>
              {translate('datacosmos.catalogAndItems.purchaseDialog.discount', {
                discount: totalPrice?.discount,
                currency: totalPrice?.currency,
              })}
            </span>
            <span className="text-lg">
              {translate(
                'datacosmos.catalogAndItems.purchaseDialog.totalPrice'
              )}
            </span>
            {withNoPrice(
              <span className="text-sm">
                {totalPrice?.final} {totalPrice?.currency}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmPurchaseItemList;
