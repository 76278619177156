import { Menu, MenuItem } from '@blueprintjs/core';
import { ContextMenu2 } from '@blueprintjs/popover2';
import React from 'react';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { useCommandCopyPaste } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandCopyPaste';
import { useCommandPileHandlers } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandPileHandlers';

type Props = {
  pileItemIndex: number;
  children: React.ReactNode;
};

const CommandPileContextMenu = ({ children, pileItemIndex: index }: Props) => {
  const {
    state: { selectedMultiplePileItems },
  } = useOperate();

  const { handleCopySelectedCommands, handlePasteCmd } = useCommandCopyPaste();
  const { handleDeleteSelectedPileItems } = useCommandPileHandlers();

  return (
    <ContextMenu2
      content={
        <Menu>
          <MenuItem
            text={
              selectedMultiplePileItems?.length
                ? `Copy ${selectedMultiplePileItems.length} commands`
                : 'Copy command'
            }
            onClick={() => handleCopySelectedCommands()}
            icon="duplicate"
          />
          <MenuItem
            text={'Paste below'}
            onClick={() => handlePasteCmd(index)}
            icon="clipboard"
          />
          <MenuItem
            text={
              selectedMultiplePileItems?.length
                ? `Delete ${selectedMultiplePileItems.length} commands`
                : 'Delete command'
            }
            onClick={() => handleDeleteSelectedPileItems()}
            icon="warning-sign"
            intent="danger"
          />
        </Menu>
      }
    >
      {children}
    </ContextMenu2>
  );
};

export default CommandPileContextMenu;
