import {
  Tab as RACTab,
  TabList as RACTabList,
  TabPanel as RACTabPanel,
  Tabs as RACTabs,
  TabListProps,
  TabPanelProps,
  TabProps as RACTabProps,
  TabsProps,
  composeRenderProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";

const tabsStyles = tv({
  base: "flex gap-4",
  variants: {
    orientation: {
      horizontal: "flex-col",
      vertical: "flex-row w-[800px]",
    },
  },
});

export function Tabs(props: TabsProps) {
  return (
    <RACTabs
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabsStyles({
          ...renderProps,
          className,
        })
      )}
      style={{
        maxHeight: "inherit",
      }}
    />
  );
}

const tabListStyles = tv({
  base: "flex gap-2 items-center",
  variants: {
    orientation: {
      horizontal: "flex-row",
      vertical: "flex-col items-start",
    },
    direction: {
      ["top-to-bottom"]: ["flex-col items-start"],
      ["bottom-to-top"]: ["flex-col-reverse items-end"],
    },
  },
});

export function TabList<T extends object>(props: TabListProps<T>) {
  return (
    <RACTabList
      {...props}
      className={composeRenderProps(
        props.className,
        (className, renderProps) => {
          return tabListStyles({
            ...renderProps,
            className,
          });
        }
      )}
    />
  );
}

const tabProps = tv({
  base: "flex items-center gap-1 box-border cursor-pointer py-1 text-sm font-medium",
  variants: {
    isSelected: {
      false: "text-item-contrast dark:text-item-dark-contrast",
      true: "text-item-contrast dark:text-item-dark-contrast border-b-4 border-accent outline-none ",
    },
    isDisabled: {
      true: "text-item-contrast-inactive cursor-not-allowed",
    },
    fill: {
      true: "w-full",
      false: "px-4",
    },
  },
});

type TabProps = RACTabProps & {
  fill?: boolean;
};
export function Tab(props: TabProps) {
  return (
    <RACTab
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabProps({ ...renderProps, className, fill: props.fill })
      )}
    />
  );
}

const tabPanelStyles = tv({
  base: "flex-1 p-4 text-sm overflow-y-auto",
});

export function TabPanel(props: TabPanelProps) {
  return (
    <RACTabPanel
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabPanelStyles({ ...renderProps, className })
      )}
      style={{
        maxHeight: "inherit",
      }}
    />
  );
}
