import type { Dispatch, Key, SetStateAction } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import TaskingFilters from './TaskingFiltersModal';
import { TaskingPanel } from 'datacosmos/components/Tasking/helpers';
import type { useTaskingRequestList } from './RequestViewing/useTaskingRequestList';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import SecondaryButton from '_molecules/Button/SecondaryButton';
interface IProps {
  isRequestListOpen: boolean;
  handleTaskingSelectionChange: (value: Key) => void;
  handleShowAllSwathsPress: () => void;
  taskingRequestsHook: ReturnType<typeof useTaskingRequestList>;
  showRequestIDs: boolean;
  handleSetShowRequestIDs: Dispatch<SetStateAction<boolean>>;
}

const TaskingHeaderAndFilters = ({
  isRequestListOpen,
  handleTaskingSelectionChange,
  handleShowAllSwathsPress,
  taskingRequestsHook,
  showRequestIDs,
  handleSetShowRequestIDs,
}: IProps) => {
  const { translate } = useLocalisation();

  return (
    <div className="mb-px dark:bg-surface-dark flex p-2">
      <div className="flex items-center w-full gap-2 dark:text-item-dark-contrast">
        {isRequestListOpen ? (
          <PrimaryButton
            icon="Tasking"
            size={24}
            text={translate('datacosmos.tasking.new.title')}
            data-testid="datacosmos-show-all-swaths-button"
            onPress={() => {
              handleTaskingSelectionChange(TaskingPanel.New);
            }}
          />
        ) : (
          <SecondaryButton
            icon="ArrowLeft"
            size={24}
            text={translate('datacosmos.tasking.existing.title')}
            data-testid="datacosmos-show-all-swaths-button"
            onPress={() => {
              handleTaskingSelectionChange(TaskingPanel.Existing);
            }}
          />
        )}
        {isRequestListOpen && (
          <>
            <SecondaryButton
              icon={showRequestIDs ? 'EyeClosed' : 'Eye'}
              size={24}
              text={'IDs'}
              onPress={() => handleSetShowRequestIDs((prev) => !prev)}
            />
            <SecondaryButton
              icon="Swath"
              size={24}
              text={translate('datacosmos.tooltips.tasking.existing.viewSwath')}
              data-testid="datacosmos-show-all-swaths-button"
              onPress={handleShowAllSwathsPress}
            />
            <TaskingFilters taskingRequestsHook={taskingRequestsHook} />
          </>
        )}
      </div>
    </div>
  );
};

export default TaskingHeaderAndFilters;
