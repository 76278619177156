import { IconNames } from '@blueprintjs/icons';
import type { IBottomBarTab } from '../../../pages/ops/Library/components/BottomNavBar';

export const SCRIPTING_BOTTOM_NAVBAR_TABS: IBottomBarTab[] = [
  {
    id: 'nodered',
    title: 'Scripting',
    icon: IconNames.CODE,
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: IconNames.DESKTOP,
  },
];

export const DEFAULT_TAB_STATE = 'nodered';
