import type { IconName } from '@blueprintjs/core';
import { getOr } from 'lodash/fp';
import {
  FAVORITES_COMMANDS_GROUP,
  GROUP_ICON_MAPPING,
} from 'constants/ops/rti/oparate/constants';
import { Accordion } from 'opencosmos-ui';
import s from './index.module.scss';

type CommandListGroupBodyProps = {
  groupName: string;
  defaultIcon: IconName;
  children: React.ReactNode;
};

const CommandListGroupBody = ({
  groupName,
  defaultIcon,
  children,
}: CommandListGroupBodyProps) => {
  const isFavoriteTagStyles =
    FAVORITES_COMMANDS_GROUP === groupName ? s.commandFeatureTag : null;

  return (
    <li className={isFavoriteTagStyles ?? undefined}>
      <Accordion
        labelText={groupName}
        icon={getOr(defaultIcon, groupName, GROUP_ICON_MAPPING)}
        className="h-8"
      >
        <div className="flex flex-col gap-1">{children}</div>
      </Accordion>
    </li>
  );
};

export default CommandListGroupBody;
