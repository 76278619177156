import classNames from "classnames";
import {
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker,
  DatePickerProps,
  DateSegment,
  DateValue,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
  ValidationResult,
} from "react-aria-components";
import { FieldError, Text } from "react-aria-components";
import Button from "../../core/Button/Button";
import Calendar from "../Calendars/Calendar/Calendar";

interface MyDatePickerProps<T extends DateValue>
  extends Omit<DatePickerProps<T>, "className" | "onChange"> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  fill?: boolean;
  className?: string;
  onChange?: (date: DateValue) => void;
}

function MyDatePicker<T extends DateValue>({
  label,
  description,
  errorMessage,
  fill,
  ...props
}: MyDatePickerProps<T>) {
  return (
    <DatePicker
      {...props}
      className={classNames(props.className, "dark:text-item-dark-contrast", {
        "w-full": fill,
      })}
      value={props.value}
      onChange={(date) => {
        props.onChange?.(date);
      }}
    >
      <Label>{label}</Label>
      <Group className={"flex items-center"}>
        <DateInput
          className={classNames(
            "flex items-center border border-item-dark-selected dark:border-item-dark-contrast p-1 h-8",
            {
              "w-full": fill,
            }
          )}
        >
          {(segment) => <DateSegment segment={segment} />}
        </DateInput>
        <Button
          icon="Calendar"
          className={"h-8 dark:border-item-dark-contrast"}
          data-testid="date-picker-button"
        />
      </Group>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
      <Popover
        className={"bg-item dark:bg-item-dark dark:text-item-dark-contrast"}
      >
        <Dialog>
          <Calendar
            highligtedDates={[]}
            onChange={(date) => {
              props.onChange?.(date);
            }}
            value={props.value}
          />
        </Dialog>
      </Popover>
    </DatePicker>
  );
}

export default MyDatePicker;
