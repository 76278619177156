import useLocalStorage from '../../../../../utils/hooks/useLocalStorage';
import { useWindowSize } from '../../../../../utils/hooks/useWindowSize';

export type RtiPanelDimensions = {
  libraryPanel: number;
  parametersPanel: number;
  eventsPanel: number;
  commandHistoryPanel: number;
};

/**
 * useRtiPanelDimensions hook stores and retrieves the dimensions of the RTI panels
 * from the local storage.
 */
export const useRtiPanelDimensions = () => {
  const windowSize = useWindowSize();
  const [panelDimensions, setPanelDimensions] =
    useLocalStorage<RtiPanelDimensions>('rtiPanelWidth', {
      libraryPanel: 340,
      parametersPanel: 360,
      eventsPanel: windowSize.height / 16,
      commandHistoryPanel: windowSize.height / 3,
    });

  const setLibraryPanelWidth = (width: number | undefined) => {
    setPanelDimensions({
      ...panelDimensions,
      libraryPanel: width ?? panelDimensions.libraryPanel,
    });
  };

  const setParametersPanelWidth = (width: number | undefined) => {
    setPanelDimensions({
      ...panelDimensions,
      parametersPanel: width ?? panelDimensions.parametersPanel,
    });
  };

  const setEventsPanelHeight = (height: number | undefined) => {
    setPanelDimensions({
      ...panelDimensions,
      eventsPanel: height ?? panelDimensions.eventsPanel,
    });
  };

  const setCommandHistoryHeight = (height: number | undefined) => {
    setPanelDimensions({
      ...panelDimensions,
      commandHistoryPanel: height ?? panelDimensions.commandHistoryPanel,
    });
  };

  return {
    panelDimensions,
    setLibraryPanelWidth,
    setParametersPanelWidth,
    setEventsPanelHeight,
    setCommandHistoryHeight,
  };
};
