import DefaultLayout from '../../layouts/DefaultLayout';
import type { ReactElement } from 'react';
import React from 'react';
import NoPermissions from '../../components/common/NoPermissions';

const NoPermissionsPage = (): ReactElement => (
  <DefaultLayout>
    <NoPermissions />
  </DefaultLayout>
);

export default NoPermissionsPage;
