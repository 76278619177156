import React from 'react';
import { SATELLITE_ID_LIST } from 'api/satellites/types';
import { useMission } from 'services/Missions';
import classNames from 'classnames';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import { Icon } from 'opencosmos-ui/src/icons/Icon';

interface IProps {
  icon?: IconName;
  title: string | React.ReactNode;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  minimal?: boolean;
}

// TODO: This should be modified to no longer fit blueprint tags
const getMissionStyle = (missionId?: number) => {
  if (String(missionId) === SATELLITE_ID_LIST.MENUT) {
    return 'bg-[#ACB4D8]';
  }

  if (String(missionId) === SATELLITE_ID_LIST.MANTIS) {
    return 'bg-[#cdf7f3]';
  }

  if (String(missionId) === SATELLITE_ID_LIST.PLATERO) {
    return 'bg-[#f794b5]';
  }

  if (String(missionId) === SATELLITE_ID_LIST.ALISIO) {
    return 'bg-[#caebbc]';
  }

  if (String(missionId) === SATELLITE_ID_LIST.PHISAT2) {
    return 'bg-[#6e0e21] text-white';
  }

  if (String(missionId) === SATELLITE_ID_LIST.PHISAT2_EM) {
    return {
      tagOverride: { backgroundColor: '#6e0e21' },
      tagClassName: 'lightMissionColor',
    };
  }

  if (String(missionId) === SATELLITE_ID_LIST.COGNISAT_6) {
    return 'bg-[#11085c] text-white';
  }

  if (String(missionId) === SATELLITE_ID_LIST.COGNISAT_6_EM) {
    return 'bg-[#11085c] text-white';
  }

  return {
    tagOverride: {},
    tagClassName: 'noSpecificMission',
  };
};

const TagHeader = ({ className, onClick, title, children, icon }: IProps) => {
  const { currentMissionId } = useMission();

  // TODO: Retrieve color per mission from back-end
  const productionMissionStyle = getMissionStyle(currentMissionId);

  return (
    <div
      className={classNames(
        className,
        productionMissionStyle,
        'bg-header dark:bg-header-dark dark:text-header-dark-contrast'
      )}
      onClick={onClick}
    >
      <div className="w-full p-1 pr-2 pl-2">
        <span className="flex items-center gap-2 whitespace-nowrap bg-transparent">
          {icon && <Icon icon={icon} />}
          {title}
        </span>
        <div className="flex items-center w-full">{children}</div>
      </div>
    </div>
  );
};

export default TagHeader;
