import { useBandAlgebra } from 'datacosmos/stores/BandAlgebraProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IExpressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import { expressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import { useState } from 'react';
import Accordion from '_molecules/Accordion/Accordion';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import { useActivePage } from '../Toolbar/ActivePageProvider';
import ExpressionBased from './CustomAlgebra/ExpressionBased';
import MultiChannel from './CustomAlgebra/MultiChannel';
import SingleBand from './CustomAlgebra/SingleBand';
import IndexCard from './IndexCard';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Tab, TabList, TabPanel, Tabs } from 'opencosmos-ui';

const BandAlgebra = () => {
  const { setAlgebraMenuOpen } = useActivePage();
  const { setLayersMenuOpen } = useMapLayers();
  const {
    isIndexSelected,
    toggleSelectedIndex,
    saveCustomAlgebra,
    getCustomBandAlgebra,
    deleteIndex,
  } = useBandAlgebra();

  const { translate } = useLocalisation();

  const [customAlgebra, setCustomAlgebra] = useState<IExpressionDetails[]>(
    getCustomBandAlgebra()
  );

  return (
    <div style={{ maxHeight: 'inherit', overflowY: 'hidden' }}>
      <SidebarHeader
        title={translate('datacosmos.layers.bandAlgebra.title')}
        rightIcon={{
          iconName: 'ChevronRight',
          onIconClick: () => {
            setLayersMenuOpen(true);
            setAlgebraMenuOpen(false);
          },
          text: translate('datacosmos.layers.backToLayers'),
        }}
      />

      <Tabs className="p-4 dark:text-item-dark-contrast">
        <TabList>
          <Tab id="1" key="1" fill>
            <div
              className="w-full"
              onClick={() => setCustomAlgebra(getCustomBandAlgebra())}
            >
              {translate('datacosmos.layers.bandAlgebra.indexTab')}
            </div>
          </Tab>
          <Tab id="2" key="2" fill>
            {translate('datacosmos.layers.bandAlgebra.custom.title')}
          </Tab>
        </TabList>
        <TabPanel id="1" className={'pb-16'}>
          <div>
            <Accordion
              labelText={translate(
                'datacosmos.layers.bandAlgebra.predefined.title'
              )}
              startExpanded
            >
              <div className="w-full">
                {expressionDetails.map((exp) => (
                  <IndexCard
                    index={exp}
                    isIndexSelected={isIndexSelected(exp)}
                    toggleSelectedIndex={(index) => {
                      toggleSelectedIndex(index);
                    }}
                    key={exp.id}
                  />
                ))}
              </div>
            </Accordion>

            <Accordion
              labelText={translate('datacosmos.layers.bandAlgebra.customTab')}
              startExpanded
            >
              <div className="w-full">
                {customAlgebra.map((exp) => (
                  <IndexCard
                    key={exp.id}
                    index={exp}
                    isIndexSelected={isIndexSelected(exp)}
                    toggleSelectedIndex={toggleSelectedIndex}
                    deleteHandler={(expression) => {
                      deleteIndex(expression);
                      setCustomAlgebra(getCustomBandAlgebra());
                      if (isIndexSelected(expression)) {
                        toggleSelectedIndex(expression);
                      }
                    }}
                  />
                ))}
              </div>
            </Accordion>
          </div>
        </TabPanel>

        <TabPanel id={'2'} className={'overflow-y-hidden'}>
          <Tabs>
            <TabList>
              <Tab id={'1'} key={'1'} fill>
                {translate('datacosmos.layers.bandAlgebra.custom.single.title')}
              </Tab>

              <Tab id={'2'} key={'2'} fill>
                {translate('datacosmos.layers.bandAlgebra.custom.rgb.title')}
              </Tab>

              <Tab id={'3'} key={'3'} fill>
                {translate('datacosmos.layers.bandAlgebra.custom.index.title')}
              </Tab>
            </TabList>

            <TabPanel id={'1'} key={'1'} className={'pb-40'}>
              <SingleBand
                saveCustomAlgebra={saveCustomAlgebra}
                toggleSelectedIndex={toggleSelectedIndex}
              />
            </TabPanel>

            <TabPanel id={'2'} key={'2'} className={'pb-40'}>
              <MultiChannel
                saveCustomAlgebra={saveCustomAlgebra}
                toggleSelectedIndex={toggleSelectedIndex}
              />
            </TabPanel>

            <TabPanel id={'3'} key={'3'} className={'pb-40'}>
              <ExpressionBased
                saveCustomAlgebra={saveCustomAlgebra}
                toggleSelectedIndex={toggleSelectedIndex}
              />
            </TabPanel>
          </Tabs>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default BandAlgebra;
