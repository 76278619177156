import { isNil } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useApiGroundStationService } from 'pages/ops/GSScheduling/context/GroundStationProvider';
import type { AntennaOpportunity, Window } from 'api/groundStation/types';
import { durationToReadableFormat } from 'utils/common/dateUtils';
import { DATETIME_FORMAT } from 'constants/datetime';

import s from './bookPassModal.module.scss';
import { useMission } from 'services/Missions';
import {
  Button,
  Group,
  Icon,
  MultiSelect,
  MultiSlider,
  RangeSlider,
  Select,
  SelectValue,
  SliderThumb,
  Tooltip,
} from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import classNames from 'classnames';

const unixToCalendar = (unix: number, format: string) =>
  moment.unix(unix).utc().format(format);

type Props = {
  segments: Window[];
  intent: 'none' | 'primary' | 'success' | 'warning' | undefined;
};

// SegmentsSlider - the first component made by Pau Hebrero and the day his career changed forever
// ~ 19/04/2021
const SegmentsSlider = ({ segments, intent }: Props) => {
  const { opportunityInView } = useApiGroundStationService();

  if (opportunityInView === null) {
    return <span>Internal error, opportunity not available</span>;
  }

  // TODO: Make a custom MultiSlider and use it here
  return (
    <MultiSlider
      minValue={moment.utc(opportunityInView.bookingLimits.start).unix()}
      maxValue={moment.utc(opportunityInView.bookingLimits.end).unix()}
      labelRenderer={(value: number) => unixToCalendar(value, 'mm:ss')}
      isReadOnly={true}
    >
      {segments.map((segment) => (
        <SliderThumb
          key={segment.start}
          value={moment.utc(segment.start).unix()}
          trackIntentBefore="none"
        />
      ))}
      {segments.map((segment) => (
        <SliderThumb
          key={segment.start}
          value={moment.utc(segment.end).unix()}
          trackIntentBefore={intent}
        />
      ))}
    </MultiSlider>
  );
};

const AntennaRow = ({
  antennaAvailability,
}: {
  antennaAvailability: AntennaOpportunity;
}) => {
  const { antennas, setSelectedAntennaAvailability, setSelectedWindow } =
    useApiGroundStationService();

  const currentAntenna = antennas[antennaAvailability.id];

  const chooseUplink = () => {
    setSelectedAntennaAvailability(antennaAvailability);
    setSelectedWindow({
      start: moment
        .utc(antennaAvailability.uplink.segments[0].window.start)
        .unix(),
      end: moment.utc(antennaAvailability.uplink.segments[0].window.end).unix(),
    });
  };

  const chooseDownlink = () => {
    setSelectedAntennaAvailability(antennaAvailability);
    setSelectedWindow({
      start: moment
        .utc(antennaAvailability.downlink.segments[0].window.start)
        .unix(),
      end: moment
        .utc(antennaAvailability.downlink.segments[0].window.end)
        .unix(),
    });
  };

  return (
    <tbody>
      <tr
        className={classNames(
          s.availabilityRow,
          'hover:bg-item-hover hover:dark:bg-item-dark-hover'
        )}
      >
        <td className={s.antennaColumn}>
          <b>{currentAntenna.name}</b>
        </td>
        <td className={s.typeColumn}>Availability</td>
        <td className={s.segmentColumn}>
          <SegmentsSlider
            segments={antennaAvailability.availabilities}
            intent={'success'}
          />
        </td>
        <td className={s.durationColumn}></td>
      </tr>
      <tr
        className={classNames(
          s.availabilityRow,
          'hover:bg-item-hover hover:dark:bg-item-dark-hover'
        )}
        onClick={chooseUplink}
      >
        <td className={s.antennaColumn} />
        <td className={s.typeColumn}>Uplink</td>
        <td className={s.segmentColumn}>
          <SegmentsSlider
            segments={antennaAvailability.uplink.segments.map(
              (segment) => segment.window
            )}
            intent={'primary'}
          />
        </td>
        <td className={s.durationColumn}>
          {durationToReadableFormat(antennaAvailability.uplink.duration)}
        </td>
      </tr>
      <tr
        className={classNames(
          s.availabilityRow,
          'hover:bg-item-hover hover:dark:bg-item-dark-hover'
        )}
        onClick={chooseDownlink}
      >
        <td className={s.antennaColumn}></td>
        <td className={s.typeColumn}>Downlink</td>
        <td className={s.segmentColumn}>
          <SegmentsSlider
            segments={antennaAvailability.downlink.segments.map(
              (segment) => segment.window
            )}
            intent={'primary'}
          />
        </td>
        <td className={s.durationColumn}>
          {durationToReadableFormat(antennaAvailability.downlink.duration)}
        </td>
      </tr>
      <tr>
        <td className={s.antennaColumn}></td>
        <td className={s.typeColumn}></td>
        <td className={s.segmentColumn}></td>
        <td className={s.durationColumn}></td>
      </tr>
    </tbody>
  );
};

const PassWindowPickerInfo = () => {
  const { selectedWindow, selectedAntennaAvailability } =
    useApiGroundStationService();

  return (
    <tbody>
      <tr>
        <td className={s.antennaColumn}></td>
        <td className={s.typeColumn}></td>
        <td className={s.segmentColumn}>
          <table className={s.pickerInformationTable}>
            <tr>
              <th className={s.pickerInformationColumn}>
                <b>From (UTC)</b>
              </th>
              <th className={s.pickerInformationColumn}>
                <b>Max. Elevation (°)</b>
              </th>
              <th className={s.pickerInformationColumn}>
                <b>To (UTC)</b>
              </th>
            </tr>
            <tr>
              <td className={s.pickerInformationColumn}>
                {isNil(selectedWindow)
                  ? '-'
                  : unixToCalendar(selectedWindow.start, DATETIME_FORMAT)}
              </td>
              <td className={s.pickerInformationColumn}>
                {isNil(selectedWindow)
                  ? '-'
                  : selectedAntennaAvailability?.uplink.maxElevation.toFixed(2)}
              </td>
              <td className={s.pickerInformationColumn}>
                {isNil(selectedWindow)
                  ? '-'
                  : unixToCalendar(selectedWindow.end, DATETIME_FORMAT)}
              </td>
            </tr>
          </table>
        </td>
        <td className={s.durationColumn}></td>
      </tr>
    </tbody>
  );
};

const PassWindowPicker = () => {
  const {
    opportunityInView,
    selectedWindow,
    selectedAntennaAvailability,
    antennas,
    setSelectedWindow,
  } = useApiGroundStationService();

  const selectedAntenna = isNil(selectedAntennaAvailability)
    ? '-'
    : antennas[selectedAntennaAvailability.id].name;

  const selectedAntennaCell = opportunityInView?.bookByAntenna ? (
    selectedAntenna
  ) : (
    <div className="bg-item dark:bg-item-dark p-4 flex items-center gap-2">
      <Icon icon="warning-sign" />
      <span>?</span>
    </div>
  );

  let segmentSlider = (
    <div className="bg-item dark:bg-item-dark p-4 flex items-center gap-2">
      <Icon icon="info-sign" />{' '}
      <span>
        Please select an initial window by clicking on one of the rows.
      </span>
    </div>
  );
  if (selectedWindow !== null && opportunityInView !== null) {
    segmentSlider = (
      <RangeSlider
        value={[selectedWindow.start, selectedWindow.end]}
        numberOfHandles={2}
        handleLabelFormatter={(value) => {
          return unixToCalendar(value, 'mm:ss');
        }}
        minValue={moment.utc(opportunityInView.bookingLimits.start).unix()}
        maxValue={moment.utc(opportunityInView.bookingLimits.end).unix()}
        showScale={false}
        showValuesAboveHandles={true}
        onChange={(value) => {
          if (typeof value === 'number') {
            return;
          }
          if (
            value[1] - value[0] > opportunityInView.maxPassLength &&
            value[0] !== selectedWindow.start
          ) {
            setSelectedWindow({
              start: value[0],
              end:
                value[1] +
                (opportunityInView.maxPassLength - (value[1] - value[0])),
            });
          } else if (
            value[1] - value[0] > opportunityInView.maxPassLength &&
            value[1] !== selectedWindow.end
          ) {
            setSelectedWindow({
              start:
                value[0] -
                (opportunityInView.maxPassLength - (value[1] - value[0])),
              end: value[1],
            });
          } else {
            setSelectedWindow({ start: value[0], end: value[1] });
          }
        }}
      />
    );
  }

  const duration = isNil(selectedWindow)
    ? '-'
    : durationToReadableFormat(selectedWindow.end - selectedWindow.start);

  return (
    <tr>
      <td className={s.antennaColumn}>{selectedAntennaCell}</td>
      <td className={s.typeColumn}>Booking</td>
      <td className={s.segmentColumn}>{segmentSlider}</td>
      <td className={s.durationColumn}>{duration}</td>
    </tr>
  );
};

const BookPass = () => {
  const {
    opportunityInView,
    bookPass,
    selectedAntennaAvailability,
    isBookPassProcessing,
    setSelectedRadioLinks,
    selectedRadioLinks,
    selectedProviderDeployment,
    setSelectedProviderDeployment,
  } = useApiGroundStationService();

  const { currentMissionId } = useMission();

  const [availabilities, setAvailabilities] = useState<AntennaOpportunity[]>();

  const { hasPermission: isAllowedToBookPass } = useCheckPermissions({
    permissions: {
      type: 'mission',
      actionScope: 'portal:gs:pass:write',
      id: currentMissionId,
    },
  });

  useEffect(() => {
    const newAntennaOpportunities = isNil(opportunityInView)
      ? ([] as AntennaOpportunity[])
      : opportunityInView.antennas;

    setAvailabilities(newAntennaOpportunities);
  }, [opportunityInView]);

  if (!currentMissionId) {
    return <span>Loading mission...</span>;
  }

  if (!opportunityInView || isBookPassProcessing)
    return (
      <div className={s.container}>
        <Spinner className={s.autoMargine} />
      </div>
    );

  const bookButtonDisabled =
    isNil(selectedAntennaAvailability) ||
    !isAllowedToBookPass ||
    !selectedRadioLinks[0];
  const explanationDisabled = !bookButtonDisabled;
  const disabledButtonMessage = [
    !isAllowedToBookPass
      ? "You don't have sufficient permissions to book a pass."
      : '',
    isNil(selectedAntennaAvailability)
      ? 'The antenna is not available to book a pass.'
      : '',
    !selectedRadioLinks[0] ? 'You must select a radio link' : '',
  ]
    .filter((msg) => msg !== '')
    .join(', ');

  const footerActions = (
    <>
      <Group className={'flex items-center'}>
        {selectedAntennaAvailability && (
          <Select
            className={'h-8'}
            onSelectionChange={(key) => {
              setSelectedProviderDeployment(key.toString());
            }}
            selectedKey={selectedProviderDeployment}
          >
            {selectedAntennaAvailability?.baseband_deployments.map((bd) => (
              <SelectValue key={bd}>{bd}</SelectValue>
            ))}
          </Select>
        )}
        {selectedAntennaAvailability && (
          <MultiSelect
            items={selectedAntennaAvailability.radio_links.map((rl) => ({
              id: rl.id.toString(),
              value: `${rl.frequency} (${rl.direction})`,
            }))}
            defaultSelectedKeys={selectedRadioLinks.map((rl) =>
              rl.id.toString()
            )}
            onSelectionChange={(itemId) => {
              const foundSelectedRadioLink = selectedRadioLinks.some(
                (srl) => srl.id.toString() === itemId.toString()
              );

              const foundClickedRadioLink =
                selectedAntennaAvailability.radio_links.find(
                  (rl) => rl.id.toString() === itemId.toString()
                );

              if (foundSelectedRadioLink) {
                setSelectedRadioLinks((prev) =>
                  prev.filter((p) => p.id.toString() !== itemId.toString())
                );
              } else {
                foundClickedRadioLink &&
                  setSelectedRadioLinks((prev) => [
                    ...prev,
                    foundClickedRadioLink,
                  ]);
              }
            }}
          />
        )}
        <Tooltip
          isDisabled={explanationDisabled}
          content={disabledButtonMessage}
          placement="bottom"
          delay={0}
        >
          <Button
            onPress={bookPass}
            intent={'primary'}
            icon={'book'}
            isDisabled={
              isNil(selectedAntennaAvailability) ||
              !isAllowedToBookPass ||
              !selectedRadioLinks[0]
            }
            className="h-8"
          >
            <span className="text-sm">Book</span>
          </Button>
        </Tooltip>
      </Group>
    </>
  );

  let message = 'Book selected time range';

  if (!isAllowedToBookPass) {
    message = 'You are not allowed to book passes';
  }

  if (!selectedRadioLinks[0]) {
    message = 'Must select a radio link before booking';
  }

  if (isNil(selectedAntennaAvailability)) {
    message = 'Must select a time range before booking';
  }

  return (
    <>
      <div>
        <div className={s.availabilityTableContainer}>
          <table className={s.availabilityTable}>
            <thead className="border-b border-item-contrast-inactive ">
              <tr>
                <th className={s.antennaColumn}>
                  <h3>Antenna</h3>
                </th>
                <th className={s.typeColumn} />
                <th className={s.segmentColumn}>
                  <h3>Opportunities</h3>
                </th>
                <th className={s.durationColumn}>
                  <h3>Duration</h3>
                </th>
              </tr>
            </thead>
            {(availabilities ? availabilities : []).map(
              (antennaAvailability) => (
                <AntennaRow
                  key={antennaAvailability.id}
                  antennaAvailability={antennaAvailability}
                />
              )
            )}
          </table>
        </div>
        <div className={s.availabilityTableContainer}>
          <table className={s.availabilityTable}>
            <PassWindowPickerInfo />
            <PassWindowPicker />
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between p-2">
        <span> {message}</span>
        <div className="flex items-center">{footerActions}</div>
      </div>
    </>
  );
};

export default BookPass;
