import { Icon } from 'opencosmos-ui';
import { Tooltip } from 'ui/Tooltip';
import s from './index.module.scss';

const SPLITTER_CONTENT =
  'The transformer will be applied sequentially in the direction of the arrows to generate the final satellite frame.';

const LibraryEncodingSplitter = () => {
  const renderDoubleDownArrow = () => (
    <Icon
      className={s.librarySplitterArrow}
      size={18}
      icon="double-chevron-down"
    />
  );

  return (
    <div className={s.librarySplitter}>
      <div className={s.librarySplitterContainer}>
        {renderDoubleDownArrow()}
        {renderDoubleDownArrow()}
        <Tooltip content={SPLITTER_CONTENT}>
          <span className={s.librarySplitterText}>Transform direction</span>
        </Tooltip>
        {renderDoubleDownArrow()}
        {renderDoubleDownArrow()}
      </div>
    </div>
  );
};

export default LibraryEncodingSplitter;
