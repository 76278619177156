import {
  DateRangePicker,
  MultiSelect,
  getLeafColumnIds,
  Button,
} from 'opencosmos-ui';
import { parseDateRange } from 'opencosmos-ui/src/date/DatePicker/helpers';
import type { DateValue } from '@internationalized/date';
import type { RangeValue } from '@react-types/shared';
import React, { useMemo } from 'react';
import type {
  ActivityType,
  ActivityStatus,
  ActivityWithRequestNote,
} from 'api/activities/service';
import SchedulingFilter from 'pages/ops/Scheduling/components/SchedulingFilter';
import s from './index.module.scss';
import type { FromToDate, ViewMode } from 'pages/ops/Scheduling';
import { EXCLUDED_COLUMNS } from '../SchedulingTable/SchedulingTable';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import classNames from 'classnames';

type SchedulingHeaderProps = {
  selectedStatuses: ActivityStatus[];
  setActivityStatus: React.Dispatch<React.SetStateAction<ActivityStatus[]>>;
  fromToDate: FromToDate;
  setFromToDate: React.Dispatch<React.SetStateAction<FromToDate>>;
  selectedTypes: ActivityType[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<ActivityType[]>>;
  viewMode: ViewMode;
  setViewMode: (mode: ViewMode) => void;
  activities: ActivityWithRequestNote[];
  toggleHiddenColumns: (column: string) => void;
  isSaveChangesButtonEnabled: boolean;
  isUpdatingActivityStatus: boolean;
  batchUpdateActivityStatus: () => Promise<void>;
  setIsSaveChangesButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  refetchActivities: () => Promise<void>;
  tableHiddenCols: string[];
  isActivitySelected: boolean;
};

const SchedulingHeader = ({
  selectedStatuses: activityStatus,
  setActivityStatus,
  setFromToDate,
  fromToDate,
  selectedTypes: activityType,
  setSelectedTypes: setActivityType,
  viewMode,
  setViewMode,
  activities,
  toggleHiddenColumns,
  isSaveChangesButtonEnabled,
  isUpdatingActivityStatus,
  batchUpdateActivityStatus,
  setIsSaveChangesButtonEnabled,
  refetchActivities,
  tableHiddenCols,
  isActivitySelected,
}: SchedulingHeaderProps) => {
  const selectColumns: { id: string; value: string }[] = useMemo(() => {
    return getLeafColumnIds(activities?.[0] ?? {}, EXCLUDED_COLUMNS).map(
      (c, i) => ({
        id: String(i),
        value: c,
      })
    );
  }, [activities]);

  const { sendInfo } = useAnalytics();
  return (
    <div className={classNames(s.container, 'dark:bg-surface-dark')}>
      <h1>Activities</h1>
      <div
        className={classNames({
          'flex w-fit gap-12 items-center ml-auto':
            !isActivitySelected && viewMode === 'table',
          [s.filtersWrapper]: viewMode !== 'table',
          'grid shadow-none grid-rows-2 ml-auto gap-1':
            viewMode === 'table' && isActivitySelected,
        })}
      >
        <div className="flex items-center">
          {viewMode === 'table' && (
            <Button
              intent="primary"
              onPress={async () => {
                await batchUpdateActivityStatus();
                setIsSaveChangesButtonEnabled(false);
              }}
              icon="Save"
              size="base"
              loading={isUpdatingActivityStatus}
              isDisabled={
                !isUpdatingActivityStatus && !isSaveChangesButtonEnabled
              }
              variant="square"
            />
          )}
          {viewMode === 'table' && (
            <div className={'w-[440px]'}>
              <MultiSelect
                fill
                defaultSelectedKeys={selectColumns
                  .filter((c) => {
                    return !tableHiddenCols.includes(c.value);
                  })
                  .map((c) => c.id)}
                items={selectColumns}
                label="Cols"
                onSelectionChange={(item) => {
                  toggleHiddenColumns(item.value as string);
                }}
                onRemove={(item) => {
                  toggleHiddenColumns(item.value as string);
                }}
              />
            </div>
          )}
        </div>

        <div className="flex items-center gap-3">
          <div>
            <Button
              aria-label="calendar button"
              name="calendar"
              icon="calendar"
              onPress={() => {
                if (viewMode === 'calendar') {
                  setViewMode('list');
                  sendInfo({
                    action: 'click',
                    item: 'Calendar view button',
                    type: 'Calendar view close',
                  });
                } else {
                  setViewMode('calendar');
                  sendInfo({
                    action: 'click',
                    item: 'Calendar view button',
                    type: 'Calendar view open',
                  });
                }
              }}
              variant="square"
              isActive={viewMode === 'calendar'}
            />
          </div>
          <div>
            <Button
              aria-label="table button"
              name="table"
              icon="panel-table"
              onPress={() => {
                if (viewMode === 'table') {
                  setViewMode('list');
                  sendInfo({
                    action: 'click',
                    item: 'Table view button',
                    type: 'Table view close',
                  });
                } else {
                  setViewMode('table');
                  sendInfo({
                    action: 'click',
                    item: 'Table view button',
                    type: 'Table view open',
                  });
                }
              }}
              variant="square"
              isActive={viewMode === 'table'}
            />
          </div>
          <SchedulingFilter
            setSelectedStatuses={setActivityStatus}
            selectedStatuses={activityStatus}
            selectedTypes={activityType}
            setSelectedTypes={setActivityType}
          />

          <div>
            <Button
              aria-label="refresh activities"
              name="refresh"
              icon="refresh"
              onPress={async () => {
                await refetchActivities();
              }}
              variant="square"
            />
          </div>
          <DateRangePicker
            value={
              parseDateRange({
                end: fromToDate.to,
                start: fromToDate.from,
              }) as RangeValue<DateValue>
            }
            onChange={(value) => {
              setFromToDate({
                from: value.start.toDate('Etc/Utc'),
                to: value.end.toDate('Etc/Utc'),
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SchedulingHeader;
