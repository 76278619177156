import { Link } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { Breadcrumbs, Icon } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import type { ITerminalSession } from 'services/api/ops/terminalSessions';

import mainS from '../../../index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

interface IProps {
  session?: ITerminalSession;
  isConnected: boolean;
}

const OperateHeader = (props: IProps) => {
  const { sendInfo } = useAnalytics();
  return (
    <div
      className={mainS.headerContainer}
      // @ts-expect-error
      name={props.isConnected ? 'ws-connected' : 'ws-not_connected'}
    >
      <Breadcrumbs
        items={[
          {
            text: (
              <Link
                className="underline underline-offset-4"
                onClick={() => {
                  sendInfo({
                    type: 'Breadcrumb click',
                    action: 'Click',
                    item: 'RTI breadcrumbs button',
                    module: 'OPS',
                  });
                }}
                to=".."
              >
                RTI
              </Link>
            ),
          },
          { text: props.session?.name ?? 'Session' },
        ]}
      />
      <Tooltip
        content={`Connection to OpenApp is ${
          props.isConnected ? 'established' : 'not established'
        }`}
      >
        <Icon
          iconSize={20}
          icon={IconNames.SYMBOL_CIRCLE}
          color={props.isConnected ? 'rgb(90, 190, 150)' : 'rgb(228, 105, 94)'}
          onMouseEnter={() => {
            sendInfo({
              type: 'Connection status hover',
              action: 'Hover',
              item: 'Connection status icon',
              module: 'OPS',
            });
          }}
        />
      </Tooltip>
    </div>
  );
};

export default OperateHeader;
