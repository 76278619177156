import { DefaultGeoJSONLayerOptions } from 'datacosmos/entities/geojsonLayer';
import type { Layer } from 'datacosmos/entities/layer';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { MAX_ALLOWED_AOI_SIZE_M2 } from 'datacosmos/components/Tasking/helpers';
import type { IMapContext } from 'datacosmos/stores/MapProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { downloadGeojsonAsKml } from 'datacosmos/download/geojson';
import { Button, Input } from 'opencosmos-ui';

type TaskingAoiCardProps = {
  region: PolygonLayer;
  replaceLayer: (layer: Layer) => void;
  editPolygon: IMapContext['editPolygon'];
  disableEditing: IMapContext['disableEditing'];
  removeLayer: (layerid: string) => void;
  centerRegion: (region: PolygonLayer) => void;
  isRegionValid: boolean;
  disabled: boolean;
};

export const TaskingAoiCard = ({
  region,
  replaceLayer,
  disableEditing,
  editPolygon,
  removeLayer,
  centerRegion,
  isRegionValid,
  disabled,
}: TaskingAoiCardProps) => {
  const { translate } = useLocalisation();
  return (
    <div className="flex flex-col px-4 py-1 gap-2  bg-item dark:bg-item-dark last:border-b-[1px] last:border-item mb-px">
      <div
        className="mb-1"
        onMouseOver={() =>
          replaceLayer(region.cloneWithOptions({ fillOpacity: 0.5, weight: 8 }))
        }
        onMouseOut={() =>
          replaceLayer(
            region.cloneWithOptions({
              fillOpacity: DefaultGeoJSONLayerOptions.fillOpacity,
              weight: DefaultGeoJSONLayerOptions.weight,
            })
          )
        }
      >
        <Input
          id="dc-tasking-aoi-name"
          label={{
            text: translate('datacosmos.tasking.new.label'),
            position: 'left',
          }}
          placeholder="Eg. Region XYZ..."
          type="text"
          value={region.name}
          onChange={(e) => {
            replaceLayer(region.cloneWithName(e.target.value));
          }}
          className="color-item border-neutral-500"
          disabled={disabled}
        />
        <div className="flex gap-1 justify-between items-center mt-2">
          <div className="text-sm">
            <span>{region.getMeasurements()}</span>
          </div>
          <div className="flex gap-1">
            <Button
              icon="Center"
              size={'lg'}
              onPress={() => {
                centerRegion(region);
              }}
              isDisabled={disabled}
              isMinimal
              isTransparent
            />
            <Button
              icon="Pencil"
              size={'lg'}
              onPress={() => editPolygon(region)}
              isDisabled={disabled}
              isMinimal
              isTransparent
            />
            <Button
              icon="Trash"
              size={'lg'}
              onPress={() => {
                disableEditing(region.getLeafletLayerMetadata());
                removeLayer(region.id);
              }}
              isDisabled={disabled}
              isMinimal
              isTransparent
            />
            <Button
              icon="Download"
              size={'lg'}
              onPress={() => {
                downloadGeojsonAsKml(region.data, region.name);
              }}
              isDisabled={disabled}
              isMinimal
              isTransparent
            />
          </div>
        </div>
      </div>

      {!isRegionValid && region.areaInM2 > MAX_ALLOWED_AOI_SIZE_M2 && (
        <span className="text-xs text-warning">
          {translate('datacosmos.tasking.errors.tooLarge')}
        </span>
      )}
    </div>
  );
};
