import User from 'pages/portal/Administration/User/components/AdministrationUserPage';
import type { PermissionType } from 'api/administration/permissions';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import useUser from 'services/api/portal/administration/hook/useAllUsers';
import useResource from 'pages/portal/shared/hooks/useResource';
import useRoles from 'services/api/portal/administration/hook/useRoles';
import AdministrationLayout from 'pages/portal/shared/components/AdministrationLayout/AdministrationLayout';
import { getUser } from 'api/users/service';

const UserPage = () => {
  const location = useLocation();
  const history = useHistory();

  const [selectedPermission, setSelectedPermission] =
    useState<PermissionType>();

  const [resourceId, setResourceId] = useState<string | number>('');
  const [user, setUser] = useState<IUserWithPermissions>();

  const handleUsersLinkClick = () => {
    history.push(`/portal/users`);
  };

  const { availableRolesByType } = useRoles();
  const { deleteRoleAssignments, assignRole: assignGlobalRole } = useUser();
  const { assignRole } = useResource(selectedPermission, resourceId);

  const getUserInformation = useCallback(async () => {
    const urlParts = location.pathname.split('/portal/users/');
    const extractedID = urlParts[1];
    const matchedUserObj = await getUser({
      params: extractedID ? { subject: extractedID } : undefined,
    });
    setUser(matchedUserObj.data as IUserWithPermissions);
  }, [location.pathname]);

  useEffect(() => {
    if (!location.state) {
      void getUserInformation();
      return;
    }
    setUser(location.state as IUserWithPermissions);
  }, [location.state, getUserInformation]);

  return (
    <AdministrationLayout
      breadcrumbs={[
        { text: 'Users', onClick: handleUsersLinkClick },
        { text: 'User' },
      ]}
      graphic="person"
    >
      {user && (
        <User
          user={user}
          getAvailableRolesByType={availableRolesByType}
          setSelectedPermission={setSelectedPermission}
          setResourceId={setResourceId}
          selectedPermission={selectedPermission}
          resourceId={resourceId}
          assignRole={assignRole}
          deleteRoleAssignments={deleteRoleAssignments}
          assignGlobalRole={assignGlobalRole}
        />
      )}
    </AdministrationLayout>
  );
};

export default UserPage;
