import { Button } from 'opencosmos-ui';
import type { ReactElement } from 'react';

interface IHeaderButtonProps {
  children: string | ReactElement;
}

export const HeaderButton = ({
  children,
}: IHeaderButtonProps): ReactElement => {
  return <Button className="header-button max-h-min">{children}</Button>;
};
