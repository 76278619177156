import { getAllTaskingRequests } from 'api/tasking/service';
import type { RequestType, TaskingRequest } from 'api/tasking/service';
import { useQuery } from 'api/useQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useComposedState } from 'utils/hooks/useComposedState';
import { pipe } from 'lodash/fp';
import { getUser } from 'api/users/service';
import type { User } from 'api/users/types';
import { useHistory } from 'react-router';
import moment from 'moment';

interface AvailableOrganisation {
  id: number;
  name: string;
}
interface AvailableProject {
  id: string;
  name: string;
}
export type ClassifiedRequests = {
  pending: TaskingRequest[];
  ready: TaskingRequest[];
  acquiring: TaskingRequest[];
  fulfilled: TaskingRequest[];
  partiallyFulfilled: TaskingRequest[];
  failed: TaskingRequest[];
  cancelled: TaskingRequest[];
};

export type OverviewFilters = {
  missionId: string | undefined;
  availableMissionIds: string[];
  fromDate: Date | undefined;
  toDate: Date | undefined;
  organisationId: string | undefined;
  availableOrganisations: AvailableOrganisation[];
  type: RequestType | undefined;
  projectId: string | undefined;
  availableProjects: AvailableProject[];
};

const REQUEST_URL_PARAM_KEY = 'request';

//TODO: Filter by: sat = mission

export const useTaskingOverviewData = () => {
  const [selectedRequest, setSelectedRequest] = useState<TaskingRequest>();
  const history = useHistory();

  const [isAddActivityModalOpen, setIsAddActivityModalOpen] =
    useState<boolean>(false);

  const [isCancelRequestModalOpen, setIsCancelRequestModalOpen] =
    useState<boolean>(false);

  const taskingRequestsQuery = useQuery(getAllTaskingRequests, {
    initialData: [],
  });

  const getUserQuery = useQuery(getUser, {
    params: selectedRequest
      ? { subject: selectedRequest.created_by }
      : undefined,
    skip: !selectedRequest,
    initialData: {} as User,
  });

  const [filter, setFilter, setFilterState] = useComposedState<OverviewFilters>(
    {
      type: undefined,
      fromDate: undefined,
      toDate: undefined,
      missionId: undefined,
      organisationId: undefined,
      projectId: undefined,
      availableMissionIds: [],
      availableOrganisations: [],
      availableProjects: [],
    }
  );

  const filterByOrganisationId = useCallback(
    (requests: TaskingRequest[]) => {
      const filtered = requests.filter(
        (req) => Number(filter.organisationId) === req.organisation_id
      );

      return filtered.length === 0 ? requests : filtered;
    },
    [filter.organisationId]
  );

  const filterByProjectId = useCallback(
    (requests: TaskingRequest[]) => {
      const filtered = requests.filter(
        (req) => filter.projectId === req.project_id
      );

      return filtered.length === 0 ? requests : filtered;
    },
    [filter.projectId]
  );

  const filterByMissionId = useCallback(
    (requests: TaskingRequest[]) => {
      const filtered = requests.filter((req) =>
        req.activities.some((a) => filter.missionId === a.mission_id)
      );

      return filtered.length === 0 ? requests : filtered;
    },
    [filter.missionId]
  );

  const filterByType = useCallback(
    (requests: TaskingRequest[]) => {
      const filtered = requests.filter((req) => filter.type === req.type);

      return filtered.length === 0 ? requests : filtered;
    },
    [filter.type]
  );

  // Would by created date be more appropriate?
  const filterByUpdateDateStart = useCallback(
    (requests: TaskingRequest[]) => {
      return filter.fromDate
        ? requests.filter((req) => new Date(req.updated_at) >= filter.fromDate!)
        : requests;
    },
    [filter.fromDate]
  );

  // Would by created date be more appropriate?
  const filterByUpdateDateEnd = useCallback(
    (requests: TaskingRequest[]) => {
      return filter.toDate
        ? requests.filter((req) => new Date(req.updated_at) <= filter.toDate!)
        : requests;
    },
    [filter.toDate]
  );

  const selectRequestByItsURLId = useCallback(() => {
    if (taskingRequestsQuery.loading) return;
    if (taskingRequestsQuery.data.length === 0) return;

    const urlReqId = new URLSearchParams(history.location.search).get(
      REQUEST_URL_PARAM_KEY
    );

    if (!urlReqId) return;

    setSelectedRequest(
      taskingRequestsQuery.data.find((req) => req.id === urlReqId)
    );
  }, [
    history.location.search,
    taskingRequestsQuery.data,
    taskingRequestsQuery.loading,
  ]);

  const addTaskingRequestIdToURL = useCallback(
    (reqId: string) => {
      history.replace(
        `${history.location.pathname}?${REQUEST_URL_PARAM_KEY}=${reqId}`
      );
    },
    [history]
  );

  const removeTaskingRequestIdFromURL = useCallback(() => {
    history.replace(history.location.pathname);
  }, [history]);

  const handleTaskingRequestClick = useCallback(
    (req: TaskingRequest) => {
      const wasDeselected = selectedRequest?.id === req.id;

      if (wasDeselected) {
        removeTaskingRequestIdFromURL();
        setSelectedRequest(undefined);
        return;
      }

      addTaskingRequestIdToURL(req.id);
      setSelectedRequest(req);
    },
    [
      addTaskingRequestIdToURL,
      removeTaskingRequestIdFromURL,
      selectedRequest?.id,
    ]
  );

  const classifiedRequests = useMemo(
    () =>
      pipe(
        filterByMissionId,
        filterByOrganisationId,
        filterByProjectId,
        filterByType,
        filterByUpdateDateStart,
        filterByUpdateDateEnd
      )(taskingRequestsQuery.data).reduce(
        (acc, request) => {
          if (request.status === 'ACQUIRING')
            acc = { ...acc, acquiring: [...acc.acquiring, request] };
          if (request.status === 'CANCELLED')
            acc = { ...acc, cancelled: [...acc.cancelled, request] };
          if (request.status === 'READY')
            acc = { ...acc, ready: [...acc.ready, request] };
          if (request.status === 'FAILED')
            acc = { ...acc, failed: [...acc.failed, request] };
          if (request.status === 'PENDING')
            acc = { ...acc, pending: [...acc.pending, request] };
          if (request.status === 'FULFILLED')
            acc = { ...acc, fulfilled: [...acc.fulfilled, request] };
          if (request.status === 'PARTIALLY_FULFILLED')
            acc = {
              ...acc,
              partiallyFulfilled: [...acc.partiallyFulfilled, request],
            };

          return acc;
        },
        {
          acquiring: [],
          cancelled: [],
          ready: [],
          failed: [],
          pending: [],
          fulfilled: [],
          partiallyFulfilled: [],
        } as ClassifiedRequests
      ),
    [
      filterByMissionId,
      filterByOrganisationId,
      filterByProjectId,
      filterByType,
      filterByUpdateDateEnd,
      filterByUpdateDateStart,
      taskingRequestsQuery.data,
    ]
  );

  //Todo: use this after select supports multiple selection
  // const handleOrganisationIdFilterClick = useCallback(
  //   (organisationId: string) => {
  //     if (filter.organisationIds.includes(organisationId)) {
  //       setFilter.organisationIds(
  //         filter.organisationIds.filter((id) => id !== organisationId)
  //       );
  //     } else {
  //       setFilter.organisationIds([...filter.organisationIds, organisationId]);
  //     }
  //   },
  //   [filter.organisationIds, setFilter]
  // );

  const handleOrganisationIdFilterClick = useCallback(
    (organisationId: string) => {
      if (filter.organisationId === organisationId) {
        setFilter.organisationId(undefined);
      } else {
        setFilter.organisationId(organisationId);
      }
    },
    [filter.organisationId, setFilter]
  );

  //Todo: use this after select supports multiple selection
  // const handleMissionIdFilterClick = useCallback(
  //   (missionId: string) => {
  //     if (filter.missionIds.includes(missionId)) {
  //       setFilter.missionIds(
  //         filter.missionIds.filter((id) => id !== missionId)
  //       );
  //     } else {
  //       setFilter.missionIds([...filter.missionIds, missionId]);
  //     }
  //   },
  //   [filter.missionIds, setFilter]
  // );

  const handleMissionIdFilterClick = useCallback(
    (missionId: string) => {
      if (filter.missionId === missionId) {
        setFilter.missionId(undefined);
      } else {
        setFilter.missionId(missionId);
      }
    },
    [filter.missionId, setFilter]
  );

  const handleTypeFilterClick = useCallback(
    (type: string) => {
      if (filter.type === type) {
        setFilter.type(undefined);
      } else {
        setFilter.type(type as RequestType);
      }
    },
    [filter.type, setFilter]
  );

  const handleProjectIdFilterClick = useCallback(
    (projectId: string) => {
      if (filter.projectId === projectId) {
        setFilter.projectId(undefined);
      } else {
        setFilter.projectId(projectId);
      }
    },
    [filter.projectId, setFilter]
  );

  useEffect(() => {
    selectRequestByItsURLId();
    if (taskingRequestsQuery.data.length > 0) {
      setFilterState((prev) => ({
        ...prev,
        availableMissionIds: [
          ...new Set(
            taskingRequestsQuery.data
              .map((r) => r.activities.map((a) => a.mission_id))
              .flat()
          ),
        ],
        availableOrganisations: [
          ...new Map(
            taskingRequestsQuery.data.map((r) => [
              r.organisation_id,
              { id: r.organisation_id, name: r.organisation_name },
            ])
          ).values(),
        ],
        availableProjects: [
          ...new Map(
            taskingRequestsQuery.data.map((r) => [
              r.project_id,
              { id: r.project_id, name: r.project_name },
            ])
          ).values(),
        ],
      }));
    }
  }, [selectRequestByItsURLId, setFilterState, taskingRequestsQuery.data]);

  const disableAddActivity = useMemo(() => {
    if (selectedRequest?.constraints) {
      const endDate = selectedRequest.constraints.find(
        (aqDate) => aqDate.type === 'ACQUISITION_DATE'
      )?.max;

      return endDate
        ? moment(new Date()).add(48, 'hours').startOf('day').toDate() >
            moment.unix(endDate as number).toDate()
        : true;
    }
    return false;
  }, [selectedRequest]);

  const disableCancelRequest = useMemo(() => {
    return selectedRequest?.activities.some(
      (activity) => moment(moment.now()).diff(activity.start_date) >= 0
    );
  }, [selectedRequest?.activities]);

  return {
    classifiedRequests,
    loading: taskingRequestsQuery.loading,
    userLoading: getUserQuery.loading,
    handleTaskingRequestClick,
    selectedRequest,
    setFilter,
    filter,
    handleMissionIdFilterClick,
    handleOrganisationIdFilterClick,
    handleTypeFilterClick,
    handleProjectIdFilterClick,
    customer: getUserQuery.data,
    isAddActivityModalOpen,
    setIsAddActivityModalOpen,
    refetchTaskingRequests: taskingRequestsQuery.refetch,
    disableAddActivity,
    isCancelRequestModalOpen,
    setIsCancelRequestModalOpen,
    disableCancelRequest,
  };
};
