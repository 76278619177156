import { useEffect, useMemo } from 'react';
import moment from 'moment';

import { parseDate } from '@internationalized/date';
import DateField from '_molecules/DateField/DateField';
import RangeCalendar from '_molecules/Calendars/RangeCalendar/RangeCalendar';

type TaskingDateProps = {
  setDateTo: (DateTo: Date) => void;
  setDateFrom: (DateFrom: Date) => void;
  dateTo?: Date;
  dateFrom?: Date;
  maxDateAllowedForSelection?: Date;
  minDateAllowedForSelection?: Date;
};

export const TaskingDate = (props: TaskingDateProps) => {
  const {
    setDateFrom,
    setDateTo,
    dateTo,
    dateFrom,
    maxDateAllowedForSelection,
    minDateAllowedForSelection,
  } = props;

  // minDate is the current date, startDate is the default start date for opportunity search
  const minDate = useMemo(() => moment(new Date()).startOf('day'), []);

  const startDate = useMemo(
    () => moment(new Date()).add(48, 'hours').startOf('day'),
    []
  );
  const endDate = useMemo(
    () => moment(dateTo).add(8, 'days').endOf('day'),
    [dateTo]
  );

  useEffect(() => {
    setDateFrom(dateFrom ? dateFrom : startDate.toDate());
    setDateTo(dateTo ? dateTo : endDate.toDate());
    return () => {
      setDateFrom(startDate.toDate());
      setDateTo(dateTo ? dateTo : endDate.toDate());
    };
  }, [dateFrom, dateTo, startDate, endDate, minDate, setDateFrom, setDateTo]);

  const maxValue = useMemo(() => {
    const date =
      maxDateAllowedForSelection ??
      moment().endOf('day').add(3, 'months').toDate();
    return parseDate(moment(date).format('YYYY-MM-DD'));
  }, [maxDateAllowedForSelection]);

  const minValue = useMemo(() => {
    const date = minDateAllowedForSelection ?? startDate;
    return parseDate(moment(date).format('YYYY-MM-DD'));
  }, [minDateAllowedForSelection, startDate]);

  return (
    <div className="flex flex-col gap-5 dark:bg-surface-dark dark:text-item-dark-contrast">
      <div className="flex justify-between p-3">
        <DateField
          value={parseDate(moment(dateFrom).format('YYYY-MM-DD'))}
          onChange={(val) => setDateFrom(new Date(val.toString()))}
          label="Start"
          minValue={parseDate(minDate.format('YYYY-MM-DD'))}
          maxValue={parseDate(
            moment(moment().endOf('day').add(3, 'months').toDate()).format(
              'YYYY-MM-DD'
            )
          )}
        />
        <div className="w-px bg-item-contrast" />
        <DateField
          value={parseDate(moment(dateTo).format('YYYY-MM-DD'))}
          onChange={(val) => setDateTo(new Date(val.toString()))}
          label="End"
          minValue={parseDate(moment(new Date()).format('YYYY-MM-DD'))}
          maxValue={
            maxDateAllowedForSelection
              ? parseDate(
                  moment(maxDateAllowedForSelection).format('YYYY-MM-DD')
                )
              : parseDate(
                  moment(
                    moment().endOf('day').add(3, 'months').toDate()
                  ).format('YYYY-MM-DD')
                )
          }
        />
      </div>

      <RangeCalendar
        highligtedDates={[]}
        value={{
          start: parseDate(moment(dateFrom).format('YYYY-MM-DD')),
          end: parseDate(moment(dateTo).format('YYYY-MM-DD')),
        }}
        onChange={(range) => {
          setDateFrom(new Date(range.start.toString()));
          setDateTo(new Date(range.end.toString()));
        }}
        minValue={minValue}
        maxValue={maxValue}
      />
    </div>
  );
};
