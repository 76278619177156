import React from 'react';

import { OperateProvider } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { TransferProgressProvider } from 'pages/ops/shared/context/TransferProgressProvider';

import Operate from './index';
import { TerminalSessionProvider } from 'pages/ops/RTI/Operate/context/TerminalSessionProvider';

function RTI() {
  return (
    <OperateProvider>
      <TransferProgressProvider>
        <TerminalSessionProvider>
          <Operate />
        </TerminalSessionProvider>
      </TransferProgressProvider>
    </OperateProvider>
  );
}

export default RTI;
