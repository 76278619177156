import { useRef, useState } from 'react';
import { UL, Button } from '@blueprintjs/core';
import type { PermissionType } from 'api/administration/permissions';
import s from './index.module.scss';
import AdministrationRole from './role';
import { IconNames } from '@blueprintjs/icons';
import { sortBy } from 'lodash';
import type {
  ICreateRoleAttachment,
  IRole,
  IRoleAttachment,
  IUpdateRole,
} from 'services/api/portal/administration/api/types';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import RoleAttachments from './RoleAttachments';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

interface IProps {
  title: string;
  rolesList: IRole[];
  resourceType: PermissionType;
  updateRole: (role: IUpdateRole) => Promise<void>;
  createRole: (role: IUpdateRole) => Promise<void>;
  getRoleAttachments: (role: IUpdateRole) => Promise<IRoleAttachment[]>;
  addRolesAttachment: (
    roleAttachment: ICreateRoleAttachment,
    parentRoleId: string
  ) => Promise<void>;
  getAvailableRolesByType: (type: PermissionType) => IRole[];
  deleteRolesAttachment: (role: IRoleAttachment) => Promise<void>;
}

const AdministrationRolesList = (props: IProps) => {
  const [isAddingNewRole, setAddingNewRole] = useState(false);
  const [selectedRoleAttachmentID, setSelectedRoleAttachmentID] = useState<
    number | null
  >(null);
  const { translate } = useLocalisation();
  const [roleAttachments, setRoleAttachments] = useState<IRoleAttachment[]>([]);
  const [selectedPermission, setSelectedPermission] =
    useState<PermissionType>();

  const [resourceId, setResourceId] = useState<string | number>('');

  const { hasPermission: isAllowedToWriteRole } = useCheckPermissions({
    permissions: { type: 'global', actionScope: 'role:write' },
  });

  const roleList = props.rolesList || [];

  const sortedRoleList = sortBy(roleList, 'id');

  const containerRef = useRef<HTMLDivElement>(null);

  const getRoleAttachmentsByID = async (role: IRole) => {
    const results = (await props.getRoleAttachments(role)) as IRoleAttachment[];
    setRoleAttachments(results);
  };

  return (
    <div>
      <span className={s.title}>{props.title}</span>
      <UL className={s.listContainer}>
        {sortedRoleList.map((role) => (
          <div ref={containerRef} key={role.id}>
            <AdministrationRole
              saveRole={(newRole: IUpdateRole) => {
                return props.updateRole({
                  ...newRole,
                  id: role.id,
                });
              }}
              isAllowedToWriteRole={Boolean(isAllowedToWriteRole)}
              role={role}
            />
            {props.resourceType === 'global' && (
              <>
                {selectedRoleAttachmentID === role.id ? (
                  <Button
                    className={s.addUserButton}
                    large
                    minimal
                    text={translate('rolesPage.closeAttachments')}
                    rightIcon={IconNames.ChevronUp}
                    onClick={() => setSelectedRoleAttachmentID(null)}
                  />
                ) : (
                  <Button
                    className={s.addUserButton}
                    large
                    minimal
                    text={translate('rolesPage.viewAttachments')}
                    rightIcon={IconNames.ChevronDown}
                    onClick={() => {
                      void getRoleAttachmentsByID(role);
                      setSelectedRoleAttachmentID(role.id);
                    }}
                  />
                )}
                {selectedRoleAttachmentID === role.id && (
                  <RoleAttachments
                    attachments={roleAttachments}
                    getAvailableRolesByType={props.getAvailableRolesByType}
                    setSelectedPermission={setSelectedPermission}
                    selectedPermission={selectedPermission}
                    resourceId={resourceId}
                    setResourceId={setResourceId}
                    assignRoleAttachment={async (roleId) => {
                      const postObj: ICreateRoleAttachment = {
                        parentResourceType: 'global',
                        parentResourceId: '',
                        childRoleId: roleId,
                        childResourceType: selectedPermission ?? '',
                        childResourceId: resourceId.toString(),
                      };

                      await props.addRolesAttachment(
                        postObj,
                        selectedRoleAttachmentID.toString()
                      );
                      void getRoleAttachmentsByID(role);
                    }}
                    deleteRoleAttachment={async (deleteRole) => {
                      await props.deleteRolesAttachment(deleteRole);
                      void getRoleAttachmentsByID(role);
                    }}
                  />
                )}
              </>
            )}
          </div>
        ))}
        {isAddingNewRole ? (
          <AdministrationRole
            key="new"
            initialEdit
            saveRole={async (role) => {
              const v = await props.createRole(role);
              setAddingNewRole(false);
              return v;
            }}
            isAllowedToWriteRole={Boolean(isAllowedToWriteRole)}
            role={{
              id: 0,
              name: 'New role',
              permissions: [],
              userAssignable: false,
              createdAt: undefined,
              description: '',
              resourceType: props.resourceType,
            }}
          />
        ) : (
          <Button
            className={s.addUserButton}
            large
            minimal
            text={'Add ' + props.title}
            rightIcon={IconNames.PLUS}
            onClick={() => setAddingNewRole(true)}
          />
        )}
      </UL>
    </div>
  );
};

export default AdministrationRolesList;
