export type SchedulingLayoutProps = {
  left: JSX.Element;
  right: JSX.Element;
  hideRight?: boolean;
  hideLeft?: boolean;
};

export const SchedulingLayout = ({
  left,
  right,
  hideLeft,
  hideRight,
}: SchedulingLayoutProps) => {
  if (hideLeft) {
    return (
      <div
        style={{
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
          overflow: 'auto',
        }}
        className="w-full bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast"
      >
        <div
          style={{
            height: 'calc(100vh - 70px)',
          }}
          className="bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast"
        >
          {right}
        </div>
      </div>
    );
  }

  if (hideRight) {
    return (
      <div
        style={{
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
          overflow: 'auto',
        }}
        className="w-full bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast"
      >
        <div
          className="bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast"
          style={{
            height: 'calc(100vh - 70px)',
          }}
        >
          {left}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 50px)',
        maxHeight: 'calc(100vh - 50px)',
        display: 'grid',
        gridTemplateColumns: '3fr 3fr',
      }}
      className="bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast"
    >
      <div
        className="bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast"
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
        }}
      >
        {left}
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
        }}
        className="bg-surface dark:bg-surface-dark dark:text-surface-dark-contrast "
      >
        {right}
      </div>
    </div>
  );
};
